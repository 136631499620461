.react-lesson-files {
  display: flex;
  flex-direction: row;
  .uploaded-files {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 100px;
    width: 100px;
    margin: 5px 5px;
    position: relative;
    &:hover {
      opacity: 0.7;
    }
    .upload-files-del {
      position: absolute;
      bottom: 5px;
      right: 26px;
      svg {
        &:hover {
          fill: red;
        }
      }
    }
    .upload-files-preview {
      position: absolute;
      bottom: 5px;
      left: 26px;
      svg {
        &:hover {
          fill: rgb(48, 47, 47);
        }
      }
    }
  }
}
