#rescheduler-form {
  background-color: white;
  .ant-checkbox-inner {
    border: 1px solid #555;
  }

  .ant-ribbon.ant-ribbon-placement-start {
    left: 22px;
    border-bottom-left-radius: 0;
    top: 0px;
  }
  .main-container {
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #e8e7e7;
  }
  .filter-container {
    .filters {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      align-items: center;
      margin: 10px 10px 50px 35px;
      .show-past-toggle {
        display: flex;
        gap: 10px;
        align-self: end;
        .toggle-label {
          height: 20px;
          width: 203px;
          color: #555;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .date-filter {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .date-label {
          height: 20px;
          width: 203px;
          color: #555;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .range-picker-filter {
          width: 300px;
        }
      }
      .filter-button {
        align-self: end;
      }
      .clear-button {
        border-radius: 5px;
        background-color: #fff;
        border: 0.8px solid #106682;
        color: #106682;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        gap: 5px;
      }
      .ant-btn[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        display: flex;
        gap: 5px;
        svg {
          path {
            fill: rgba(0, 0, 0, 0.25) !important;
          }
        }
      }
    }
    .action-button {
      display: flex;
      gap: 10px;
      margin: 0px 30px 10px 30px;
      align-items: center;
      .action-button-icon {
        margin-right: 5px;
      }
      Button {
        border-radius: 5px;
        background-color: #106682;
        color: white;
        display: flex;
        align-items: center;
      }
    }
    .hide-action-button {
      visibility: hidden;
      display: flex;
      gap: 10px;
      margin: 0px 30px 10px 30px;
      align-items: center;
      .action-button-icon {
        margin-right: 5px;
      }
      Button {
        border-radius: 5px;
        background-color: #106682;
        color: white;
        display: flex;
        align-items: center;
      }
    }
  }

  .rescheduler-table {
    margin: 10px;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    flex-direction: row;
  }

  .capitalize {
    text-transform: capitalize;
  }
  .reschedule-assignment-category {
    text-transform: capitalize;
    // text-align: center;
  }
  .rescheduler-actions {
    display: flex;
    gap: 20px;
    // justify-content: center;
    svg {
      &:hover {
        g {
          fill: red !important;
        }
      }
    }
  }

  .reschedule-icon {
    svg {
      &:hover {
        g {
          fill: #106682 !important;
        }
      }
    }
  }

  .reschedule-date-time {
    color: #999;
    font-family: Poppins;
    font-size: 12px;
    line-height: 20px;
  }
  .not-available {
    color: #888;
    font-style: italic;
  }

  .update-assignment-datepicker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .categories-options {
    text-transform: capitalize;
  }

  .reschedule-datepicker {
    width: 100%;
  }
}

.assignments-table {
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(16, 102, 130, 0.1) !important;
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: rgba(16, 102, 130, 0.25) !important;
  }
}
