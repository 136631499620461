//
// Collaborate Styles
// -------------------------------------------------
.content-container {
  // background-color: $content-outer-container-bg;
  padding-top: 10px;
}
.collaborate-layout {
  box-shadow: 0px 0px 2px 1px #c4c4c4;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: #fff;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  .page-data {
    overflow-y: auto;
    height: 100%;
  }
}

.collaborate {
  height: calc(100vh - 148px);
  .empty-sidebar {
    max-width: 244px;
    min-height: 370px;
    margin: 0 auto;
    background-image: url(../images/empty-states/budget-user.svg);
    background-size: contain;
    background-position: center center;
    .empty-title {
      font-weight: bold;
      padding-top: 135px;
      font-size: 13px;
    }
  }
  .chat-contacts-container {
    width: 30%;
    float: left;
    height: 100%;
  }
  .new-message-count {
    @extend .round;
    @extend .text-center;
    position: absolute;
    right: 8px;
    width: 20px;
    height: 20px;
    padding-top: 4px;
    font-size: 12px;
    line-height: 1;
    background-color: $primary-color;
    color: #fff;
  }

  .group-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
    font-weight: 500;
  }
  .unseen-chat {
    color: $primary-color !important;
  }
}

.collaborate-title {
  padding: 14px;
  margin-left: 15px;
  border-bottom: 1px solid $gray;
  min-height: 55px;
  background-color: $white;
  //margin-top: -9px;
  position: fixed;
  top: 74px;
  height: 54px;
  width: calc(100% - 260px);
  z-index: 10;
  // border-top-left-radius: 20px;
  box-shadow: 0 8px 6px -6px #00000038;
  h4 {
    flex-basis: 275px;
  }
  .chat-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    fill: #3cb21f;
  }
  .messenger-icon {
    width: 25px;
    height: 26px;
    margin-right: 10px;
  }
  .search-container {
    position: relative;
    width: 70%;
    text-align: -webkit-center;
    flex: 1 1 center;
    top: 0px !important;
    > .btn {
      flex-basis: 100px;
    }
  }
  .icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
  }
  .form-control {
    position: relative;
    z-index: 3;
    //padding-left: 35px;
    //background-color: $inputBgColor;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    height: 34px;
  }
}

.create-chat {
  padding: 30px;
  .actionbar {
    @extend .text-right;
    margin-top: 20px;
  }
  .person {
    padding: 10px 0;
    cursor: pointer;
    user-select: none;
    border-top: 1px solid $gray;
    .avatar {
      margin: 0 10px 0 20px;
    }
  }
  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 0 auto;
    background-image: url(../images/empty-states/connect-n-collabrate.svg);
    background-size: contain;
    background-position: center center;
    .empty-title {
      max-width: 600px;
      margin: 0 auto 40px;
    }
  }
}

.create-chat-title {
  margin-top: 30px;
  padding-bottom: 15px;
}

.collaborate-layout {
  overflow: hidden;
}

.chatbox {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .users-detail {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    background: rgba(256, 256, 256, 0.85);
    z-index: 8001;
    //display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.show-user {
      display: flex !important;

      height: 100%;
    }
    &.ng-animate {
      transition: 2.5s linear all;
      opacity: 0;
    }
    &.show-user-add-active {
      opacity: 1;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .user-img {
        height: 278px;
        width: 278px;
        border-radius: 10px;
      }
      .user-title {
        margin-top: 18px;
        color: $primary-color;
        font-size: 26px;
        line-height: 34px;
      }
      .user-city {
        margin-top: 10px;
        opacity: 0.75;
        color: #353334;
        font-size: 16px;
        line-height: 22px;
      }
      .user-country {
        opacity: 0.6;
        color: #353334;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}

.chatbox-options {
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill: $darker-gray;
  }
}
.show-start {
  justify-content: flex-end;
}
.chatbox-title {
  padding: 6.5px 12.5px;
  background-color: #ffffff;
  border-bottom: 1px solid $border-color;
  align-items: flex-end;
  justify-content: space-between;
  //justify-content: flex-end;
  .person {
    align-self: flex-start !important;
    //position: absolute !important;
    margin-left: 10px !important;
  }
}

.chatbox-group-title {
  padding: 6px 12.5px;
  background-color: #ffffff;
  border-bottom: 1px solid $border-color;
  align-items: flex-end;
  .person {
    align-self: flex-start !important;
    position: absolute !important;
    left: 10px !important;
  }
}

.chatbox-messages {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px 20px 0;
  .seprator {
    @extend .text-center;
    margin: 20px 0;
    padding-top: 20px;
    height: 50px;
  }

  .line {
    border: 0;
    border-top: 1px solid $gray;
  }

  .seprator-label {
    @extend .text-uppercase;
    font-size: 12px;
    display: inline-block;
    padding: 11px 20px;
    font-weight: bold;
    background-color: #fff;
    position: relative;
    top: -28px;
  }

  &.blocked {
    background: #f9f9f9;
    .seprator-label {
      background: #f9f9f9;
    }
  }
}

.message {
  margin-bottom: 20px;

  a {
    color: #116682 !important;
  }

  .message-dropdown {
    position: absolute;
    top: 6px;
    right: 3px;
    display: none;

    .icon {
      width: 24px;
      height: 24px;
      fill: white;
    }

    .dropdown-menu {
      min-width: 100px;
      top: 75%;
      box-shadow: 0px 2px 8px 0 #9e9e9e;
    }
  }

  .message-info:hover {
    .message-dropdown {
      display: block;
      color: #ffffff;
    }
  }

  &.media {
    .message-info {
      padding: 0 !important;
      border-radius: 15px !important;
    }
    .hsp-files {
      margin: 0 !important;
      box-shadow: unset !important;
      outline-color: unset !important;
      outline-style: unset !important;
      .view-actionbar {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 12px;
        span {
          margin-top: 5px !important;
        }
        .dropdown-menu {
          span {
            color: black;
          }
        }
      }
    }
  }
  &.me {
    flex-direction: row-reverse;
    .message-meta {
    }
    .message-avatar {
      display: none;
      margin-right: 0;
      margin-left: 10px;
    }
    .message-info {
      margin-bottom: 20px;
      background-color: $primary-color;
      color: #ffffff;
      border-radius: 30px;
      padding: 5px 25px 5px;
    }
    .message-time {
      right: 40px;
    }
    a {
      //color: $white !important;
    }
  }

  .message-avatar {
    @extend .avatar;
    margin-right: 10px;
  }
  .message-info {
    margin-bottom: 20px;
    position: relative;
    margin-left: -38px;
    margin-top: 5px;
    min-width: 150px;
    max-width: 500px;
    padding: 5px 20px 5px;
    border-radius: 30px;
    background-color: lighten($gray, 3);
    &:before {
      display: none;
      position: absolute;
      left: -14px;
      top: 0;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 16px 16px 0;
      border-color: transparent lighten($gray, 3) transparent transparent;
    }
  }

  .message-meta {
    display: none;
    line-height: 1;
    margin-bottom: 10px;
    time {
      font-size: 70%;
      font-weight: normal;
    }
  }
  .message-content {
    max-width: 100%;
    word-wrap: break-word;
    font-size: 14px;
  }
  .message-time {
    color: $darker-gray;
    position: relative;
    bottom: 14px;
    right: 4px;
    font-size: 10px;
    line-height: 1;
  }
}

.chatbox-footer {
  padding: 10px;
  background-color: $gray;

  .text-container {
    position: relative;
    left: 0;
    right: 0;
    margin-right: 10px;
    width: 100%;
    max-width: 1230px;
    .message-input {
      border-radius: 30px !important;
      border-radius: 30px !important;
      padding-left: 11px;
      height: 40px;
      font-size: 13px;
    }
    > textarea {
      resize: none;
      min-height: 37px;
    }

    .emoji-wysiwyg-editor {
      //overflow-y: hidden;
      line-height: 25px;
      padding-right: 50px;
      margin-left: 30px;

      width: 98%;
      word-break: break-all;
      white-space: normal;
      margin-bottom: 15px;
      &::-webkit-scrollbar {
      }
      &::-webkit-input-placeholder {
        line-height: normal;
      }
    }

    .emoji-picker-icon.emoji-picker {
      left: -10px;
    }
  }
}

.collaborate-search {
  padding: 30px;
  .search-info {
    margin-bottom: 20px;
    font-weight: 300;
  }
  .person {
    padding: 10px 0;
  }
  .search-results td {
    width: 1000px;
    padding: 0;
    border-spacing: 0;
    border-bottom: 1px solid $gray;
  }
  .icon {
    margin-right: 8px;
  }
  .btn-invitation-sent {
    background-color: #dad8d9 !important;
    border-color: #dad8d9 !important;
    color: black !important;
    width: 157px;
    .btn-icon {
      fill: #000;
    }
  }
}

.overlay-check {
  height: 200px;
  width: 200px;
}

.gif-menu {
  padding: 8px 0;

  min-height: 100px;
  min-width: 254px;
  max-width: 254px;
  overflow-y: auto;

  > * {
    padding: 0 8px;
  }

  .form-group.gif-input {
    .form-control {
      background: #eee;
      border: 0;
    }
  }

  .gifs-data {
    max-height: 350px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.2em;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }

    img {
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}

.chat-unread-indicator {
  position: absolute;
  left: 50%;
  bottom: 90px;
  transform: translateX(-50%);
  display: inline-block;
  font-size: 0;
  line-height: 0;
  border: 1px solid $light-gray;
  border-radius: 50%;
}

.chat-unread-icon {
  width: 60px;
  height: 60px;
}

.round-search-button {
  border-top-right-radius: 19px !important;
  border-bottom-right-radius: 19px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 500;
  background-color: $primary-color !important;
}
.round-left-button {
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 500;
}
.chat-title-name {
  font-size: 18px;
  //text-transform: capitalize;
  color: #000000;
  font-weight: 500;
  margin-left: 10px;
}
.group-chat {
  max-width: 150px;
  //white-space: nowrap;
  color: $primary-color;
}

.send-message {
  position: absolute !important;
  right: 17px !important;
  border-radius: 50%;
  padding: 2px;
  height: 30px;
  width: 30px;
  bottom: 30px;
  z-index: 10000;
}

.send-message-icon {
  height: 25px;
  width: 25px;
  fill: $white;
}
.search-chats-container {
  margin: 10px 10px;
  .search-chat-input {
    &input {
      padding-left: 30px !important;
      padding-top: 9px important;
      background: #ccc;
    }
    .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 5;
    }
  }
}
.contact-search {
  padding-left: 32px !important;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}
.custom-searchbtn {
  padding-bottom: 6px !important;
}
.attachment-icon {
  width: 22px !important;
  height: 22px !important;
  fill: #919498;
  margin-right: -7px;
  margin-bottom: -1px;
}
.rounded-left-input {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.page-with-sidebar .collaborate {
  flex-direction: row !important;
  .page-data {
    min-height: unset;
    height: unset;
  }
}
@media (max-width: 768px) {
  .text-word-break {
    max-width: 150px !important;
  }
  .collaborate .chat-contacts-container {
    width: 100%;
    float: none;
  }
  .page-with-sidebar .collaborate {
    flex-direction: column !important;
  }
  .send-message {
    padding: 0 !important;
    margin-right: 5px;
    right: 20px !important;
    bottom: unset;
  }

  .sidebar-date {
    min-height: auto !important;
    height: auto !important;
    padding-bottom: 20px;
  }
  .collaborate-layout .page-data {
    background: white;
  }
}

.chat-sidenav {
  border-bottom: 1px solid #e8e7e7;
  .nav.nav-tabs {
    padding-left: 0;
    width: 100%;
    list-style: none;
    li {
      width: 33.335%;
      display: inline-block;
      a {
        text-align: center;
        display: block;
        color: #767676;
        padding: 10px 0px;
        &.active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .search-container {
    width: 100%;
    .icon {
      position: absolute;
      top: 14px;
      right: 15px;
    }
    .form-control {
      border-radius: 25px;
      padding-right: 30px;
      margin: 5px;
    }
  }
}

.group-info-modal {
  .top-title {
    text-align: center;
  }

  .text-red {
    color: red;
  }

  .panda-group-members {
    .media {
      overflow: visible;
      border-bottom: 1px solid #f1f1f1;
      padding-top: 10px;
      padding-bottom: 10px;
      .media-body {
        width: 100%;
        position: relative;
        overflow: visible;

        .btn-primary {
          position: absolute;
          right: 20px;
          top: 8px;
        }

        .more-options {
          position: absolute;
          right: 0px;
          top: 12px;
        }
      }
    }
  }

  .outer-box {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;

    img {
      width: 300px;
      height: auto;
    }

    .inner-box {
      background: #000;
      height: 30%;
      width: 100%;
      opacity: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      padding: 0;
      transition: opacity 0.5s;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon {
        fill: #d5d1d2;
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      .inner-box {
        opacity: 0.5;
        transition: opacity 0.5s;
      }
    }
  }
}

.view-actionbar {
  overflow: visible !important;
}

.message.media {
  overflow: visible !important;
}

textarea[ui-mention],
textarea[ui-mention] + * {
  line-height: 1em;
  font-size: 1rem;
  padding: 5px;
  border: 1px;
  font-family: helvetica;
  font-weight: normal;
}

textarea[ui-mention] {
  // box-sizing: content-box;
  min-height: 100px;
  width: 100%;
  display: block;
  border: 1px solid;
  z-index: 2;
  position: relative;
  &[ui-mention] {
    background: rgba(0, 0, 0, 0);
  }
}
.mention-container {
  position: relative;
}
.mention-highlight {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px;
  padding: 2px;
  z-index: 1;
  color: rgba(0, 0, 0, 0);
  white-space: pre-wrap;
  border: 1px solid rgba(0, 0, 0, 0);
  width: 100%;

  span {
    border-radius: 3px;
    background: lightblue;
    box-shadow: 0px 0px 0px 1px blue;
  }
}
.emoji-wysiwyg-editor {
  position: relative;
  z-index: 1000;
}
//New Message
.message.me .message-info {
  border-radius: 10px;
  background-color: rgba(16, 102, 130, 0.1);
  color: #555;
  padding: 10px;
}
.message .message-info {
  border-radius: 10px;
  color: #555;
  padding: 10px;
}

.message {
  .reply_message {
    border-radius: 10px;
    padding: 10px 15px;
    background-color: rgba(85, 85, 85, 0.18);
    border-right: 4px solid #999;
    margin-bottom: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    .reply_title {
      font-size: 13px;
      font-weight: 600;
    }
    .reply_body {
      font-size: 13px;
    }
  }
  &.me {
    .reply_message {
      border-right: 0;
      border-left: 4px solid #999;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .hsp-files img {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.other-folder {
  p {
    font-size: 12px;
    padding: 10px;
  }
}
