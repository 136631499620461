//
// Sass variables
// --------------------------------------------------
$primary-font: "Poppins", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
	Roboto, Ubuntu, "Emoji One", "AppleColorEmoji", sans-serif;
$heading-font: "Poppins", "Nunito", -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, Ubuntu, sans-serif;
// $primary-color: #9aca40;
$primary-color: #116682;
// $primary-color: #119090;
// $primary-light-color: #3cb21f;
$dark-primary-color: #0d435b;
$active-link: #a7d382;
$primary-light-color: #116682;
$content-outer-container-bg: #e8e7e7;
$border-color: #efeded;
$login-bg: #262833;
$sidebar-color: #116682;
// $sidebar-color: #2e343e;
$inputBgColor: #eae8e8;
$content-container-bg: #e8e7e7;
$light-gray: #e8e7e7;
$lighter-gray: #d5d6d7;
$gray: #e9eaeb;
$dark-gray: #edeeee;
$darker-gray: #919498;
$white: #fff;
$black: #000;
$silver: #c8c9cb;
$error-red: #f80505;
$blue: #006fe0;
$brand-success: #32b67a;
$brand-danger: #5fdefe;
$primary-font-color: #444850;
$secondary-font-color: $darker-gray;
$status-red: $brand-danger;
$status-gray: $darker-gray;
$status-green: $brand-success;
$status-yellow: #f6d62e;
$default-font-color: #555;

$sidebar-width: 256px;
