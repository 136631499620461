.icon-panda-post {
  width: 25px;
  height: 25px;
  fill: #555;
  //position: relative;
  //top: 6px;
}
.feed-page {
  display: flex;
  flex-direction: row;
  padding: 15px 15px;
  margin-top: 60px;
  .main-feed {
    //width: 614px;
    border-radius: 0px;
    box-shadow: 0 0 0 0 #e2e5e8;
    padding: 0px !important;
    // background-color: #F7F9FC !important;
  }
  .feed-list {
    min-height: 70vh;
    //border-radius: 25px;
    // background: #ffffff;
    //box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
    padding: 0px 10px;
    width: 58%;
    // margin-top: 60px;
    .feed-container {
      //max-width: 550px;
      margin: 10px auto;

      .group-div {
        .add-ellipsis {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        //width:100%;
        h3 {
          margin-top: 15px;
          color: black;
          text-align: center;
        }
        h4 {
          margin-top: 0;
          color: black;
          cursor: pointer;
          max-width: 215px;
          word-break: break-word;
          &:hover {
            text-decoration: underline;
          }
        }
        .desc-title {
          font-weight: 500;
          color: #ababab;
        }
        .desc-p {
          font-size: 13px;
          color: #555;
        }
        .wrapped-text {
          //display: -webkit-box;
          line-height: 1.5;
          //max-height: 42px;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
        .show-more {
          color: $primary-color;
          text-decoration: underline;
        }
        .group-info {
          text-align: center;
          font-size: 14px;
          //margin-top: 5px;
          span {
            text-transform: uppercase;
          }
          .invite-link {
            text-transform: capitalize;
            color: $primary-color;
            cursor: pointer;
            margin-left: 5px;
            font-weight: 500;
          }
        }

        .icon-privacy {
          // border: 1px solid #908f8f;
          // border-radius: 25px;
          width: 22px;
          height: 22px;
          padding: 2px;
          margin-right: 5px;
        }
        .group-privacy {
          display: flex;
          line-height: 26px;
          font-size: 14px;
          text-transform: uppercase;
          //margin-bottom: 5px;
        }
      }

      .empty-state {
        max-width: 760px;
        min-height: 440px;
        margin: 25px auto;
        background-image: url(../images/empty-states/lesson-planner.svg);
        background-position: center center;
        .empty-title {
          margin: 0 10px 40px;
        }
      }
      .loading-state {
        max-width: 760px;
        min-height: 200px;
        margin: 50px auto;
        z-index: 5;
        line-height: 1;
        .spinner {
          margin-right: 10px;
        }
      }
    }

    .group-media {
      .media-object {
        width: 170px !important;
        height: 170px !important;
      }
      .group-div {
        padding-left: 20px;
        padding-bottom: 10px;
        padding-right: 5px;
        word-break: break-word;
        .group-info {
          text-align: left;
        }
      }
      .outer-box {
        display: block;
        position: relative;

        img {
          width: 300px;
          height: auto;
        }

        .inner-box {
          background: #000;
          height: 30%;
          width: 100%;
          opacity: 0;
          bottom: 0;
          left: 0;
          position: absolute;
          padding: 0;
          transition: opacity 0.5s;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .icon {
            fill: #d5d1d2;
            width: 20px;
            height: 20px;
          }
        }

        &:hover {
          .inner-box {
            opacity: 0.5;
            transition: opacity 0.5s;
          }
        }
      }
    }

    .feed-page-title {
      padding: 15px;
      position: relative;
      padding-top: 15px;
      margin: 0 auto;
      max-width: 1080px;
      border-bottom: 1px solid #e9eaeb;
    }

    .feed-add {
      border-radius: 15px;
      background-color: #fff;
      box-shadow: 0 2px 2px 0 #e2e5e8;
      margin-top: 0px;
      padding: 0;
      .feed-content {
        .emoji-picker-icon {
          //top: 54px !important;
          //margin-left: 20px;
        }
      }
    }

    .feed-content {
      margin: 0 2%;
      padding: 0;
      width: 95.75%;

      img {
        margin: 1rem 0;
        padding: 0;
      }

      .textarea_wrap {
        //display: -webkit-box;
        border-radius: 15px;
        background-color: #eef0f4;
        margin-left: 12px;
        padding-left: 20px;
        cursor: text;
        width: 90%;
        position: relative;
      }

      textarea {
        border: 0;
        //margin: 0rem 0 0.5rem 0;
        outline: 0;
        padding: 1rem 0 1rem 1.25rem;
        resize: none;
        font-size: 14px;
        width: 100%;
        font-family: inherit;
      }

      .user-avatar {
        @extend .round;
        display: block;
        width: 50px;
        height: 50px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        // margin: 0 15px 0px 0px;
      }

      .emoji-picker-icon {
        position: relative !important;
        //.right: -22px !important;
        left: -33px;
        top: 70px !important;
        outline: none;
        z-index: unset;
        padding-top: 5px;
      }
      .emoji-menu {
        position: relative !important;
        left: 0;
        bottom: -68px !important;
        // z-index: 99;
        //overflow: unset;
      }
      .emoji-wysiwyg-editor {
        //min-height: 0px !important;
        //height: 0px !important;
        // width: inherit;
        border: unset !important;
        z-index: 0;
        //margin-top: 20px;
        outline: none;
        overflow-y: hidden !important;
        max-height: unset !important ;
      }
    }

    .feed-footer {
      border-top: solid #e8e8e8 1px;
      line-height: 3rem;
      padding: 0 2% 0 3%;
      justify-content: space-between;
      align-items: center;

      .navbar-nav {
        padding: 0;

        li {
          display: inline-block;
        }
        a {
          display: block;
          padding: 2rem 1.25rem 2.2rem 1.25rem;
        }
        .glyphicon {
          line-height: 0;
        }
      }

      div {
        padding: 0;
        text-align: right;
      }
    }

    .post-button {
      padding: 6px 10px;
      width: 82px;
      float: right;
      margin: 10px;
      margin-left: 20px;
    }

    .loading-state {
      max-width: 760px;
      min-height: 200px;
      margin: 50px auto;
      z-index: 5;
      line-height: 1;
      .spinner {
        margin-right: 10px;
      }
    }

    .panel {
      box-shadow: 0 2px 2px 0 #e2e5e8;
      background: #fff;
      border-radius: 15px;
      margin-top: 25px;
    }
    .panel-heading {
      display: flex;
      flex-grow: 1;
      background: none;
      justify-content: space-between;
      padding-left: 10px;
      padding-top: 9px;
      padding-bottom: 12px;
      border-bottom: 1px solid $gray;
      .strong {
        font-weight: bold;
      }
      .buttons {
        display: flex;
        .astext {
          background: none;
          border: none;
          font-size: x-small;
          color: grey;
          margin-right: 30px;
        }
      }
      .user-avatar {
        @extend .round;
        display: block;
        width: 50px;
        height: 50px;
        margin: 0 15px 0px 0px;
        border-radius: 5px;
        object-fit: cover;
      }

      .more-options-icon {
        margin-right: 16px !important;
      }
    }
    .panel-body {
      display: flex;
      flex-direction: column;

      .slider-content {
        min-width: unset;
        margin: unset;
        .mediaViewShow {
          width: 100%;
        }
        .banner-media {
          // max-width: 550px;
          height: 200px;
          object-fit: cover;
        }
      }
      .feed-action {
        padding: 10px;
      }
      .description-view {
        height: inherit;
        align-items: center;
        justify-content: center;
        display: flex;
        //min-height: 356px;
        //padding: 10px 55px;
        background: #fff;
        p {
          text-align: center;
          display: -webkit-box;
          font-size: 17px;
          -webkit-line-clamp: 5;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }

      .file-card {
        padding: 12px 18px;
        background-color: #f4f2f3;
        display: flex;
        justify-content: space-between;
        .name {
          display: flex;
          align-items: center;
          font-size: 15px;
          .more-options-icon {
            transform: rotate(-145deg);
            fill: #000000;
            width: 28px;
            height: 28px;
            margin-right: 10px !important;
          }
          span {
            //width: calc(100% - 70px);
            word-break: break-word;
          }
        }
        .icon-download {
          fill: #000;
        }
      }
      .firstrow {
        // padding: 6px;
        padding-left: 10px;
        padding-bottom: 10px;
        display: flex;
        .firstpar {
          padding-right: 15px;
          width: 100px;
          color: grey;
          position: relative;
          &:after {
            content: ':';
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .d-flex {
          display: inline-block;
        }
        .d-flex span:first-child {
          float: left;
          margin-right: 10px;
        }
        .feed-owner {
          font-weight: bold;
          font-size: 14px;
          color: $black;
          padding-right: 10px;
        }
        .content {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 14px;
          color: $black;
          font-weight: normal;
        }
        .feed-see-more {
          font-weight: normal;
          font-size: 13px;
          color: #116682;
        }
        //.feed-see-more:hover{
        //	text-decoration: underline;
        //}
        .wrapped-text {
          display: -webkit-box;
          line-height: 1.5;
          max-height: 42px;
          //max-width: 360px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .view-comment {
        color: grey;
      }
    }

    .panel-comment {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      border-top: solid #e8e8e8 1px;
      // padding: 10px;
      margin-top: 10px;

      .send-icon-custom {
        fill: $primary-color;
      }
      .send-btn-custom {
        background-color: unset !important;
        align-self: flex-end;
        margin-bottom: 5px;
      }
      .fill-width-comment {
        position: relative;
        left: 0;
        right: 0;
        margin-right: 10px;
        width: 100%;
        .emoji-picker-icon {
          top: 4px;
        }
      }
      .comment-box {
        //margin-left: 40px;
        width: calc(100% - 40px);
        border: none;
        box-shadow: none;
        outline: none;
        font-size: 13px;
        font-family: inherit;
        height: 35px;
        padding: 10px;
        overflow-y: hidden;
        resize: none;
        border-bottom-left-radius: 10px;
        -webkit-transition: height 0.1s ease;
        -moz-transition: height 0.1s ease;
        -ms-transition: height 0.1s ease;
        -o-transition: height 0.1s ease;
        transition: height 0.1s ease;
      }
      .icon-send-button {
        fill: $primary-color;
      }
    }
  }

  .feed-right-list {
    position: relative !important;
    display: flex;
    //width: 250px;
    border-radius: 15px;
    box-shadow: 0 0 2px 0 #e2e5e8;
    background: #ffffff;
    height: fit-content;
    min-height: 184px;
    padding: 20px 15px 15px 15px;
    position: sticky;
    position: -webkit-sticky;
    &.margin-left {
      margin-left: 10px;
    }
    // margin-top: 60px;

    // .feed-page-title {
    // 	h4 {
    // 		font-size: 16px;
    // 	}
    // 	.create-group {
    // 		cursor: pointer;
    // 		padding-left: 10px;
    // 		.icon {
    // 			fill: $primary-color;
    // 			width: 20px;
    // 			height: 20px;
    // 			margin-bottom: -6px;
    // 			margin-left: 3px;
    // 		}
    // 	}
    // }
    .panel {
      .row {
        margin-top: 15px !important;
        position: relative;
        .col-md-4 {
          width: 45px;
          img {
            height: 45px;
            width: 45px;
            object-fit: cover;
            object-position: center;
          }
        }
        .col-md-6 {
          //padding-left:0;
          width: calc(100% - 65px);
        }
      }
    }
    .high-text {
      display: inline-block;
      padding-bottom: 10px;
      font-size: 15px;
      color: black;
      cursor: pointer;
      p {
        color: #555555;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        width: 100%;
      }
      .line-clamp {
        font-size: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        word-break: break-word;
        height: 30px;
        line-height: 15px;
        margin-top: -2px;
      }
    }
    .like-panel {
      position: absolute;
      bottom: 0;
      left: 55px;
      padding-left: 5px;
      padding-right: 0;
      padding-top: 8px;
      height: 25px;
      line-height: 25px;
      width: calc(100% - 75px);
      .icon {
        width: 14px;
        height: 14px;
      }
      span {
        display: inline-block;
        margin-bottom: 4px;
        vertical-align: middle;
        line-height: normal;
        margin-left: 10px;
      }
    }
    .connect-div {
      .contact-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: calc(100% - 75px);
        overflow-y: auto;
        padding-top: 4px;
        .media {
          margin-right: 15px;
          margin-bottom: 15px;
          position: relative;
          .user-status {
            right: -5px !important;
            top: -4px !important;
            left: unset;
            width: 10px;
            height: 10px;
          }
          .dot {
            width: 10px;
            height: 10px;
            position: absolute;
            background: #37b54a;
            top: 0;
            right: -4px;
            border-radius: 50%;
          }
          .img-circle {
            border-radius: 5px;
            width: 45px;
            height: 45px;
          }
        }
      }
      .connect-height {
        height: calc(100% - 25px);
        //overflow-y: auto;
        .contact-container1 {
          //display: flex;
          //flex-wrap: wrap;
          //padding: 5px;
          height: 100%;

          .item {
            margin: 8px;
            width: 45px;
            height: 45px;
            position: relative;
            .user-status {
              right: -5px !important;
              top: -4px !important;
              left: unset;
              width: 10px;
              height: 10px;
            }
            .img-circle {
              border-radius: 5px;
              width: 45px;
              height: 45px;
            }
          }

          .media {
            overflow: hidden;
            zoom: 1;
            padding-top: 10px;
            .media-left {
              display: table-cell;
              vertical-align: top;
              position: relative;
              margin-right: 10px;
              a {
                .user-status {
                  right: -5px !important;
                  top: -4px !important;
                  left: unset;
                  width: 10px;
                  height: 10px;
                }
              }
              .media-object {
                display: block;
                width: 45px;
                height: 45px;
                min-width: 45px;
                border-radius: 5px;
                object-fit: cover;
              }
            }
            .media-body {
              display: table-cell;
              vertical-align: middle;
              overflow: hidden;
              zoom: 1;
              padding-left: 10px;
              .media-heading {
                margin-top: 0;
                margin-bottom: 2px;
                font-size: 13px;
                color: $primary-color;
                word-break: break-word;
                padding-right: 5px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
              }
              .media-detail {
                font-size: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                span {
                  color: $primary-color;
                  padding-left: 2px;
                }
              }
            }
            .show-more {
              span {
                color: $primary-color;
                font-size: 11px;
              }
              .icon-show-more {
                fill: $primary-color;
                width: 15px;
                height: 10px;
                margin-right: 7px;
              }
            }
          }
        }
      }

      .show-more {
        margin-top: 0;
      }
    }

    .connect-right-list {
      top: 50%;
    }
    .connect-right {
      height: 32% !important;
      top: 41%;
      .connect-height {
        height: calc(100% - 40px);
      }
    }
    .show-more {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      color: #999;
      font-size: 13px;
      .icon-show-more {
        fill: $primary-color;
        width: 15.422px;
        height: 15px;
        margin-right: 7px;
      }
    }

    .follow-div {
      position: absolute;
      top: 74%;
      h4 {
        font-size: 16px;
      }
      .follow-list {
        .media {
          overflow: hidden;
          zoom: 1;
          margin-top: 10px;
          .media-left {
            display: table-cell;
            vertical-align: top;
            .media-object {
              display: block;
              width: 45px;
              height: 45px;
              min-width: 45px;
              border-radius: 5px;
              object-fit: cover;
            }
          }
          .media-body {
            display: table-cell;
            vertical-align: middle;
            overflow: hidden;
            zoom: 1;
            padding-left: 10px;
            .media-heading {
              margin-top: 0;
              margin-bottom: 2px;
              font-size: 13px;
              color: $primary-color;
              word-break: break-word;
              padding-right: 5px;
              font-weight: 500;
            }
            .media-detail {
              font-size: 12px;
              span {
                color: $primary-color;
                padding-left: 2px;
              }
            }
          }
          .show-more {
            margin-top: 0px !important;
            span {
              color: $primary-color;
              font-size: 11px;
            }
            .icon-show-more {
              fill: $primary-color;
              width: 15px;
              height: 10px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }

  .feed-left-list {
    display: flex;
    //width: 250px;
    border-radius: 15px;
    box-shadow: 0 0 2px 0 #e2e5e8;
    background: #ffffff;
    height: fit-content;
    min-height: 176px;
    margin-right: 10px;
    position: sticky !important;
    top: 75px;
    width: 20%;
  }

  // .feed-left-list {
  // 	margin-left: 0;
  // 	margin-right: 10px;
  // 	padding: 15px 15px;
  // 	justify-content: space-between;
  // 	// margin-top: 60px;
  // 	.comingsoon {
  // 		position: absolute;
  // 		top: 30%;
  // 		left: 0;
  // 		z-index: 10;
  // 		max-width: 100%;
  // 		width: 100%;
  // 		//margin-left: -15px;
  // 		text-align: center;
  // 		img {
  // 			width: 110px;
  // 		}
  // 		p {
  // 			color: $primary-color;
  // 		}
  // 		h4 {
  // 			color: $primary-color;
  // 			font-weight: 600;
  // 		}
  // 	}
  // 	.connect-div {
  // 		//position: unset;
  // 		//top: unset;
  // 	}
  // 	.opacity {
  // 		opacity: 0.4;
  // 	}
  // 	.coming-div {
  // 		position: relative;
  // 		margin-top: 15px;
  // 		min-height: 44%;
  // 		.comingsoon {
  // 			position: absolute;
  // 			margin-left: 0px;
  // 			top: 0;
  // 			width: 100%;
  // 			height: 100%;
  // 			text-align: center;
  // 			background: rgba(255, 255, 255, 0.52);
  // 			.btn-block {
  // 				position: absolute;
  // 				width: 100%;
  // 				top: 45%;
  // 				left: 0;
  // 				color: $white;
  // 				background: $primary-color;
  // 			}
  // 		}
  // 	}
  // 	.media {
  // 		overflow: hidden;
  // 		zoom: 1;
  // 		margin-top: 10px;
  // 		.media-left {
  // 			display: table-cell;
  // 			vertical-align: top;
  // 			.media-object {
  // 				display: block;
  // 				width: 45px;
  // 				height: 45px;
  // 				min-width: 45px;
  // 				border-radius: 5px;
  // 				object-fit: cover;
  // 			}
  // 		}
  // 		.media-body {
  // 			display: table-cell;
  // 			vertical-align: middle;
  // 			overflow: hidden;
  // 			zoom: 1;
  // 			padding-left: 10px;
  // 			.media-heading {
  // 				margin-top: 0;
  // 				margin-bottom: 2px;
  // 				font-size: 13px;
  // 				color: $primary-color;
  // 				word-break: break-word;
  // 				padding-right: 5px;
  // 				font-weight: 500;
  // 			}
  // 			.line-clamp {
  // 				display: -webkit-box;
  // 				-webkit-line-clamp: 1;
  // 				text-overflow: ellipsis;
  // 				word-break: break-word;
  // 				height: 15px;
  // 				overflow: hidden;
  // 				line-height: 15px;
  // 				-webkit-box-orient: vertical;
  // 			}
  // 			.media-detail {
  // 				font-size: 12px;
  // 				span {
  // 					color: $primary-color;
  // 					padding-left: 2px;
  // 				}
  // 			}
  // 		}
  // 		.create-group {
  // 			span {
  // 				font-size: 11px;
  // 				color: #555555;
  // 				padding-bottom: 2px;
  // 			}
  // 		}
  // 		.show-more {
  // 			span {
  // 				color: $primary-color;
  // 				font-size: 11px;
  // 			}
  // 			.icon-show-more {
  // 				fill: $primary-color;
  // 				width: 15px;
  // 				height: 10px;
  // 				margin-right: 7px;
  // 			}
  // 		}
  // 	}
  // 	.events {
  // 		.media-left {
  // 			vertical-align: bottom;
  // 		}
  // 		.media-body {
  // 			vertical-align: bottom;
  // 			.media-heading {
  // 				height: 14px;
  // 				line-height: 14px;
  // 				font-size: 12px;
  // 				word-break: break-word;
  // 				padding-right: 5px;
  // 				font-weight: 500;
  // 				display: -webkit-box;
  // 				-webkit-line-clamp: 1;
  // 				overflow: hidden;
  // 				text-overflow: ellipsis;
  // 				-webkit-box-orient: vertical;
  // 			}
  // 			.media-detail {
  // 				-webkit-line-clamp: 1;
  // 				text-overflow: ellipsis !important;
  // 				word-break: break-word;
  // 				display: -webkit-box;
  // 				line-height: 15px;
  // 				overflow: hidden;
  // 				-webkit-box-orient: vertical;
  // 				margin-top: 2px;
  // 				font-size: 11px;
  // 				height: 15px;
  // 				.icon {
  // 					width: 10px;
  // 					height: 10px;
  // 					margin-right: 2px;
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  .card-load-more {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 15px;
    justify-content: center;
  }

  .hsp-files {
    margin: 10px;
    width: 100px;
    height: 100px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    outline-color: $darker-gray;
    outline-width: thin;
    outline-style: inset;
    position: relative;
    .view-image {
      position: relative;
      overflow: hidden;
      width: 100px;
      height: 100px;
      cursor: pointer;

      .play-icon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(50, 50, 50, 0.3);
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        .icon {
          fill: #fff;
          width: 40px;
          height: 40px;
        }
      }
    }
    img {
      min-width: 100px;
      min-height: 100px;
      width: auto;
      height: auto;
    }
    .close-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      transform: rotate(45deg);
    }
    .view-actionbar,
    .view-upload,
    .view-temp {
      position: absolute;
      padding: 5px;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .file-name {
      font-size: 12px;
      text-overflow: ellipsis;
      width: 140px;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
    }
    .view-actionbar {
      height: 30px;
      background: #b4b4b4;
      .dropdown-menu {
        min-width: 120px;
        max-width: 120px;
        box-shadow: none;
        border-radius: 0;
        border: 1px solid #888;
        li {
          padding: 5px 10px;
        }
        a {
          font-size: 10px;
          align-content: center;
          vertical-align: center;
        }
        .icon {
          width: 12px;
          height: 12px;
        }
      }
    }
    .view-temp {
      top: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 10;
      .icon {
        fill: $secondary-font-color;
        width: 16px;
        height: 16px;
      }
      .close-icon:hover .icon,
      .icon:hover {
        fill: darken($secondary-font-color, 20);
      }
      .file-name {
        font-weight: bold;
        white-space: normal;
      }
    }
    .view-upload {
      top: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 10;
      .file-name,
      * {
        color: $white;
      }
      .abort-icon {
        position: absolute;
        bottom: 10px;
        .icon {
          fill: $light-gray;
          width: 16px;
          height: 16px;
          transform: rotate(45deg);
          transition: fill 0.3s ease-in-out;
        }
        &:hover .icon {
          fill: lighten($light-gray, 20);
        }
      }
    }
    .file-upload-progress {
      width: 140px;
      height: 4px;
      border-radius: 2px;
      background: $dark-gray;
      margin: 10px 0;
      position: relative;
      .progress-bar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 4px;
        border-radius: 2px;
        background: $blue;
        transition: all 0.1s ease-in;
      }
    }
  }
}
.feed-page.padding-0 {
  padding: 0 !important;
}

.like-class {
  fill: #d75a4a !important;
}

.post-detail {
  display: flex;
  margin: -40px -100px;
  border-radius: 0px;
  background-color: white;
  max-height: 450px;
  min-height: 450px;
  .sideleft-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100%;
    border-right: 1px solid #dcdcdc;
    img {
      width: 100%;
      max-height: 450px;
      object-fit: contain;
    }
  }
  .description-view {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px 55px;
    p {
      text-align: center;
      display: -webkit-box;
      font-size: 22px;
      -webkit-line-clamp: 8;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
  }

  .bg-feeddetail {
    background: #f4f2f2;
  }

  .sideright-detail {
    padding: 0px 10px;
    position: relative;
    .media {
      overflow: hidden;
      zoom: 1;
      margin-top: 10px;
      margin-bottom: 10px;

      .media-left {
        display: table-cell;
        vertical-align: top;

        .media-object {
          display: block;
          width: 40px;
          height: 40px;
          min-width: 45px;
          border-radius: 50%;
          border: 1px solid #ccc;
        }
      }

      .media-body {
        display: table-cell;
        vertical-align: top;
        overflow: hidden;
        zoom: 1;
        padding-left: 10px;

        .media-heading {
          margin-top: 5px;
          margin-bottom: 5px;
          font-size: 13px;
          font-weight: 500;
          color: $black;
        }

        .media-detail {
          font-size: 12px;
        }
        .more-options {
          position: absolute;
          right: 5px;
          top: 20px;
        }
      }
    }

    .profile-hr {
      width: calc(100% + 20px);
      margin-left: -10px;
      height: 1px;
      border: 0;
      border-top: 1px solid #dcdcdc;
    }

    .comments-container {
      width: 100%;
      height: calc(100% - 175px);

      .post-top {
        padding-bottom: 10px;
        margin-left: -12px;
        .media-object {
          width: 45px !important;
          height: 45px !important;
          min-width: 45px !important;
        }
        .media-body {
          .wrapped-text {
            display: -webkit-box;
            line-height: 1.5;
            max-height: 42px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .feed-see-more {
            color: #116682;
          }
        }
      }
      .media-list {
        overflow-y: auto;
        padding: 0px 12px;
        height: calc(100% - 6px);
        .media-object {
          width: 35px;
          height: 35px;
          min-width: 35px;
        }
        .media-heading {
          margin-top: 0;
        }
        &::-webkit-scrollbar {
          width: 0.2em;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid slategrey;
        }
      }
    }
    .bottom-container {
      position: absolute;
      bottom: 50px;
      width: calc(100% - 20px);
      height: 60px;
      padding: 20px;
      padding-left: 0px;
    }
  }
}

.user-post {
  margin-top: 0px !important;
  .media {
    // new css added
    border-radius: 15px 15px 0 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #e2e5e8;
    padding: 10px;
    margin-top: 0px;
    //margin-top: 25px;
    //
    overflow: hidden;
    zoom: 1;
    padding-bottom: 0px;
    //margin-bottom: 10px;

    .media-left {
      display: table-cell;
      vertical-align: top;

      .media-object {
        display: block;
        width: 180px;
        height: 180px;
        max-width: 180px;
        border-radius: 50%;
        border: 1px solid #ccc;
      }
    }

    .media-body {
      padding-top: 15px;
      //padding-left: 20px !important;
      flex: 1;
      display: table-cell;
      vertical-align: top;
      overflow: hidden;
      zoom: 1;
      padding-left: 30px;
      min-width: 300px;

      .media-heading {
        margin-top: -3px;
        //margin-bottom: 5px;
        //font-size: 13px;
        font-weight: 500;
        color: #555;
      }

      .media-detail {
        //font-size: 12px;
        color: #bdbdbd;
      }
      .post-like-container {
        width: inherit;
        //margin-left: -20px;
        margin-bottom: 15px;
        .post-like-item {
          //flex: 1;
          text-align: center;
          margin-top: 20px;

          p {
            color: black;
          }
          h4 {
            color: $primary-color;
          }
        }
      }
      .btn-message {
        margin-right: 20px;
        color: $primary-color;
        border-color: #dedcdd;
        background-color: #eeeaeb;
      }
    }

    .media-right {
      display: table-cell;
      vertical-align: top;
      width: 100%;

      .media-object {
        display: block;
        width: 150px;
        height: 150px;
        max-width: 150px;
        border-radius: 10px;
      }
    }
  }

  .user-bio-buttons {
    padding: 10px;
  }
  .user-bio {
    padding-top: 15px;
    padding-bottom: 15px;
    color: #555;
    font-size: 15px;
    //padding-left: 15px;
  }

  .user-bio-hr {
    width: calc(100% - 10px);
    height: 2px;
    border: 0;
    border-top: 2px solid #e9eaeb;
  }

  .grid-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
    //padding: 5px;
    margin-top: 10px;
    justify-content: start;
    .grid-item {
      margin: 0px 10px 10px 0px;
      width: 170px;
      height: 170px;
      background-color: #e9e7e8;
      object-fit: cover;
    }
  }
  .btn-tab-active {
    border: 0;
    border-top: 3px solid #116682 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    //background: #5eddfd;
  }
  .footer-btn {
    font-size: 16px;
    color: #999;
  }
  .footer-btn-selected {
    color: #116682 !important;
  }
}

.mediaViewHide {
  display: none;
}
.mediaViewShow {
  display: block;
}

.slider-content {
  position: relative;
  margin: 20px 10px;
  min-width: 100%;
  .image {
    width: 550px;
    height: 200px;
    //border-top-right-radius: 10px;
    //border-top-left-radius: 10px;
    object-fit: cover;
  }
  .slider-btn {
    color: #fff !important;
    background-color: #000 !important;
    border: none;
    display: inline-block;
    //padding: 0px 12px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    outline: none;
    line-height: 30px;
  }

  .slider-btn-left {
    position: absolute;
    top: 50%;
    left: 1%;
    transform: translate(0%, -50%);
  }
  .slider-btn-right {
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(0%, -50%);
  }
}

.feed-top-header,
.lesson-content-top {
  position: fixed;
  // top: 74px;
  top: 70px;
  height: 54px;
  width: calc(100% - 260px);
  z-index: 10;
  // border-top-left-radius: 20px;
  box-shadow: 0 8px 6px -6px #00000038;

  h5 {
    position: relative;
    bottom: 0px;
  }
  .close-icon {
    fill: #999ea2;
    position: relative;
    .icon {
      transform: rotate(45deg);
      position: absolute;
      top: -4px;
      left: 6px;
    }
  }
  .feed-close-icon {
    fill: #999ea2;
    position: relative;
    right: 30px;
    top: 12px;
    .icon {
      transform: rotate(45deg);
      position: absolute;
      top: -4px;
      left: 6px;
    }
  }
  .feed-search {
    //position: absolute;
    //top: 10px;
    //left: 20%;
    min-width: 50%;

    .feed-search-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .form-control {
      //padding-left: 30px;
    }
    .search-container.form-group {
      overflow: visible;
    }
    .dropdown-menu.dropdown-menu-left {
      min-width: 450px;
      left: 10px;
    }
    .filter-button {
      padding: 4.5px 20px !important;
      display: flex;
      align-items: center;
    }
    .search-inside {
      flex-direction: column;
      padding: 20px;
    }
  }
  .feed-myfollow {
    position: absolute;
    top: 14px;
    left: 56%;

    .item {
      display: inline;
      padding-left: 20px;
      .number {
        color: $primary-color;
        font-size: 14px;
        margin-right: 5px;
        font-weight: 500;
      }
      .title {
        font-size: 14px;
      }
      .btn {
        padding: 5px 12px !important;
      }
    }
  }
}

.margin-top-like-share {
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .feed-page {
    .feed-right-list {
      justify-content: space-between;
      .panel {
        .row {
          .col-md-4 {
            img {
              height: 55px;
              width: 55px;
            }
          }
          .col-md-6 {
            padding-left: 0;
            .high-text {
              .line-clamp {
                margin-top: -4px;
              }
            }
          }
          .like-panel {
            bottom: -3px;
            left: 60px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .feed-top-header {
    //border-top-left-radius: 20px;
    .feed-myfollow {
      margin-top: 13px;
      position: static;
      text-align: center;
      padding-bottom: 10px;
      margin-right: 10px;
      .item {
        padding-left: 15px;
        .title {
          padding-left: 0;
        }
      }
    }
  }
  .add-space {
    justify-content: space-between;
  }
  .feed-page {
    .feed-container {
      .media {
        .media-body {
          .post-like-container {
            width: 82%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .feed-single {
    .sideleft-img {
      max-width: 100%;
      flex-grow: 100;
    }

    .sideright-detail {
      max-width: 100%;
      flex-grow: 100;
    }
  }
  .feed-page {
    .feed-right-list {
      display: none;
    }
    .feed-list {
      max-width: 100%;
    }
    .feed-container {
      .panel {
        .panel-heading {
          display: flex !important;
          .more-options {
            .chatbox-options {
              margin-top: -12px;
            }
          }
        }
      }
      .media {
        text-align: center;
        width: 100%;
        .media-left {
          text-align: center;
          display: unset;
          vertical-align: unset;
          .media-object {
            margin-left: auto;
            margin-right: auto;
          }
        }
        .media-body {
          width: 100%;
          text-align: center;
          margin-top: 20px;
          display: unset;
          vertical-align: unset;
          .post-like-container {
            width: 100%;
          }
        }
      }
    }
  }
  .feed-top-header {
    .search-container {
      display: none;
    }
    .feed-myfollow {
      margin-top: 13px;
      position: static;
      text-align: center;
      padding-bottom: 10px;
      margin-right: 10px;
    }
  }
  .add-space {
    justify-content: space-between;
  }
}

@media (max-width: 576px) {
  .feed-single {
    .sideleft-img {
      max-width: 100%;
      flex-grow: 100;
    }

    .sideright-detail {
      max-width: 100%;
      flex-grow: 100;
    }
  }
  .feed-top-header {
    .feed-myfollow {
      margin-top: 13px;
      position: static;
      text-align: center;
      padding-bottom: 10px;
      margin-right: 10px;
    }
  }
  .add-space {
    justify-content: space-between;
  }
  .feed-page {
    .user-post {
      .grid-item {
        padding: 0;
        width: 150px;
        height: 150px;
      }
    }
  }
}

@media (max-width: 465px) {
  .margin-top-like-share {
    margin-top: 25px;
  }
  .post-detail {
    display: block;
    margin: 0px;
    border-radius: 0px;
    background-color: white;
    max-height: unset;
    min-height: unset;
  }
  .post-detail {
    .sideleft-img {
      max-width: 100%;
      flex-grow: 100;
    }
    .sideright-detail {
      max-width: 100%;
      flex-grow: 100;
      .comments-container .media-list {
        max-height: 300px;
      }
    }
    .comments-container .media-list {
    }
  }
  .feed-single {
    .sideleft-img {
      max-width: 100%;
      flex-grow: 100;
    }

    .sideright-detail {
      max-width: 100%;
      flex-grow: 100;
    }
  }
  .feed-top-header {
    .feed-myfollow {
      margin-top: 13px;
      position: static;
      text-align: center;
      padding-bottom: 10px;
      margin-right: 10px;
      .item .title {
        display: none;
      }
    }
  }
  .add-space {
    justify-content: space-between;
  }
  .feed-page {
    .user-post {
      .grid-item {
        padding: 0;
        width: 150px;
        height: 150px;
      }
    }
  }
}

.panel-comment-model {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-top: 10px;

  .send-icon-custom {
    fill: $primary-color;
  }
  .send-btn-custom {
    background-color: unset !important;
  }
  .fill-width-comment {
    position: relative;
    left: 0;
    right: 0;
    margin-right: 10px;
    width: 100%;
    .emoji-picker-icon {
      top: 0px;
      left: 0px;
    }
  }
  .comment-box {
    width: calc(100% - 35px);
    //margin-left: 35px;
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 13px;
    font-family: inherit;
  }
  .icon-send-button {
    fill: $primary-color;
  }
}
.feed-edit-top {
  position: unset;
  top: 0;
  height: unset;
  border: unset;
  box-shadow: unset;
  width: 100%;
  padding: 0 12px;
  .icon-panda-post {
    width: 25px;
    height: 25px;
    fill: #555;
    position: relative;
    top: 6px;
  }
}
.feed-edit-data {
  min-height: unset;
}

.follower-list {
  min-height: 300px;
  .card-load-more {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 15px;
    justify-content: center;
  }
  .follow-container {
    width: 100%;
    height: calc(100% - 175px);

    .btn-tabs {
      border-radius: 25px;
      background: #dad8d9;
      font-weight: 500;
      //color:#707070;
      margin-right: 5px;
    }

    .media-list {
      overflow-y: auto;
      padding: 0px 12px;
      min-height: 300px;

      .media {
        overflow: hidden;
        zoom: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;

        .media-left {
          display: table-cell;
          vertical-align: top;

          .media-object {
            width: 45px !important;
            height: 45px !important;
            min-width: 45px !important;
          }
        }

        .media-body {
          display: table-cell;
          vertical-align: top;
          overflow: hidden;
          zoom: 1;
          //padding-left: 10px;

          .media-heading {
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 13px;
            font-weight: 500;
            color: #000;
          }

          .media-detail {
            font-size: 12px;

            .btn-follow {
              padding: 3px 15px;
              font-size: 12px;
              font-weight: 500;
              position: absolute;
              right: 0;
              top: 19%;
            }
          }
        }
      }
    }
    .row {
      margin-left: -15px;
      margin-right: -15px;
    }
    .form-settings {
      margin-bottom: 10px;
      display: flex;
      p {
        line-height: 30px;
        text-align: left;
      }

      .form-control {
        display: block;
        width: 100%;
        height: 30px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
      }
      .feed-close-icon {
        fill: #999ea2;
        position: absolute;
        right: 20px;
        top: 7px;
        .icon {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.feed-single {
  display: flex;
  height: calc(100vh - 160px);
  #NavbarCollapse {
    max-height: inherit !important;
  }
  .navbar-overflow {
    overflow: inherit !important;
    font-size: 14px !important;
    text-align: inherit !important;
  }
  .sideleft-img {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background: white;
    width: 100%;
    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .slider-content {
      margin: 0;
      position: relative;
      min-width: 100%;
      //height: 100%;
    }
  }
  .sideright-detail {
    padding: 10px;
    margin: 0 10px;
    border: 1px solid #dcdcdc;
    .profile-hr {
      width: calc(100% + 20px);
      margin-left: -10px;
      height: 1px;
      border: 0;
      border-top: 1px solid #dcdcdc;
    }
    .comments-container {
      width: 100%;
      height: calc(100% - 180px);
    }
    .media-list {
      overflow-y: auto;
      padding: 0px 12px;
      max-height: calc(100% - 6px);
      .post-top {
        .media-body {
          .wrapped-text {
            display: -webkit-box;
            line-height: 1.5;
            max-height: 42px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .media {
      overflow: hidden;
      zoom: 1;
      margin-bottom: 10px;
      .media-left {
        display: table-cell;
        vertical-align: top;

        .media-object {
          display: block;
          width: 40px;
          height: 40px;
          min-width: 40px;
          border-radius: 50%;
          border: 1px solid #ccc;
        }
      }
      .comment-reply {
        position: relative !important;
        min-width: 100% !important;
        width: 100% !important;
        .more-options {
          position: absolute;
          top: 8px;
          right: 0px;
        }
        .media {
          .media-body {
            min-width: unset;
          }
        }
      }
      .media-body {
        display: table-cell;
        vertical-align: top;
        overflow: hidden;
        zoom: 1;
        padding-left: 10px;
        min-width: 300px;
        .feed-see-more {
          color: #116682;
        }
        .media-heading {
          margin-top: 5px;
          margin-bottom: 5px;
          font-size: 13px;
          font-weight: 500;
          color: #000;
        }

        .media-detail {
          font-size: 12px;
          //color: #bdbdbd;
          word-break: break-word;
          padding-right: 6px;
        }
        .media {
          .media-object {
            width: 30px;
            height: 30px;
            min-width: 30px;
            border-radius: 50%;
            border: 1px solid #ccc;
          }
        }
      }
    }
  }
}

.like-hover:hover {
  color: #116682;
}

.group-settings {
  // margin-top: -20px;
  // margin-bottom: -20px;

  .page-title {
    color: black;
    border-bottom: none;
    padding-bottom: 0;
    .modal-description {
      display: flex;
      margin-left: -80px;
      margin-right: -80px;
      border-bottom: 1px solid #e9eaeb;
      margin-bottom: 15px;
      padding-bottom: 12px;
      padding-left: 35px;
      padding-right: 35px;
      span {
        text-align: left;
        color: #9b9b9b;
      }
    }
    .group-info {
      color: #555;
      text-align: center;
      text-transform: uppercase;
    }
    .avatar-container {
      .files {
        justify-content: center;
      }
      .hsp-files .close-icon {
        transform: unset;
      }
    }
  }

  .form-settings {
    margin-bottom: 10px;
    //display: flex;
    tags-input {
      .tags {
        .input {
          height: 20px !important;
        }
      }
    }
    .add-asterisk:after {
      content: '*';
      color: #9b9b9b;
    }
    .error-asterisk:after {
      content: '*';
      color: red;
    }
    .add-margin-top {
      margin-top: 8px;
    }
    p {
      line-height: 30px;
      text-align: left;
      color: #9b9b9b;
    }

    textarea {
      min-height: 120px;
      resize: none;
    }

    .line-height-30 {
      line-height: 30px;
    }

    .checkbox {
      //display: inline;
      margin-right: 10px;
      font-size: 14px;
    }

    .privacy-div {
      div {
        display: inline;
        position: relative;
        top: -7px;
        margin-right: 5px;
      }

      .more-options {
        top: 0;
      }
    }

    .icon-privacy {
      //border: 1px solid #908f8f;
      //border-radius: 25px;
      //width: 25px;
      //height: 25px;
      //padding: 2px;
      margin-right: 5px;
    }

    .form-control {
      display: block;
      width: 100%;
      height: 30px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
    }
    .has-error {
      border-color: #f80505;
      box-shadow: 0 0 3px #f80505;
    }
    .member-alert {
      color: #f80505;
    }

    .feed-close-icon {
      fill: #999ea2;
      position: absolute;
      right: 20px;
      top: 7px;
      .icon {
        transform: rotate(45deg);
      }
    }

    .btn-close {
      width: 100% !important;
      padding-left: 17px !important;
    }
    .delete-group {
      color: $primary-color;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .btn-tabs {
    border-radius: 25px;
    background: #dad8d9;
    font-weight: 500;
    //color:#707070;
    margin-right: 5px;
    padding: 5px 20px !important;
  }

  .btn-tab-active {
    background: #5eddfd;
  }

  .members-div {
    height: 350px;
    overflow-y: auto;
    padding: 0px 5px 20px 0px;
    .media {
      overflow: visible;
      zoom: 1;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f1f1f1;
      position: relative;

      .media-left {
        display: table-cell;
        vertical-align: top;
        position: relative;

        a {
          .user-status {
            right: -5px !important;
            top: -4px !important;
            left: unset;
            width: 10px;
            height: 10px;
          }
        }

        .media-object {
          display: block;
          width: 45px;
          height: 45px;
          min-width: 45px;
          border-radius: 5px;
          object-fit: cover;
        }
      }

      .media-body {
        display: table-cell;
        vertical-align: middle;
        overflow: hidden;
        zoom: 1;
        //padding-left: 10px;

        .media-heading {
          margin-top: 0;
          margin-bottom: 2px;
          font-size: 13px;
          color: #555555;
          word-break: break-word;
          padding-right: 5px;
          font-weight: 500;
        }

        .media-detail {
          font-size: 12px;
          display: flex;
          span {
            color: $primary-color;
            padding-left: 2px;
          }
        }
        .btn-primary {
          position: absolute;
          right: 23px;
          padding: 3px 20px;
          top: 18px;
          font-weight: 500;
        }
        .btn-following {
          background-color: #dad8d9;
          border-color: #dad8d9;
          color: black;
        }
        .member-approval {
          position: absolute;
          right: 0;
          top: 20px;
          .btn-primary {
            position: unset;
            margin-left: 5px;
          }
          .btn-reject {
            background-color: #dad8d9;
            border-color: #dad8d9;
            color: black;
          }
        }

        .more-options {
          position: absolute;
          top: 22px;
          right: 0;

          .more-options-icon {
            padding: 2px;
          }
          .dropdown-menu {
            max-width: 180px !important;
          }
        }
      }
    }

    .add-member {
      border-bottom: 1px solid #f1f1f1;
    }
  }
  .clearfix:before,
  .clearfix:after {
    content: '.';
    display: block;
    height: 0;
    overflow: hidden;
  }
  .clearfix:after {
    clear: both;
  }
  .clearfix {
    zoom: 1;
  } /* IE < 8 */
}

.member-settings {
  height: 500px;
  margin-bottom: 0 !important;
}

.group-photos {
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    //padding: 5px;
    margin-top: 10px;
    justify-content: start;
    .grid-item {
      margin: 0px 10px 10px 0px;
      width: 170px;
      height: 170px;
      background-color: #e9e7e8;
      object-fit: cover;
    }
  }
}
.image-box-size {
  width: 545px;
  //margin-left: 25px;
  //margin-right: 25px;
}
.create-new-group {
  .left-heading-flex {
    flex: 1;
  }
  .right-fields-flex {
    flex: 3;
  }
  .form-control {
    height: 35px !important;
  }
  .tags {
    min-height: 35px !important;
  }
  .hr-line-align {
    border: 1px solid #e9eaeb;
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(100% - -100px);
    position: relative;
    bottom: -24px;
    right: 74px;
  }
  .top-heading {
    p {
      font-weight: 400;
      color: #555;
      position: absolute;
      left: 4%;
      top: 2%;
    }
  }
  .form-settings {
    margin-bottom: 20px;
    align-items: center;
  }
  tags-input .tags {
    padding: 0px;
  }
  tags-input .host {
    margin: 0px !important;
  }
  tags-input .tags .input {
    position: relative;
    left: 2%;
    top: 3px;
  }
  .group-btn {
    height: 35px;
    width: 105px;
  }
  input {
    font-size: 13px !important;
  }
  .group-user-img {
    //height: 199px !important;
    //width: 242px !important;
    height: 170px !important;
    width: 170px !important;
  }
  .modal-description {
    display: flex;
    margin-left: -80px;
    margin-right: -80px;
    border-bottom: 1px solid #e9eaeb;
    margin-bottom: 15px;
    padding-bottom: 12px;
    padding-left: 35px;
    padding-right: 35px;
    span {
      text-align: left;
      color: #9b9b9b;
    }
  }
  .decrease-margin {
    .col-md-2,
    .col-md-3,
    .col-md-6 {
      padding-left: 5px;
      padding-right: 5px;
      p {
        color: #9b9b9b;
      }
    }
    .btn {
      padding: 7px 25px;
    }
  }
  .filter-row {
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 35px;
    span {
      color: #9b9b9b;
    }
  }
  .search-div {
    //border-bottom: 1px solid #e9eaeb;
    margin-bottom: 15px;
    padding-bottom: 15px;
    margin-top: 10px;
    .btn-size {
      margin-left: 12px;
      padding: 7px 20px;
    }
  }
  .avatar-container {
    position: relative;
    //margin: 0 auto;
    //width: 120px;
    .camera-size {
      width: 26px;
      height: 22px;
    }
    margin-bottom: 20px;
    .outer-box {
      display: block;
      position: relative;

      img {
        width: 120px;
        height: auto;
        border-radius: 17px;
        object-fit: cover;
      }

      //.file-upload {
      //	@extend .round;
      //	position: absolute;
      //	right: -4px;
      //	bottom: 7px;
      //	padding: 4px 6px 5px;
      //	width: 26px;
      //	height: 26px;
      //	background-color: $sidebar-color;
      //	fill: #fff;
      //}

      .inner-box {
        background: #000;
        height: 30%;
        width: 100%;
        opacity: 0;
        bottom: 0;
        left: 0;
        position: absolute !important;
        padding: 0;
        transition: opacity 0.5s;
        border-bottom-left-radius: 17px;
        border-bottom-right-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
          fill: #d5d1d2;
          width: 20px;
          height: 20px;
        }
      }

      .inner-box {
        opacity: 0.5;
        transition: opacity 0.5s;
      }
    }
  }

  .event-banner {
    opacity: 0.9;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #ccc;

    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //margin-left: -80px;

    .event-banner-inner {
      position: absolute;
      top: 0;
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      .icon {
        fill: white;
      }
    }
    .upload-image {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 15px;

      height: 45px;
      width: 163px;
      border-radius: 25px;
      background-color: #eef0f4;
      .icon-upload {
        width: 60px;
        fill: #a6a6a6;
        height: 60px;
      }
      img {
        height: 200px;
        width: 550px;
        object-fit: cover;
      }
    }
  }
}
.date-div {
  height: 35px;
  width: 150px;
  height: inherit;
  position: relative;
  .icon-calender {
    position: absolute;
    right: 24px;
    bottom: 7px;
  }
}
.datetime-label-div {
  line-height: 50px;
  height: 50px;
  display: flex;
  align-items: flex-end;
}
.time-div {
  //margin-left: -10px;
  position: relative;
  left: 10px;
  padding-left: 0;
  span {
    padding-left: 10px;
  }
  .timepicker {
    display: flex !important;
    border: unset;
    padding: 0;
    .hour {
      margin-left: 0px;
    }
    .form-control {
      width: 50px !important;
      padding: 6px 7px;
    }
  }
}

.upload-div {
  //border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  width: 100px;
  height: 100px;
  //border: 1px solid #8b8786;
  margin-bottom: 15px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    //border-radius: 50%;
  }
  .file-upload {
    @extend .round;
    position: absolute;
    right: -4px;
    bottom: 7px;
    padding: 4px 6px 5px;
    width: 26px;
    height: 26px;
    background-color: $sidebar-color;
    fill: #fff;
  }
  .icon {
    fill: #8b8786;
    line-height: 100px;
    height: 100px;
    width: 45px;
  }
  .icon-upload {
    fill: #fff;
    height: 15px;
    width: 15px;
  }
}

.event-tab {
  .event-top {
    margin-left: 20px;
    margin-top: 20px;
    .event-btn {
      padding-right: 10px;
      border-right: 1.5px solid #dcdadb;
      margin-left: 10px;
      display: inline;
      color: #555555;
      font-weight: 500;
      text-transform: capitalize;
      &:last-child {
        border-right: none;
      }
    }
    .event-btn-active {
      color: $primary-color;
    }
    .event-last-child {
      border-right: none;
    }
    .btn-create {
      float: right;
      margin-top: -23px;
      text-transform: capitalize;
      font-weight: 500;
    }
  }
}
.post-permission-div {
  margin-left: auto;
  height: 25px;
  line-height: 25px;
  justify-content: center;
  display: flex;
  .chatbox-options {
    align-items: center;
    justify-content: center;
    display: flex;
    .more-options-icon {
      fill: black;
    }
  }
}

.event-panel {
  .event-desc {
    margin-top: 10px;
    .event-title {
      color: #555555;
      font-size: 18px;
      font-weight: 600;
      margin-left: 10px;
      width: calc(100% - 285px);
    }
    .event-status {
      float: right;
      margin-right: 10px;
      margin-top: 5px;
      .align-div-content {
        display: flex;
        justify-content: center;
      }
      .btn-primary {
        margin-left: 10px;
        text-transform: capitalize;
        padding: 4px 20px !important;
        .icon {
          fill: #ffffff;
          vertical-align: middle;
        }
        .mrgn-lft {
          margin-left: 3px;
        }
      }
      .btn-following {
        background-color: #dad8d9;
        border-color: #dad8d9;
        color: black;
        text-transform: capitalize;
      }
    }
  }
  .event-status {
    float: right;
    margin-right: 7px;
    .btn-primary {
      margin-left: 7px;
      text-transform: capitalize;
      padding: 4px 20px !important;
      .icon {
        fill: #ffffff;
        vertical-align: middle;
      }
      .mrgn-lft {
        margin-left: 3px;
      }
    }
    .btn-following {
      background-color: #dad8d9;
      border-color: #dad8d9;
      color: black;
      text-transform: capitalize;
    }
  }
  .event-detail {
    padding: 10px 10px;
    color: #555555;
    font-size: 14px;

    .event-date {
      color: #555555;
      font-size: 15px;
      .icon {
        fill: #555555;
      }
    }
    .event-time {
      margin-left: 20px;
      color: #555555;
      font-size: 15px;
      .icon {
        fill: #555555;
      }
    }
    p .icon {
      margin-right: 4px;
      margin-bottom: -2px;
    }
    .event-location {
      -webkit-line-clamp: 2;
      line-height: 18px;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      display: -webkit-box;
      font-size: 15px;
      color: #555555;
      .icon {
        fill: #555555;
      }
    }
  }
  .event-about {
    padding: 5px 10px;
    margin-bottom: 10px;
    //border-bottom: 1px solid #ccc;
    border-bottom: unset;
    font-size: 13px;
    .about {
      font-weight: 500;
    }
    .about-detail {
      font-size: 13px;
      color: #555555;
    }
    .wrapped-about {
      display: -webkit-box;
      //line-height: 1.5;
      //max-height: 42px;
      -webkit-line-clamp: 5;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      //line-height: 15px;
      -webkit-box-orient: vertical;
    }
  }
}

.btn-following {
  background-color: #dad8d9 !important;
  border-color: #dad8d9 !important;
  color: black !important;
}

.appointment-attendees-add-button {
  margin-left: 19px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  height: 40px;
  width: 100px;
}

.event-add-attendees-btn {
  border-top-left-radius: 19px !important;
  border-bottom-left-radius: 19px !important;
  margin-left: 10px !important;
  height: 40px;
  width: 100px;
}
.group-event-add-attendees-btn {
  border-top-left-radius: 19px !important;
  border-bottom-left-radius: 19px !important;
  margin-left: 10px;
  height: 35px;
  width: 100px;
  padding: 5px 25px;
  margin-top: 7px;
}

.appointment-attendees-each {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  padding-top: 10px;
}

.event-invite-attendees-name {
  color: #555;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  display: block;
  padding-top: 15px;
}

.event-invite-attendees-type {
  line-height: 1;
  text-transform: capitalize;
  color: #919498;
  /* padding-top: 5px; */
  font-size: 12px;
  padding-left: 10px;
}
.event-invite-popup-going-count {
  text-transform: uppercase;
  margin-top: 5px;
}

.btn-going {
  width: 107px;
  padding: 4px 10px !important;
  font-weight: 500;
  margin-top: 7px;
}

.btn-pending {
  width: 107px;
  padding: 4px 10px !important;
  font-weight: 500;
  background-color: #dad8d9 !important;
  border-color: #dad8d9 !important;
  color: black !important;
  margin-top: 7px;
}

.coming-div {
  position: relative;
  margin-top: 15px;
  min-height: 44%;
  .comingsoon {
    position: absolute;
    margin-left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.52);
    .btn-block {
      position: absolute;
      width: 100%;
      top: 45%;
      left: 0;
      color: $white;
      background: $primary-color;
    }
  }
}
.media {
  overflow: hidden;
  zoom: 1;
  margin-top: 10px;

  .media-left {
    display: table-cell;
    vertical-align: top;

    .media-object {
      display: block;
      width: 45px;
      height: 45px;
      min-width: 45px;
      border-radius: 5px;
      object-fit: cover;
    }
  }

  .media-body {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    zoom: 1;
    //padding-left: 10px;

    .media-heading {
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 17px;
      color: $primary-color;
      word-break: break-word;
      padding-right: 10px;
      font-weight: 500;
    }

    .line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      word-break: break-all;
      height: 15px;
      overflow: hidden;
      line-height: 15px;
      -webkit-box-orient: vertical;
    }

    .media-detail {
      font-size: 12px;

      span {
        color: $primary-color;
        padding-left: 2px;
      }
    }
  }
}

.if-no-group {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 16px;
    color: #555555;
  }
}
.scroll-div {
  height: 400px;
  overflow-y: auto;
  .search-result-div {
    display: flex;
    span {
      text-align: center;
      color: #555555;
    }
  }
  .empty-state {
    min-width: 550px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;
    .empty-title {
      margin: 0 10px 40px;
    }
  }
  .media {
    .join-position {
      justify-content: space-between;
      margin-right: 25px;
      .media-body {
        padding-left: 10px;
      }
    }
    .media-body {
      .line-clamp {
        text-align: left;
        height: 17px;
      }
      .media-heading {
        font-size: 16px;
      }
      .icon-privacy {
        border: 1px solid #908f8f;
        border-radius: 25px;
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 5px;
      }
    }
    .media-left {
      .media-object {
        width: 68px;
        height: 68px;
      }
    }
    .media-description {
      display: flex;
      border-bottom: 1px solid #e9eaeb;
      margin-bottom: 10px;
      padding-bottom: 12px;
      margin-right: 25px;
      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      .description {
        word-break: break-word;
        text-align: left;
        color: #555555;
        padding-right: 25px;
        padding-top: 5px;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }
  .card-load-more {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 15px;
    justify-content: center;
  }
}

.left-caption {
  //display: flex;
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  .description-view {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 373px;
    padding: 10px 55px;
    p {
      text-align: center;
      display: -webkit-box;
      font-size: 20px;
      -webkit-line-clamp: 8;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
  }
}

.form-create-height {
  // height: 503px;
  overflow-y: auto;
  // margin-left: -80px;
  // margin-right: -70px;
  // padding-right: 25px;
  // padding-left: 35px;
}

.join-button {
  text-transform: capitalize;
  width: 100px;
  margin-right: 20px !important;
}

.tags .tag-list {
  .tag-item {
    background: none !important;
    border-radius: 15px !important;
    line-height: 22px !important;
    margin: 4px 5px !important;
    height: 23px !important;
  }
}

.not-valid-date {
  color: red !important;
  font-size: 12px;
  line-height: 15px !important;
}

.text-under-upload-image {
  text-align: left;
  font-size: 12px;
  color: #555555;
  margin-top: 3px;
}

.no-group-div {
  .sidebar-icon {
    fill: #dcdadb;
    width: 100%;
  }
  .no-found-text {
    margin-top: -25px;
    text-align: center;
    p {
      color: #dcdadb;
      text-transform: capitalize;
      font-size: 14px;
    }
  }
  .create-group {
    text-align: center;
    margin-top: 15px;
    span {
      color: $primary-color;
      font-size: 16px;
    }
  }
}

.event-join {
  .decrease-margin {
    .col-md-2,
    .col-md-3,
    .col-md-6 {
      padding-left: 5px;
      padding-right: 5px;
      p {
        color: #9b9b9b;
      }
    }
    .btn {
      padding: 7px 25px;
    }
  }
  .search-div {
    border-bottom: 1px solid #e9eaeb;
    margin-bottom: 15px;
    padding-bottom: 15px;
    margin-top: 10px;
    .btn-size {
      margin-left: 12px;
      padding: 7px 20px;
    }
  }
}

.main-search {
  height: 400px;
  overflow-y: auto;
  .event-search-result {
    margin-top: 20px;
    box-shadow: 0px 0px 3px 0px rgba(74, 74, 74, 0.39);
    border-radius: 10px;
    padding: 1px 5px;
    margin-right: 25px;
    margin-left: 2px;
  }
  .search-result-div {
    display: flex;
    margin-top: 15px;
    span {
      text-align: center;
      color: #555555;
    }
  }
}

.create-event-part {
  //height: 488px;
  padding-right: 15px;
  overflow-y: auto;
  .form-div-margin {
    margin-left: 25px;
    margin-right: 25px;
  }
  .icon-img {
    width: 22px;
    height: 21px;
  }
  .left-heading-flex {
    flex: 1;
  }
  .right-fields-flex {
    flex: 3;
  }
  .group-btn {
    height: 35px;
    width: 105px;
  }
  .form-settings {
    margin-bottom: 20px;
    align-items: center;
  }
  .file-upload {
    //width: 165px !important;
  }
  .time-div .timepicker .form-control {
    font-size: 13px !important;
  }
  .date-align {
    position: absolute;
    right: 7%;
  }
  .image-box-size {
    width: 100% !important;
  }
  .event-banner .upload-image img {
    height: 200px;
    width: 400px;
    object-fit: inherit;
    border-radius: 15px !important;
  }
  .page-title {
    //margin-left: 14%;
  }
  .create-new-group .event-banner {
    border-radius: 5px !important;
    overflow: hidden;
    .remove-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 9px 11px;
      .icon-img {
        width: 13px;
        height: 14px;
      }
    }
    .upload-image {
      flex-direction: row;
    }
    .file-upload {
      max-width: unset;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.create-event-heading {
  .top-heading {
    p {
      font-weight: 400;
      color: #555;
      position: absolute;
      left: 4%;
      top: 3%;
    }
  }
  .hr-line-align {
    position: relative;
    top: 20px;
    left: -30px;
    border: 1px solid #e9eaeb;
    margin-bottom: 20px;
    margin-left: 0px;
    width: calc(100% + 60px);
  }
}

.select-group-error {
  color: red !important;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: -10px;
}
.assignment-header-category {
  font-weight: 500px;
  color: #000000;
}
.group-title-search {
  font-size: 14px;
  font-weight: 500;
  color: #555;
  margin-left: 10px;
}

.join-private-group {
  font-size: 15px;
  color: $primary-color;
  font-weight: 500;
}

.event-media-detail {
  background-color: #e9e9e9;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 20px;
  }
}

.comment-row {
  .more-options {
    display: inline;
    position: relative !important;
    top: 3px !important;
    //right: 15px !important;
    margin-top: 5px;
    .chatbox-options {
      .icon {
        height: 20px;
      }
    }
  }
  //&:hover {
  //		.more-options {
  //			display: inline;
  //		}
  //}
}

.send-email-success {
  text-align: center;
  color: #116682;
  font-size: 15px;
  margin-top: 10px;
}

.follow-accept-reject {
  margin: 1.3em 0em;
  text-align: center;

  .btn-reversed {
    background-color: #fff;
    color: #116682;
    border: 1px solid #116682;
  }
}

.group-files-modal {
  .file-card {
    margin-bottom: 10px;
    padding: 12px 18px;
    background-color: #f4f2f3;
    display: flex;
    justify-content: space-between;
    .name {
      display: flex;
      align-items: center;
      font-size: 15px;
      .more-options-icon {
        transform: rotate(-145deg);
        fill: #000000;
        width: 28px;
        height: 28px;
        margin-right: 10px !important;
      }
      span {
        //width: calc(100% - 70px);
        word-break: break-word;
      }
    }
    .icon-download {
      fill: #000;
    }
  }
}

.file-panel-top {
  table {
    width: 100%;
    padding: 15px;
  }
  margin-top: unset !important;
  .file-card {
    padding: 10px;
  }
}

.profileIcon {
  width: 15px;
  height: 18px;
}
.groupIcon {
  width: 24px;
  height: 18px;
}
.plusIcon {
  width: 17.964px;
  height: 18.768px;
}

.group-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 25px;
  background-color: #106682;
  &.group-size {
    height: 30px;
    width: 220px;
  }
  &.profile-size {
    height: 30px;
    width: 94px;
  }
  &:hover {
    background-color: #0b5168;
  }
}
.font-size-13 {
  font-size: 13px;
  font-weight: 200;
}
.font-size-14 {
  font-size: 14px;
}

.profile-heading {
  padding-left: 10px;
  color: #555;
  padding-left: 15px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-10 {
  padding-left: 10px;
}
.user-img {
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
}
.text-align {
  text-align: center;
}
.border-1px-E8E7E7 {
  border: 1px solid #e8e7e7;
}
.padding-top-20 {
  padding-top: 20px;
}
.margin-left-15 {
  margin-left: 15px;
}
.color-white {
  color: #fff;
}
.sides-margin {
  margin: 20px 0px 0px 0px;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.groups-heading {
  padding-left: 10px;
  color: #555;
}
.padding-top-16 {
  padding-top: 16px;
}
.group-img {
  border-radius: 10px;
  width: 70px !important;
  min-width: 70px !important;
  height: 70px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.color-999 {
  color: #999 !important;
}
.color-555 {
  color: #555 !important;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.flex-basis-35 {
  flex-basis: 35px;
}
.event-date-size {
  width: 11px !important;
  height: 11px !important;
}
.font-size-12 {
  font-size: 12px;
}
.padding-left-2 {
  padding-left: 2px;
}
.padding-left-5 {
  padding-left: 5px;
}
.event-location-size {
  width: 8px !important;
  height: 12px !important;
}
.padding-left-12 {
  padding-left: 12px;
}
.rightbar-connect {
  display: flex;
  //width: 250px;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 0 0 2px 0 #e2e5e8;
  background: #ffffff;
  height: fit-content;
  min-height: 114px !important;
  margin-left: 10px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-bottom-15 {
  margin: 15px 0px;
}
.connect-user {
  //border-radius: 50%;
  //@at-root: -2px 5px 10px 0px #888888;
  position: relative;
  left: 5px;
  width: 40px;
  height: 40px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.margin-0 {
  margin: 0px !important;
}
.opacity-camera {
  opacity: 0.4;
}
.public-post-icon {
  width: 15px !important;
  height: 14.6px !important;
  position: relative;
  right: 7px;
}
.private-post-icon {
  width: 20px !important;
  height: 20px !important;
}
.padding-left-3 {
  padding-left: 3px;
}
.height-50 {
  height: 50px;
}
.feed-userInfo {
  font-weight: 600;
  margin-bottom: -5px;
  font-size: 13px;
}
.font-normal {
  font-weight: normal;
}
.feed-caption {
  -webkit-box-orient: vertical;
  color: #1c1e21;
  word-wrap: break-word !important;
  width: 100% !important;
}
.feed-likes {
  margin-left: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.feed-comment {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  word-break: break-word;
  align-items: center;
  display: block;
}
.feed-comment-text {
  font-weight: bold;
  color: #555;
  padding-right: 10px;
  display: block;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.icon-options {
  width: 15px !important;
  height: 14px !important;
  transform: rotate(90deg);
  margin-right: 0px;
}
.icon-delete {
  width: 15px !important;
  height: 15px !important;
}
.icon-send-button-size {
  height: 32.53px !important;
  width: 32.53px !important;
}
.profile-img-size {
  border-radius: 50% !important;
  width: 70px !important;
  height: 70px !important;
}
.align-self {
  align-self: center;
  margin-left: 14%;
}
.height-356 {
  height: 356px;
}
.connect-default-img {
  border: 1px solid #ccc;
}
.connect-align {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.connect-align-img {
  border: 1px solid #fff;
}
.feed-img-size {
  border-radius: 50% !important;
}
a.text-hover:hover {
  color: #fff;
}
.onclick-background {
  display: flex;
  align-items: center;
  background-color: #eef0f4;
  width: auto;
  height: 38px;
  border-radius: 10px;
  padding-left: 5px;
}
.font-size-11 {
  font-size: 11px;
}
.margin-top-24 {
  margin-top: 24px;
}
.connect-button-size {
  width: 116.5px;
  height: 28px;
}
.group-hr-btn {
  margin-block-end: 0 !important;
  border-top: 1px solid #fff;
  border: 1px solid #fff;
}
.description-sqr-text {
  height: fit-content !important;
}
.description-view-text {
  padding: 15px !important;
  background-image: none !important;
  line-height: 2 !important;
  display: inherit !important;
}

.feed-caption-text {
  font-size: 14px !important;
  display: flex !important;
  text-align: inherit !important;
}
.left-caption-text {
}
.background-image {
  //background-image: url(/images/feed-background.jpg) !important;
}
.feed-left-width {
  flex: 1 0 20%;
}
.feed-right-width {
  flex: 1 0 20%;
  margin-left: 10px;
  position: sticky !important;
  top: 75px;
  height: fit-content;
  width: 20%;
}
.feed-main-width {
  flex: 2 0 40%;
}
.display-left {
  position: absolute;
  top: 50%;
  left: -3%;
  transform: translate(0%, -50%);
}
.display-right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
}
.slider-btn-size {
  height: 35px;
  width: 35px !important;
  position: absolute;
  top: 14%;
  left: 13%;
}
.slider-ellipse {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  background-color: #fff;
  border: 1px solid #e9eaeb;
  border-radius: 50%;
}
.mySlides {
  height: 353px;
  width: 242px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 #e2e5e8;
}

.join-btn {
  margin-right: 5px;
  text-transform: capitalize;
  font-weight: 600;
  width: inherit;
  height: inherit;
}
.joined-btn {
  padding: 5px 18px !important;
  text-transform: capitalize;
  font-weight: 600;
  width: inherit;
  height: inherit;
}
.slider-footer-btn {
  height: 35px;
  width: 200px;
}
.hr-line-align {
  border: 1px solid #e9eaeb;
  margin-bottom: 20px;
  margin-left: 20px;
  width: calc(100% - 20px);
}

.group-event-join {
  .interested-btn {
    height: 35px;
    width: 137px;
    font-weight: 600;
    border-radius: 25px;
  }
  .event-location-size {
    width: 9px !important;
    height: 14px !important;
  }
  .event-date-size {
    width: 14px !important;
    height: 14px !important;
  }
  .top-event-header {
    height: 40px;
    width: 800px;
    border-radius: 15px 15px 0 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #e2e5e8;
  }
  .footer-btn {
    font-size: 16px;
    color: #999;
  }
  .footer-btn-selected {
    color: #116682 !important;
  }
  .btn-tab-active {
    border: 0;
    border-top: 3px solid #116682 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    //background: #5eddfd;
  }
  .padding-right-24 {
    padding-right: 24px;
  }
  .btn-size {
    height: 35px;
    width: 137px;
    border-radius: 25px;
  }
  .interested-star-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interested-star-icon {
    width: 16px !important;
    height: 16px !important;
    margin-right: 3px;
    position: relative;
    top: -1px;
  }
  .margin-left-8 {
    margin-left: 8px;
  }
  .icon-share-container {
    position: relative;
    height: 35px;
    width: 60px;
    border-radius: 25px;
    background-color: #e9eaeb;
  }
  .icon-share-panda {
    position: relative !important;
    right: 8px;
    top: 1px;
  }
}
.group-user-img {
  //height: 199px !important;
  //width: 242px !important;
  height: 190px !important;
  width: fit-content !important;
}

.comment-reply {
  .textarea_wrap {
    //display: -webkit-box;
    border-radius: 20px;
    background-color: #eef0f4;
    //margin-left: 12px;
    //padding-left: 20px;
    cursor: text;
    width: 100%;
    position: relative;
    padding: 0px 15px;
    margin-bottom: 5px;
  }

  .textarea_wrap2 {
    //display: -webkit-box;
    border-radius: 5px;
    //background-color: #EEF0F4;
    //margin-left: 12px;
    //padding-left: 20px;
    cursor: text;
    width: fit-content;
    position: relative;
    //padding: 10px 15px;
    // margin-bottom: 5px;
    display: flex;
    .feed-comment-img {
      width: 30px !important;
      height: 30px !important;
    }
  }

  textarea {
    border: 0;
    //margin: 0rem 0 0.5rem 0;
    outline: 0;
    //padding: 1rem 0 1rem 1.25rem;
    resize: none;
    font-size: 14px;
    width: 100%;
    font-family: inherit;
    background-color: #eef0f4;
    // border-radius: 15px;
    // padding: 5px 15px;
    padding-top: 8px;
  }

  .media {
    display: flex;
    overflow: visible !important;
    //width: 100%;
    .media-left {
      .media-object {
        width: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
      }
    }
    .media-body {
      width: 100%;
      /* display: flex; */
      overflow: visible;
    }
  }
}

.membership {
  .request-panel {
    padding: 20px 20px;
    .membership-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 2px solid #e2e5e8;
      p {
        font-size: 16px;
      }
      .btn-list {
        display: flex;
        .btn {
          padding: 7px 10px;
          font-weight: normal;
          text-transform: capitalize;
        }
        .btn-decline {
          border: unset;
          background-color: #eef0f4;
          color: #555;
        }
        .btn:first-child {
          margin-right: 10px;
        }
      }
    }
    .member-list {
      margin-top: 20px;
      //padding-bottom: 20px;
      //border-bottom: 2px solid #E2E5E8;
      border: 1px solid #e9eaeb;
      border-radius: 10px;
      padding: 15px 10px;
      .member-detail {
        display: flex;
        padding-bottom: 10px;
        .member-profile {
          flex: 1.7;

          padding: 10px;
          .member-title {
            display: flex;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }
          .profile-detail {
            margin-top: 20px;
            font-size: 13px;
          }
        }
        .btn-list {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          height: 100%;
          .btn {
            padding: 7px 10px;
            font-weight: normal;
            text-transform: capitalize;
          }
          .btn-decline {
            border: unset;
            background-color: #eef0f4;
            color: #555;
          }
          .btn:first-child {
            margin-right: 10px;
          }
        }
      }
      .questions {
        margin-bottom: 10px;
        .title {
          font-size: 13px;
          color: #999;
          margin-bottom: 10px;
        }
        .answer {
          font-size: 13px;
          color: #555;
        }
      }
      //&:last-child{
      //	border-bottom: unset;
      //}
    }
    .new-line {
      margin-top: -10px;
      padding: 0px 20px;
    }
  }
  .question-title {
    display: flex;
    justify-content: space-between;
    padding: 25px 20px;
    p {
      font-size: 16px;
    }
  }
  .question-list {
    padding: 25px 20px;
    .quest {
      margin-bottom: 5px;
      color: #999;
      display: block;
    }
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      .btn:first-child {
        margin-right: 20px;
      }
      .btn-edit {
        background-color: rgba(16, 102, 130, 0.1);
        border: unset;
        color: #106682;
      }
      .btn-delete {
        background-color: #eef0f4;
        color: #555;
        border: unset;
      }
    }
    .option-list {
      display: flex;
      border-bottom: 1px solid #e9eaeb;
      padding-bottom: 15px;
      margin-bottom: 15px;
      align-items: center;
      .checkbox-circle {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 25px;
        border-radius: 50%;
      }
      .radio-circle {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 25px;
        border-radius: 4px;
      }
      .option-text {
        color: #999;
        flex-grow: 1;
        input {
          width: 80%;
        }
      }
      .input-div {
        border: 1px solid #ccc;
        border-radius: 25px;
        height: 120px;
        width: 60%;
        color: #999;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 10px;
        padding-left: 20px;
      }
    }
    .no-border {
      border: unset;
    }
  }
}

.new-modal {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  .top-heading {
    .new-line {
      margin-top: 10px;
      margin-right: -10px;
      margin-left: -10px;
      border: 1px solid #e9eaeb;
      margin-bottom: 20px;
    }
  }
  .question-panel {
    display: flex;
    align-items: center;
    .question-text {
      flex-grow: 1;
      padding-left: 20px;
      padding-right: 20px;
      input {
        width: 100%;
      }
    }
    p {
      font-size: 13px;
      color: #999;
    }
    .question-type {
      .more-options {
        .ques-dropdown {
          width: 100%;
          display: flex;
          height: 26px;
          justify-content: center;
          align-items: center;
          background: #eef0f4;
          border-radius: 25px;
          padding: 18px 20px;
          color: #555;
        }
      }
    }
  }

  .option-panel {
    margin-top: 30px;

    .option-list {
      display: flex;
      border-bottom: 1px solid #e9eaeb;
      padding-bottom: 15px;
      margin-bottom: 15px;
      align-items: center;
      .checkbox-circle {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 25px;
        border-radius: 50%;
      }
      .radio-circle {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 25px;
        border-radius: 4px;
      }
      .option-text {
        color: #555;
        flex-grow: 1;
        input {
          width: 80%;
        }
      }
    }
    .btn-quest-add {
      background-color: rgba(16, 102, 130, 0.1);
      border: unset;
      color: #106682;
      font-weight: normal;
      padding: 10px 30px;
      padding: 10px 30px;
    }
  }

  .footer-panel {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .btn {
      padding: 10px 30px;
    }
    .btn:first-child {
      margin-right: 10px;
    }
    .btn-cancel {
      border: 1px solid #e9eaeb;
      color: #919498;
      background: white;
    }
  }

  .group-panel {
    display: flex;
    border-bottom: 1px solid #e9eaeb;
    padding-bottom: 15px;
    margin-bottom: 15px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 15px;
      margin-right: 30px;
    }
    .group-detail-title {
      font-size: 16px;
      color: #555;
    }

    .group-member-count {
      color: #999;
      span {
        color: #555;
        margin-left: 10px;
      }
    }
    .group-privacy {
      display: flex;
      align-items: center;
      span {
        display: block;
        margin-left: 10px;
      }
    }
  }
  .request-panel {
    padding: 10px;
    background-color: #eef0f4;
    border-radius: 5px;
    margin-bottom: 20px;
    .title {
      font-size: 13px;
      color: #555;
    }
    .desc {
      font-size: 13px;
      color: #999;
    }
  }

  .answer-question-panel {
    margin-bottom: 20px;
    .question-text {
      font-size: 16px;
      color: #555;
    }
    .question-helper {
      font-size: 13px;
      color: #999;
    }
    .question-options-list {
      margin-top: 15px;
      label {
        margin-left: 10px;
        font-size: 13px;
      }
      textarea {
        height: unset;
        resize: none;
      }
    }
  }
}

.group-container {
  margin: 0px !important;
  .user-post .btn-tab-active {
    border-top: unset !important;
  }
  .group-media {
    border-radius: 15px 15px 0 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #e2e5e8;

    .new-tabs {
      padding-left: 5px !important;
    }
    .group-div {
      padding-bottom: unset !important;

      .group-tabs {
        // background-color: #e9e9e9;
        // border-radius: 5px;
        // border: 1px solid #c1c1c1;
        //height: 30px;
        margin-top: 5px;
        // margin-bottom: 10px;
        // position: relative;
        //overflow: hidden;
        .btn-group-justified {
          width: 100%;
          table-layout: fixed;
          border-collapse: separate;
          display: table;
          .btn-group {
            display: table-cell;
            float: none;
            position: relative;
            .btn {
              width: 100%;
              background: none;
              // border-right: 1.5px solid #c1c1c1;
              border-radius: 0px;
              height: unset;
              line-height: unset;
              font-weight: normal;
              padding: 0 !important;
              color: #999;
              font-size: 16px;
              text-transform: capitalize;
            }
            .btn-tab-active {
              //background: #a6edff;
              //border-bottom: 3px solid #106682;
            }
            .group-hr-btn.btn-tab-active {
              border-top: 3px solid #116682 !important;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }
            //.padding-0{
            //	padding: 0 !important;
            //}
            &:last-child {
              .btn {
                border-right: none;
              }
            }
          }
          .opacity-4 {
            opacity: 0.4;
          }
        }
        .btn-group-justified-full {
          width: 100%;
        }
        .icon-settings {
          position: absolute;
          top: 0;
          right: 0px;
          height: 28px;
          background: none;
          border: none;
          line-height: 34px;
          border-left: 1.5px solid #c1c1c1;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
  .group-description {
    .desc-title {
      font-size: 16px;
      color: #999;
      font-weight: normal !important;
    }
    .desc-p {
      font-size: 13px;
      color: #555;
    }
  }
  .group-info {
    display: flex;
    align-items: center;
    .group-privacy {
      margin-right: 20px;
    }
    .btn-invite-member {
      padding: 5px 12px;
      font-weight: normal !important;
      margin-left: 30px;
    }
  }
  .user-post {
    .media {
      .media-left {
        .media-object {
          border-radius: 10px !important;
          border: unset !important;
        }
      }
    }
  }

  .group-panel {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 2px solid #e9eaeb;

    border-radius: unset;
    background-color: unset;
    box-shadow: unset;

    .icon-settings {
      position: absolute;
      top: 20px;
      right: 10px;
      border: unset;
      background: transparent;
      .member-indicator {
        height: 13px;
        width: 13px;
        position: absolute;
        top: -4px;
        right: -4px;
      }
      .pending-div {
        height: 18px;
        width: 18px;
        background-color: #5fdefe;
        display: inline-flex;
        margin-left: 10px;
        margin-bottom: -2px;
        border-radius: 50%;
        justify-content: center;
        font-size: 12px;
      }
    }
  }
}
.group-nav-collapse {
  margin-bottom: 15px;
}
.group-button.group-size {
  background-color: rgba(16, 102, 130, 0.1) !important;
}
.new-panda-join-btn {
  display: flex;
  color: #106682 !important;
  background-color: rgba(16, 102, 130, 0.1) !important;
  justify-content: center;
  align-items: center;
  max-width: 220px;
  .plusIcon {
    fill: #106682 !important;
  }
  .explore-icon {
    fill: #555 !important;
    width: 15px;
    height: 15px;
    margin-left: -24px;
  }
  span {
    color: #106682 !important;
    font-size: 13px;
    font-weight: 200;
    text-transform: capitalize;
  }
  &:hover {
    background-color: #106682 !important;
    .plusIcon,
    .explore-icon {
      fill: white !important;
    }
    span {
      color: white !important;
    }
  }
  &.explore-btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.new-panda-explore-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 220px;
  border: 1px solid #e9eaeb !important;
  color: #555 !important;
  padding-top: 6px;
  background: white !important;
  padding-bottom: 6px;
  .explore-icon {
    fill: #555 !important;
    width: 15px;
    height: 15px;
  }
  span {
    color: #555 !important;
    font-size: 13px;
    font-weight: 200;
    text-transform: capitalize;
  }
  &:hover {
    background-color: #106682 !important;
    .explore-icon {
      fill: white !important;
    }
    span {
      color: white !important;
    }
  }
}

.icon-group-next {
  width: 11px;
  height: 15px;
}

.feed-group-header {
  display: flex;
  align-items: center;
}

.create-new-group .hr-line-align {
  position: relative;
  top: 20px;
  left: -30px;
  border: 1px solid #e9eaeb;
  margin-bottom: 20px;
  margin-left: 0px;
  width: calc(100% + 60px);
}

.event-new-sidebar {
  p.event-title {
    font-size: 16px;
    //font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .event-detail-div {
    display: flex;

    img {
      width: 70px;
      height: 70px;
    }

    .event-right-detail {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      justify-content: center;
      .event-new-date {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .location-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }
    }
  }
}
.new-event-banner {
  img {
    width: 100% !important;
    height: 300px;
  }
}

.event-feed-panel {
  display: flex;
  padding-left: 10px;
  padding-top: 9px;
  padding-bottom: 12px;
  img {
    width: 100px;
    height: 100px;
  }
  .event-left-side {
    margin-left: 10px;
    .event-feed-detail {
      margin-top: 8px;
    }
  }
}

.group-feed-header {
  display: flex;
  flex-direction: column;
}

.event-tab {
  .event-top {
    background: white;
    border-radius: 15px;
    margin-left: 0px;
    display: flex;
    height: 48px;
    align-items: center;
    padding: 0px 10px;
    .event-top-bar {
      flex-grow: 1;
    }
    .btn-create {
      margin-top: 0px;
      color: #106682 !important;
      background-color: rgba(16, 102, 130, 0.1) !important;
      //border-color: rgba(16,102,130,0.1) !important;
      border: unset !important;
      .plusIcon {
        fill: #106682 !important;
      }
      span {
        vertical-align: text-top;
      }
      &:hover {
        background-color: #106682 !important;
        .plusIcon {
          fill: white !important;
        }
        span {
          color: white !important;
        }
      }
    }
  }
}

.event-edit {
  .image-box-size {
    width: 100%;
  }
  .datetime-label-div {
    height: unset;
  }
}

.feed-comment {
  .feed-comment-div {
    display: flex;
    width: 100%;
    .feed-comment-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      border: 1px solid #ccc;
    }
    .feed-comment-description {
      width: 100%;
    }
  }
}
.wrapped-about {
  display: -webkit-box;
  //line-height: 1.5;
  //max-height: 42px;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  //line-height: 15px;
  -webkit-box-orient: vertical;
}

.event-single-title {
  width: calc(100% - 315px);
  .add-ellipsis {
    max-width: unset !important;
  }
}

.post-right-detail {
  .media {
    .media-left {
      .media-object {
        width: 40px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 50% !important;
      }
    }
  }
}

.reply_time {
  color: #999;
  margin-right: 10px;
}

.new-group-title-sidebar {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin-bottom: 1px;
}
.new-group-member-sidebar {
  margin-bottom: 1px;
}
.new-group-visibility-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-public-sidebar {
    height: 13.6px;
    width: 14px;
    margin-right: 5px;
  }
  .icon-private-sidebar {
    fill: #999;
  }
}

.text-capitalize {
  text-transform: capitalize;
}
#icon-explore-btn {
  fill: #106682 !important;
}

.feed-page .feed-list .panel-body .description-view {
  justify-content: flex-start;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  p {
    text-align: left;
    &.show-all-feed-caption {
      -webkit-line-clamp: unset;
    }
  }
  .feed-see-more {
    color: #116682;
    font-size: 14px;
    font-weight: 700;
    display: block;
    text-transform: capitalize;
  }
}
