//
// Calendar Styles
// -------------------------------------------------
.adv-option {
  font-size: 16px;
  padding: 10px;
  .checkbox {
    margin-top: 0px !important;
  }
}

.book-filter {
  font-size: 14px;
  padding: 5px;
  .checkbox {
    margin-top: 0px !important;
  }
}

.calendar-icon {
  width: 25px;
  height: 25px;
  display: inline;
  fill: #555;
}

.input-border {
  border-radius: 15px;
}

// .sicon1 {
//     position: absolute;
//     bottom: 44px;
//     display: inline-block;
//     right: 413px;
//     padding-right: 6px;
//     height: 28px;
//     width: 27px;
//     fill: #7ea82f;
//     transform: rotate(90deg);
//     margin-right: 5px;
// }
.sicon {
  position: absolute;
  bottom: 5px;
  display: inline-block;
  right: 0px;
  padding-right: 6px;
  height: 28px;
  width: 27px;
  fill: $primary-color;
  transform: rotate(90deg);
  margin-right: 5px;
}

.calendar {
  padding: 15px 30px 30px 30px;
  .fc-content {
    display: flex;
    // align-items: center;
    &.lesson-cell {
      justify-content: space-between;
      // flex-wrap: wrap;
      flex-direction: column;
    }
    .title-hide {
      display: none;
    }
    .fc-title {
      flex: 1 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
    }
    .fc-content-status {
      position: absolute;
    }
    .fc-content-header,
    .fc-subheader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      max-height: 30px;
      font-size: 14px;

      .fc-title {
        padding-left: 25px;
      }
    }
    .fc-subheader {
      .fc-title {
        padding-left: 0px;
      }
    }
  }
  // .fc-content:hover > span {
  //     display: inline-block;
  //     position: absolute;
  //     top: -25px;
  //     left: 0;
  //     border: 2px solid red;
  //     background-color: yellow;
  // }
  .create-icon {
    display: inline-block;
    margin-top: 8px;
    font-size: 0;
    .icon {
      width: 26px;
      height: 26px;
      fill: $primary-light-color;
    }
  }
  .calendar-options {
    height: 24px;
    width: 24px;
    .icon {
      height: 24px;
      width: 24px;
      fill: $darker-gray;
    }
  }
  .lesson-cell {
    .attendance {
      @extend .round;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: bold;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border: 1px solid #fff;
      &.A {
        background-color: $brand-danger;
      }
    }
    .fc-title {
      flex: 1 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .status {
      border: 2px solid white;
      height: 10px;
      width: 10px;
      min-width: 10px;
    }
  }
  .fc-toolbar.fc-header-toolbar {
    height: 0;
    margin: 0;
  }
}

.calendar-header {
  user-select: none;
  position: relative;
  padding-bottom: 40px;
  padding-top: 15px;
  &.fixedHeaderClass {
    border-top-left-radius: 20px;
    position: fixed;
    // margin-left: 5px;
    top: 60px;
    background: #fff;
    left: 20px;
    right: 0px;
    padding: 7.5px 27px;
    z-index: 999;
    //box-shadow: 0px 3px 3px 0 rgba(200,201,203,0.7);
  }
  .left {
    flex: 1 1 auto;
    max-width: 40%;
  }
  .center {
    flex: 1 1 auto;
    // max-width: 35%;
  }
  .right {
    flex: 1 1 auto;
    // max-width: 35%;
  }
  .heading {
    @extend .text-center;
    max-width: 330px;
  }
  .icon {
    height: 18px;
    width: 18px;
  }
  .form-group {
    margin-bottom: 0px;
  }
  .chevron:hover {
    fill: $primary-light-color;
  }
  .calendar-search-icon {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    fill: #919498;
  }
  .calendar-back-search-icon {
    fill: #919498;
    border-right: 1px solid #e9eaeb;
    padding-right: 15px;
    margin-top: 5px;
  }
  .calendar-search-box {
    width: 700px;
  }
  .create-btn {
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 46px;
    cursor: pointer;
    color: $primary-color;
  }
}
#uiCalendar {
  &.fixedHeaderClass {
    .fc-widget-header.fc-row {
      //position: fixed;
      //top: 133px;
      //width: calc(100% - 330px);
      //background: white;
      //left: 296px;

      position: fixed;
      top: 128px;
      width: calc(100% - 331px);
      background: white;
      left: 295px;
      z-index: 100;
      border: 1px solid #ddd;
    }
  }
}
#calender-content-top {
  &.fixedHeaderClass {
    box-shadow: unset !important;
  }
}

.cal-buttons {
  .btn {
    border-radius: 0;
    background-color: white;
    border: 1px solid $silver;
    color: $secondary-font-color;
    font-size: 14px;
  }
  .btn.active {
    background-color: $primary-color;
    color: #fff;
  }
  .btn:first-child {
    border-right-color: transparent;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .btn:last-child {
    border-left-color: transparent;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.create-appointment {
  .file-upload {
    max-width: 100%;
  }
  .add-asterisk:after {
    content: "*";
    color: #9b9b9b;
  }
  .error-asterisk:after {
    content: "*";
    color: red;
  }
}

.create-assignment,
.create-appointment {
  .add-asterisk:after {
    content: "*";
    color: #9b9b9b;
  }
  .error-asterisk:after {
    content: "*";
    color: red;
  }
  textarea {
    border-radius: 15px;
  }
  .close-icon {
    transform: none !important;
  }
  .file-tab-upload,
  .file-drop {
    min-height: 90px;
    border: 1px dashed #919498;
    color: #919498;
    margin-top: 10px;
    // max-width: 50%;
    background: #dee0e1;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }
  .glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .assignment-dropdown {
    .dropdown-menu {
      position: absolute !important;
      max-height: 250px;
      overflow-y: auto;
      width: 100%;
    }
    // #scrollable-dropdown-menu {
    // 	.dropdown-menu {
    // 		max-height: 250px;
    // 		overflow-y: auto;
    // 		width: 100%;
    // 	}
    // }
  }
  .dropdown-menu {
    position: relative !important;
  }
  .date-input {
    width: 190px;
  }
  .input-width {
    width: 190px;
  }
  .title {
    margin-bottom: 40px;
  }
  .actionbar {
    // @extend .text-right;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
  .dropdown-menu {
    color: $primary-font-color;
    li {
      padding: 5px;
    }
  }
  .student-name {
    margin-left: 10px;
    padding-bottom: 15px;
  }
  .timepicker .meridian {
    margin-right: 0px;
  }
}

.create-appointment {
  .radio-inline {
    min-width: 80px;
    max-width: 85px;
  }
  .max-occurrence {
    .form-control {
      max-width: 120px;
    }
  }
  a.primary,
  a.danger {
    font-size: 14px;
    font-weight: bold;
  }
  a.danger {
    &,
    &:hover,
    &:active,
    &:focus {
      color: $brand-danger;
    }
  }
  a.primary {
    &,
    &:hover,
    &:active,
    &:focus {
      color: $primary-light-color;
      text-decoration: none;
    }
  }
}

.create-assignment {
  .tab {
    margin-bottom: 20px;
    border-bottom: 1px solid $gray;
  }
}

.view-appointment {
  .add-assignment-details {
    width: 480px;
    span {
      display: inline-block;
      &.title {
        font-weight: bold;
        width: 110px;
      }
      &.value {
        word-break: break-all;
      }
      &.value:before {
        margin-left: 10px;
        content: ":";
        margin-right: 10px;
      }
    }
  }
  padding-bottom: 60px;
  word-break: break-all;
  // .title {
  //     margin-bottom: 50px;
  // }
  .action {
    display: inline-block;
    margin-left: 20px;
  }
  .view-title {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray;
  }
  .location {
    display: inline-block;
  }
  .view-place {
    // margin: 30px 0;
    margin-right: 8px;
    .icon {
      margin-right: 8px;
    }
  }
  .reminder-label::before {
    margin-left: 30px;
    content: ":";
    padding-right: 30px;
  }
}

//#uiCalendar.fixedHeaderClass {
//    .fc-head {
//        position: fixed;
//        left: 286px;
//        top: 70px;
//        right: 29px;
//        background: #fff;
//        z-index: 10000;
//    }
//}

.studentTag {
  background: none !important;
}

.studentTag.selected {
  background: #f2f3f3 !important;
}

.student-tag-form {
  overflow: visible;
}

.appointment-attendees-add-button {
  border-radius: 0;
  border-top-right-radius: 19px !important;
  border-bottom-right-radius: 19px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 500;
  background-color: $primary-color !important;
  margin-left: -17px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.appointment-attendees-view {
  margin-left: 0;
  margin-bottom: 30px;
}

.appointment-attendees-list {
  list-style: none;
  padding: 0;
}

.appointment-attendees-each {
}

.attendees-rsvp {
  font-size: 60%;
  vertical-align: middle;
}

.attendees-type {
  line-height: 1;
  text-transform: capitalize;
  color: #919498;
  padding-top: 5px;
}

.attendees-name {
  color: #555;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

.attendees-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
}

.attendees-avatar-dropdown {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 2px;
}

.popover-card {
  min-width: 200px;
  max-width: 350px;
  //max-height: 250px;
  //overflow: hidden;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  .popover-content {
    padding: 0;
  }

  &::after {
    content: "";
    width: 5px;
    height: calc(100% + 2px);

    position: absolute;
    display: flex;
    top: -1px;
    left: -1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.popover-card.notStarted {
  //border-left: 5px solid $status-gray;
  &::after {
    background-color: $status-gray;
  }
}

.popover-card.inProgress {
  //border-left: 5px solid $status-yellow;
  &::after {
    background-color: $status-yellow;
  }
}

.popover-card.complete {
  //border-left: 5px solid $status-green;
  &::after {
    background-color: $status-green;
  }
}

.popover-card.onHold {
  //border-left: 5px solid $status-red;
  &::after {
    background-color: $status-red;
  }
}

.popover-card.right-top.notStarted {
  border-left: none;
  //border-right: 5px solid $status-gray;
  &::after {
    background-color: $status-gray;
  }
}

.popover-card.right-top.inProgress {
  border-left: none;
  //border-right: 5px solid $status-yellow;
  &::after {
    background-color: $status-yellow;
  }
}

.popover-card.right-top.complete {
  border-left: none;
  //border-right: 5px solid $status-green;
  &::after {
    background-color: $status-green;
  }
}

.popover-card.right-top.onHold {
  border-left: none;
  //border-right: 5px solid $status-red;
  &::after {
    background-color: $status-red;
  }
}

.popover-card-title {
  font-weight: 600;
  text-transform: capitalize;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  font-size: 17px;
}

.popover-card-booktitle {
  font-weight: 600;
  text-transform: capitalize;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $gray;
}

.popover-title-initials {
  text-transform: uppercase;
  height: 26px;
  width: 26px;
  background: $primary-color;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  word-break: break-word;
}

.popover-card-subtitle {
  padding: 5px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-word;
  max-height: 75px;
}

.popover-card-subBookTitle {
  padding: 5px 10px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popover-card-students {
  padding: 5px 10px;
  border-top: 1px solid $gray;
  font-size: 16px;
  display: flex;
  align-items: center;
  .uncomplete-status {
    height: 18px;
    min-width: 18px;
    width: 18px;
    border: 1px solid #ccc;
    background: white;
    border-radius: 4px;
  }
  .complete-status {
    height: 18px;
    min-width: 18px;
    width: 18px;
    border: 1px solid $primary-color;
    background: $primary-color;
    border-radius: 4px;
    position: relative;
    &::before {
      position: absolute;
      left: -1px;
      top: 50%;
      height: 25%;
      width: 2px;
      background-color: white;
      content: "";
      transform: translateX(6px) rotate(-45deg);
      transform-origin: left bottom;
    }
    &::after {
      position: absolute;
      left: 1px;
      bottom: 3px;
      height: 2px;
      width: 65%;
      background-color: white;
      content: "";
      transform: translateX(5px) rotate(-45deg);
      transform-origin: left bottom;
    }
  }

  .card-student-title {
    text-transform: capitalize;
    padding-left: 10px;
    width: 60%;
    margin-right: 20px;
  }
  .card-student-percentage,
  .card-student-grade {
    background: #f1f2f5;
    border-radius: 15px;
    padding: 3px 19px;
    font-size: 14px;
    width: 66px;
    text-align: center;
  }
  .card-student-grade {
    margin-left: 10px;
  }
}

.event-weekday-select {
  margin-top: -1px;
  background-color: #fff;
  .days {
    display: flex;
    .select-day {
      flex: 1;
      span {
        width: 100%;
        text-align: center;
      }
    }
  }
  input[type="checkbox"] {
    display: none;
    &:disabled + .text-label {
      cursor: not-allowed;
    }
    &:checked + .text-label {
      background-color: $primary-color;
      color: #fff;
    }
  }
  .days label:first-child .text-label {
    border-radius: 2px 0 0 2px;
  }
  .days label:last-child .text-label {
    border-radius: 0 2px 2px 0;
  }
  .select-day + .select-day .text-label {
    border-left: 0;
  }
  .text-label {
    @extend .text-capitalize;
    font-size: 14px;
    padding: 7px;
    border: 1px solid $light-gray;
    height: 35px;
    display: inline-block;
    user-select: none;
    cursor: pointer;
  }
}

.cal-view-dropdown {
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  select {
    background-color: $primary-color;
    color: #fff;
    border: 1px solid $primary-color;
    font-weight: bold;
    background-image: url("../images/caret-down.png") !important;
    background-size: 7px 14px;
    background-position-x: 95%;
  }
  option {
    background-color: #fff;
    color: #444850;
    padding: 10px 20px;
  }
}

.stu-view-dropdown {
  min-width: 165px;
  max-width: 165px;
  border-radius: 20px;
}

.calendar-search-result {
  margin-top: 20px;
  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;
    .empty-title {
      margin: 0 10px 40px;
    }
  }
}

.calendar-each-result {
  box-shadow: rgba(0, 0, 0, 0.26) 0px 0px 5px 0px;
  border-radius: 15px;
  // padding: 10px;
  margin: 10px 0;
  .search-label {
    margin-bottom: 5px;
    color: #919498;
  }
  .calendar-search-option {
    margin-left: 10px;
    fill: #919498;
  }
  .result-header {
    border-bottom: 1px solid #e9eaeb;
    padding: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }
  .result-body {
    border-bottom: 1px solid #f2f2f2;
    padding: 20px 10px;
  }
  .result-footer {
    padding: 7px 13px;
    background-color: #fafafa;
    .div-align {
      display: flex;
      align-items: center;
    }
  }
  .result-footer-lesson {
    display: flex;
    justify-content: space-between;
  }
  .result-lesson-label {
    text-transform: capitalize;
    min-width: 200px;
    max-width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .lesson-time-label {
    font-size: 12px;
    color: #919498;
    padding-left: 3px;
    // position: absolute;
    // right: 25px;
    // bottom: 15px;
  }
  .calendar-datetime-icon {
    height: 18px;
    width: 18px;
    fill: #919498;
  }
  .lesson-timer {
    position: absolute;
    top: 5px;
    right: 170px;
  }
  .lesson-day-label {
    text-transform: capitalize;
    height: 26px;
    width: 26px;
    background: #fafafa;
    border-radius: 50%;
    color: #919498;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: 5px;
  }
  .lesson-calendar-icon {
    position: absolute;
    top: 9px;
    right: 170px;
    width: 12px;
    height: 12px;
    fill: #919498;
  }
}

.calenday-result-body {
  height: 160px;
}

.caret-pos {
  position: absolute;
  top: 15px;
  right: 35px;
}

.calendar-student-data {
  list-style: none;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  li {
    margin-right: 20px;
    margin-bottom: 10px;
    max-width: 120px;
    display: flex;
    .name {
      max-width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name-length {
      max-width: 75px;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 3px;
    }
  }
}

.calendar-course-label {
  text-transform: capitalize;
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-students-name {
  text-transform: capitalize;
}

.calendar-attendees-name {
  // color: #555;
  // font-weight: 600;
  line-height: 1;
}

.calendar-search-header {
  display: flex;
  justify-content: center;
  width: 100%;
  .sicon {
    position: absolute;
    bottom: 2px;
    display: inline-block;
    right: 0px;
    padding-right: 6px;
    height: 28px;
    width: 27px;
    fill: $primary-color;
    transform: rotate(90deg);
    margin-right: 15px;
  }
}

.calendar-search-back-icon {
  display: flex;
  position: absolute;
  h3 {
    margin-top: 5px;
  }
}

.calendar-lesson-assignment-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
}

.calendar-lesson-assignment-header {
  display: flex;
}

.calendar-lesson-label {
  font-size: 14px;
}

.calendar-lesson-duration-label {
  display: flex;
  justify-content: space-around;
}

.calendar-lesson-duration-icon {
  margin-right: 10px;
  position: relative;
  top: 4px;
  svg {
    fill: #919498;
  }
}

.calendar-lesson-duration-text {
  font-size: 12px;
  color: #919498;
}

.calendar-lesson-assignment-name {
  // padding-top: 6px;
  margin-left: 20px;
}

.calendar-lesson-assignment-status-background {
  background: #f6f7f7;
  padding: 2px 5px;
  ul {
    min-width: 130px !important;
  }
  a {
    font-size: 12px;
    span.status-icon {
      margin-left: 5px !important;
    }
  }
}

.calendar-lesson-status {
  width: 12px !important;
  height: 12px !important;
}

.calendar-lesson-status-section {
  display: flex;
  justify-content: flex-end;
}

.calendar-assignment-status {
  text-align: right;
  min-width: 120px;
}

.calendar-lesson-data {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e9eaeb;
}

.search-clear-icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  fill: #919498;
}

.search-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(45deg);
  fill: #999ea2;
}

.updateStatusForAll {
  margin-top: 5px;
  margin-right: 15px;
  font-weight: 600;
}

.calendar-assignment-detail-data {
  padding-bottom: 15px;
  border-bottom: 1px solid #e9eaeb;
}

.calendar-search-location {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-reschedule-cta-section {
  padding-right: 0px;
  position: absolute;
  right: 0px;
}

.pending-assignments {
  cursor: pointer;
  height: 17px;
  width: 17px;
  background: #f06260;
  border-radius: 50%;
  position: absolute;
  border: 3px solid #fff;
  right: -5px;
  top: 1px;
}

.disable-student {
  background-color: #f4f4f4;
  // pointer-events:none;
  // cursor: not-allowed;
}

.disable-pointer {
  pointer-events: none;
}

.printer {
  background-color: #fff;
  border: 1px solid $primary-color;
  font-weight: 600;
  color: $primary-color;
  &.btn-disabled {
    pointer-events: none;
    opacity: 0.5 !important;
  }
}

.margin-top-web-5 {
  margin-top: 5px;
}

.flex-50 {
  width: 50%;
}

.create-padding-right {
  padding-right: 15px;
}

.caret-event-select {
  bottom: 14px;
  right: 16px;
  position: absolute;
}

@media (max-width: 1024px) {
  #uiCalendar.fixedHeaderClass .fc-widget-header.fc-row {
    top: 176px;
  }
  .page.sidebar-is-close #uiCalendar.fixedHeaderClass .fc-widget-header.fc-row {
    top: 176px;
  }

  .calendar-header {
    display: inline;
    .left {
      max-width: 100%;
      margin-left: 25%;
    }
    .right {
      flex: 0;
      margin: 10px 0 10px 0;
    }
    .calendar-search-box {
      width: 100%;
    }
    .align-calendar-search-icon {
      top: -12px;
    }
    .form-margin-920 {
      top: 36px;
      position: relative;
    }
    .search-back-icon-920 {
      top: 0px;
    }
    .from-top-15 {
      top: 15px;
    }
    .from-top-10 {
      top: 5px;
    }
    .from-top-ngt {
      top: -47px;
    }
    .calendar-search-result {
      margin-top: 35px;
    }
    .create-btn {
      right: 95px;
    }
    .calendar-search-icon {
      margin-left: 35px;
      margin-right: 35px;
      margin-top: 5px;
    }
    .mr-20 {
      margin-right: 35px !important;
    }
    .margin-left-20 {
      margin-left: 25px;
    }
    .caret-pos {
      right: 48px;
    }
  }
  .create-padding-right {
    padding-right: 35px;
  }
}
@media (max-width: 920px) {
  .calendar-header {
    display: inline;
    .left {
      max-width: 100%;
      margin-left: 25%;
    }
    .right {
      flex: 0;
      margin: 10px 0 10px 0;
    }
    .calendar-search-box {
      width: 100%;
    }
    .align-calendar-search-icon {
      top: -12px;
    }
    .form-margin-920 {
      top: 36px;
      position: relative;
    }
    .search-back-icon-920 {
      top: 0px;
    }
    .from-top-15 {
      top: 15px;
    }
    .from-top-10 {
      top: 5px;
    }
    .from-top-ngt {
      top: -47px;
    }
    .calendar-search-result {
      margin-top: 35px;
    }
    .create-btn {
      right: 75px;
    }
    .calendar-search-icon {
      margin-left: 25px;
      margin-right: 25px;
      margin-top: 5px;
    }
    .mr-20 {
      margin-right: 25px !important;
    }
    .margin-left-20 {
      margin-left: 25px;
    }
    .caret-pos {
      right: 48px;
    }
  }
  .create-padding-right {
    padding-right: 25px;
  }
}
@media (max-width: 768px) {
  .create-assignment,
  .create-appointment {
    .input-width {
      width: 100% !important;
    }
    .date-input {
      width: 100% !important;
    }
  }
  .margin-top-30 {
    margin-top: 30px;
  }
  .margin-top-20-768 {
    margin-top: 20px !important;
  }
  .timepicker-width-768 {
    width: 33.33% !important;
    margin-left: 0 !important;
  }
  .margin-top-web-5 {
    margin-top: 0px;
  }
  .move-more-option {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .calendar-search-result {
    margin-top: 35px;
  }
  .display-inline-force {
    display: inline !important;
  }
  .full-width-force {
    width: 100% !important;
  }
  .flex-50 {
    width: 100%;
  }
  .create-padding-right {
    padding-right: 35px;
  }
}
@media (max-width: 680px) {
  .calendar-header {
    .left {
      max-width: 100%;
      margin-left: 0;
    }
    .right {
      flex: 0;
      margin: 10px 0 10px 0;
    }
  }
  .add-margin-right {
    margin-left: 57px;
    margin-right: -30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .distribute-box {
    display: inline;
  }
  .calendar-options {
    margin-top: 5px;
    margin-left: 10px;
  }
  .allstudents-margin {
    margin-left: -10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .modal .close-icon {
    top: 34px;
  }
  .reschedule-assignments-header {
    padding: 0px 5px 0px 1px;
  }
  .calander-modal-data {
    display: flex;
    justify-content: space-evenly;
    padding-right: 0px;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .calendar-lesson-data {
    margin-bottom: 10px;
    border-bottom: 0px solid #e9eaeb;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .calendar-reschedule-cta-section {
    padding-right: 50px;
    margin-top: 5px;
  }
}

.checkbox-calendar-search {
  padding: 0px !important;
  margin: 0px !important;
}

// progress bar
progress[value] {
  /* Get rid of the default appearance */
  appearance: none;

  /* This unfortunately leaves a trail of border behind in Firefox and Opera. We can remove that by setting the border to none. */
  border: none;

  /* Add dimensions */
  width: 100%;
  height: 20px;

  /* Although firefox doesn't provide any additional pseudo class to style the progress element container, any style applied here works on the container. */
  background-color: whiteSmoke;
  border-radius: 33px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;

  /* Of all IE, only IE10 supports progress element that too partially. It only allows to change the background-color of the progress value using the 'color' attribute. */
  color: royalblue;

  position: relative;
  margin: 0 0 1.5em;
}

/*
Webkit browsers provide two pseudo classes that can be use to style HTML5 progress element.
-webkit-progress-bar -> To style the progress element container
-webkit-progress-value -> To style the progress element value.
*/

progress[value]::-webkit-progress-bar {
  background-color: whiteSmoke;
  border-radius: 33px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;
}

progress[value]::-webkit-progress-value {
  position: relative;

  background-size: 35px 20px, 100% 100%, 100% 100%;
  border-radius: 33px;

  /* Let's animate this */
  animation: animate-stripes 5s linear infinite;
}

@keyframes animate-stripes {
  100% {
    background-position: -100px 0;
  }
}

/* Let's spice up things little bit by using pseudo elements. */

progress[value]::-webkit-progress-value:after {
  /* Only webkit/blink browsers understand pseudo elements on pseudo classes. A rare phenomenon! */
  content: "";
  position: absolute;

  width: 5px;
  height: 5px;
  top: 7px;
  right: 7px;

  background-color: white;
}

/* Firefox provides a single pseudo class to style the progress element value and not for container. -moz-progress-bar */

progress[value]::-moz-progress-bar {
  /* Gradient background with Stripes */
  background-image: -moz-linear-gradient(left, #116682, #2d4a54);

  background-size: 35px 20px, 100% 100%, 100% 100%;
  border-radius: 33px;

  /* Firefox doesn't support CSS3 keyframe animations on progress element. Hence, we did not include animate-stripes in this code block */
}

/* Fallback technique styles */
.progress-bar {
  background-color: whiteSmoke;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5) inset;

  /* Dimensions should be similar to the parent progress element. */
  width: 100%;
  height: 20px;
}

.progress-bar span {
  background-color: royalblue;
  border-radius: 3px;

  display: block;
  text-indent: -9999px;
}

.marks-input {
  width: 153px;
}

.grade-text {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-view {
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding: 5px 10px;
  //border: 1px solid #ccc;
  margin-top: 12px;
  margin-right: 6px;
  border-radius: 25px;
  background-color: #f6f7f7;
}

.percentage-box {
  width: 100%;
  margin-bottom: 10px;
}

.grade-delete {
  justify-content: space-between;
  display: flex;
  margin-top: 15px;
  margin-left: auto;
  margin-right: 6px;
}

p[data-value] {
  position: relative;
}

/* The percentage will automatically fall in place as soon as we make the width fluid. Now making widths fluid. */

p[data-value]:after {
  content: attr(data-value) "%";
  position: absolute;
  right: 0;
}

.html5::-webkit-progress-value,
.python::-webkit-progress-value {
  /* Gradient background with Stripes */
  background-image: -webkit-linear-gradient(#116682, #74a5b5);
}

// preserve line breaks in Assignment Name in Lesson Plan

.text-error {
  color: red;
}

@media (min-width: 768px) {
  .statusWidthZero {
    width: 34.666667%;
    //padding-right: 0px;
  }
}

//.white-space-preserve {
//	white-space: pre-wrap !important;
//}

.show-table-view {
  padding: 15px;
  .top-view {
    width: 100%;
    background-color: #eee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 20px;
  }
  .show-table {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    background-color: #eee;
    border-collapse: collapse;
    tr {
      th {
        border-bottom: 1px solid #9f9f9f;
        border-right: 1px solid #9f9f9f;
        padding: 5px 5px 5px 5px;
        text-align: center;
      }
      td {
        border-right: 1px solid #9f9f9f;
        text-align: center;
        color: #9f9f9f;
      }
    }
  }
}
.timeDuration {
  .form-control.hour,
  .form-control.minute,
  .form-control.meridian {
    border: none;
    padding: 0px;
    color: #116682;
  }
  select.minute {
    display: none;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .timepicker .hour {
    margin-left: 0px;
  }
  .timepicker .meridian {
    margin-right: 0px;
  }
  select {
    padding-right: 0px !important;
  }
  .date-input .icon {
    right: 0;
  }
  .timepicker .form-control {
    width: 25px;
  }
  .calendar-time .form-control {
    width: 50px;
  }
}
.student-empty-error {
  .tags {
    border: 1px solid #ff0000 !important;
    box-shadow: 0 0 3px #f80505;
  }
}

.smalldesc {
  height: 0px;
  overflow: hidden;
  display: none;
}
.bigdesc {
  height: auto;
}
.assign-expander {
  text-decoration: none;
  margin-left: 10px;
  font-size: large;
  position: absolute;
  bottom: 15%;
  left: 6%;
}

.hide-expand-label {
  display: block;
  position: absolute;
  top: 0%;
  left: 7%;
  font-size: initial;
  color: #116682;
}
.hide-expand-label-input {
  position: absolute;
  left: -999em;
}
/* example 3 */
.ex3 .hide3 {
  width: 100%;
  display: table;
  height: 64px; /* any arbitrary height but best at the minimum initial height you would want. */
  overflow: hidden;
  background: transparent;
  transition: all 0.5s ease;
  margin-bottom: 5px;
}
.inner3 {
  padding-top: 10px;
}
.ex3 input[type="checkbox"]:checked + .hide3 {
  height: 0 !important;
  opacity: 0;
  display: block;
}

@media (max-width: 1366px) {
  .tooltip.customClass {
    bottom: 78px !important;
  }
}
.hasSuccess {
  .tags {
    //.student-empty-error .tags{
    border: 1px solid #116682 !important;
    box-shadow: 0 0 3px #116682 !important;
  }
  //}
}

.has-hidden-fields {
  display: none;
}
#NavbarCollapse {
  max-height: 25vh;
  overflow-y: auto;
}
.rotate-180 {
  transform: rotate(180deg);
}
.accordion-toggle {
  text-decoration: none;
}
.dropdown-lesson {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px 20px;
  background-color: gainsboro;
  color: #333;
}
.filter-item {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.tick-margin {
  margin-left: 10px;
}
.margin-top-75 {
  margin-top: 70px !important;
}
.clear_filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
}

.new-student-grading {
  margin-right: 0;
  .grading-see-more {
    padding: 10px 0;
    text-align: center;
    color: #106682;
    font-size: 16px;
    font-weight: 500;
  }
  .student-grading-header {
    margin-right: 0;
    margin-left: 0;
    .title {
      font-weight: 500;
      margin-right: 0;
      text-align: center;
    }
  }
  .student-grading-list {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    margin-right: 0;
    margin-left: 0;
    .student-col {
      padding-left: 0;
    }
    .student-detail-left {
      display: flex;
      align-items: center;
    }

    .input-grade-div {
      position: relative;
      max-width: 200px;
      margin: 0 auto;
      .dropdown-menu.dropdown-menu-right {
        //text-align: center;
        border-radius: 10px;
        max-height: 250px;
        overflow: auto;
      }
      .form-control {
        width: 100%;
        height: 30px;
      }
      .grading-list-drop-arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f1f2f5;
        border: 1px solid #ccc;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        .icon-grade-dropdown {
          height: 18px;
          width: 18px;
          fill: #555;
        }
      }
    }

    .student-grade-clear {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
    }
  }

  .grade-main-box {
    text-align: center;
    width: 100%;
    .grade-input-div {
      background-color: #f1f2f5;
      border-radius: 25px;
      height: 30px;
      width: 70px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.graded-box {
  background: #fff;
  color: black;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 11px;

  &.graded {
    color: #3eb149;
  }
  .tick-mark {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 3px;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      height: 50%;
      width: 2px;
      background-color: #3eb149;
      content: "";
      transform: translateX(4px) rotate(-45deg);
      transform-origin: left bottom;
    }
    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: #3eb149;
      content: "";
      transform: translateX(4px) rotate(-45deg);
      transform-origin: left bottom;
    }
  }
}

#grade-book-widget {
  .conversation {
    display: flex;
    .student-profile-detail {
      display: flex;
      align-items: center;
      padding-top: 16px;
      width: 200px;
      .student-name {
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 120px;
      }
      .assignment-check-complete {
        margin-right: 10px;
      }
    }
    .student-lesson-details {
      flex: 1;
      padding-left: 20px;
      .conversation-meta {
        .assignment-date {
          .assignment-details-flex {
            margin-top: auto;
            margin-bottom: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 10px;
          }
        }
        .assignment-date-box {
          width: 105px;
          padding-right: 0;
        }
        .assignment-details-flex {
          margin-top: auto;
          margin-bottom: auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 10px;
        }
        .assignment-type {
          text-transform: capitalize;
          align-items: center;
          display: flex;
          font-weight: 500;
          padding-top: 15px;
          width: 100px;
          margin-right: 0;
        }

        .percentage-dropdown {
          width: 160px;
          margin: auto 0;
          padding-right: 0;

          .input-grade-div {
            position: relative;
            max-width: 200px;
            margin: 0 auto;
            margin-bottom: 15px;
            .dropdown-menu.dropdown-menu-right {
              //text-align: center;
              border-radius: 10px;
              width: 175px;
              max-height: 250px !important;
              overflow: auto;
              margin-bottom: 45px;
            }
            .form-control {
              width: 100%;
              height: 30px;
            }
            .grading-list-drop-arrow {
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f1f2f5;
              border-radius: 0;
              border: 1px solid #ccc;
              border-top-right-radius: 25px;
              border-bottom-right-radius: 25px;
              margin-right: 0px;
              padding: 0;
              .icon-grade-dropdown {
                height: 18px;
                width: 18px;
                fill: #555;
              }
            }
          }
        }
        .percentage-title-top {
          margin-bottom: 10px;
          font-weight: 500;
        }
        .percentage-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0;
          padding-right: 0;
          margin-right: 0;
          flex-direction: column;
          margin-bottom: 15px;
          width: 100px;
          .percentage-title-top {
            width: 100%;
            text-align: right;
          }

          .grade-main-box {
            text-align: center;
            width: 100%;
            .grade-input-div {
              background-color: #f1f2f5;
              border-radius: 25px;
              height: 30px;
              width: 70px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 0px 0 auto;
            }
          }
        }
      }
    }

    &.not-first-item {
      .student-profile-detail {
        padding-top: 0;
      }
      .assignment-details-flex {
        justify-content: center;
        padding-bottom: 0;
      }
      .assignment-details-flex {
        justify-content: center !important;
        padding-bottom: 0 !important;
      }
      .assignment-type {
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
      .input-grade-div {
        margin-bottom: 0 !important;
      }
      .percentage-box {
        margin-bottom: 0 !important;
        justify-content: center !important;
        padding-bottom: 0px !important;
      }
    }
  }
}
.go-to-date-label {
  &:hover {
    cursor: pointer;
  }
}
.go-to-date {
  border: none;
  color: white;
  position: absolute;
  left: 6.5%;
  top: 60%;
  width: 1px;
  &:focus {
    border: none;
    color: transparent;
    outline: none;
  }
  &:active {
    border: none;
  }
}
.cell-Bg {
  background-color: #5fdefe;
}

.resassignment-title-show-less {
  overflow: hidden;
  max-height: 50px;
}

.resassignment-title-show-more {
  height: auto;
}
