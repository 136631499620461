//
// Login and signup page styles
// --------------------------------------------------

.login,
.signup,
.forgot-password,
.reset-password {
	min-height: 100vh;
	// background-color: $login-bg;
	// padding: 60px 20px 40px;
	overflow-y: scroll;
	// height: 400px;
	@media (min-height: 700px) {
		// padding-top: 80px;
	}

	.logo {
		display: block;
		max-width: 200px;
		margin: auto;
		// margin: 0 auto 120px;
		@media (max-width: 850px) {
			margin-bottom: 60px;
		}
		img {
			display: inline-block;
			max-width: 100%;
		}
	}

	.wrapper {
		max-width: 900px;
		margin: 0 auto;
		position: relative;
	}

	.form-item-wrap {
		display: flex;
	}

	.form-group {
		&.has-error .help-text {
			color: $brand-danger;
		}
		&.has-error .form-control-icon {
			fill: $brand-danger;
		}
		&.has-error .form-control {
			box-shadow: none;
		}
		.help-text {
			font-size: 13px;
		}
	}

	.form-control {
		padding-left: 30px;
		&:focus {
			box-shadow: none;
		}
	}

	.form-control-icon {
		width: 14px;
		height: 14px;
		position: absolute;
		top: 10px;
		left: 10px;
		fill: #777;
		&.show {
			visibility: hidden;
			left: auto;
			right: 10px;
			cursor: pointer;
		}
	}

	.main-card {
		position: relative;
		left: 0;
		right: 0;
		padding: 80px 60px;
		z-index: 1;
		background-color: #fff;
		border-radius: 10px;
		@media (max-width: 850px) {
			padding: 20px;
			margin-top: 20px;
		}
	}

	.main-card-title {
		font-weight: 300;
		font-size: 30px;
	}

	.main-card-desc {
		max-width: 350px;
		font-weight: 300;
		line-height: 1.33;
		font-size: 18px;
		margin-bottom: 30px;
	}

	.signup-heading {
		font-size: 23px;
		color: #116682;
		font-weight: 600;
		font-family: 'Nunito';
		margin-bottom: 10px;
		max-width: 366px;
	}

	.holding-card {
		padding: 20px;
		background-color: #fff;
		// box-shadow: 0px 10px 50px 0 rgba(163, 163, 163, 0.5);
		color: $secondary-font-color;
		// border-radius: 10px;

		.parents {
			width: 80% !important;
			.row {
				display: flex;
				justify-content: center;
			}
			.align-items-center {
				align-items: center;
			}
			h6 {
				width: 62%;
				border-bottom: 2px solid rgba(0, 0, 0, 0.1);
				line-height: 0.05em;
				margin: 18px;
				text-align: center;
				font-size: 0.9rem;
				color: #999;
				span {
					background: #fff;
					padding: 0 10px;
				}
			}
	
			.btn:not(:disabled):not(.disabled) {
				cursor: pointer;
			}
	
			.btn-facebook {
				color: #fff;
				background-color: #3b5998;
				border-color: rgba(0,0,0,0.2);
			}
	
			.btn-google {
				color: #fff;
				background-color: #dd4b39;
				border-color: rgba(0,0,0,0.2);
			}
			
	
			.btn-social {
				position: relative;
				padding-left: 44px;
				text-align: left;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-radius: 0.25rem;
				margin: 14px;
				font-size: 0.9rem;
				padding-right: 20px;
			}
	
			.btn-social>:first-child {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 32px;
				line-height: 34px;
				font-size: 1.6em;
				text-align: center;
				border-right: 1px solid rgba(0,0,0,0.2);
			}
	
			.fa {
				display: inline-block;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
	
			.parents .fa-facebook {
				color: white;
				padding-top: 2px;
			}
	
			.fa-facebook:before {
				content: "\f09a";
			}
	
			.fa-google:before {
				content: "\f1a0";
			}
		}

		@media (min-width: 850px) {
			// position: absolute;
			padding: 30px;
			// top: 50%;
			// width: 420px;
			z-index: 2;
			// transform: translateY(-50%);
			margin-left: auto;
		}
	}

	.holding-card-title {
		@extend .text-primary;
		padding-bottom: 21px;
		font-weight: 300;
	}

	.external-services {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.external-link {
		display: inline-flex;
		align-items: center;
		margin: 0 10px;
		color: inherit;
		text-decoration: none;
	}

	.external-icon {
		width: 16px;
		height: 16px;
		margin-right: 2px;
	}
}
