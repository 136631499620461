@import "./CalendarComponents/TopHeader/topheader.less";
@import "../react-rescheduler/rescheduler.less";
#react-calendar {
  .fc-view-harness {
    background-color: white;

    // .fc-timegrid-event .fc-v-event .fc-event {
    //   background-color: none !important;
    //   border: none !important;
    // }
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    background: transparent !important;
    border: none !important;
  }
}
.page-heading {
  min-height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-left: 30px;
  padding-right: 20px;
  border-bottom: 1px solid #dddddd;

  .title-left {
    display: flex;
    align-items: center;
    img {
      height: 30px;
      width: 30px;
    }

    h1 {
      color: #555;
      font-size: 16px;
      margin-bottom: 0;
      padding-left: 10px;
    }
    svg {
      &:hover {
        cursor: default;
      }
    }
  }
  .banner-header {
    padding: 10px 0px;
    .ant-page-header-heading-title {
      font-size: 16px !important;
    }
  }

  .tutorialicons_right {
    svg {
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        path {
          fill: #116682;
        }
      }
    }
  }
}
.hsp-day-picker {
  .DayPicker-Month {
    width: 36%;
    height: 36%;
    .DayPicker-Day {
      display: table-cell;
      padding: 0.2em;
      border-radius: 4px;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      background-clip: content-box, padding-box;
    }
    .DayPicker-Day--today {
      color: #116682;
      font-weight: 800;
    }
    .DayPicker-Day--daysOff {
      color: #ff9f01 !important;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
        .DayPicker-Day--outside
      ) {
      position: relative;
      background-color: @primary-color;
      color: #f0f8ff;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}
// .fc {
//   height: 91vh !important;
// }
.fc-popover {
  z-index: 4 !important;
}

.ant-modal {
  height: 75vh;
}

.ant-modal-body {
  //min-height: 40vh;
  max-height: 78vh;
  overflow-y: auto;
}

.fc-daygrid-event {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;

  // background: rgb(34, 193, 195);
  // background: linear-gradient(
  //   0deg,
  //   rgba(34, 193, 195, 1) 0%,
  //   rgba(253, 187, 45, 1) 100%
  // );
  // background: linear-gradient(
  //   45deg,
  //   rgba(0, 0, 0, 1) 3%,
  //   rgba(0, 0, 0, 1) 23%,
  //   rgba(132, 136, 136, 1) 77%,
  //   rgba(138, 144, 145, 1) 91%
  // );
  //background-color: red;
  &:hover {
    opacity: 0.9;
  }
}

.fc-daygrid-day {
  height: 174px;
}

.calendarSpinner {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullWidth {
  width: 100%;
}

.daysOff-drawer {
  // width: 100%;
  .ant-avatar-image {
    height: 25px !important;
    width: 25px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
  }
  .ant-avatar-circle {
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
  }

  .ant-avatar-string {
    font-size: 10px !important;
  }

  height: 100%;
  display: grid;
  grid-template-rows: 0.5fr 9fr;
  .daysOff-day-picker {
    // height: 400px;
    overflow-y: scroll;
    border: 1px solid white;
    box-shadow: 0 0 2px #e9f0f5;
  }
  .daysOff-drawer-from {
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 0%;
  }
}

.calendar-tiles {
  width: 100%;
  height: 50px;
  padding: 3px;
  font-size: 13px;
  border-radius: 4px;
  .event-cat-container {
    display: flex;
    justify-content: space-between;
    margin-top: -4px;
    .icon-cat-wrapper {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .cat-title-wrapper {
      font-size: 12px;
      font-style: italic;
      // display: inline-flex;
      padding: 3px 8px;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 85%;
    }
  }
}

.chore-popover-container {
  width: 417px;
  border-radius: 16px;
  background: #fff;
  .tooltip-header {
    display: flex;
    // justify-content: space-around;
    .tooltip-icon {
      width: 39.446px;
      height: 39.446px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 59.873px;
      background: rgba(217, 217, 217, 0.25);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .tooltip-text {
      .chore-title {
        color: #3d3d3d;
        font-family: Poppins;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .chore-info {
        display: flex;
        align-items: center;
        color: #b7b6b6;
        text-align: right;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
      }
    }
    .tooltip-category {
      // display: table;
      margin-left: auto;
      max-width: 40%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: italic;
      padding: 4px 8px;
      border-radius: 25.569px;
      background: #1e6682;
      box-shadow: 0px 5.812600612640381px 21.97702980041504px 0px
        rgba(79, 74, 74, 0.09);
      color: rgb(255, 255, 255);
      height: 27px;
    }
  }
  .tooltip-body {
    margin-top: 15px;
    border-radius: 11.27px;
    background: #fff;
    box-shadow: 0px 4.508108139038086px 16.905405044555664px 0px
      rgba(162, 161, 161, 0.18);
    padding: 15px;
  }
}

.assignee-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .ant-checkbox-wrapper {
    height: 20px;
  }
  .assignee-info {
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    height: 30px;
  }
}

.calendar-event-tile {
  display: grid;
  grid-template-columns: 2fr 1fr;
  .calendar-event-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    text-transform: capitalize;
  }
  .avatar-group-custom {
    justify-self: end;
    align-self: center;
  }
  .assignment-student-avatars {
    align-self: end;
    justify-self: end;
  }
}
.disabledDiv{
  opacity: .7;
  pointer-events: none;
  cursor: not-allowed;
}

.calendarEvents {
  .grade-checkbox {
    grid-area: checkbox;
  }

  .lessonName {
    grid-area: lessonName;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 5px;
    line-height: 1.7;
    text-transform: capitalize;
  }

  .assignment-student-avatars {
    grid-area: avatarGroup;
    justify-self: end;
    align-self: end;
  }

  .assignment-title {
    grid-area: title;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    max-width: 120px;
    justify-content: start;
    align-self: center;
    text-transform: capitalize;
  }

  .gradeStatus {
    grid-area: graded;
    color: black;
    align-self: center;
    justify-self: end;
    border-radius: 25px;
    font-weight: 500;
    font-size: 10px;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -20px;
    padding: 2px 6px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 5fr 3fr;
  row-gap: 1px;
  grid-template-areas:
    "checkbox lessonName avatarGroup"
    "title title graded";
}

.TooltipHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  .header-Title {
    font-size: 17px;
    margin-left: 14px;
    text-decoration: none;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    width: 100%;
  }
}

.tooltip-data {
  color: black;
width: 100%;
  .tooltip-title {
    width: 100%;
    color: #555;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-transform: capitalize;
    margin: 8px 0px 10px 0px;
  }
}

.student-Info-Container {
  width: 100%;
  display: grid;

  grid-template-columns: 51% 21% 10%;
  gap: 10px;
}
.assignment-tooltip-scroll {
  height: 130px;
  overflow-y: auto;
}

.tooltip-Row {
  display: grid;
  grid-template-columns: 7% 60% 17% 16%;
  gap: 5px;
  margin-top: 10px;
  width: 95%;
  .ant-avatar {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    .ant-avatar-string {
      position: absolute;
      left: 50%;
      transform-origin: 0 center;
      bottom: -4px;
    }
  }
  .toolPercent {
    border-radius: 10px;
    background-color: #f1f2f5;
    width: 50px;
    padding: 1px;
    text-align: center;
  }
}

.anticon {
  &:hover {
    cursor: pointer;
  }
}

//Add to Calendar Page model Styling

//Create Cancel Buttons at the footer of the Form
// .hspButton-Container {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }
// .createHsp-Button {
//   height: 35px !important;
//   width: 106px !important;
//   border-radius: 5px !important;
//   background-color: #106682 !important;
//   color: #fff !important;
//   border: none !important;
//   font-size: 13px;
//   text-transform: uppercase;
// }
// .cancelHsp-Button {
//   height: 35px !important;
//   width: 106px !important;
//   border-radius: 5px !important;
//   background-color: #fff !important;
//   border: 1px solid #e9eaeb !important;
//   color: #919498 !important;
//   border: 1px solid #e9eaeb !important;
//   font-size: 13px;
//   margin-right: 30px;
//   text-transform: uppercase;
// }

.eventPopContainer {
  width: 100%;
  .eventPopTitle {
    width: 100%;
    color: #555;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-transform: capitalize;
    margin: 8px 0px 10px 0px;
  }

  .eventPopDate {
    color: #919498;
    font-family: "Poppins";
    font-size: 14px;
    line-height: 24px;
  }
  .right-check {
    height: 150px;
    overflow-y: scroll;
  }

  .students-Container {
    margin-top: 20px;

    .student {
      margin: 10px 0px;
      display: flex;
      align-items: center;
    }
  }
}

.eventTooltipHeader {
  display: flex;
  align-items: center;
  width: 100%;

  .eventToolTipTitle {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//Create Assignment Form Styling
.createAssignmentModal {
  .CreateAssignmentHeader {
    display: grid;
    grid-template-columns: 5fr 1fr;
    .modal-title {
      padding-left: 10px;
      text-transform: capitalize;
      font-weight: bold;
    }
    .tutorialicons_right {
      svg {
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          path {
            fill: #116682;
          }
        }
      }
    }
  }
}

.assignmentFormContainer {
  .ant-form-item-explain {
    font-size: 13px !important;
  }
  .ant-avatar-image {
    height: 25px !important;
    width: 25px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
  }
  .ant-avatar-circle {
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
  }

  .ant-avatar-string {
    font-size: 10px !important;
  }
  .createAssignmentGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    .ant-radio-group {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
    }

    // .studentOptions {
    //   .ant-select-selection-item {
    //     display: flex !important;
    //     align-items: flex-start !important;
    //   }
    //   font-size: 12px !important;
    //   font-weight: 12px !important;
    //   height: 35px;
    //   width: 100%;
    //   border-radius: 5px;
    // }
    // .studentsSelectOption {
    //   font-size: 12px !important;
    //   font-weight: 12px !important;
    // }

    .GradedItem {
      .ant-form-item {
        flex-direction: row !important;
        align-items: baseline !important;
      }
      .assignmentGradeSwitch {
        margin-left: 15px;
      }
    }

    .Gradespan {
      margin-right: 30px;
    }
  }

  .ant-tabs-content-holder {
    min-height: 120px !important;
  }
}

//  View Days Off Styling
.DayOffTooltipTitle {
  display: flex;
  align-items: center;
  margin-bottom: -8px;

  b {
    margin-left: 20px;
  }
}

.viewDayoffContainer {
  .viewDayOffData {
    display: grid;
    grid-template-columns: 62% 35%;
    column-gap: 15px;

    .space {
      display: grid;
      grid-template-columns: 26% 64%;
      column-gap: 10px;
      margin-bottom: 10px;

      .info-Title {
        color: #999;
        font-size: 16px;
      }

      .dayOff-Info {
        color: #555;
        font-size: 16px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        cursor: pointer;
      }
    }

    .viewButtonContainer {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      // column-gap: 10px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: end;
      .delIcon {
        svg {
          path {
            &:hover {
              fill: red;
            }
          }
        }
      }
    }
  }
}

//   .event-file-container {
//     height: 16em;
//     overflow-y: auto;
//   }

//   //Event Form Styling

.calendar-event-view {
  display: grid;
  grid-template-columns: 90% 10%;

  .event-view-info {
    display: grid;
    grid-template-columns: 15% 80%;
    gap: 15px;
    margin-top: 7px;

    .viewEvent-title {
      // color: #999;
      font-size: 16px;
      // font-family: Poppins;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .event-show-text {
      color: #116682;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
    }

    .viewEvent-Info {
      //   color: #555;
      //font-family: Poppins;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .show-event-text {
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  .event-selection {
    display: flex;
    align-items: center;
  }
  .location-Container {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .viewEvent-location {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
      //  color: #555;
      //  font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      width: 90%;
    }

    .locationIcon {
      font-size: 20px;
      margin-right: 15px;
    }
  }
}

//View Event Attendies Styles
.attendees-tabpane {
  display: grid;
  // grid-template-columns: 47% 47% 10%;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  .ant-card-bordered {
    border: none !important;
  }
  .attendees-container {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 0px;
    .attendees-Info {
      display: flex;
      align-items: center;
      //border justify-content: space-around;
      .ant-avatar-circle {
        width: 40px;
        height: 40px;
        .ant-avatar-string {
          position: absolute;
          left: 50%;
          top: 11%;
          transform-origin: 0 center;
        }
      }
      .name-container {
        display: flex;
        margin-left: 12px;
        flex-direction: column;
        justify-content: flex-start;
        h4 {
          color: #555;
          font-size: 16px;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 140px;
        }
        .type {
          color: #999;
          font-size: 11px;
          line-height: 15px;
        }
      }
    }
    .attendees-span {
      width: 100%;
      cursor: pointer;
      border-radius: 15px;
      background-color: #7dc568;
      color: #fff;
      font-size: 11px;
      padding: 4px 15px;
      margin: 0px 11px 0px 26px;
    }
  }
}
// .ant-avatar {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.event-Form-Container {
  .ant-avatar-image {
    height: 25px !important;
    width: 25px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
  }
  .ant-avatar-circle {
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
  }

  .ant-avatar-string {
    font-size: 10px !important;
  }
  .event-Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .sprite_dates {
      display: flex;
      gap: 10px;
      .ant-picker {
        width: 100%;
      }
    }

    .btn-event-repeatition {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: 10px;
      }
    }
  }
  .event-color-dots {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.event-recurrence-list {
  width: 100%;

  .repeat-options {
    .repeatLabel {
      display: block;
      margin-bottom: 9px;
    }
  }

  .ant-select {
    width: 100%;
  }

  .repeat-select-input {
    .repeateveryLabel {
      font-size: 14px;
      font-weight: bold;
      color: #919498;
      display: block;
      margin-top: 10px;
      margin: 10px 0px;
    }
  }

  .repeatendLabel {
    font-size: 14px;
    font-weight: bold;
    color: #919498;
    display: block;
    margin: 10px 0px;
  }

  .radioGroupContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;

    .radioGroupInput {
      margin: 0 15px;
    }

    .ant-picker {
      margin-left: 27px;
    }
  }
  .weekday-select {
    .weekdaySelectLabel {
      display: block;
      margin: 10px 0px;
    }
    .ant-tag {
      text-transform: capitalize;
    }
  }
}

.fc-day-selected {
  background: #daf3fb !important;
}

svg {
  &:hover {
    cursor: pointer;

    .DeleteIcon_react_svg__delete-svg {
      fill: red;
    }
  }
}

// CalanderSetting
.ViewAssignmentModel {
  .ant-modal-close {
    margin-top: 15px !important;
  }
}

.calender-header {
  display: flex;
  align-items: center;
  .confirmation-icon {
    font-size: 20px;
    color: #fa9917;
  }
  .modal-title {
    padding-left: 10px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
}
.modal-calendar-header {
  display: grid;
  grid-template-columns: 4fr 1fr;

  .check-1 {
    display: flex;
    align-items: center;
  }
  .button-container {
    display: flex;
    margin-right: 1rem;
    .button-styling {
      width: 3.2rem;
      height: 2.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 4px;
      cursor: pointer !important;
    }

    .delete-button {
      cursor: pointer !important;
      svg:hover {
        color: red;
      }
    }
    .edit-button:hover {
      svg:hover {
        color: @primary-color;
      }
    }
  }

  .modal-title {
    padding-left: 10px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    .cancel-edit {
      padding-left: 10px;
    }

    .assignment-category {
      width: 130px;
    }
    &:hover {
      .editIconShow {
        display: block;
      }

      cursor: pointer !important;
    }
    .editIconShow {
      display: none;
      padding-left: 5px;
    }
  }
}
.main-assignment-container {
  .assignment-detail {
    .assignment-info {
      display: grid;
      grid-template-columns: 18% 82%;
      .progress {
        height: 20px;
        width: 50%;
      }
      .course {
        text-transform: capitalize;
      }

      .ant-space-vertical {
        flex-direction: row !important;
        align-items: center;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: end;
  }
}

.grading-detail {
  .grading-button {
    display: flex;
    p {
      font-size: 14px;
      font-weight: 500;
    }
    .switch-button {
      margin: 5px 0 0 5px;
    }
  }
  .grading {
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  margin-top: 20px;
}

.grading-score {
  display: grid;
  grid-template-columns: 1.7fr 2fr 2fr 1fr 1fr;
  margin-top: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 9px;
  align-items: center;
  justify-content: center;

  .autocomplete-input {
    align-self: center;
    justify-self: end;
  }

  .grading-input {
    background-color: #f1f2f5;
    border-radius: 25px;
    height: 30px;
    width: 70px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clear-button {
    justify-self: center;
    align-self: center;
  }

  .clear-button :hover {
    color: red;
    cursor: pointer;
  }

  .clear-data {
    justify-self: center;
    align-items: center;
  }

  .username-checkboxes {
    .student-avatar {
      margin: 0px 10px;
    }
    .student-name {
      font-size: 16px;
    }
  }
}

.under-grading-line {
  margin-top: 30px;
  margin-bottom: 20px;
  background: #e9eaeb;
  width: 100%;
  height: 2px;
}

.show-more-text {
  padding: 10px 0;
  text-align: center;
  color: #106682;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.assignment-inner-text {
  background: #eef0f4;
  min-height: 70px;
  border-radius: 5px;
  padding: 10px;
  .empty-data {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .assignment-files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 170px;
    overflow-y: scroll;

    .loading-state {
      border-radius: 4px;
      border: 1px solid #ccc;
      width: 125px;
      height: 130px;
      text-align: center;
      margin: 5px 5px;
      background-color: #00000080;

      .ant-progress-circle {
        .ant-progress-text {
          color: white;
        }
      }
    }

    .uploaded-files {
      border-radius: 4px;
      border: 1px solid #ccc;
      width: 125px;
      height: 130px;
      text-align: center;
      margin: 5px 5px;
      position: relative;

      &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        opacity: 0;
        transition: all 0.3s;
        content: " ";
      }
      &:hover {
        svg {
          fill: white;
          z-index: 2;
        }
      }
      &:hover:before {
        opacity: 1;
      }

      .uploaded-file-actions {
        z-index: 2;
        position: absolute;
        bottom: 50px;
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding: 0 10px;
        .upload-files-del {
          cursor: pointer;
          position: relative;
          left: unset;
          bottom: unset;
          right: unset;
          svg {
            &:hover {
              fill: red;
            }
          }
        }

        .upload-files-preview {
          cursor: pointer;
          position: relative;
          left: unset;
          bottom: unset;
          right: unset;
          svg {
            &:hover {
              //fill: rgb(48, 47, 47);
            }
          }
        }
      }
    }
  }
}

.collapse-header {
  .ant-collapse-header {
    font-size: 14px;
    font-weight: 500;
    background-color: white;
    &:hover,
    &:active {
      background-color: #eef0f4;
    }

    .draggable-icon {
      width: 15px;
      height: 15px;
      margin: 3px 2px 2px 2px;
    }
  }
  margin-top: 7px;
  box-shadow: 0px 0px 0px 1px #e2e5e8;
  border: none;
}

//confirmation modal
.single-students-buttons {
  display: flex;
  flex-direction: column;
  min-height: 125px;
  justify-content: space-evenly;
  margin-top: 20px;
}

.multiple-students-buttons {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  justify-content: space-evenly;
  margin-top: 20px;
}
.confirmation-heading {
  color: #555555;
  font-weight: 500;
}

//for editor
.ck-editor {
  .editor-button {
    display: flex;
    justify-content: end;
    margin-top: 10px;

    .save-button {
      margin-right: 5px;
    }
  }
}

//edit assignment modal

.edit-assignment-container {
  .date-picker {
    width: 100%;
  }
  .duration-time {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    height: 5rem;
    .time-picker {
      width: 100%;
    }
    .all-day-button {
      display: flex;
      justify-content: space-between;
      width: 35%;
      align-items: center;
    }
    .time-piker-separate {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }
  .ant-avatar-image {
    height: 25px !important;
    width: 25px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
  }
  .ant-avatar-circle {
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
  }

  .ant-avatar-string {
    font-size: 10px !important;
  }
  .update-assignment-datepicker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
.fc-day-today {
  //  background: #eef0f4 !important;
  background: #daf3fb !important;
}

.view-assignment-date-picker {
  .ant-picker-footer {
    .ant-picker-footer-extra {
      border: none !important;
      .time-range-picker {
        display: flex !important;
        .range-picker-switch {
          padding-left: 5px;
        }
      }
    }
    .ant-picker-ranges {
      margin-top: -40px !important;
    }
  }
}
.WinkingPandaContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .winkingPandaLoader {
    // position: fixed;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, -50%);
    width: 400px;
    height: auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // z-index: 1000;
    // transform: translate(-50%, -50%);
  }
}

.dots-check {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
.dropdown-class-set {
  .ant-select-item-option-selected {
    align-items: center !important;
  }
}

.event-todo-tab {
  margin-left: 10px;
  overflow-y: auto;
  max-height: 200px;
  .event-todo {
    margin-bottom: 10px;
  }
  .event-todo-title {
    text-transform: capitalize;
    margin-left: 10px;
  }
}

.event-title-color {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 10px;
}

.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 77px;
  border-top: 1px solid #ddd;
}

.daysOffFormContainer {
  .ant-avatar-image {
    height: 25px !important;
    width: 25px !important;
  }
  .ant-select-selection-item {
    align-items: center !important;
  }
  .ant-avatar-circle {
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
  }

  .ant-avatar-string {
    font-size: 10px !important;
  }
}
.fc-daygrid {
  .fc-scrollgrid-sync-table {
    height: 682px !important;
  }
}

.radio-confirmation-modal {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  height: 80px !important;
}
.fc-event-main {
  overflow: hidden;
}

//create assignment

.create-assignment-datepicker {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  column-gap: 2%;
  align-items: center;
  .ant-picker {
    width: 100%;
  }
}

//dayoff

.days-off-datepicker {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2%;
}

.fc-resourceTimeGridDay-view,
.fc-timeGridDay-view,
.fc-timeGridWeek-view {
  .calendarEvents .assignment-title {
    white-space: unset;
    max-width: 100%;
    overflow: visible;
  }
}

.ant-modal-body {
  max-height: 88vh;
}

.daysoff-multiple-header {
  width: 95%;
  display: flex;
  .daysoff-title {
    font-size: 18px;
  }
  .daysoff-play-info-svg {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    svg {
      width: 22px;
      height: 22px;
      margin-left: 10px;
      &:hover {
        fill: @primary-color;
      }
    }
  }
}

.daysoff-multiple-container {
  position: relative;
  height: 100%;
  .ant-spin-nested-loading {
    height: 100% !important;
  }
  .ant-spin-container {
    height: 100% !important;
  }
  .info-flexbox {
    display: flex;
    align-items: center;
    .info-container {
      width: 40%;
      display: flex;
      justify-content: space-between;
      color: #263238;
      .stats-wrapper {
        .stats-info {
          display: flex;
          .info-header {
            white-space: nowrap;
            font-weight: 600;
          }
          .academic-year {
            white-space: nowrap;
          }
          .stat-label {
            width: 125px;
          }
        }
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #707070;
  }
  .calendar-container {
    height: 78%;
    overflow-y: scroll;
    .date-wrapper {
      width: 25px;
      height: 25px;
      display: table;
      margin: auto;
    }
    .DayPicker {
      width: 100%;
    }
    .DayPicker-Months {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      .DayPicker-Month {
        font-size: 15px;
        margin-top: 0.5em;
        .DayPicker-Day {
          padding: 0;
          padding-top: 1px;
          padding-left: 1px;
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        .DayPicker-Day--today {
          color: #116682;
          font-weight: 800;
        }
        .DayPicker-Day--daysOff {
          position: relative;
          text-decoration: none;
          color: #fff;
          z-index: 1;
          background-color: transparent;
        }
        .DayPicker-Day--daysOff:not(.DayPicker-Day--assignments):not(
            .DayPicker-Day--selected
          ):not(.DayPicker-Day--outside)::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #ff9f01;
          z-index: -1;
        }
        .DayPicker-Day--assignments {
          position: relative;
          text-decoration: none;
          color: #fff;
          z-index: 1;
          background-color: transparent;
        }
        .DayPicker-Day--assignments:not(.DayPicker-Day--daysOff):not(
            .DayPicker-Day--selected
          ):not(.DayPicker-Day--outside)::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #1e6682;
          z-index: -1;
        }
        .DayPicker-Day--daysOff.DayPicker-Day--assignments {
          position: relative;
          text-decoration: none;
          color: #fff;
          z-index: 1;
          background-color: transparent;
        }
        .DayPicker-Day--daysOff.DayPicker-Day--assignments:not(
            .DayPicker-Day--selected
          ):not(.DayPicker-Day--outside)::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: linear-gradient(0deg, #ff9f01 50%, #1e6682 50%);
          z-index: -1;
        }
        .DayPicker-Day--selected {
          position: relative;
          z-index: 1;
          background-color: transparent;
          color: #000 !important;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--outside)::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 2px solid #ff9f01;
          z-index: -1;
        }
      }
    }
  }

  .button-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -1px 5px #00000029;
    background-color: #fff;
    z-index: 1;
    .manage-daysoff-button {
      width: 218px;
      height: 35px;
      font-size: 16px;
      background-color: #fff;
      color: #1e6682;
      border: 1px solid #1e6682;
      &:hover {
        background-color: #1e6682;
        color: #fff;
      }
    }
    .create-wrapper {
      position: relative;
      width: 35%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .days-off-count {
        min-width: 100px;
      }
      .multi-daysoff-button {
        width: 218px;
        height: 35px;
        font-size: 16px;
        background-color: #1e6682;
        color: #fff;
      }
    }
  }
}

.student-group-container {
  width: 310px;
  color: #686868;
  .group-item {
    width: 100%;
    display: flex;
    align-items: center;
    .item-icon {
      width: 18%;
    }
    .item-text {
      width: 57%;
    }
    .item-assignee {
      width: 25%;
    }
  }
}

.student-info-container {
  width: 229px;
  color: #686868;
  .info-header {
    width: 100%;
    display: flex;
    align-items: center;
    .avatar-wrapper {
      width: 22%;
    }
    .student-name {
      width: 78%;
      font-weight: 600;
      color: #263238;
    }
  }
  .info-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
    .item-icon {
      width: 22%;
    }
    .item-text {
      width: 78%;
    }
  }
}

.avatar-tooltip-container {
  color: #686868;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #263238;
    margin-bottom: 5px;
  }
  .year {
    font-size: 12px;
  }
  .info {
    display: flex;
    font-size: 12px;
    .info-label {
      width: 125px;
    }
  }
}

.manage-daysoff-container {
  width: 100%;
  .daysoff-description {
    width: 90%;
    font-size: 16px;
    line-height: 30px;
    color: #555555;
  }
  .manage-filters-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .daysOff-table-container {
    margin-top: 20px;
  }
}

.manage-daysOff-icons {
  display: flex;
  justify-content: space-around;
  svg {
    path {
      fill: black;
    }
  }
}

.ant-select-dropdown {
  z-index: 9999;
}

.skip-note {
  font-size: 14px;
  margin-top: 10px;
  color: #116682;
}

.disabledFields {
  pointer-events: none;
  cursor: wait;
}
:where(.css-dev-only-do-not-override-db9xna).ant-spin-nested-loading
  > div
  > .ant-spin {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

@media screen and (max-height: 800px) {
  .daysoff-multiple-container {
    .calendar-container {
      height: 75% !important;
    }
  }
}

@media screen and (max-height: 728px) {
  .daysoff-multiple-container {
    .calendar-container {
      height: 73% !important;
    }
  }
}
