.checkbox {
	font-size: 15px;
	position: relative;
	padding-left: 0;
	margin-top: 5px;
	margin-bottom: 5px;
	input[type="checkbox"] {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		filter: alpha(opacity=0);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		& + span {
			position: relative;
			cursor: pointer;
			padding: 0;
			&:before {
				content: "";
				margin-right: 10px;
				display: inline-block;
				vertical-align: text-top;
				width: 16px;
				height: 16px;
				border: 2px solid;
				margin-top: 2px;
			}
		}
		&:checked {
			border-width: 1px;
			background-color: $primary-color;
		}
		&:checked + span {
			&:before {
				border-color: $primary-light-color;
				background: $primary-color;
			}
			&:after {
				content: "";
				position: absolute;
				background: #116682;
				border: 1px solid #fff;
				border-width: 3px;
				border-top: none;
				border-right: none;
				/* border-bottom-width: 0px; */
				width: 10px;
				height: 5px;
				top: 7px;
				left: 3px;
				transform: rotate(-45deg);
			}
		}
		&:disabled + span {
			&:before {
				border-color: $darker-gray;
			}
			&:after {
				background: $gray;
			}
		}
		&:disabled {
			cursor: not-allowed;
		}
	}
	&:hover,
	&:focus {
		input[type="checkbox"]:not(:disabled) + span:before {
			border-color: $primary-light-color;
		}
	}
}
