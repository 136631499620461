.redirect-overlay {
  display: flex;
  justify-content: center;
  flex-direction:column;
  z-index:1000;

  img.overlay-image{
    margin-top:20px;
    margin-left:auto;
    margin-right:auto;
  }
  
  .overlay-up{
    min-height:55%;
    border-bottom:1px solid grey;
  }
  
  .overlay-down{
    height:40%;
  }
  
  .overlay-width{
    width:50%;
  }
  
  
  p.overlay-p{
    margin-top:30px;
    text-align:center;
    font-size:16px;
  }

  .action-img{
    width: 160px;
    margin-top: 20px;
  }
}

