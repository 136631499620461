//
// Error page Styles
// -------------------------------------------------
.error-page {
	text-align: center;
	padding-bottom: 70px;
	> img {
		display: block;
		margin: 0 auto 20px;
		max-width: 400px;
	}
	.error-message {
		font-size: 18px;
		margin: 20px 0;
	}
	.error-code { margin-bottom: 20px; }
}