//
// User profile
// -------------------------------------------------
.user-profile {
	.usercard {
		max-width: 390px;
		margin: 0 auto;
		padding: 40px;
		border: 1px solid $silver;
		border-radius: 4px;
		word-break: break-all;
	}
	.user-avatar {
		@extend .round;
		display: block;
		width: 160px;
		height: 160px;
		margin: 0 30px 30px;
		border-radius: 10px;
		border-radius: 5px;
	}
	.user-name,
	.user-email {
		@extend .text-center;
	}
	.user-email {
		margin-bottom: 40px;
	}
	.user-info {
		font-weight: 300;
		line-height: 1;
		margin-bottom: 10px;
	}
	.change-password {
		font-size: 14px;
		font-weight: bold;
		margin-top: 20px;
		line-height: 1;
		> a {
			color: inherit;
		}
		.icon {
			width: 12px;
			height: 12px;
			margin-right: 8px;
		}
	}
	.actionbar {
		@extend .text-center;
		margin-top: 30px;
	}
}

.margin-on-kid {
	margin-left: 0px !important;
}

.edit-profile {
	.edit-form {
		max-width: 620px;
		margin: 40px auto 0;
		// margin-left: -70px;
		// margin-right: -70px;
	}
	.avatar-container {
		position: relative;
		// margin: 0 auto;
		width: 160px;
		.user-avatar {
			width: 100px;
			height: 100px;
			border-radius: 5%;
		}
	}
	.user-avatar {
		@extend .round;
	}
	.file-upload {
		@extend .round;
		position: absolute;
		left: 100px;
		top: 70px;
		padding: 5px 7px 5px;
		width: 30px;
		height: 30px;
		background-color: $sidebar-color;
		fill: #fff;
	}
	.inputbox {
		flex: 1 1 50%;
		flex-basis: 50%;
		& + .inputbox {
			margin-left: 60px;
		}
		tags-input {
			.host {
				margin-top: 0px;
			}
			.tags {
				.input {
					height: 20px !important;
				}
			}
		}
	}
	[layout="row"] > .form-group {
		flex: 1 1 50%;
		flex-basis: 50%;
		& + .form-group {
			margin-left: 60px;
		}
	}
	.control-label {
		font-weight: 700;
		font-size: 14px;
	}
	.actionbar {
		@extend .text-center;
		margin-top: 40px;
		.btn + .btn {
			margin-left: 40px;
		}
	}
}

.typeahead-group {
	.dropdown-menu {
		max-height: 300px;
		overflow-y: auto;
		overflow-x: hidden;
		right: 0;

		&::-webkit-scrollbar {
			width: 0.5em;
		}
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
		}
		&::-webkit-scrollbar-thumb {
			background-color: darkgrey;
			outline: 1px solid slategrey;
		}

		& > li {
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.required-input-star {
	display: inline-block;
	color: #fff;
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	border-top: 20px solid #9aca40;
	border-left: 20px solid transparent;
}
.round-input {
	border-top-right-radius: 25px !important;
	border-bottom-right-radius: 25px !important;
	border-top-left-radius: 25px !important;
	border-bottom-left-radius: 25px !important;
}
.display-list-item {
	display: block;
}
@media (max-width: 768px) {
	.inputbox {
		margin-left: 0px !important;
	}
}

.not-active {
	opacity: 0.3;
}

.profile-view {
	border-right: 1px solid #D3D3D3;
	position: relative;
	overflow: hidden;
	.margin-top-40 {
		margin-top: 40px;
	}
	.margin-top-10 {
		margin-top: 10px;
	}
	.active-header {
		background-color: #C8C8C8 !important;
	}
	.edit-div {
		position: absolute;
		right: 25px;
		top: 60px;
	}
	.header-tab {
		padding: 10px 15px;
		border-bottom: 1px solid $primary-color;
		p {
			color: $primary-color;
		}
	}
	.body-tab {
		padding: 10px 15px;
		border-bottom: 1px solid $primary-color;
		border-top: 1px solid $primary-color;
		p {
			color: $primary-color;
		}
	}
	.profile-body-tab {
		padding: 15px 15px;
	}
	.label-div {
		.add-asterisk:after {
			content: "*";
			color: #116682;
		}

		.label-user {
			// margin-right: 20px;
			color: #919498;
		}
	}
	.content-dev {
		width: 60%;
		.content {
			margin-left: 20px;
			margin-right: 10px;
			color: #555;
			max-width: 100%;
			overflow: hidden;
			word-break: break-word;
			text-overflow: ellipsis;
			max-height: 25px;
			-webkit-box-orient: vertical;
			white-space: nowrap;
		}
	}
}

.margin-bottom-0 {
	margin-bottom: 0px !important;
}

.note-div {
	margin-top: 10px;
	padding: 0px 25px 15px 25px;
	.note-heading {
		font-size: 12px;
		font-weight: 500;
	}
	.note-text {
		font-size: 12px;
		color: #8C8C8C;
	}
}

.margin-top-60 {
	margin-top: 60px;
}

.payment-display-flex {
	display: flex;
	flex-direction: column;
}


.cancellation-modal {

	.heading{
		margin-left: -12px;
		margin-top: -10px;
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 22px;
		color: #555
	}

	.hr-divider{
		margin-left: -30px;
		margin-right: -25px;
		width: 110%;
		height: 2px;
		background-color: #E9EAEB;
		border: none;
	}

	.boldtext{
		margin-top: 15px;
		margin-bottom: 15px;
		font-weight: 600;
		color: #555;
		font-size: 16px;
		line-height: 22px;
	}

	.descript-container{
		margin-bottom: 15px;
		background-color: #EEF0F4;
		padding: 10px;
		border-radius: 5px;
		line-height: 30px;
		font-size: 14px;
		color: #555;
	}

	.content-area{
		font-size: 14px;
		line-height: 35px;
		color: #555;

		input[type="checkbox"]{
			margin-right: 10px;
		}

		textarea{
			resize: none;
			outline: none;
			padding: 10px;
			border-radius: 10px;
			border: 1px solid #ccc;
			background-color: #fff;
			width: 100%;
		}

		.text-area-label{
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 14px;
		}

		.error-message{
			text-align: center;
			color: red;
			margin: 8px;
		}
	}
}
