//
// Tabs
// --------------------------------------------------

.tab {
	@extend .list-unstyled;
	margin-bottom: 10px;
	overflow-x: auto;
	overflow-y: hidden;
	display: -webkit-box;
	&::-webkit-scrollbar {
		height: 0.1em;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
	}
	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}
}
.tab-item {
	display: block;
	padding: 8px 10px;
	color: inherit;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	&:hover { color: $primary-light-color; }
	&.active {
		font-weight: bold;
		color: $primary-light-color;
		border-bottom-color: $primary-light-color;
	}
}

