//
// Buttons
// --------------------------------------------------
.btn {
	display: inline-block;
	padding: 5px 30px;
	cursor: pointer;
	font-size: 13px;
	font-family: inherit;
	border: 0;
	border-radius: 20px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	&:hover,
	&:focus,
	&:active {
		outline: none;
		text-decoration: none;
	}
	&[disabled] {
		background-color: $light-gray;
		cursor: not-allowed;
	}
	.btn-icon {
		width: 16px;
		height: 16px;
		display: inline-block;
		margin-right: 8px;
		vertical-align: middle;
	}
	.spinner {
		margin-right: 5px;
		border-left-color: #fff;
		border-top-color: #fff;
	}
	&.btn-rounded {
		border-radius: 30px;
	}
	&.btn-lg {
		padding: 12px 20px;
	}
	&.btn-sm {
		padding: 6px 20px;
	}
	&.btn-primary {
		background-color: $primary-color;
		color: #fff;
		border: 1px solid $primary-color;
		.btn-icon {
			fill: #fff;
		}
		&:hover {
			color: #fff;
			background-color: darken($primary-color, 10);
			border-color: darken($primary-color, 10);
		}
		&[disabled] {
			background-color: lighten($primary-color, 10);
			border-color: lighten($primary-color, 10);
		}
	}
	&.btn-secondary {
		background-color: #fff;
		border: 1px solid $gray;
		color: $secondary-font-color;
		&:hover {
			border-color: darken($gray, 10);
		}
	}
	&.btn-danger {
		background-color: $primary-color;
		color: #fff;
		.btn-icon {
			fill: #fff;
		}
		&:hover {
			color: #fff;
			background-color: darken($primary-color, 10);
		}
		&[disabled] {
			background-color: lighten($primary-color, 10);
		}
	}
}
