//
// Lesson Pages
// -------------------------------------------------
.lesson-content {
  // background-color: #e8e7e7;
  // background-color: #F1F2F5;
  //padding-bottom: 10px;
  //height: 100%;
}

.lesson-content-top {
  //padding: 14px;
  //margin-bottom: 10px;
  height: 54px;
  background-color: white;

  h5 {
    display: inline;
  }
}

.import-contents {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.lesson-card {
  padding: 15px 19px 12px 19px !important;
  width: calc(100% - 30px) !important;
  margin: 15px 15px 0px 15px;
  overflow: visible;

  .page-title {
    border-bottom-color: transparent;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .page-title a {
    display: inline-flex;
    color: $primary-color !important;
  }

  .page-title .icon {
    fill: $primary-light-color;
    margin-right: 8px;
  }

  .custom_lesson_list {
    padding: 0px !important;
    margin-left: 0px;
    width: 100% !important;
  }

  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;

    .empty-title {
      margin: 0 10px 40px;
    }
  }

  .loading-state {
    max-width: 760px;
    min-height: 200px;
    margin: 50px auto;
    z-index: 5;
    line-height: 1;

    .spinner {
      margin-right: 10px;
    }
  }

  .create-lesson-btn {
    color: #116682;
    border: 1px solid #116682;
    background-color: #fff;
    font-weight: 500 !important;
    border-radius: 25px !important;
    margin-right: 20px;
    width: 210px;
    display: flex;
    align-items: center;
    padding: 5px 20px;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  .import-lesson-btn {
    color: #fff;
    border: 1px solid #fff;
    background-color: #116682;
    font-weight: 500 !important;
    border-radius: 25px !important;
    width: 170px;
    display: flex;
    align-items: center;
    padding: 5px 20px;

    &:hover {
      background-color: #0b4355;
    }
  }
}

.lesson-list {
  padding: 15px 19px 12px 19px !important;
  width: calc(100% - 30px) !important;
  margin: 15px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 1px rgba(196, 196, 196, 1);
  min-height: 75vh;
  //height: calc(100% - 70px);
  overflow: hidden;

  .page-title {
    border-bottom-color: transparent;
  }

  .page-title a {
    display: inline-flex;
    color: $primary-color !important;
    text-decoration: none;
  }

  .page-title .icon {
    fill: $primary-light-color;
    width: 25px !important;
    height: 25px !important;
    margin-right: 8px;
  }

  .custom_lesson_list {
    border: 0px;
    box-shadow: unset;
    margin-top: -15px;
    background-color: transparent;
    min-height: unset;
  }

  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;

    .empty-title {
      margin: 0 10px 40px;
    }
  }

  .loading-state {
    max-width: 760px;
    min-height: 200px;
    margin: 50px auto;
    z-index: 5;
    line-height: 1;

    .spinner {
      margin-right: 10px;
    }
  }

  #scrollable-dropdown-menu {
    .dropdown-menu {
      max-height: 250px;
      overflow-y: auto;
      width: 100%;
    }
  }

  .middle-scroll {
    overflow-y: scroll;
    max-height: 500px;
    padding-bottom: 100px;
  }
}

.course-card-container {
  padding-top: 10px;
  margin-bottom: 5px;
  // border-top: 1px solid $gray;
  // border-bottom: 1px solid $gray;

  .course-card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $gray;
    font-size: 22px;
    text-transform: capitalize;

    .course-name {
      // font-weight: 600;
      text-decoration: underline;
    }
  }

  .course-card-suggestion {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid $gray;
    font-size: 22px;
    text-transform: capitalize;

    .course-name {
      text-decoration: underline;
    }

    .card-suggestion-option {
      vertical-align: sub;
      /* font-size: smaller; */
      font-size: 12px;
      margin-left: 10px;
      margin-bottom: 5px;
    }

    .course-card-suggestion-title {
      display: flex;
      align-items: flex-end;
    }
  }

  a.accordion-toggle {
    text-transform: capitalize;
    @extend .text-primary-light;
    text-decoration: none;
  }

  .panel-title {
    padding-left: 38px;
    padding-bottom: 11px;

    .icon.chevron {
      margin-right: 16px;
      transition: all 0.2s ease-in-out;
      transform: rotate(-90deg);
    }
  }

  .lesson-card {
    padding: 20px 50px 20px 50px;
    width: 100%;
    overflow: visible;

    &__container {
      width: 50%;
      padding-bottom: 20px;
    }

    &__item {
      // border: 0px solid $gray !important;
      padding: 10px;
      height: 100%;
      margin-bottom: 10px;
      border-radius: 15px;
      box-shadow: 0 2px 2px 0 #e2e5e8;
      background-color: #fff;

      .duration-label {
        color: $secondary-font-color;
        min-width: 130px;
      }
    }

    &__item-header {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: start;
      // padding-bottom: 10px;
      border-bottom: 1px solid $gray;

      .lesson-import-price {
        position: absolute;
        right: -10px;
        bottom: -40px;
        height: 30px;
        width: 100px;
        border-radius: 15px 0 0 15px;
        background-color: #7dc569;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        span {
          text-transform: uppercase;
          font-size: 16px;
        }
      }

      .lessons-label {
        font-weight: 300;
      }

      .subject-name h4 {
        min-width: 200px;
        max-width: 420px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
      }

      .subject-sub-name {
        color: #919498;
      }

      .assign-button {
        margin: 7px 10px;

        .btn.btn-sm {
          padding: 4px 15px;
        }
      }

      .more-options {
        margin-top: 5px;
        margin-right: 10px;
        margin-left: 5px;

        &-icon {
          width: 30px;
          height: 40px;
          transform: rotate(90deg);
          transition: fill 0.3s ease-in-out;
          // border: 1px solid $darker-gray;
          padding: 5px;
          fill: #555;
          border-radius: 4px;
          background-color: #eef0f4;
        }

        .dropdown-menu {
          .icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }

          a.menu-item {
            display: flex;
          }

          .lesson-options:hover {
            background-color: #f5f5f5;
          }
        }
      }

      .lesson-duration {
        min-width: 340px;
      }
    }

    .student-details {
      .duration-label {
        min-width: 130px;
      }

      .inline-list {
        list-style: none;
        padding: 0;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;

        li {
          margin-right: 20px;
          margin-bottom: 10px;
          max-width: 200px;
          display: flex;

          .name {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .avatar {
          @extend .round;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 2px !important;
        }
      }
    }

    .lesson-sharing {
      border-bottom: 1px solid $primary-color;
      padding-bottom: 10px;
    }
  }

  .lesson-data {
    padding-left: 60px;
    padding-right: 50px;
    width: 100%;

    td {
      padding: 10px;
      border-bottom: 1px solid $gray;
    }

    .subject-name {
      width: 20%;
      max-width: 20%;
      word-wrap: break-word;
    }

    .subject-sub-name {
      color: #919498;
    }
    .lesson-duration {
      width: 40%;
      max-width: 40%;
    }

    .duration-label {
      color: $secondary-font-color;
    }

    .btn-icon {
      width: 23px;
      height: 17px;
    }

    .action {
      display: inline-block;
      font-size: 0;
      margin-left: 20px;

      .icon {
        fill: $secondary-font-color;
        width: 20px;
        height: 20px;
      }
    }

    tr:last-child td {
      border-color: transparent;
    }
  }

  &.panel-open .panel-title {
    padding-bottom: 10px;
    border-bottom: 0px solid #000000 !important;

    .icon.chevron {
      transform: rotate(0deg) !important;
    }
  }

  &.panel-open {
    border-top: 1px solid #116682;
    border-bottom: 1px solid #116682;
    background-color: #daf3fb;
  }
}

.course-container {
  padding-top: 10px;
  margin-bottom: 5px;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  margin-left: -30px;
  margin-right: -30px;

  a.accordion-toggle {
    text-transform: capitalize;
    @extend .text-primary-light;
    text-decoration: none;
  }

  .panel-title {
    padding-left: 38px;
    padding-bottom: 11px;

    .icon.chevron {
      margin-right: 16px;
      transition: all 0.2s ease-in-out;
      transform: rotate(-90deg);
    }
  }

  .lesson-list {
    padding: 20px 50px 20px 50px;
    width: 100%;

    &__item {
      border: 0px solid $gray !important;
      padding: 10px;
      margin-bottom: 10px;

      .duration-label {
        color: $secondary-font-color;
      }
    }

    &__item-header {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: start;

      .lessons-label {
        font-weight: 300;
      }

      .subject-name h4 {
        min-width: 200px;
        max-width: 420px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .subject-sub-name {
        color: #919498;
      }
      .assign-button {
        margin: 7px 10px;

        .btn.btn-sm {
          padding: 3px 6px;
        }
      }

      .more-options {
        margin-top: 5px;

        &-icon {
          width: 30px;
          height: 30px;
          transform: rotate(90deg);
          transition: fill 0.3s ease-in-out;
          border: 1px solid $darker-gray;
          padding: 5px;
          fill: $darker-gray;
          border-radius: 4px;
        }

        .dropdown-menu {
          .icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }

          a.menu-item {
            display: flex;
          }
        }
      }
      .subject-sub-name {
        color: #919498;
      }
      .lesson-duration {
        min-width: 340px;
      }
    }

    .student-details {
      .duration-label {
        min-width: 130px;
      }

      .inline-list {
        list-style: none;
        padding: 0;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;

        li {
          margin-right: 20px;
          margin-bottom: 10px;
          max-width: 200px;
          display: flex;

          .name {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .avatar {
          @extend .round;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 2px !important;
        }
      }
    }

    .lesson-sharing {
      border-bottom: 1px solid $primary-color;
      padding-bottom: 10px;
    }
  }

  .lesson-data {
    padding-left: 60px;
    padding-right: 50px;
    width: 100%;

    td {
      padding: 10px;
      border-bottom: 1px solid $gray;
    }

    .subject-name {
      width: 20%;
      max-width: 20%;
      word-wrap: break-word;
    }

    .lesson-duration {
      width: 40%;
      max-width: 40%;
    }

    .duration-label {
      color: $secondary-font-color;
    }

    .btn-icon {
      width: 23px;
      height: 17px;
    }

    .action {
      display: inline-block;
      font-size: 0;
      margin-left: 20px;

      .icon {
        fill: $secondary-font-color;
        width: 20px;
        height: 20px;
      }
    }

    tr:last-child td {
      border-color: transparent;
    }
  }

  &.panel-open .panel-title {
    padding-bottom: 10px;
    border-bottom: 0px solid #000000 !important;

    .icon.chevron {
      transform: rotate(0deg) !important;
    }
  }

  &.panel-open {
    border-top: 1px solid #116682;
    border-bottom: 1px solid #116682;
    background-color: #daf3fb;
  }
}

.lesson-assignment {
  @extend .list-unstyled;
  font-size: 14px;
  overflow: hidden;

  &.form {
    > li {
      border: none;
      padding: 5px;
    }
  }

  .week-name,
  .day-name,
  .assignment-info,
  .assignment-graded,
  .assignment-extra,
  .grading-options,
  .more-options {
    display: table-cell;
  }

  .week-name,
  .day-name {
    width: 60px;
  }

  .assignment-graded,
  .assignment-extra {
    width: 115px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .assignment-graded {
    position: relative;

    .assignment-graded-title {
      padding-left: 25px;
    }
  }

  > li {
    display: table;
    width: 100%;
    padding: 20px 20px 20px 0;
    border-bottom: 1px solid $gray;

    &:not(.week) {
      max-width: calc(100% - 60px);
      margin-left: 60px;

      .week-name {
        display: none;
      }

      &.day {
        .day-name {
          visibility: hidden;
        }
      }
    }

    &.day {
      .day-name {
        visibility: hidden;
      }
    }
  }

  .grading-options {
    position: relative;
  }

  .grading-options,
  .more-options {
    width: 40px;

    &-icon {
      width: 45px;
      height: 30px;
      position: absolute;
      top: 2px;
      transition: fill 0.3s ease-in-out;
      // border: 1px solid $darker-gray;
      padding: 5px;
      fill: #555;
      border-radius: 4px;
      background-color: #eef0f4;
    }

    .dropdown-menu {
      .icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      a.menu-item {
        display: flex;
      }

      .lesson-options:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.add-lesson {
  max-width: 750px;
  margin: 0 auto;
  font-size: 14px;

  .lesson-details {
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
  }

  .tab {
    //margin-top: 10px;
    font-size: 16px;
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
    z-index: 200;
  }

  .week-input {
    min-width: 280px;
  }

  .actionbar {
    @extend .text-center;
    margin: 20px 0 40px;
  }

  .prefixes-label {
    width: 130px;
  }

  .range-label {
    width: 70px;
  }

  .month {
    @extend .month;
    width: 55px;
    border-radius: 0px;
  }

  .days {
    @extend .days;
    border-left: 0px;
    border-radius: 0px;
    width: 58px;
  }

  .control-label {
    font-weight: 700;
    margin-bottom: 3px;
    color: $darker-gray;
  }

  .hyphen-label:before {
    padding-top: 30px;
    content: "-";
    padding-right: 8%;
    padding-left: 8%;
  }

  .fixedHeaderClass {
    position: fixed;
    top: 0;
    background: #fff;
    width: 51.5%;
  }

  .file-tab-upload {
    min-height: 148px;
    border: 1px dashed $darker-gray;
    color: $darker-gray;
    margin: 10px;
    background: $light-gray;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  .file-drop {
    @extend .file-tab-upload;
  }

  .rate-block {
    position: relative;
    padding-bottom: 20px;

    .form-group {
      margin-bottom: 0;
    }
  }

  .has-error.help-block {
    padding-bottom: 10px;
    margin-top: -25px;
    color: $brand-danger;
  }

  .lesson-add-icon {
    fill: $primary-light-color;
    width: 24px;
    height: 24px;
  }

  .lesson-remove-icon {
    fill: $darker-gray;
    width: 24px;
    height: 24px;
  }

  .lesson-row-manage {
    display: none; // inline-block;
    font-size: 0;
    vertical-align: middle;
  }

  .round-input {
    .duration {
      .hours {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }

      .minutes {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }

  .view-layout {
    .assignment-info {
      .form-control {
        border-radius: 25px;
      }
    }

    .assignment-extra {
      .form-control {
        border-radius: 25px;
      }
    }

    .tab-content {
      .form-control {
        border-radius: 15px;
      }
    }
  }
}

.assign-lesson {
  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;

    .empty-title {
      margin: 0 10px 40px;
    }
  }

  .heading {
    padding: 20px;
    background-color: $gray;
    font-family: $primary-font;
    color: $primary-light-color;
  }

  .avatar {
    margin-right: 10px;
    border-radius: 5px;
  }

  .assignment-data {
    max-width: 850px;
    margin: 0 auto;

    .assignment-desc h4 {
      max-width: inherit;
      word-wrap: break-word;
    }

    .duration-label {
      color: $secondary-font-color;
    }
  }

  .student {
    padding: 10px;
    border-bottom: 1px solid $light-gray;
  }

  .assignment-students {
    margin-top: 20px;
  }

  .assigned-students,
  .assign-students {
    display: table-cell;
  }

  .assigned-students {
    min-width: 250px;
  }
  .student-select {
    cursor: pointer;
  }
  .student-select:hover {
    background-color: #f5f5f5;
  }
  .assign-students {
    min-width: 600px;
    border-left: 1px solid $light-gray;
  }

  .assign-data {
    border-bottom: 1px solid $light-gray;

    .student {
      border: 0;
    }
  }

  .actionbar {
    @extend .text-center;
    margin-top: 50px;
  }

  .assign-form {
    padding: 10px 0 10px 10px;

    .date-input {
      max-width: 140px;
    }
  }
}

.assign-form {
  margin-top: -1px;
  background-color: #fff;

  .select-day + .select-day .text-label {
    border-left: 0;
  }

  .text-label {
    @extend .text-capitalize;
    font-size: 14px;
    padding: 7px;
    border: 1px solid $light-gray;
    height: 35px;
    display: inline-block;
    user-select: none;
    cursor: pointer;
  }

  .validation-message {
    color: $state-danger-text;
  }

  .days label:first-child .text-label {
    border-radius: 2px 0 0 2px;
  }

  .days label:last-child .text-label {
    border-radius: 0 2px 2px 0;
  }

  input[type="checkbox"] {
    display: none;

    &:disabled + .text-label {
      cursor: not-allowed;
    }

    &:checked + .text-label {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .assign-space {
    margin: 20px;
    padding-right: 8px;
  }
}

.lesson-detail {
  .title-details {
    margin-bottom: 10px;

    .title {
      word-wrap: break-word;
      width: 600px;
      color: $primary-color;
    }
    .sub-title {
      color: #919498;
    }
    .action {
      display: inline-block;
      font-size: 0;
      margin-left: 20px;

      .icon {
        fill: $secondary-font-color;
        width: 16px;
        height: 16px;
      }
    }
  }

  .assign-btn {
    min-width: 130px;

    .btn.btn-sm {
      padding: 3px 6px;
    }
  }

  .assigned-lessons {
    .avatar {
      @extend .round;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 2px;
    }

    &__item {
      padding-top: 5px;
      padding-bottom: 5px;

      &:not(:last-child) {
        border-bottom: 1px solid $gray;
      }

      .student-name {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .lesson-duration,
  .public-label {
    color: $secondary-font-color;
  }

  .tab {
    margin-top: 30px;
    border-bottom: 2px solid $primary-color;
  }

  .tab-content {
    min-height: 180px;
    margin-bottom: 40px;
  }

  .empty-text-template {
    padding: 10px;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #f4f4f4;

    p {
      font-size: 14px;
      color: $darker-gray;
      font-weight: bold;
    }
  }

  .assignment-extra {
    width: 110px;
  }

  .assignment-type {
    @extend .text-center;
    @extend .text-primary-light;
    @extend .text-capitalize;
    font-weight: bold;
    height: auto;
    padding: 5px 10px;
  }

  .lesson-assignment {
    font-size: inherit;

    .week-name,
    .day-name {
      width: 80px;
    }

    .assignment-graded,
    .assignment-extra {
      width: 160px;
      padding-left: 30px;
    }

    > li {
      border-bottom: 0;

      &:not(.week) {
        max-width: calc(100% - 80px);
        margin-left: 80px;

        .week-name {
          display: none;
        }
      }
    }
  }
}

.share-lesson-popover {
  max-width: 700px;
  width: 700px;
  padding-left: 14px;
  padding-right: 14px;

  input {
    width: 450px;
  }
}

.assignment-details {
  .assigned-lessons {
    .avatar {
      @extend .round;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 2px;
    }

    &__item {
      padding-top: 5px;
      padding-bottom: 5px;

      &:not(:last-child) {
        border-bottom: 1px solid $gray;
      }
    }
  }

  .empty-state {
    max-width: 500px;
    min-height: 300px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;

    .empty-title {
      margin: 0 10px 40px;
    }
  }

  .student-avatar {
    width: 150px;
    height: 150px;
    border-radius: 5px;
  }

  .title-details {
    color: $primary-color;
    margin-bottom: 50px;

    .dropdown-menu {
      border-radius: 0;
      border: 1px solid $light-gray;
      margin-top: 10px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

      li {
        padding: 5px;
      }
    }
  }

  .title {
    margin-right: 30px;
  }

  .detail-data {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray;
  }

  .attendance {
    display: inline-flex;
  }

  .tab {
    border-bottom: 1px solid $gray;
  }

  .tab-content {
    min-height: 180px;
    margin-bottom: 40px;
  }

  .image-upload {
    color: inherit;
    display: inline-flex;
    text-decoration: none;

    .icon {
      margin-right: 8px;
    }
  }

  .images {
    margin-top: 20px;
  }

  .attendance {
    display: inline-flex;
  }

  .attendance input {
    margin: 0 5px 0;
    display: inline-block;
  }

  .add-assignment-details {
    width: 480px;

    span {
      display: inline-block;

      &.title {
        font-weight: bold;
        width: 110px;
      }

      &.value {
        word-break: break-all;
      }

      &.value:before {
        margin-left: 10px;
        content: ":";
        margin-right: 10px;
      }
    }
  }

  .empty-text-template {
    padding: 10px;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #f4f4f4;

    p {
      font-size: 14px;
      color: $darker-gray;
      font-weight: bold;
    }
  }

  .form-control {
    border-radius: 25px;
  }

  .student-error {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
  }
}

.image-card {
  position: relative;
  display: inline-block;
  width: 200px;

  &:hover .overlay {
    opacity: 1;
  }

  .overlay {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.3s ease-in-out;
  }

  .icon {
    fill: #fff;
    margin-right: 8px;
  }
}

span.status.notStarted {
  background: #b5b3b4;
}

span.status.inProgress {
  background: $status-yellow;
}

span.status.complete {
  background: $status-green;
}

span.status.onHold {
  background: $status-red;
}

span.status {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dropdown {
  .icon {
    height: 24px;
    padding-top: 8px;
  }
}

//------------Over-rides---------//
.create-assignment .tab-header {
  border-top: 1px solid $lighter-gray;
}

.appointment-subject {
  padding-top: 6px;
  padding-bottom: 6px;
}

.text-wrapping {
  white-space: nowrap;
  overflow: hidden;
  max-width: 125px;
  vertical-align: top;
  text-overflow: ellipsis;
}

.assign-lesson-modal {
  .days {
    display: flex;

    .select-day {
      flex: 1;

      span {
        width: 100%;
        text-align: center;
      }
    }
  }

  .conflicts {
    border: 1px solid #ccc;
    padding: 10px;
    position: relative;

    .spinner {
      top: 7px;
      margin-right: 10px;
      margin-left: 10px;
    }

    .close-icon {
      top: 4px;
      right: 4px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    &.error {
      background: rgba($brand-danger, 0.1);
    }

    &.loading {
      background: rgba($blue, 0.1);
    }

    &.success {
      background: rgba($brand-success, 0.1);
    }
  }

  .lessons-table {
    tr:last-child {
      td {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .lesson-list-add-icon {
    width: 45px;
    height: 30px;
    transition: fill 0.3s ease-in-out;
    padding: 5px;
    fill: #555;
    border-radius: 4px;
    background-color: #eef0f4;
  }

  .lesson-options {
    display: flex;
    padding: 10px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  }

  .lesson-options:hover {
    background-color: #f5f5f5;
  }
}

// .agree-backdrop{
//     background: rgba(0,0,0,0.5);
// }
.modal[uib-modal-window] {
  background: rgba(0, 0, 0, 0.5);
}

/* Lesson-Import */

.lesson-import {
  max-width: 750px;
  margin: 0 auto;

  .note {
    color: gray;
    margin-top: 20px;
    margin-left: 5px;
  }

  .sicon {
    position: absolute;
    top: 26px;
    display: inline-block;
    right: 0px;
    padding-right: 6px;
    height: 28px;
    width: 27px;
    fill: $primary-color;
    font-style: normal;
    margin-top: 2px;
    margin-right: 10px;
  }

  .sicon1 {
    position: relative;
    height: 21px;
    padding: 0px;
    top: 5px;
    width: 25px;
    fill: $primary-color;
  }

  .inner-addon {
    position: relative;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    // background-image: none;
    // border: 1px solid #ccc;
    border-radius: 4px;
    // -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    // -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    // -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    // transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }

  .search {
    font-size: 19px;
    padding: 8px;
    margin-top: 20px;
    border-bottom: 1px solid $gray;
  }

  .panel {
    margin-top: 25px;
    border: 1px solid $gray;
    border-radius: 4px;
  }

  .panel-heading {
    display: flex;
    flex-grow: 1;
    background: none;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 9px;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray;

    .strong {
      font-weight: bold;
    }

    .buttons {
      display: flex;

      .astext {
        background: none;
        border: none;
        font-size: x-small;
        color: grey;
        margin-right: 30px;
      }
    }
  }

  .panel-body {
    display: flex;
    flex-direction: column;

    .firstrow {
      padding: 6px;
      padding-left: 20px;
      // padding-bottom: 8px;
      display: flex;

      .firstpar {
        padding-right: 15px;
        width: 100px;
        color: grey;
        position: relative;

        &:after {
          content: ":";
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .content {
        margin-left: 10px;
      }
    }
  }

  .content-details {
    font-weight: 400;
    font-size: 13px;
  }

  .import {
    background: none;
    border: none;
    margin-right: 21px;
    right: 0px;
    padding: 0;
    font-size: x-small;
    color: $primary-color;
  }

  .loading-state {
    max-width: 760px;
    min-height: 200px;
    margin: 50px auto;
    z-index: 5;
    line-height: 1;

    .spinner {
      margin-right: 10px;
    }
  }

  button:focus {
    outline: 0;
  }
}

/* New Assignment Design */

.assignment-title {
  font-size: 16px;
}

// .assignment-title-underline {
//     position: relative;
//     padding-bottom: 3px;
// }

// .assignment-title-underline:before {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 1px;
//     bottom: 0;
//     /* left: 25%; */
//     border-bottom: 2px solid rgb(150, 150, 150);
//}

.btn-progress-style {
  border-radius: 1px;
}

.assignment-status {
  text-align: right;
}

.assignment-status-background {
  background: #f6f7f7;
  padding: 5px 10px;
}

.assignment-status-background-spinner {
  margin: 0 !important;
}

.reschedule-assignment-status-background {
  background: #f6f7f7;
  padding: 2px 10px;
  height: 34px;

  a.unstyled.status-dropdown {
    display: flex;
    justify-content: center;
    font-weight: 700;
  }
}

.reschedule-status-list-all {
  margin: 5px 10px;
  font-weight: 700;
}

.reschedule-assignments-header {
  border: 1px solid #e9eaeb;
  margin-bottom: 30px;
  padding: 16px 0px 5px 15px;
}

.assingment-name {
  font-size: 20px;
  color: rgb(150, 150, 150);
}

.assignment-category {
  text-transform: uppercase;
}

.assignment-header-category {
  text-transform: capitalize;
}

.assignment-detail-data {
  padding-bottom: 30px;

  .value {
    text-transform: capitalize;
  }

  .details {
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 16px;

    .student-list {
      //color: yellow;
      text-align: end;
      display: flex;
      justify-content: flex-end;

      .show-students-avatar {
        .single-avatar {
          text-align: center;
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          line-height: 2.3;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .lesson-info {
      display: grid;
      grid-template-columns: 33% auto;

      .lesson-progress {
        background-color: #f1f2f5;
        border-radius: 12px;
        height: 20px;
        margin-top: 2.5px;

        div {
          box-shadow: 0px 2px 0px 0px;
          background-color: #106682;
          z-index: 2;
          color: white;
          border-radius: 12px;
          height: 100%;
          text-align: center;
          //display: flex;
          //align-items: center;
          font-size: 13px;
        }
      }
    }

    .assignment-detail-names {
      display: flex;
      flex-direction: column;
    }

    .assignment-detail-values {
      display: flex;
      flex-direction: column;
    }
  }
}

.card-load-more {
  justify-content: center;
}

.reschedule-assignment-category-label {
  display: inline;
  // padding: .2em .6em .3em;
  padding-left: 0px;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: $primary-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.calendar-reschedule-assignment-title {
  margin-right: 10px;
}

.search-options {
  a {
    display: inline-flex;
    color: inherit;
    text-decoration: none;
  }

  .icon {
    fill: $primary-color;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}

.resources-cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: flex-start;
  padding: 0;

  .card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 15px;
    border: 2px solid $gray;
    border-radius: 5px;
    transition: 0.2s;

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        color: black;
        font-weight: bold;
        max-width: 172px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .first {
    display: flex;
  }

  .second {
    margin-top: 5px;
  }

  .right {
    margin-left: 12px;
  }
}

// .close-icon {
//     transform: rotate(45deg);
// }
.margin-left-52 {
  margin-left: 52px;
}

.book-header {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .book-img {
    width: 60px;
    height: 60px;
    border: 1px solid $gray;
    margin-right: 10px;
  }
}

.book-description {
  height: 60px;
  line-height: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.lesson-add-book-card {
  border: 1px solid #e9eaeb;
  // margin: 10px 0;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.lesson-book-image {
  max-height: 85px;
  width: 21%;
}

.add-lesson-book-image {
  max-height: 120px;
  width: 75px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.lesson-book-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 10px 0px;
  font-size: 12px;
  height: 85px;
  background-color: white;
}

.lesson-book-title {
  text-transform: capitalize;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: $primary-color;
}

.add-lesson-book-title {
  max-width: 200px;
}

.lesson-book-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // border-bottom: 1px solid #e9eaeb;
  margin-top: 5px;

  a {
    margin-right: 7px;
    margin-top: 2px;
  }

  .checkbox.select {
    input[type="checkbox"] + span:before {
      margin-top: 3px !important;
      margin-left: 1px;
    }
  }
}

.checkbox.select {
  margin-top: 0px;

  input[type="checkbox"] + span:before {
    margin-top: -2px !important;
    width: 14px;
    height: 14px;
  }

  input[type="checkbox"]:checked + span:after {
    left: 3px;
    top: 7px;
  }
}

.lesson-book-details {
  margin-left: 10px;
  max-width: 100%;

  p,
  span {
    font-size: 12px;
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.add-lesson-book-details {
  margin-left: 10px;
  max-width: 100%;

  p,
  span {
    font-size: 12px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.sicon-lesson-book {
  height: 17px;
  width: 17px;
  fill: $primary-color;
  font-style: normal;
  margin-right: 5px;
  margin-top: 1px;
}

.lesson-book {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
  border-radius: 10px;
}

.select-lesson-day {
  height: 24px !important;
  padding: 0px 7px !important;
  font-size: 12px !important;
  width: 42px;
  text-align-last: center;
}

.add-lesson-book-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  height: 120px;
}

.select-duration-label {
  font-weight: 600;
  // color: #919498;
}

.lesson-plan-book-name {
  font-size: 15px;
  color: #555;
}

.lesson-plan-book-label-width {
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lesson-plan-book-label {
  font-size: 14px;
}

.icon-lesson {
  width: 25px;
  height: 25px;
  display: inline;
  fill: #555;
}

.tab-item {
  padding: 1px 11px;

  &.active {
    font-weight: bold;
    color: $white;
    border-bottom-color: $primary-color;
    background-color: $primary-color;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.tab {
  border-bottom: 2px solid $primary-color !important;
  border-top: unset !important;
}

.chatbox-options {
  border: 0;
  outline: none;
}

.flex-10 {
  width: 10%;
}

.flex-15 {
  width: 15%;
}

.flex-20 {
  width: 20%;
}

.flex-30 {
  width: 30%;
}

.flex-32 {
  width: 32%;
}

.flex-35 {
  width: 35%;
}

.flex-45 {
  width: 45%;
}

.flex-5 {
  width: 47%;
}

.margin-top-15 {
  margin-top: 0px;
}

@media (max-width: 768px) {
  .page-title {
    display: block;
    text-align: center;
  }

  .btn-primary {
    background-color: #116682;
    color: #fff;
    border: 1px solid #116682;
  }

  .lesson-list__item-header {
    .assign-button {
      margin: 0px 0px !important;
    }
  }

  .lesson-card__item-header {
    .assign-button {
      margin: 0px 0px !important;
    }
  }

  .align-cont {
    justify-content: space-between;
  }

  .more-options {
    margin-top: 17px !important;
  }

  .student-details {
    display: block;
  }

  .slider {
    &.slider-round {
      width: 30px;
    }
  }

  .margin-right-30 {
    margin-right: 0px;
  }

  .margin-right-50 {
    margin-right: 0px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }

  .margin-left-15 {
    margin-left: 15px;
  }

  .margin-bottom-0 {
    margin-bottom: 0px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .flex-30 {
    width: 100%;
  }

  .flex-32 {
    width: 32%;
  }

  .flex-35 {
    width: 100%;
  }

  .flex-45 {
    width: 100%;
  }

  .flex-5 {
    width: 40%;
  }

  .flex-10 {
    width: 100%;
  }

  .flex-20 {
    width: 100%;
  }

  .flex-15 {
    width: 60%;
  }

  .add-lesson {
    .range-label {
      width: 100%;
    }

    .hyphen-label:before {
      padding-left: 8%;
      padding-right: 8%;
    }
  }

  .lesson-assignment {
    > li {
      display: inline;
    }

    .week-name,
    .day-name {
      width: 100px;
    }
  }

  .panel-heading {
    display: inline !important;
  }

  .assign-lesson-768 {
    display: grid;
  }

  .add-lesson {
    .lesson-row-manage {
      width: 100%;
      text-align: right;
    }
  }
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.add-resource {
  padding: 0;
  position: relative;

  .add-book {
    display: flex;
    outline: none;

    .icon {
      position: relative;
      top: 6px;
      height: 25px;
      width: 25px;
      fill: $primary-color !important;
    }

    h4 {
      margin-top: 5px;
      margin-left: 8px;
    }
  }

  .electronic-form {
    .form-control {
      width: 100%;

      &.ng-invalid.ng-touched {
        border-color: red;
      }
    }

    .padding-b-2 {
      padding-bottom: 20px;
    }
  }
}

.add-lesson-resource-card {
  height: unset;
  padding: 0px 0px 10px;

  .lesson-book-header a {
    position: absolute;
    right: 15px;
  }

  .lesson-book-header a.edit {
    right: 40px;
  }
}

.course-taginput {
  .tag-item {
    border: unset !important;
  }
}

.input-width {
  // width: 600px !important;
  width: 620px !important;
}

.dropdown-list {
  .has-error {
    border-color: #f80505;
  }

  .dropdown-menu {
    .uib-typeahead-match {
      padding: 0px 0px;

      a {
        text-decoration: none;
        color: #555;
        padding: 10px 20px;
      }

      &.active {
        a {
          background-color: $primary-color;
          color: white;
        }

        a:hover {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }
}

.rating-details {
  .select {
    width: 16px;
    height: 16px;
  }

  .rating {
    color: #a9a9a9;
    margin: 0;
    // margin-left: 10px;
    padding: 0;
  }

  ul.rating {
    display: inline-block;
  }

  .rating li {
    list-style-type: none;
    display: inline-block;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .rating .filled {
    color: #ffff1e;
  }
}

.view-layout-margin {
  height: 5rem;
}

tags-input .autocomplete .suggestion-list {
  max-height: 200px !important;
}

.lesson-border-0 {
  border: none;
}

.lesson-border-bottom-1 {
  border-bottom: 1px solid #e8e7e7;
}

.panda-desc-1 {
  padding-top: 20px;
}

.panda-desc-2 {
  padding-top: 5px;
}

.no-animate {
  -webkit-transition: none !important;
  transition: none !important;
}

.assignment-type-text {
  .has-error {
    border-color: #f80505;
  }
}

.grading-instructions {
  .instruction-title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  .create-assignment-type-btn {
    color: #116682;
    border: 1px solid #116682;
    background-color: #fff;
    font-weight: 500 !important;
    border-radius: 25px !important;
    margin-right: 20px;
    width: 115px;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    position: absolute;
    bottom: 70px;
    right: 250px;
    height: 34px;

    &:hover {
      background-color: #e5e5e5;
    }
  }
}

.grading-instructions-table {
  position: relative;
  margin-top: 15px;
  margin-bottom: 50px;
  text-transform: capitalize;

  table {
    border: none;
    border-spacing: 10px;
    border-collapse: collapse;
    width: 400px;

    .half-width {
      width: 50%;
    }

    .width-60 {
      width: 60%;
    }

    .width-40 {
      width: 40%;
    }

    .text-width {
      width: 70%;
    }

    .footer-text {
      font-weight: bold;
    }

    .footer-warning {
      position: absolute;
      right: 200px;
      padding-top: 20px;
      color: red;
    }

    .border_bottom {
      border-bottom: 1px solid black;
    }

    .border_top {
      border-top: 1px solid black;
    }

    th {
      padding: 10px 10px 5px 10px;

      p {
        font-weight: bold;
      }
    }

    td {
      padding: 15px 15px 0 15px;
    }

    .grading-category-title {
      display: flex;
      align-items: center;
    }

    th,
    td {
      border-left: 1px solid #000;
      // border-right: 1px solid #000;
    }

    td:first-child,
    th:first-child {
      border-left: none;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }

    tr:last-child td:last-child {
      padding-bottom: 10px;
    }
  }
}

@-moz-document url-prefix() {
  .lesson-assignment .assignment-info {
    display: flex;
  }

  .lesson-assignment .grading-options {
    .icon {
      top: 0;
    }
  }
}

.divider-view-assignment {
  width: 100%;
  height: 2px;
  background-color: #e9eaeb;
}

.lesson-progress-bar {
  margin-top: 5px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 30% 29%;

  .lesson-progress-label {
    height: 22px;
    width: 131px;
    color: #555;
    font-family: Poppins;
    font-size: 16px;
    line-height: 22px;
  }

  .lesson-progress {
    background-color: #f1f2f5;
    border-radius: 13px;

    .progress-bar {
      background-color: #106682;
      border-radius: 14px;
      color: white;
      text-align: center;
      height: 100%;
    }
  }
}

.lesson-headings {
  height: 18px;
  width: 80px;
  color: #555;
  // font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.lesson-resources {
  margin-bottom: 20px;
  .files-header {
    //margin: 10px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .add-resources-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 25px;
      color: #106682;
      border: none;
      background: #e7eff2;
      padding: 9px 13px;
      margin: 10px 0;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
        background-color: #d8e8ef;
      }
      span {
        line-height: 1;
      }
      #add-resources-icon {
        fill: #116682;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
    .upload-lesson-files {
      cursor: pointer;
      padding: 5px 21px;
      background-color: #e7eff2;
      border-radius: 19px;
      color: #106682;
      div {
        display: flex;
        align-items: center;
        .plus-icon {
          margin-right: 10px;
          width: 18px;
        }
      }
    }
  }
  // ::-webkit-scrollbar {
  // 	width: 10px;
  // }
  // ::-webkit-scrollbar-track,
  // ::-webkit-scrollbar-thumb {
  // 	border-radius: 14px;
  // }
  // ::-webkit-scrollbar-track {
  // 	background: #f1f1f1;
  // 	margin: 3px;
  // }
  // ::-webkit-scrollbar-thumb {
  // 	background: rgb(190, 190, 190);
  // }
  // ::-webkit-scrollbar-thumb:hover {
  // 	background: #555;
  // }

  .resources-list {
    width: 100%;
    background-color: #eef0f4;
    min-height: 70px;
    max-height: 220px;
    border-radius: 8px;
    overflow-y: scroll;
    overflow-x: none;
    #empty-resources {
      text-align: center;
      margin-top: 17px;
      font-size: 14px;
      width: 200%;
    }
    .single-resource {
      //margin: 10px;
      width: 100%;
      height: 83px;
      border: 1px solid #c8c8c8;
      border-radius: 8px;
      background-color: white;
      .resource-book {
        display: grid;
        grid-template-columns: 18% 82%;
        img {
          max-height: 81px;
          width: 100%;
        }
        .resource-book-details {
          display: flex;
          margin-left: 10px;
          flex-direction: column;
          .book-title-show {
            display: flex;
            justify-content: space-between;
            .-book-title {
              margin-top: 5px;
              color: #116682;
              font-size: 15px;
              font-weight: 800;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
            }
            a {
              margin: 5px;
              &:hover {
                fill: red;
              }
            }
          }
          ._book-details {
            .book-author {
              font-size: 13px;
            }
          }
        }
      }
      .lesson-add-electronicMedia-card {
        display: grid;
        grid-template-columns: 18% 82%;
        .elctronic-media-info {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .electronic-media-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
            margin-right: 5px;
            .icon {
              &:hover {
                fill: red;
              }
            }
          }
          span {
            font-size: 14px;
            margin-left: 10px;
            color: #116682;
            a {
              text-decoration-line: none;
            }
          }
          .media-title {
            font-weight: 600;
          }
          .electronic-media-link {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.lesson-files {
  .files-header {
    //margin: 10px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .upload-lesson-files {
      cursor: pointer;
      padding: 5px 21px;
      background-color: #e7eff2;
      border-radius: 19px;
      color: #106682;

      div {
        display: flex;
        align-items: center;

        .plus-icon {
          margin-right: 10px;
          width: 18px;
        }
      }
    }
  }

  .show-lesson-files {
    min-height: 70px;
    background-color: #eef0f4;
    border-radius: 8px;
    position: relative;
  }
}

.lesson-header {
  margin: 18px 30px 10px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .group-icon {
    width: 39px;
    height: 39px;
    border-radius: 22px;
    background: #eef0f4;
    padding: 2px;
  }
  .single-icon {
    width: 35px;
    height: 35px;
    border-radius: 30px;
    padding: 2px;
    background: #eef0f4;
  }
  .lesson-title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;

    position: absolute;
    left: 45px;
    top: 12px;
  }

  .del-update-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .edit-btn {
      margin-right: 14px;
      font-size: 13px;
      color: #377f96;
      font-weight: 500;
      text-transform: capitalize;
      padding: 9px 46px;
      border-radius: 15px;
      background-color: #e7eff2;
      border: none;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #d8e8ef;
        cursor: pointer;
      }
    }
  }

  .delete-assignment {
    border: none;
    color: #106682;
    float: right;
    background: #e7eff2;
    padding: 9px 23px;
    border-radius: 25px;
    //height: 34px;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      svg {
        fill: red;
      }
    }

    &:focus {
      outline: none;
    }

    svg {
      height: 14px;
      width: 14px;
      margin-right: 13px;

      &:hover {
        fill: red;
      }
    }

    &:hover {
      color: red;
    }
  }
}

.lesson-name {
  margin: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  .icon-assignment-status {
    width: 25px;
    height: 25px;
  }

  span {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bolder;
  }
}

// .assignment-details{
// 	display: grid;
// 	grid-template-columns: 20% 40%;
// 	.details{
// 		display: flex;
// 		flex-direction: column;
// 	}
// }

.richText {
  border-radius: 8px;
  z-index: 2;
  .richText-editor {
    min-height: 150px;
    height: auto;
    word-break: break-all;
  }
  //background-color: #106682;
}

.assignment-files {
  position: relative;
  margin-bottom: 20px;

  .files {
    background-color: #eef0f4;
    border-radius: 8px;
    margin-top: 60px;
    height: 171px;
    overflow-y: scroll;

    .hsp-files {
      margin: 20px 0px 20px 10px;
      box-shadow: 0px 0px 0px;
      width: 130px !important;
      height: 130px !important;
      outline: none;

      .view-image {
        border-radius: 8px;
        width: 130px !important;
        height: 130px !important;

        img {
          width: 130px;
          height: 120px;
          min-width: 130px;
          min-height: 120px;
        }
      }

      .view-actionbar {
        border-radius: 0 0 8px 8px;
        background-color: white;
      }
    }

    .file-upload {
      position: absolute !important;
      top: -120px !important;
      right: 34px !important;

      .assignment-upload {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100% !important;
        margin: 0 !important;
        box-shadow: 0px 0px 0px !important;
        border-radius: 24px;
        padding: 9px 20px !important;
        background-color: #e7eff2 !important;
        border: none;

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: pointer !important;
          background-color: #d8e8ef !important;
        }

        svg {
          fill: $base-color !important;
          margin: 0 10px 0 0 !important;
          height: 16px !important;
          width: 16px !important;
        }

        p {
          font-size: 13px !important;
          color: $base-color !important;
        }
      }
    }
  }
}

.save-changes {
  text-align: end;
  margin-top: 10px;
}
.assignment-text {
  word-break: break-all;
  width: 100%;
  padding: 10px;
  min-height: 70px;
  background-color: #eef0f4;
  border-radius: 5px;
  border: none;
}
.empty-notepad {
  height: 70px;
  background-color: #eef0f4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customCollapse {
  transform: rotate(180deg) !important;
}
.cardOpen {
  margin: 10px 0;
}

.lesson-description-show-less {
  overflow: hidden;
  max-height: 60px;
}

.lesson-description-show-more {
  height: auto;
}
.angular-accordion {
  border-radius: 5px;
  box-shadow: 0px 0px 4px #e2e5e8;
  margin-top: 10px;
  transition: 0.4s;
  .ng-accordion-header {
    border-radius: 5px 5px 0 0;
    box-shadow: 1px 1px 1px 0px #e2e5e8;
    color: #555;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;

    // &::before {
    // 	content: "";
    // 	position: absolute;
    // 	background-color: #a2a0a02b;
    // 	padding: 50%;
    // 	border-radius: 50%;
    // 	left: 50%;
    // 	top: 50%;
    // 	transform: translate(-50%, -50%) scale(1);
    // 	opacity: 0;
    // 	transition: transform 0.4s, opacity 2s;
    // }
    // &:active::before {
    // 	transition: 0s;
    // 	opacity: 1;
    // 	transform: translate(-50%, -50%) scale(0);
    // }
    &:hover {
      background-color: #f1f2f5;
    }
    span {
      font-weight: bold;
    }
    svg {
      width: 15px;
      height: 15px;
    }
    .assignment-element-title {
      cursor: move;
    }
  }
  .ng-accordion-body {
    padding: 5px 12px;
    transition: all 0.4s ease-out;
    background-color: white;
    overflow: hidden;
    display: none;
  }
  .show-accordion-content {
    display: block !important;
  }
}
.dndDraggingSource {
  display: none;
}
.dndPlaceholder {
  background-color: #ddd;
}

.trumbowyg-box {
  border-radius: 8px;
  min-height: 200px;

  height: auto;
  .trumbowyg-editor {
    min-height: 200px;
    height: auto !important;
    word-break: break-all;
  }
}

.assignment-titles {
  // position: relative;
  // border: 1px solid #ccc;
  // border-radius: 34px;
  // padding: 10px;
}
.assignment-title-lesson {
  min-height: 34px;
  max-height: 80px;
  overflow: auto;
  word-break: break-all;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 0 5px 5px;
}
.visibility {
  visibility: hidden;
}
.borderLess {
  border: 1px solid white;
}
.item-centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  //line-height: 3;
}
.create-lesson-assignment {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr 1fr 1fr 1fr 1fr;
  //border-bottom: 1px solid;
  margin-bottom: 10px;

  .lesson-assignment-dropdown {
    background: #eef0f4;
    padding: 3px 11px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 33px;
  }
  .create-lesson-divider {
    color: #ccc;
    grid-column: 1/9;
  }
  .lesson-options:hover {
    background-color: #f5f5f5;
  }
}
#graded {
  margin-left: 10px;
}

.flex-space-between {
  display: flex !important;
  justify-content: start !important;
  span {
    margin-left: 10px;
  }
}

.add-noteTitle {
  display: grid;
  grid-template-columns: 1fr 1fr 10fr;
  .note-Title-content {
    border: 1px solid #ccc;
    border-radius: 34px;
    padding: 10px;
  }
}
.trumboEditor {
  .trumbowyg {
    z-index: 10 !important;
  }
}
.fixed-lesson-btn {
  background: #ffffff;
  padding: 15px 362px;
  position: fixed;
  bottom: -10px;
  right: 20px;
  border-radius: 0 0 8px 8px;
  z-index: 2;
  border-top: 1px solid #ccc;
  left: 20px;

  z-index: 2;
  border-top: 1px solid #ccc;
}
.custom-form {
  .form-group {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 2558px) {
  .fixed-lesson-btn {
    padding: 15px 26.5%;
  }
}
@media (max-width: 1440px) {
  .fixed-lesson-btn {
    padding: 15px 120px;
  }
}
@media (max-width: 1113px) {
  .fixed-lesson-btn {
    padding: 15px 10px;
  }
}
@media (max-width: 1024px) {
  .fixed-lesson-btn {
    width: 96%;
    padding: 15px 10px;
  }
}
@media (max-width: 768px) {
  .fixed-lesson-btn {
    width: 94.5%;
    padding: 15px 10px;
  }
}

.lesson-trumboEditor {
  .trumbowyg-box {
    z-index: 2;
  }
}
/*
save cancel buttons in lesson

	*/
.assignment-update-confirm {
  line-height: 1;
  margin-bottom: 10px;

  cursor: pointer;

  #option-all {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
}
.align-self-apply-btn {
  align-self: flex-end;
  margin-bottom: 4px;
}
.icon-bigger {
  width: 20px !important;
  height: 20px !important;
}
.grading-title {
  p {
    visibility: hidden !important;
  }
}
.testt {
  width: 35px;
  height: 35px;
  background: #eef0f4;
  border-radius: 28px;
  padding: 3px;
}

.list-items {
  &:focus {
    outline: none;
  }
}
.assignment-type {
  .selectize-input {
    border-radius: 24px;
    //margin-left: 10px;
    //margin-top: 5px;
    background-color: white !important;
    background-image: none !important;
  }
}
.hideAssignIcon {
  visibility: hidden;
}
.bold {
  font-weight: bold;
}
.ad-hoc {
  max-width: 1270px;
  .padding-left {
    padding-left: 10px;
  }
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  .ad-hoc-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    .ad-hoc-detail {
      display: grid;
      grid-template-columns: 1fr 4fr;
      margin-bottom: 10px;
      .ad-hoc-headings {
        color: #999;
      }
    }
    .ad-hoc-title {
      margin-top: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .ad-hoc-student {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }
  }
}
.adHoc-img {
  width: 18px;
  height: 18px;
}
.flex-css {
  display: flex;
  align-items: center;
  span {
    background-color: #f4f4f4;
    padding: 1px 23px;
    border-radius: 24px;
  }
}
