@font-face {
  font-family: 'emoticons';
  src: url('../fonts/emoticons.eot?5zh0vu');
  src: url('../fonts/emoticons.eot?#iefix5zh0vu') format('embedded-opentype'),
    url('../fonts/emoticons.woff?5zh0vu') format('woff'),
    url('../fonts/emoticons.ttf?5zh0vu') format('truetype'),
    url('../fonts/emoticons.svg?5zh0vu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'emoticons';
  src: url('../fonts/emoticons.eot?5zh0vu');
  src: url('../fonts/emoticons.eot?#iefix5zh0vu') format('embedded-opentype'),
    url('../fonts/emoticons.woff?5zh0vu') format('woff'),
    url('../fonts/emoticons.ttf?5zh0vu') format('truetype'),
    url('../fonts/emoticons.svg?5zh0vu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ne-code {
  font-size: 14px;
  padding: 20px;
  font-family: Consolas, Menlo, Monaco, monospace;
  padding: 1.5em;
}

pre {
  position: relative;
  margin-bottom: 24px;
  border-radius: 2px;
  overflow: hidden;
}

code.has-numbering {
  display: block;
  padding: 12px 30px;
  overflow-y: auto;
  font-weight: 300;
  font-family: Consolas, Menlo, Monaco, monospace;
  font-size: 14px;
  line-height: 20px;
  margin-left: 36px;
}

.pre-numbering {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  padding: 1.1em 5px 12px 0;
  border-right: 1px solid #e8e8e8;
  border-radius: 3px 0 0 3px;
  background-color: #eee;
  text-align: right;
  font-family: Consolas, Menlo, Monaco, monospace;
  font-size: 14px;
  color: #aaa;
  line-height: 20px;
  margin-top: -4px;
  li {
    list-style-type: none;
  }
}

.ne-pdf iframe {
  box-sizing: border-box;
  width: 420px;
  height: 530px;
  display: block;
}

.ne-pdf-icon {
  box-sizing: border-box;
  width: 200px;
  height: 150px;
  float: left;
  border-right: 1px solid #eee;
  i {
    margin: 29px 62px;
    color: #999;
    font-size: 84px;
  }
}

.ne-pdf-view button {
  font-family: inherit;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 6px 15px;
  border-radius: 2px;
  color: #333;
  cursor: pointer;
  box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.26);
  outline: 0;
  border-bottom: 0;
  float: right;
  margin: 80px 10px 0 10px;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -kthtml-transition: all 0.5s linear;
  transition: all 0.5s linear;
  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
  }
  i {
    margin-right: 6px;
  }
  a {
    color: #333;
  }
}

.ne-pdf-viewer {
  iframe {
    margin: 0 auto;
  }
  background-color: #ccc;
  position: relative;
  i {
    position: absolute;
    right: 10px;
    top: 8px;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
  }
}

.ne-audio {
  background-color: #1d1d1d;
  border-radius: 2px;
  padding-top: 6px;
  audio {
    width: 100%;
  }
}

.ne-video,
.ne-pdf {
  box-sizing: border-box;
  margin: 10px 0;
  border-bottom: 0;
  overflow: auto;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -kthtml-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.ne-embed {
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  margin: 20px 0;
  box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.26);
  border-bottom: 0;
  padding-bottom: 1px;
  iframe {
    border: 0;
    background-color: #ccc;
    background-image: url('../images/loader.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }
}

.ne-plunker {
  background-color: #ffffff !important;
}

.ne-video {
  video {
    width: 100%;
  }
  iframe,
  object {
    width: 100%;
    border: 0;
  }
}

.ne-audio iframe {
  width: 100%;
  border: 0;
}

.ne-video-preview,
.ne-pdf-preview {
  overflow: auto;
}

.ne-video-preview img {
  display: block;
  max-width: 100%;
  border-radius: 2px;
  background-color: #ccc;
}

.ne-video-thumb {
  cursor: pointer;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: #fff;
  }
}

.ne-pdf-detail {
  box-sizing: border-box;
  width: 250px;
  float: left;
  padding-left: 15px;
  height: 90px;
}

.ne-video-detail {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}

.ne-video-title,
.ne-pdf-title {
  font-weight: bolder;
  flex: 1;
  padding-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.ne-video-desc {
  font-size: 12px;
  color: #666;
  height: 90px;
}

.ne-stats {
  font-size: 12px;
  padding-top: 7px;
  overflow: auto;
}

.ne-video-stats span {
  font-size: 12px;
  padding: 0 15px;
  i {
    margin-right: 10px;
  }
}

.ne-video-player {
  background-color: #ccc;
  background-image: url('../images/loader.svg');
  background-repeat: no-repeat;
  background-position: center;
  iframe {
    width: 100%;
  }
}

.ne-image {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -kthtml-transition: all 0.5s linear;
  transition: all 0.5s linear;
  box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.26);
  border-bottom: 5px solid #ffffff;
  margin: 10px 0;
}

.ne-image-long {
  max-height: 800px;
}

.ne-image img,
.ne-image-long img {
  width: 100%;
  display: block;
  min-height: 250px;
}

.ne-image-wrapper {
  margin: 5px;
  margin-bottom: 0;
  background-color: #ccc;
  background-image: url('../images/loader.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  overflow: hidden;
}

.ne-gist body {
  margin: 0 !important;
}

.emoticons-image-wrapper {
  width: 100%;
}

.emoticons-image {
  width: 100%;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #f2f2f2;
  display: block;
  margin: 20px 0;
}

.ne-emoticon {
  font-family: 'emoticons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.emoticon {
  text-indent: -9999px;
  display: inline-block;
  height: 22px;
  width: 22px;
  vertical-align: middle;
}

@media all and (-webkit-min-device-pixel-ratio: 1),
  all and (min--moz-device-pixel-ratio: 1),
  all and (-o-min-device-pixel-ratio: 1),
  all and (min-device-pixel-ratio: 1),
  all and (min-resolution: 96dpi) {
  .emoticon {
    background: transparent url(../images/emojis.png) 0 0 no-repeat;
  }
}

@media all and (-webkit-min-device-pixel-ratio: 1.5),
  all and (-o-min-device-pixel-ratio: 3 / 2),
  all and (min-device-pixel-ratio: 1.5),
  all and (min-resolution: 144dpi) {
  .emoticon {
    background: transparent url(../images/emojis@2x.png) 0 0 no-repeat;
    background-size: 27776px 22px;
  }
}

@-moz-document url-prefix() {
  .emoticon {
    background: transparent url(../images/emojis.png) 0 0 no-repeat;
  }
}

.emoticon-bowtie {
  background-position: -5px 0;
}

.emoticon-smile {
  background-position: -37px 0;
}

.emoticon-laughing {
  background-position: -69px 0;
}

.emoticon-blush {
  background-position: -101px 0;
}

.emoticon-smiley {
  background-position: -133px 0;
}

.emoticon-relaxed {
  background-position: -165px 0;
}

.emoticon-smirk {
  background-position: -197px 0;
}

.emoticon-heart_eyes {
  background-position: -229px 0;
}

.emoticon-kissing_heart {
  background-position: -261px 0;
}

.emoticon-kissing_closed_eyes {
  background-position: -293px 0;
}

.emoticon-flushed {
  background-position: -325px 0;
}

.emoticon-relieved {
  background-position: -357px 0;
}

.emoticon-satisfied {
  background-position: -389px 0;
}

.emoticon-grin {
  background-position: -421px 0;
}

.emoticon-wink {
  background-position: -453px 0;
}

.emoticon-stuck_out_tongue_winking_eye,
.emoticon-winky_face {
  background-position: -485px 0;
}

.emoticon-stuck_out_tongue_closed_eyes {
  background-position: -517px 0;
}

.emoticon-grinning {
  background-position: -549px 0;
}

.emoticon-kissing {
  background-position: -581px 0;
}

.emoticon-kissing_smiling_eyes {
  background-position: -613px 0;
}

.emoticon-stuck_out_tongue {
  background-position: -645px 0;
}

.emoticon-sleeping {
  background-position: -677px 0;
}

.emoticon-worried {
  background-position: -709px 0;
}

.emoticon-frowning {
  background-position: -741px 0;
}

.emoticon-anguished {
  background-position: -773px 0;
}

.emoticon-open_mouth,
.emoticon-wow {
  background-position: -805px 0;
}

.emoticon-grimacing {
  background-position: -837px 0;
}

.emoticon-confused {
  background-position: -869px 0;
}

.emoticon-hushed {
  background-position: -901px 0;
}

.emoticon-expressionless {
  background-position: -933px 0;
}

.emoticon-unamused {
  background-position: -965px 0;
}

.emoticon-sweat_smile {
  background-position: -997px 0;
}

.emoticon-sweat {
  background-position: -1029px 0;
}

.emoticon-disappointed_relieved {
  background-position: -1061px 0;
}

.emoticon-weary {
  background-position: -1093px 0;
}

.emoticon-pensive {
  background-position: -1125px 0;
}

.emoticon-disappointed {
  background-position: -1157px 0;
}

.emoticon-confounded {
  background-position: -1189px 0;
}

.emoticon-fearful {
  background-position: -1221px 0;
}

.emoticon-cold_sweat {
  background-position: -1253px 0;
}

.emoticon-persevere {
  background-position: -1285px 0;
}

.emoticon-cry {
  background-position: -1317px 0;
}

.emoticon-sob {
  background-position: -1349px 0;
}

.emoticon-joy {
  background-position: -1381px 0;
}

.emoticon-astonished {
  background-position: -1413px 0;
}

.emoticon-scream {
  background-position: -1445px 0;
}

.emoticon-neckbeard {
  background-position: -1477px 0;
}

.emoticon-tired_face {
  background-position: -1509px 0;
}

.emoticon-angry {
  background-position: -1541px 0;
}

.emoticon-rage {
  background-position: -1573px 0;
}

.emoticon-triumph {
  background-position: -1605px 0;
}

.emoticon-sleepy {
  background-position: -1637px 0;
}

.emoticon-yum {
  background-position: -1669px 0;
}

.emoticon-mask {
  background-position: -1701px 0;
}

.emoticon-sunglasses {
  background-position: -1733px 0;
}

.emoticon-dizzy_face {
  background-position: -1765px 0;
}

.emoticon-imp {
  background-position: -1797px 0;
}

.emoticon-smiling_imp {
  background-position: -1829px 0;
}

.emoticon-neutral_face {
  background-position: -1861px 0;
}

.emoticon-no_mouth {
  background-position: -1893px 0;
}

.emoticon-innocent {
  background-position: -1925px 0;
}

.emoticon-alien {
  background-position: -1957px 0;
}

.emoticon-yellow_heart {
  background-position: -1989px 0;
}

.emoticon-blue_heart {
  background-position: -2021px 0;
}

.emoticon-purple_heart {
  background-position: -2053px 0;
}

.emoticon-heart {
  background-position: -2085px 0;
}

.emoticon-green_heart {
  background-position: -2117px 0;
}

.emoticon-broken_heart {
  background-position: -2149px 0;
}

.emoticon-heartbeat {
  background-position: -2181px 0;
}

.emoticon-heartpulse {
  background-position: -2213px 0;
}

.emoticon-two_hearts {
  background-position: -2245px 0;
}

.emoticon-revolving_hearts {
  background-position: -2277px 0;
}

.emoticon-cupid {
  background-position: -2309px 0;
}

.emoticon-sparkling_heart {
  background-position: -2341px 0;
}

.emoticon-sparkles {
  background-position: -2373px 0;
}

.emoticon-star {
  background-position: -2405px 0;
}

.emoticon-star2 {
  background-position: -2437px 0;
}

.emoticon-dizzy {
  background-position: -2469px 0;
}

.emoticon-boom {
  background-position: -2501px 0;
}

.emoticon-collision {
  background-position: -2533px 0;
}

.emoticon-anger {
  background-position: -2565px 0;
}

.emoticon-exclamation {
  background-position: -2597px 0;
}

.emoticon-question {
  background-position: -2629px 0;
}

.emoticon-grey_exclamation {
  background-position: -2661px 0;
}

.emoticon-grey_question {
  background-position: -2693px 0;
}

.emoticon-zzz {
  background-position: -2725px 0;
}

.emoticon-dash {
  background-position: -2757px 0;
}

.emoticon-sweat_drops {
  background-position: -2789px 0;
}

.emoticon-notes {
  background-position: -2821px 0;
}

.emoticon-musical_note {
  background-position: -2853px 0;
}

.emoticon-fire {
  background-position: -2885px 0;
}

.emoticon-hankey {
  background-position: -2917px 0;
}

.emoticon-poop {
  background-position: -2949px 0;
}

.emoticon-shit {
  background-position: -2981px 0;
}

.emoticon-\+1 {
  background-position: -3013px 0;
}

.emoticon-thumbsup {
  background-position: -3045px 0;
}

.emoticon-\-1 {
  background-position: -3077px 0;
}

.emoticon-thumbsdown {
  background-position: -3109px 0;
}

.emoticon-ok_hand {
  background-position: -3141px 0;
}

.emoticon-punch {
  background-position: -3173px 0;
}

.emoticon-facepunch {
  background-position: -3205px 0;
}

.emoticon-fist {
  background-position: -3237px 0;
}

.emoticon-v {
  background-position: -3269px 0;
}

.emoticon-wave {
  background-position: -3301px 0;
}

.emoticon-hand {
  background-position: -3333px 0;
}

.emoticon-raised_hand {
  background-position: -3365px 0;
}

.emoticon-open_hands {
  background-position: -3397px 0;
}

.emoticon-point_up {
  background-position: -3429px 0;
}

.emoticon-point_down {
  background-position: -3461px 0;
}

.emoticon-point_left {
  background-position: -3493px 0;
}

.emoticon-point_right {
  background-position: -3525px 0;
}

.emoticon-raised_hands {
  background-position: -3557px 0;
}

.emoticon-pray {
  background-position: -3589px 0;
}

.emoticon-point_up_2 {
  background-position: -3621px 0;
}

.emoticon-clap {
  background-position: -3653px 0;
}

.emoticon-muscle {
  background-position: -3685px 0;
}

.emoticon-metal {
  background-position: -3717px 0;
}

.emoticon-fu {
  background-position: -3749px 0;
}

.emoticon-walking {
  background-position: -3781px 0;
}

.emoticon-runner {
  background-position: -3813px 0;
}

.emoticon-running {
  background-position: -3845px 0;
}

.emoticon-couple {
  background-position: -3877px 0;
}

.emoticon-family {
  background-position: -3909px 0;
}

.emoticon-two_men_holding_hands {
  background-position: -3941px 0;
}

.emoticon-two_women_holding_hands {
  background-position: -3973px 0;
}

.emoticon-dancer {
  background-position: -4005px 0;
}

.emoticon-dancers {
  background-position: -4037px 0;
}

.emoticon-ok_woman {
  background-position: -4069px 0;
}

.emoticon-no_good {
  background-position: -4101px 0;
}

.emoticon-information_desk_person {
  background-position: -4133px 0;
}

.emoticon-raising_hand {
  background-position: -4165px 0;
}

.emoticon-bride_with_veil {
  background-position: -4197px 0;
}

.emoticon-person_with_pouting_face {
  background-position: -4229px 0;
}

.emoticon-person_frowning {
  background-position: -4261px 0;
}

.emoticon-bow {
  background-position: -4293px 0;
}

.emoticon-couplekiss {
  background-position: -4325px 0;
}

.emoticon-couple_with_heart {
  background-position: -4357px 0;
}

.emoticon-massage {
  background-position: -4389px 0;
}

.emoticon-haircut {
  background-position: -4421px 0;
}

.emoticon-nail_care {
  background-position: -4453px 0;
}

.emoticon-boy {
  background-position: -4485px 0;
}

.emoticon-girl {
  background-position: -4517px 0;
}

.emoticon-woman {
  background-position: -4549px 0;
}

.emoticon-man {
  background-position: -4581px 0;
}

.emoticon-baby {
  background-position: -4613px 0;
}

.emoticon-older_woman {
  background-position: -4645px 0;
}

.emoticon-older_man {
  background-position: -4677px 0;
}

.emoticon-person_with_blond_hair {
  background-position: -4709px 0;
}

.emoticon-man_with_gua_pi_mao {
  background-position: -4741px 0;
}

.emoticon-man_with_turban {
  background-position: -4773px 0;
}

.emoticon-construction_worker {
  background-position: -4805px 0;
}

.emoticon-cop {
  background-position: -4837px 0;
}

.emoticon-angel {
  background-position: -4869px 0;
}

.emoticon-princess {
  background-position: -4901px 0;
}

.emoticon-emoticon_cat {
  background-position: -4933px 0;
}

.emoticon-smile_cat {
  background-position: -4965px 0;
}

.emoticon-heart_eyes_cat {
  background-position: -4997px 0;
}

.emoticon-kissing_cat {
  background-position: -5029px 0;
}

.emoticon-smirk_cat {
  background-position: -5061px 0;
}

.emoticon-scream_cat {
  background-position: -5093px 0;
}

.emoticon-crying_cat_face {
  background-position: -5125px 0;
}

.emoticon-joy_cat {
  background-position: -5157px 0;
}

.emoticon-pouting_cat {
  background-position: -5189px 0;
}

.emoticon-japanese_ogre {
  background-position: -5221px 0;
}

.emoticon-japanese_goblin {
  background-position: -5253px 0;
}

.emoticon-see_no_evil {
  background-position: -5285px 0;
}

.emoticon-hear_no_evil {
  background-position: -5317px 0;
}

.emoticon-speak_no_evil {
  background-position: -5349px 0;
}

.emoticon-guardsman {
  background-position: -5381px 0;
}

.emoticon-skull {
  background-position: -5413px 0;
}

.emoticon-feet {
  background-position: -5445px 0;
}

.emoticon-lips {
  background-position: -5477px 0;
}

.emoticon-kiss {
  background-position: -5509px 0;
}

.emoticon-droplet {
  background-position: -5541px 0;
}

.emoticon-ear {
  background-position: -5573px 0;
}

.emoticon-eyes {
  background-position: -5605px 0;
}

.emoticon-nose {
  background-position: -5637px 0;
}

.emoticon-tongue {
  background-position: -5669px 0;
}

.emoticon-love_letter {
  background-position: -5701px 0;
}

.emoticon-bust_in_silhouette {
  background-position: -5733px 0;
}

.emoticon-busts_in_silhouette {
  background-position: -5765px 0;
}

.emoticon-speech_balloon {
  background-position: -5797px 0;
}

.emoticon-thought_balloon {
  background-position: -5829px 0;
}

.emoticon-feelsgood {
  background-position: -5861px 0;
}

.emoticon-finnadie {
  background-position: -5893px 0;
}

.emoticon-goberserk {
  background-position: -5925px 0;
}

.emoticon-godmode {
  background-position: -5957px 0;
}

.emoticon-hurtrealbad {
  background-position: -5989px 0;
}

.emoticon-rage1 {
  background-position: -6021px 0;
}

.emoticon-rage2 {
  background-position: -6053px 0;
}

.emoticon-rage3 {
  background-position: -6085px 0;
}

.emoticon-rage4 {
  background-position: -6117px 0;
}

.emoticon-suspect {
  background-position: -6149px 0;
}

.emoticon-trollface {
  background-position: -6181px 0;
}

.emoticon-sunny {
  background-position: -6213px 0;
}

.emoticon-umbrella {
  background-position: -6245px 0;
}

.emoticon-cloud {
  background-position: -6277px 0;
}

.emoticon-snowflake {
  background-position: -6309px 0;
}

.emoticon-snowman {
  background-position: -6341px 0;
}

.emoticon-zap {
  background-position: -6373px 0;
}

.emoticon-cyclone {
  background-position: -6405px 0;
}

.emoticon-foggy {
  background-position: -6437px 0;
}

.emoticon-ocean {
  background-position: -6469px 0;
}

.emoticon-cat {
  background-position: -6501px 0;
}

.emoticon-dog {
  background-position: -6533px 0;
}

.emoticon-mouse {
  background-position: -6565px 0;
}

.emoticon-hamster {
  background-position: -6597px 0;
}

.emoticon-rabbit {
  background-position: -6629px 0;
}

.emoticon-wolf {
  background-position: -6661px 0;
}

.emoticon-frog {
  background-position: -6693px 0;
}

.emoticon-tiger {
  background-position: -6725px 0;
}

.emoticon-koala {
  background-position: -6757px 0;
}

.emoticon-bear {
  background-position: -6789px 0;
}

.emoticon-pig {
  background-position: -6821px 0;
}

.emoticon-pig_nose {
  background-position: -6853px 0;
}

.emoticon-cow {
  background-position: -6885px 0;
}

.emoticon-boar {
  background-position: -6917px 0;
}

.emoticon-monkey_face {
  background-position: -6949px 0;
}

.emoticon-monkey {
  background-position: -6981px 0;
}

.emoticon-horse {
  background-position: -7013px 0;
}

.emoticon-racehorse {
  background-position: -7045px 0;
}

.emoticon-camel {
  background-position: -7077px 0;
}

.emoticon-sheep {
  background-position: -7109px 0;
}

.emoticon-elephant {
  background-position: -7141px 0;
}

.emoticon-panda_face {
  background-position: -7173px 0;
}

.emoticon-snake {
  background-position: -7205px 0;
}

.emoticon-bird {
  background-position: -7237px 0;
}

.emoticon-baby_chick {
  background-position: -7269px 0;
}

.emoticon-hatched_chick {
  background-position: -7301px 0;
}

.emoticon-hatching_chick {
  background-position: -7333px 0;
}

.emoticon-chicken {
  background-position: -7365px 0;
}

.emoticon-penguin {
  background-position: -7397px 0;
}

.emoticon-turtle {
  background-position: -7429px 0;
}

.emoticon-bug {
  background-position: -7461px 0;
}

.emoticon-honeybee {
  background-position: -7493px 0;
}

.emoticon-ant {
  background-position: -7525px 0;
}

.emoticon-beetle {
  background-position: -7557px 0;
}

.emoticon-snail {
  background-position: -7589px 0;
}

.emoticon-octopus {
  background-position: -7621px 0;
}

.emoticon-tropical_fish {
  background-position: -7653px 0;
}

.emoticon-fish {
  background-position: -7685px 0;
}

.emoticon-whale {
  background-position: -7717px 0;
}

.emoticon-whale2 {
  background-position: -7749px 0;
}

.emoticon-dolphin {
  background-position: -7781px 0;
}

.emoticon-cow2 {
  background-position: -7813px 0;
}

.emoticon-ram {
  background-position: -7845px 0;
}

.emoticon-rat {
  background-position: -7877px 0;
}

.emoticon-water_buffalo {
  background-position: -7909px 0;
}

.emoticon-tiger2 {
  background-position: -7941px 0;
}

.emoticon-rabbit2 {
  background-position: -7973px 0;
}

.emoticon-dragon {
  background-position: -8005px 0;
}

.emoticon-goat {
  background-position: -8037px 0;
}

.emoticon-rooster {
  background-position: -8069px 0;
}

.emoticon-dog2 {
  background-position: -8101px 0;
}

.emoticon-pig2 {
  background-position: -8133px 0;
}

.emoticon-mouse2 {
  background-position: -8165px 0;
}

.emoticon-ox {
  background-position: -8197px 0;
}

.emoticon-dragon_face {
  background-position: -8229px 0;
}

.emoticon-blowfish {
  background-position: -8261px 0;
}

.emoticon-crocodile {
  background-position: -8293px 0;
}

.emoticon-dromedary_camel {
  background-position: -8325px 0;
}

.emoticon-leopard {
  background-position: -8357px 0;
}

.emoticon-cat2 {
  background-position: -8389px 0;
}

.emoticon-poodle {
  background-position: -8421px 0;
}

.emoticon-paw_prints {
  background-position: -8453px 0;
}

.emoticon-bouquet {
  background-position: -8485px 0;
}

.emoticon-cherry_blossom {
  background-position: -8517px 0;
}

.emoticon-tulip {
  background-position: -8549px 0;
}

.emoticon-four_leaf_clover {
  background-position: -8581px 0;
}

.emoticon-rose {
  background-position: -8613px 0;
}

.emoticon-sunflower {
  background-position: -8645px 0;
}

.emoticon-hibiscus {
  background-position: -8677px 0;
}

.emoticon-maple_leaf {
  background-position: -8709px 0;
}

.emoticon-leaves {
  background-position: -8741px 0;
}

.emoticon-fallen_leaf {
  background-position: -8773px 0;
}

.emoticon-herb {
  background-position: -8805px 0;
}

.emoticon-mushroom {
  background-position: -8837px 0;
}

.emoticon-cactus {
  background-position: -8869px 0;
}

.emoticon-palm_tree {
  background-position: -8901px 0;
}

.emoticon-evergreen_tree {
  background-position: -8933px 0;
}

.emoticon-deciduous_tree {
  background-position: -8965px 0;
}

.emoticon-chestnut {
  background-position: -8997px 0;
}

.emoticon-seedling {
  background-position: -9029px 0;
}

.emoticon-blossom {
  background-position: -9061px 0;
}

.emoticon-ear_of_rice {
  background-position: -9093px 0;
}

.emoticon-shell {
  background-position: -9125px 0;
}

.emoticon-globe_with_meridians {
  background-position: -9157px 0;
}

.emoticon-sun_with_face {
  background-position: -9189px 0;
}

.emoticon-full_moon_with_face {
  background-position: -9221px 0;
}

.emoticon-new_moon_with_face {
  background-position: -9253px 0;
}

.emoticon-new_moon {
  background-position: -9285px 0;
}

.emoticon-waxing_crescent_moon {
  background-position: -9317px 0;
}

.emoticon-first_quarter_moon {
  background-position: -9349px 0;
}

.emoticon-waxing_gibbous_moon {
  background-position: -9381px 0;
}

.emoticon-full_moon {
  background-position: -9413px 0;
}

.emoticon-waning_gibbous_moon {
  background-position: -9445px 0;
}

.emoticon-last_quarter_moon {
  background-position: -9477px 0;
}

.emoticon-waning_crescent_moon {
  background-position: -9509px 0;
}

.emoticon-last_quarter_moon_with_face {
  background-position: -9541px 0;
}

.emoticon-first_quarter_moon_with_face {
  background-position: -9573px 0;
}

.emoticon-moon {
  background-position: -9605px 0;
}

.emoticon-earth_africa {
  background-position: -9637px 0;
}

.emoticon-earth_americas {
  background-position: -9669px 0;
}

.emoticon-earth_asia {
  background-position: -9701px 0;
}

.emoticon-volcano {
  background-position: -9733px 0;
}

.emoticon-milky_way {
  background-position: -9765px 0;
}

.emoticon-partly_sunny {
  background-position: -9797px 0;
}

.emoticon-octocat {
  background-position: -9829px 0;
}

.emoticon-squirrel {
  background-position: -9861px 0;
}

.emoticon-bamboo {
  background-position: -9893px 0;
}

.emoticon-gift_heart {
  background-position: -9925px 0;
}

.emoticon-dolls {
  background-position: -9957px 0;
}

.emoticon-school_satchel {
  background-position: -9989px 0;
}

.emoticon-mortar_board {
  background-position: -10021px 0;
}

.emoticon-flags {
  background-position: -10053px 0;
}

.emoticon-fireworks {
  background-position: -10085px 0;
}

.emoticon-sparkler {
  background-position: -10117px 0;
}

.emoticon-wind_chime {
  background-position: -10149px 0;
}

.emoticon-rice_scene {
  background-position: -10181px 0;
}

.emoticon-jack_o_lantern {
  background-position: -10213px 0;
}

.emoticon-ghost {
  background-position: -10245px 0;
}

.emoticon-santa {
  background-position: -10277px 0;
}

.emoticon-christmas_tree {
  background-position: -10309px 0;
}

.emoticon-gift {
  background-position: -10341px 0;
}

.emoticon-bell {
  background-position: -10373px 0;
}

.emoticon-no_bell {
  background-position: -10405px 0;
}

.emoticon-tanabata_tree {
  background-position: -10437px 0;
}

.emoticon-tada {
  background-position: -10469px 0;
}

.emoticon-confetti_ball {
  background-position: -10501px 0;
}

.emoticon-balloon {
  background-position: -10533px 0;
}

.emoticon-crystal_ball {
  background-position: -10565px 0;
}

.emoticon-cd {
  background-position: -10597px 0;
}

.emoticon-dvd {
  background-position: -10629px 0;
}

.emoticon-floppy_disk {
  background-position: -10661px 0;
}

.emoticon-camera {
  background-position: -10693px 0;
}

.emoticon-video_camera {
  background-position: -10725px 0;
}

.emoticon-movie_camera {
  background-position: -10757px 0;
}

.emoticon-computer {
  background-position: -10789px 0;
}

.emoticon-tv {
  background-position: -10821px 0;
}

.emoticon-iphone {
  background-position: -10853px 0;
}

.emoticon-phone {
  background-position: -10885px 0;
}

.emoticon-telephone {
  background-position: -10917px 0;
}

.emoticon-telephone_receiver {
  background-position: -10949px 0;
}

.emoticon-pager {
  background-position: -10981px 0;
}

.emoticon-fax {
  background-position: -11013px 0;
}

.emoticon-minidisc {
  background-position: -11045px 0;
}

.emoticon-vhs {
  background-position: -11077px 0;
}

.emoticon-sound {
  background-position: -11109px 0;
}

.emoticon-speaker {
  background-position: -11141px 0;
}

.emoticon-mute {
  background-position: -11173px 0;
}

.emoticon-loudspeaker {
  background-position: -11205px 0;
}

.emoticon-mega {
  background-position: -11237px 0;
}

.emoticon-hourglass {
  background-position: -11269px 0;
}

.emoticon-hourglass_flowing_sand {
  background-position: -11301px 0;
}

.emoticon-alarm_clock {
  background-position: -11333px 0;
}

.emoticon-watch {
  background-position: -11365px 0;
}

.emoticon-radio {
  background-position: -11397px 0;
}

.emoticon-satellite {
  background-position: -11429px 0;
}

.emoticon-loop {
  background-position: -11461px 0;
}

.emoticon-mag {
  background-position: -11493px 0;
}

.emoticon-mag_right {
  background-position: -11525px 0;
}

.emoticon-unlock {
  background-position: -11557px 0;
}

.emoticon-lock {
  background-position: -11589px 0;
}

.emoticon-lock_with_ink_pen {
  background-position: -11621px 0;
}

.emoticon-closed_lock_with_key {
  background-position: -11653px 0;
}

.emoticon-key {
  background-position: -11685px 0;
}

.emoticon-bulb {
  background-position: -11717px 0;
}

.emoticon-flashlight {
  background-position: -11749px 0;
}

.emoticon-high_brightness {
  background-position: -11781px 0;
}

.emoticon-low_brightness {
  background-position: -11813px 0;
}

.emoticon-electric_plug {
  background-position: -11845px 0;
}

.emoticon-battery {
  background-position: -11877px 0;
}

.emoticon-calling {
  background-position: -11909px 0;
}

.emoticon-email {
  background-position: -11941px 0;
}

.emoticon-mailbox {
  background-position: -11973px 0;
}

.emoticon-postbox {
  background-position: -12005px 0;
}

.emoticon-bath {
  background-position: -12037px 0;
}

.emoticon-bathtub {
  background-position: -12069px 0;
}

.emoticon-shower {
  background-position: -12101px 0;
}

.emoticon-toilet {
  background-position: -12133px 0;
}

.emoticon-wrench {
  background-position: -12165px 0;
}

.emoticon-nut_and_bolt {
  background-position: -12197px 0;
}

.emoticon-hammer {
  background-position: -12229px 0;
}

.emoticon-seat {
  background-position: -12261px 0;
}

.emoticon-moneybag {
  background-position: -12293px 0;
}

.emoticon-yen {
  background-position: -12325px 0;
}

.emoticon-dollar {
  background-position: -12357px 0;
}

.emoticon-pound {
  background-position: -12389px 0;
}

.emoticon-euro {
  background-position: -12421px 0;
}

.emoticon-credit_card {
  background-position: -12453px 0;
}

.emoticon-money_with_wings {
  background-position: -12485px 0;
}

.emoticon-e\-mail {
  background-position: -12517px 0;
}

.emoticon-inbox_tray {
  background-position: -12549px 0;
}

.emoticon-outbox_tray {
  background-position: -12581px 0;
}

.emoticon-envelope {
  background-position: -12613px 0;
}

.emoticon-incoming_envelope {
  background-position: -12645px 0;
}

.emoticon-postal_horn {
  background-position: -12677px 0;
}

.emoticon-mailbox_closed {
  background-position: -12709px 0;
}

.emoticon-mailbox_with_mail {
  background-position: -12741px 0;
}

.emoticon-mailbox_with_no_mail {
  background-position: -12773px 0;
}

.emoticon-door {
  background-position: -12805px 0;
}

.emoticon-smoking {
  background-position: -12837px 0;
}

.emoticon-bomb {
  background-position: -12869px 0;
}

.emoticon-gun {
  background-position: -12901px 0;
}

.emoticon-hocho {
  background-position: -12933px 0;
}

.emoticon-pill {
  background-position: -12965px 0;
}

.emoticon-syringe {
  background-position: -12997px 0;
}

.emoticon-page_facing_up {
  background-position: -13029px 0;
}

.emoticon-page_with_curl {
  background-position: -13061px 0;
}

.emoticon-bookmark_tabs {
  background-position: -13093px 0;
}

.emoticon-bar_chart {
  background-position: -13125px 0;
}

.emoticon-chart_with_upwards_trend {
  background-position: -13157px 0;
}

.emoticon-chart_with_downwards_trend {
  background-position: -13189px 0;
}

.emoticon-scroll {
  background-position: -13221px 0;
}

.emoticon-clipboard {
  background-position: -13253px 0;
}

.emoticon-calendar {
  background-position: -13285px 0;
}

.emoticon-date {
  background-position: -13317px 0;
}

.emoticon-card_index {
  background-position: -13349px 0;
}

.emoticon-file_folder {
  background-position: -13381px 0;
}

.emoticon-open_file_folder {
  background-position: -13413px 0;
}

.emoticon-scissors {
  background-position: -13445px 0;
}

.emoticon-pushpin {
  background-position: -13477px 0;
}

.emoticon-paperclip {
  background-position: -13509px 0;
}

.emoticon-black_nib {
  background-position: -13541px 0;
}

.emoticon-pencil2 {
  background-position: -13573px 0;
}

.emoticon-straight_ruler {
  background-position: -13605px 0;
}

.emoticon-triangular_ruler {
  background-position: -13637px 0;
}

.emoticon-closed_book {
  background-position: -13669px 0;
}

.emoticon-green_book {
  background-position: -13701px 0;
}

.emoticon-blue_book {
  background-position: -13733px 0;
}

.emoticon-orange_book {
  background-position: -13765px 0;
}

.emoticon-notebook {
  background-position: -13797px 0;
}

.emoticon-notebook_with_decorative_cover {
  background-position: -13829px 0;
}

.emoticon-ledger {
  background-position: -13861px 0;
}

.emoticon-books {
  background-position: -13893px 0;
}

.emoticon-bookmark {
  background-position: -13925px 0;
}

.emoticon-name_badge {
  background-position: -13957px 0;
}

.emoticon-microscope {
  background-position: -13989px 0;
}

.emoticon-telescope {
  background-position: -14021px 0;
}

.emoticon-newspaper {
  background-position: -14053px 0;
}

.emoticon-football {
  background-position: -14085px 0;
}

.emoticon-basketball {
  background-position: -14117px 0;
}

.emoticon-soccer {
  background-position: -14149px 0;
}

.emoticon-baseball {
  background-position: -14181px 0;
}

.emoticon-tennis {
  background-position: -14213px 0;
}

.emoticon-8ball {
  background-position: -14245px 0;
}

.emoticon-rugby_football {
  background-position: -14277px 0;
}

.emoticon-bowling {
  background-position: -14309px 0;
}

.emoticon-golf {
  background-position: -14341px 0;
}

.emoticon-mountain_bicyclist {
  background-position: -14373px 0;
}

.emoticon-bicyclist {
  background-position: -14405px 0;
}

.emoticon-horse_racing {
  background-position: -14437px 0;
}

.emoticon-snowboarder {
  background-position: -14469px 0;
}

.emoticon-swimmer {
  background-position: -14501px 0;
}

.emoticon-surfer {
  background-position: -14533px 0;
}

.emoticon-ski {
  background-position: -14565px 0;
}

.emoticon-spades {
  background-position: -14597px 0;
}

.emoticon-hearts {
  background-position: -14629px 0;
}

.emoticon-clubs {
  background-position: -14661px 0;
}

.emoticon-diamonds {
  background-position: -14693px 0;
}

.emoticon-gem {
  background-position: -14725px 0;
}

.emoticon-ring {
  background-position: -14757px 0;
}

.emoticon-trophy {
  background-position: -14789px 0;
}

.emoticon-musical_score {
  background-position: -14821px 0;
}

.emoticon-musical_keyboard {
  background-position: -14853px 0;
}

.emoticon-violin {
  background-position: -14885px 0;
}

.emoticon-space_invader {
  background-position: -14917px 0;
}

.emoticon-video_game {
  background-position: -14949px 0;
}

.emoticon-black_joker {
  background-position: -14981px 0;
}

.emoticon-flower_playing_cards {
  background-position: -15013px 0;
}

.emoticon-game_die {
  background-position: -15045px 0;
}

.emoticon-dart {
  background-position: -15077px 0;
}

.emoticon-mahjong {
  background-position: -15109px 0;
}

.emoticon-clapper {
  background-position: -15141px 0;
}

.emoticon-memo {
  background-position: -15173px 0;
}

.emoticon-pencil {
  background-position: -15205px 0;
}

.emoticon-book {
  background-position: -15237px 0;
}

.emoticon-art {
  background-position: -15269px 0;
}

.emoticon-microphone {
  background-position: -15301px 0;
}

.emoticon-headphones {
  background-position: -15333px 0;
}

.emoticon-trumpet {
  background-position: -15365px 0;
}

.emoticon-saxophone {
  background-position: -15397px 0;
}

.emoticon-guitar {
  background-position: -15429px 0;
}

.emoticon-shoe {
  background-position: -15461px 0;
}

.emoticon-sandal {
  background-position: -15493px 0;
}

.emoticon-high_heel {
  background-position: -15525px 0;
}

.emoticon-lipstick {
  background-position: -15557px 0;
}

.emoticon-boot {
  background-position: -15589px 0;
}

.emoticon-shirt {
  background-position: -15621px 0;
}

.emoticon-tshirt {
  background-position: -15653px 0;
}

.emoticon-necktie {
  background-position: -15685px 0;
}

.emoticon-womans_clothes {
  background-position: -15717px 0;
}

.emoticon-dress {
  background-position: -15749px 0;
}

.emoticon-running_shirt_with_sash {
  background-position: -15781px 0;
}

.emoticon-jeans {
  background-position: -15813px 0;
}

.emoticon-kimono {
  background-position: -15845px 0;
}

.emoticon-bikini {
  background-position: -15877px 0;
}

.emoticon-ribbon {
  background-position: -15909px 0;
}

.emoticon-tophat {
  background-position: -15941px 0;
}

.emoticon-crown {
  background-position: -15973px 0;
}

.emoticon-womans_hat {
  background-position: -16005px 0;
}

.emoticon-mans_shoe {
  background-position: -16037px 0;
}

.emoticon-closed_umbrella {
  background-position: -16069px 0;
}

.emoticon-briefcase {
  background-position: -16101px 0;
}

.emoticon-handbag {
  background-position: -16133px 0;
}

.emoticon-pouch {
  background-position: -16165px 0;
}

.emoticon-purse {
  background-position: -16197px 0;
}

.emoticon-eyeglasses {
  background-position: -16229px 0;
}

.emoticon-fishing_pole_and_fish {
  background-position: -16261px 0;
}

.emoticon-coffee {
  background-position: -16293px 0;
}

.emoticon-tea {
  background-position: -16325px 0;
}

.emoticon-sake {
  background-position: -16357px 0;
}

.emoticon-baby_bottle {
  background-position: -16389px 0;
}

.emoticon-beer {
  background-position: -16421px 0;
}

.emoticon-beers {
  background-position: -16453px 0;
}

.emoticon-cocktail {
  background-position: -16485px 0;
}

.emoticon-tropical_drink {
  background-position: -16517px 0;
}

.emoticon-wine_glass {
  background-position: -16549px 0;
}

.emoticon-fork_and_knife {
  background-position: -16581px 0;
}

.emoticon-pizza {
  background-position: -16613px 0;
}

.emoticon-hamburger {
  background-position: -16645px 0;
}

.emoticon-fries {
  background-position: -16677px 0;
}

.emoticon-poultry_leg {
  background-position: -16709px 0;
}

.emoticon-meat_on_bone {
  background-position: -16741px 0;
}

.emoticon-spaghetti {
  background-position: -16773px 0;
}

.emoticon-curry {
  background-position: -16805px 0;
}

.emoticon-fried_shrimp {
  background-position: -16837px 0;
}

.emoticon-bento {
  background-position: -16869px 0;
}

.emoticon-sushi {
  background-position: -16901px 0;
}

.emoticon-fish_cake {
  background-position: -16933px 0;
}

.emoticon-rice_ball {
  background-position: -16965px 0;
}

.emoticon-rice_cracker {
  background-position: -16997px 0;
}

.emoticon-rice {
  background-position: -17029px 0;
}

.emoticon-ramen {
  background-position: -17061px 0;
}

.emoticon-stew {
  background-position: -17093px 0;
}

.emoticon-oden {
  background-position: -17125px 0;
}

.emoticon-dango {
  background-position: -17157px 0;
}

.emoticon-egg {
  background-position: -17189px 0;
}

.emoticon-bread {
  background-position: -17221px 0;
}

.emoticon-doughnut {
  background-position: -17253px 0;
}

.emoticon-custard {
  background-position: -17285px 0;
}

.emoticon-icecream {
  background-position: -17317px 0;
}

.emoticon-ice_cream {
  background-position: -17349px 0;
}

.emoticon-shaved_ice {
  background-position: -17381px 0;
}

.emoticon-birthday {
  background-position: -17413px 0;
}

.emoticon-cake {
  background-position: -17445px 0;
}

.emoticon-cookie {
  background-position: -17477px 0;
}

.emoticon-chocolate_bar {
  background-position: -17509px 0;
}

.emoticon-candy {
  background-position: -17541px 0;
}

.emoticon-lollipop {
  background-position: -17573px 0;
}

.emoticon-honey_pot {
  background-position: -17605px 0;
}

.emoticon-apple {
  background-position: -17637px 0;
}

.emoticon-green_apple {
  background-position: -17669px 0;
}

.emoticon-tangerine {
  background-position: -17701px 0;
}

.emoticon-lemon {
  background-position: -17733px 0;
}

.emoticon-cherries {
  background-position: -17765px 0;
}

.emoticon-grapes {
  background-position: -17797px 0;
}

.emoticon-watermelon {
  background-position: -17829px 0;
}

.emoticon-strawberry {
  background-position: -17861px 0;
}

.emoticon-peach {
  background-position: -17893px 0;
}

.emoticon-melon {
  background-position: -17925px 0;
}

.emoticon-banana {
  background-position: -17957px 0;
}

.emoticon-pear {
  background-position: -17989px 0;
}

.emoticon-pineapple {
  background-position: -18021px 0;
}

.emoticon-sweet_potato {
  background-position: -18053px 0;
}

.emoticon-eggplant {
  background-position: -18085px 0;
}

.emoticon-tomato {
  background-position: -18117px 0;
}

.emoticon-corn {
  background-position: -18149px 0;
}

.emoticon-house {
  background-position: -18181px 0;
}

.emoticon-house_with_garden {
  background-position: -18213px 0;
}

.emoticon-school {
  background-position: -18245px 0;
}

.emoticon-office {
  background-position: -18277px 0;
}

.emoticon-post_office {
  background-position: -18309px 0;
}

.emoticon-hospital {
  background-position: -18341px 0;
}

.emoticon-bank {
  background-position: -18373px 0;
}

.emoticon-convenience_store {
  background-position: -18405px 0;
}

.emoticon-love_hotel {
  background-position: -18437px 0;
}

.emoticon-hotel {
  background-position: -18469px 0;
}

.emoticon-wedding {
  background-position: -18501px 0;
}

.emoticon-church {
  background-position: -18533px 0;
}

.emoticon-department_store {
  background-position: -18565px 0;
}

.emoticon-european_post_office {
  background-position: -18597px 0;
}

.emoticon-city_sunrise {
  background-position: -18629px 0;
}

.emoticon-city_sunset {
  background-position: -18661px 0;
}

.emoticon-japanese_castle {
  background-position: -18693px 0;
}

.emoticon-european_castle {
  background-position: -18725px 0;
}

.emoticon-tent {
  background-position: -18757px 0;
}

.emoticon-factory {
  background-position: -18789px 0;
}

.emoticon-tokyo_tower {
  background-position: -18821px 0;
}

.emoticon-japan {
  background-position: -18853px 0;
}

.emoticon-mount_fuji {
  background-position: -18885px 0;
}

.emoticon-sunrise_over_mountains {
  background-position: -18917px 0;
}

.emoticon-sunrise {
  background-position: -18949px 0;
}

.emoticon-stars,
.emoticon-themoreyouknow,
.emoticon-tmyk {
  background-position: -18981px 0;
}

.emoticon-statue_of_liberty {
  background-position: -19013px 0;
}

.emoticon-bridge_at_night {
  background-position: -19045px 0;
}

.emoticon-carousel_horse {
  background-position: -19077px 0;
}

.emoticon-rainbow {
  background-position: -19109px 0;
}

.emoticon-ferris_wheel {
  background-position: -19141px 0;
}

.emoticon-fountain {
  background-position: -19173px 0;
}

.emoticon-roller_coaster {
  background-position: -19205px 0;
}

.emoticon-ship {
  background-position: -19237px 0;
}

.emoticon-speedboat {
  background-position: -19269px 0;
}

.emoticon-boat {
  background-position: -19301px 0;
}

.emoticon-sailboat {
  background-position: -19333px 0;
}

.emoticon-rowboat {
  background-position: -19365px 0;
}

.emoticon-anchor {
  background-position: -19397px 0;
}

.emoticon-rocket {
  background-position: -19429px 0;
}

.emoticon-airplane {
  background-position: -19461px 0;
}

.emoticon-helicopter {
  background-position: -19493px 0;
}

.emoticon-steam_locomotive {
  background-position: -19525px 0;
}

.emoticon-tram {
  background-position: -19557px 0;
}

.emoticon-mountain_railway {
  background-position: -19589px 0;
}

.emoticon-bike {
  background-position: -19621px 0;
}

.emoticon-aerial_tramway {
  background-position: -19653px 0;
}

.emoticon-suspension_railway {
  background-position: -19685px 0;
}

.emoticon-mountain_cableway {
  background-position: -19717px 0;
}

.emoticon-tractor {
  background-position: -19749px 0;
}

.emoticon-blue_car {
  background-position: -19781px 0;
}

.emoticon-oncoming_automobile {
  background-position: -19813px 0;
}

.emoticon-car {
  background-position: -19845px 0;
}

.emoticon-red_car {
  background-position: -19877px 0;
}

.emoticon-taxi {
  background-position: -19909px 0;
}

.emoticon-oncoming_taxi {
  background-position: -19941px 0;
}

.emoticon-articulated_lorry {
  background-position: -19973px 0;
}

.emoticon-bus {
  background-position: -20005px 0;
}

.emoticon-oncoming_bus {
  background-position: -20037px 0;
}

.emoticon-rotating_light {
  background-position: -20069px 0;
}

.emoticon-police_car {
  background-position: -20101px 0;
}

.emoticon-oncoming_police_car {
  background-position: -20133px 0;
}

.emoticon-fire_engine {
  background-position: -20165px 0;
}

.emoticon-ambulance {
  background-position: -20197px 0;
}

.emoticon-minibus {
  background-position: -20229px 0;
}

.emoticon-truck {
  background-position: -20261px 0;
}

.emoticon-train {
  background-position: -20293px 0;
}

.emoticon-station {
  background-position: -20325px 0;
}

.emoticon-train2 {
  background-position: -20357px 0;
}

.emoticon-bullettrain_front {
  background-position: -20389px 0;
}

.emoticon-bullettrain_side {
  background-position: -20421px 0;
}

.emoticon-light_rail {
  background-position: -20453px 0;
}

.emoticon-monorail {
  background-position: -20485px 0;
}

.emoticon-railway_car {
  background-position: -20517px 0;
}

.emoticon-trolleybus {
  background-position: -20549px 0;
}

.emoticon-ticket {
  background-position: -20581px 0;
}

.emoticon-fuelpump {
  background-position: -20613px 0;
}

.emoticon-vertical_traffic_light {
  background-position: -20645px 0;
}

.emoticon-traffic_light {
  background-position: -20677px 0;
}

.emoticon-warning {
  background-position: -20709px 0;
}

.emoticon-construction {
  background-position: -20741px 0;
}

.emoticon-beginner {
  background-position: -20773px 0;
}

.emoticon-atm {
  background-position: -20805px 0;
}

.emoticon-slot_machine {
  background-position: -20837px 0;
}

.emoticon-busstop {
  background-position: -20869px 0;
}

.emoticon-barber {
  background-position: -20901px 0;
}

.emoticon-hotsprings {
  background-position: -20933px 0;
}

.emoticon-checkered_flag {
  background-position: -20965px 0;
}

.emoticon-crossed_flags {
  background-position: -20997px 0;
}

.emoticon-izakaya_lantern {
  background-position: -21029px 0;
}

.emoticon-moyai {
  background-position: -21061px 0;
}

.emoticon-circus_tent {
  background-position: -21093px 0;
}

.emoticon-performing_arts {
  background-position: -21125px 0;
}

.emoticon-round_pushpin {
  background-position: -21157px 0;
}

.emoticon-triangular_flag_on_post {
  background-position: -21189px 0;
}

.emoticon-jp {
  background-position: -21221px 0;
}

.emoticon-kr {
  background-position: -21253px 0;
}

.emoticon-cn {
  background-position: -21285px 0;
}

.emoticon-us {
  background-position: -21317px 0;
}

.emoticon-fr {
  background-position: -21349px 0;
}

.emoticon-es {
  background-position: -21381px 0;
}

.emoticon-it {
  background-position: -21413px 0;
}

.emoticon-ru {
  background-position: -21445px 0;
}

.emoticon-gb {
  background-position: -21477px 0;
}

.emoticon-uk {
  background-position: -21509px 0;
}

.emoticon-de {
  background-position: -21541px 0;
}

.emoticon-one {
  background-position: -21573px 0;
}

.emoticon-two {
  background-position: -21605px 0;
}

.emoticon-three {
  background-position: -21637px 0;
}

.emoticon-four {
  background-position: -21669px 0;
}

.emoticon-five {
  background-position: -21701px 0;
}

.emoticon-six {
  background-position: -21733px 0;
}

.emoticon-seven {
  background-position: -21765px 0;
}

.emoticon-eight {
  background-position: -21797px 0;
}

.emoticon-nine {
  background-position: -21829px 0;
}

.emoticon-keycap_ten {
  background-position: -21861px 0;
}

.emoticon-1234 {
  background-position: -21893px 0;
}

.emoticon-zero {
  background-position: -21925px 0;
}

.emoticon-hash {
  background-position: -21957px 0;
}

.emoticon-symbols {
  background-position: -21989px 0;
}

.emoticon-arrow_backward {
  background-position: -22021px 0;
}

.emoticon-arrow_down {
  background-position: -22053px 0;
}

.emoticon-arrow_forward {
  background-position: -22085px 0;
}

.emoticon-arrow_left {
  background-position: -22117px 0;
}

.emoticon-capital_abcd {
  background-position: -22149px 0;
}

.emoticon-abcd {
  background-position: -22181px 0;
}

.emoticon-abc {
  background-position: -22213px 0;
}

.emoticon-arrow_lower_left {
  background-position: -22245px 0;
}

.emoticon-arrow_lower_right {
  background-position: -22277px 0;
}

.emoticon-arrow_right {
  background-position: -22309px 0;
}

.emoticon-arrow_up {
  background-position: -22341px 0;
}

.emoticon-arrow_upper_left {
  background-position: -22373px 0;
}

.emoticon-arrow_upper_right {
  background-position: -22405px 0;
}

.emoticon-arrow_double_down {
  background-position: -22437px 0;
}

.emoticon-arrow_double_up {
  background-position: -22469px 0;
}

.emoticon-arrow_down_small {
  background-position: -22501px 0;
}

.emoticon-arrow_heading_down {
  background-position: -22533px 0;
}

.emoticon-arrow_heading_up {
  background-position: -22565px 0;
}

.emoticon-leftwards_arrow_with_hook {
  background-position: -22597px 0;
}

.emoticon-arrow_right_hook {
  background-position: -22629px 0;
}

.emoticon-left_right_arrow {
  background-position: -22661px 0;
}

.emoticon-arrow_up_down {
  background-position: -22693px 0;
}

.emoticon-arrow_up_small {
  background-position: -22725px 0;
}

.emoticon-arrows_clockwise {
  background-position: -22757px 0;
}

.emoticon-arrows_counterclockwise {
  background-position: -22789px 0;
}

.emoticon-rewind {
  background-position: -22821px 0;
}

.emoticon-fast_forward {
  background-position: -22853px 0;
}

.emoticon-information_source {
  background-position: -22885px 0;
}

.emoticon-ok {
  background-position: -22917px 0;
}

.emoticon-twisted_rightwards_arrows {
  background-position: -22949px 0;
}

.emoticon-repeat {
  background-position: -22981px 0;
}

.emoticon-repeat_one {
  background-position: -23013px 0;
}

.emoticon-new {
  background-position: -23045px 0;
}

.emoticon-top {
  background-position: -23077px 0;
}

.emoticon-up {
  background-position: -23109px 0;
}

.emoticon-cool {
  background-position: -23141px 0;
}

.emoticon-free {
  background-position: -23173px 0;
}

.emoticon-ng {
  background-position: -23205px 0;
}

.emoticon-cinema {
  background-position: -23237px 0;
}

.emoticon-koko {
  background-position: -23269px 0;
}

.emoticon-signal_strength {
  background-position: -23301px 0;
}

.emoticon-u5272 {
  background-position: -23333px 0;
}

.emoticon-u5408 {
  background-position: -23365px 0;
}

.emoticon-u55b6 {
  background-position: -23397px 0;
}

.emoticon-u6307 {
  background-position: -23429px 0;
}

.emoticon-u6708 {
  background-position: -23461px 0;
}

.emoticon-u6709 {
  background-position: -23493px 0;
}

.emoticon-u6e80 {
  background-position: -23525px 0;
}

.emoticon-u7121 {
  background-position: -23557px 0;
}

.emoticon-u7533 {
  background-position: -23589px 0;
}

.emoticon-u7a7a {
  background-position: -23621px 0;
}

.emoticon-u7981 {
  background-position: -23653px 0;
}

.emoticon-sa {
  background-position: -23685px 0;
}

.emoticon-restroom {
  background-position: -23717px 0;
}

.emoticon-mens {
  background-position: -23749px 0;
}

.emoticon-womens {
  background-position: -23781px 0;
}

.emoticon-baby_symbol {
  background-position: -23813px 0;
}

.emoticon-no_smoking {
  background-position: -23845px 0;
}

.emoticon-parking {
  background-position: -23877px 0;
}

.emoticon-wheelchair {
  background-position: -23909px 0;
}

.emoticon-metro {
  background-position: -23941px 0;
}

.emoticon-baggage_claim {
  background-position: -23973px 0;
}

.emoticon-accept {
  background-position: -24005px 0;
}

.emoticon-wc {
  background-position: -24037px 0;
}

.emoticon-potable_water {
  background-position: -24069px 0;
}

.emoticon-put_litter_in_its_place {
  background-position: -24101px 0;
}

.emoticon-secret {
  background-position: -24133px 0;
}

.emoticon-congratulations {
  background-position: -24165px 0;
}

.emoticon-m {
  background-position: -24197px 0;
}

.emoticon-passport_control {
  background-position: -24229px 0;
}

.emoticon-left_luggage {
  background-position: -24261px 0;
}

.emoticon-customs {
  background-position: -24293px 0;
}

.emoticon-ideograph_advantage {
  background-position: -24325px 0;
}

.emoticon-cl {
  background-position: -24357px 0;
}

.emoticon-sos {
  background-position: -24389px 0;
}

.emoticon-id {
  background-position: -24421px 0;
}

.emoticon-no_entry_sign {
  background-position: -24453px 0;
}

.emoticon-underage {
  background-position: -24485px 0;
}

.emoticon-no_mobile_phones {
  background-position: -24517px 0;
}

.emoticon-do_not_litter {
  background-position: -24549px 0;
}

.emoticon-non\-potable_water {
  background-position: -24581px 0;
}

.emoticon-no_bicycles {
  background-position: -24613px 0;
}

.emoticon-no_pedestrians {
  background-position: -24645px 0;
}

.emoticon-children_crossing {
  background-position: -24677px 0;
}

.emoticon-no_entry {
  background-position: -24709px 0;
}

.emoticon-eight_spoked_asterisk {
  background-position: -24741px 0;
}

.emoticon-eight_pointed_black_star {
  background-position: -24773px 0;
}

.emoticon-heart_decoration {
  background-position: -24805px 0;
}

.emoticon-vs {
  background-position: -24837px 0;
}

.emoticon-vibration_mode {
  background-position: -24869px 0;
}

.emoticon-mobile_phone_off {
  background-position: -24901px 0;
}

.emoticon-chart {
  background-position: -24933px 0;
}

.emoticon-currency_exchange {
  background-position: -24965px 0;
}

.emoticon-aries {
  background-position: -24997px 0;
}

.emoticon-taurus {
  background-position: -25029px 0;
}

.emoticon-gemini {
  background-position: -25061px 0;
}

.emoticon-cancer {
  background-position: -25093px 0;
}

.emoticon-leo {
  background-position: -25125px 0;
}

.emoticon-virgo {
  background-position: -25157px 0;
}

.emoticon-libra {
  background-position: -25189px 0;
}

.emoticon-scorpius {
  background-position: -25221px 0;
}

.emoticon-sagittarius {
  background-position: -25253px 0;
}

.emoticon-capricorn {
  background-position: -25285px 0;
}

.emoticon-aquarius {
  background-position: -25317px 0;
}

.emoticon-pisces {
  background-position: -25349px 0;
}

.emoticon-ophiuchus {
  background-position: -25381px 0;
}

.emoticon-six_pointed_star {
  background-position: -25413px 0;
}

.emoticon-negative_squared_cross_mark {
  background-position: -25445px 0;
}

.emoticon-a {
  background-position: -25477px 0;
}

.emoticon-b {
  background-position: -25509px 0;
}

.emoticon-ab {
  background-position: -25541px 0;
}

.emoticon-o2 {
  background-position: -25573px 0;
}

.emoticon-diamond_shape_with_a_dot_inside {
  background-position: -25605px 0;
}

.emoticon-recycle {
  background-position: -25637px 0;
}

.emoticon-end {
  background-position: -25669px 0;
}

.emoticon-on {
  background-position: -25701px 0;
}

.emoticon-soon {
  background-position: -25733px 0;
}

.emoticon-clock1 {
  background-position: -25765px 0;
}

.emoticon-clock130 {
  background-position: -25797px 0;
}

.emoticon-clock10 {
  background-position: -25829px 0;
}

.emoticon-clock1030 {
  background-position: -25861px 0;
}

.emoticon-clock11 {
  background-position: -25893px 0;
}

.emoticon-clock1130 {
  background-position: -25925px 0;
}

.emoticon-clock12 {
  background-position: -25957px 0;
}

.emoticon-clock1230 {
  background-position: -25989px 0;
}

.emoticon-clock2 {
  background-position: -26021px 0;
}

.emoticon-clock230 {
  background-position: -26053px 0;
}

.emoticon-clock3 {
  background-position: -26085px 0;
}

.emoticon-clock330 {
  background-position: -26117px 0;
}

.emoticon-clock4 {
  background-position: -26149px 0;
}

.emoticon-clock430 {
  background-position: -26181px 0;
}

.emoticon-clock5 {
  background-position: -26213px 0;
}

.emoticon-clock530 {
  background-position: -26245px 0;
}

.emoticon-clock6 {
  background-position: -26277px 0;
}

.emoticon-clock630 {
  background-position: -26309px 0;
}

.emoticon-clock7 {
  background-position: -26341px 0;
}

.emoticon-clock730 {
  background-position: -26373px 0;
}

.emoticon-clock8 {
  background-position: -26405px 0;
}

.emoticon-clock830 {
  background-position: -26437px 0;
}

.emoticon-clock9 {
  background-position: -26469px 0;
}

.emoticon-clock930 {
  background-position: -26501px 0;
}

.emoticon-heavy_dollar_sign {
  background-position: -26533px 0;
}

.emoticon-copyright {
  background-position: -26565px 0;
}

.emoticon-registered {
  background-position: -26597px 0;
}

.emoticon-tm {
  background-position: -26629px 0;
}

.emoticon-x {
  background-position: -26661px 0;
}

.emoticon-heavy_exclamation_mark {
  background-position: -26693px 0;
}

.emoticon-bangbang {
  background-position: -26725px 0;
}

.emoticon-interrobang {
  background-position: -26757px 0;
}

.emoticon-o {
  background-position: -26789px 0;
}

.emoticon-heavy_multiplication_x {
  background-position: -26821px 0;
}

.emoticon-heavy_plus_sign {
  background-position: -26853px 0;
}

.emoticon-heavy_minus_sign {
  background-position: -26885px 0;
}

.emoticon-heavy_division_sign {
  background-position: -26917px 0;
}

.emoticon-white_flower {
  background-position: -26949px 0;
}

.emoticon-100 {
  background-position: -26981px 0;
}

.emoticon-heavy_check_mark {
  background-position: -27013px 0;
}

.emoticon-ballot_box_with_check {
  background-position: -27045px 0;
}

.emoticon-radio_button {
  background-position: -27077px 0;
}

.emoticon-link {
  background-position: -27109px 0;
}

.emoticon-curly_loop {
  background-position: -27141px 0;
}

.emoticon-wavy_dash {
  background-position: -27173px 0;
}

.emoticon-part_alternation_mark {
  background-position: -27205px 0;
}

.emoticon-trident {
  background-position: -27237px 0;
}

.emoticon-black_large_square {
  background-position: -27269px 0;
}

.emoticon-white_large_square {
  background-position: -27301px 0;
}

.emoticon-white_check_mark {
  background-position: -27333px 0;
}

.emoticon-black_square {
  background-position: -27268px 0;
}

.emoticon-white_square {
  background-position: -27301px 0;
}

.emoticon-black_square_button {
  background-position: -27365px 0;
}

.emoticon-white_square_button {
  background-position: -27397px 0;
}

.emoticon-black_circle {
  background-position: -27429px 0;
}

.emoticon-white_circle {
  background-position: -27461px 0;
}

.emoticon-red_circle {
  background-position: -27493px 0;
}

.emoticon-large_blue_circle {
  background-position: -27525px 0;
}

.emoticon-large_blue_diamond {
  background-position: -27557px 0;
}

.emoticon-large_orange_diamond {
  background-position: -27589px 0;
}

.emoticon-small_blue_diamond {
  background-position: -27621px 0;
}

.emoticon-small_orange_diamond {
  background-position: -27653px 0;
}

.emoticon-small_red_triangle {
  background-position: -27685px 0;
}

.emoticon-small_red_triangle_down {
  background-position: -27717px 0;
}

.emoticon-shipit {
  background-position: -27749px 0;
}
