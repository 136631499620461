#react-calendar {
  .fc-header-toolbar {
    display: none;
  }
  .react-calendar-header {
    background-color: white;
    user-select: none;
    position: relative;
    padding-bottom: 25px;
    padding-top: 15px;
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 4;

    .header-left {
      display: flex;
      flex: 1 1 auto;
      max-width: 40%;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
      .headerDatePicker {
        visibility: hidden;
      }
      .ant-picker-panel-container {
        left: 23.9931px !important;
        top: 178.993px !important;
      }

      .btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        // svg {
        //   height: 15px;
        //   width: 15px;
        // }
      }
      .label-heading {
        position: relative;
        text-align: center;
        color: #555;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        min-width: 140px;
        cursor: pointer;
      }
      .btn-today {
        margin-left: 10px;
        background-color: rgba(16, 102, 130, 0.1);
        color: #106682;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        font-weight: 500;
        border-radius: 5px;
        border: 1px solid rgba(16, 102, 130, 0.1);
        text-shadow: none;
        box-shadow: none;
        padding: 6px 15px;
        &:hover {
          background-color: rgba(5, 115, 151, 0.1);
          // text-decoration: none;
          // border: 1px solid;
          color: #fff !important;
          border: 1px solid #106682;
          // outline: none;
        }
        // &:focus {
        //   // outline: none;
        //   border: 0px solid rgba(16, 102, 130, 0.1);
        // }
        // &:not(:hover) {
        //   // text-decoration: none;
        //   // outline: none;
        //   border: 1px solid rgba(16, 102, 130, 0.1);
        // }
      }
      .daysOff-button-container {
        position: relative;
        .btn-DaysOff {
          margin-left: 20px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 159, 1, 0.4);
          color: #106682;
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          border-radius: 5px;
          // border: none;
          // text-shadow: none;
          // box-shadow: none;
          padding: 8px 15px;
          &:hover {
            background-color: #ffcf80 !important;
            text-decoration: none;
            border-color: #ff9f01 !important;
            // border: 1px solid #ff9f01;
            outline: none;
          }
          // &:focus {
          //   outline: none;
          //   border: none;
          // }
          &:not(:hover) {
            border: 1px solid #ffcf80;
            text-decoration: none;
            outline: none;
          }
          span {
            margin-left: 7px;
            font-weight: 500;
          }
        }
        .beta-icon {
          position: absolute;
          right: -8px;
          bottom: -8px;
        }
      }
    }
    .header-right {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn-create-calendar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(16, 102, 130, 0.1);
        color: #106682;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        font-weight: 500;
        border-radius: 5px;
        border: none;
        text-shadow: none;
        box-shadow: none;
        padding: 6px 10px;
        &:hover {
          background-color: rgba(5, 115, 151, 0.1);
          text-decoration: none;
          // border: 1px solid;
          border: 1px solid #106682;
          color: #fff !important;
          outline: none;
        }
        &:focus {
          outline: none;
          border: none;
        }
        &:not(:hover) {
          border: 1px solid rgba(16, 102, 130, 0.1);
          text-decoration: none;
          outline: none;
        }
        svg {
          margin-right: 10px;
        }
      }
      .calendar-view-dropdown {
        margin-left: 20px;
        min-width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ccc;
        color: #555;
        color: #555;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        font-weight: 500;
        border-radius: 5px;
        text-shadow: none;
        box-shadow: none;
        padding: 6px 10px;
        &:hover {
          border: 1px solid #106682;
        }
      }
      .btn-filter-icon {
        background-color: white;
      }

      .btn-calendar-view-filters {
        margin-left: 20px;
        // min-width: 165px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        border: none;
        box-shadow: none;
        svg {
          &:hover {
            g {
              fill: #106682 !important;
            }
          }
        }
      }

      .assignment-complete {
        margin-left: 10px;
        label {
          padding-right: 10px;
        }
      }
      .reschedule-icon {
        cursor: pointer;
        margin-left: 20px;
        svg {
          &:hover {
            g {
              fill: #106682 !important;
            }
          }
        }
        // svg {
        //   width: 23px;
        // }
      }
      .calendar-options {
        padding: 0;
        margin: 0;
        margin-left: 25px;
        margin-right: 20px;
        svg {
          &:hover {
            g {
              fill: #106682 !important;
            }
          }
        }
        button {
          background: none;
          border: none;
          box-shadow: none;
          // svg {
          //   &:hover {
          //     path {
          //       fill: #116682;
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.calendar-drawer {
  .ant-drawer-header {
    border-bottom: 2px solid #e9eaeb;
    .ant-drawer-close {
      padding: 0;
      height: 55px;
      right: 10px;
    }
  }
  .ant-drawer-body {
    .drawer-switch {
      display: flex;
      align-items: center;
    }
    .drawer-control {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .ant-radio-group {
        display: flex !important;
        margin-top: 10px;
      }
      .satSun {
        color: #999;
        font-size: 14px;
        font-weight: 500;
      }
      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        // margin-bottom: 10px;
      }
      .ant-select-arrow {
        top: 44%;
      }

      .inline {
        label {
          margin-bottom: 0;
          margin-right: 40px;
        }
      }
      .fullwidth-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        // justify-content: space-between;
        .ant-picker {
          width: 49%;
        }
        .ant-checkbox-wrapper {
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  .ant-drawer-footer {
    border-top: 2px solid #e9eaeb;
    .ant-row {
      justify-content: flex-end;
      .ant-col {
        flex-direction: row;
        display: flex;
      }
      button {
        margin-left: 10px;
      }
      .btn-drawer-print {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.calendar-view-dropdown-list {
  min-width: 125px;
  font-family: "Poppins", sans-serif;
  li {
    padding: 10px 20px;
    span {
      color: #555;
      line-height: 18px;
    }
  }
}
.calendar-setting-dropdown {
  min-width: 200px;
  border: 5px;
  .ant-dropdown-menu-item {
    padding: 10px 20px;
    span {
      color: #555;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
.calendar-view-filter-dropdown {
  background-color: white;
  max-height: 410px;
  width: 290px;
  overflow: auto;
  box-shadow: 0 5px 10px 0 #9e9e9e;
  background-clip: padding-box;
  border-radius: 5px;

  .search-input {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    border-bottom: 1px solid #dddddd;
    .search-topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      Button {
        padding: 0%;
      }
      label {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
  .ant-menu {
    .ant-menu-submenu,
    .ant-menu-item {
      border-bottom: 1px solid #dddddd;
      //height: 40px;
      margin-top: 0;
      margin-bottom: 0;
      .ant-menu-submenu-title {
        margin: 0;
      }
      &::after {
        border-right: 0;
      }
      .ant-menu-submenu-title {
        &:active {
          background-color: #eef0f4;
        }
      }
    }
    .ant-menu-item-selected {
      background-color: #eef0f4;
    }
    .ant-menu-sub {
      width: 290px;
      .ant-menu-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .ant-menu-title-content {
          width: 100%;
        }
      }
    }
  }
}

.student-filter-list {
  // width: 290px;
  // max-height: 200px;
  // overflow: auto;
  // box-shadow: 0 5px 10px 0 #9e9e9e;
  // background-clip: padding-box;
  .ant-dropdown-menu-sub {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-height: 450px;
    overflow-y: auto;
  }
  .ant-menu-item {
    padding: 0px 20px !important;
  }
  .student-filter-item {
    display: flex;
    width: 100%;
    .ant-avatar {
      margin-right: 10px;
    }
    .item-left {
      flex-grow: 1;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .item-right {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.lesson-filter-list {
  // width: 290px;
  // max-height: 200px;

  .ant-dropdown-menu-sub {
    width: auto !important;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-height: 450px;
    overflow-y: auto;
  }
  .ant-menu-item {
    padding: 0px 20px !important;
  }
  .lesson-filter-item {
    display: flex;
    min-width: 250px !important;
    .item-left {
      width: 65%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .item-left-lessonName {
        cursor: pointer;
        text-transform: capitalize;
      }
    }

    .item-right {
      margin-left: 4px;
      width: 35%;
    }
  }
}
.simple-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cell-Bg {
  background-color: #5fdefe;
}

.headerDropdownDatePicker {
  left: 23.9931px !important;
  top: 178.993px !important;
}

.rescheduler-toggle {
  margin-top: 30px;
  .rescheduler-toggle-label {
    height: 20px;
    width: 95px;
    color: #555;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }
}
