//
// Typography
// --------------------------------------------------
a {
	color: $primary-color;
	cursor: pointer;
	transition: color 0.15s ease-in-out;
	&:hover,
	&:focus,
	&:active {
		color: darken($primary-color, 10);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: normal;
	line-height: 1.2;
	font-family: $heading-font;
}

h1 {
	font-size: 52px;
}

h2 {
	font-size: 34px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

p {
	margin: 0;
	font-weight: normal;
	font-size: 16px;
}

small {
	font-size: 13px;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-primary {
	color: $primary-color;
}

.text-primary-light {
	color: $primary-light-color;
}

.text-secondary {
	color: $secondary-font-color;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-danger {
	color: $brand-danger;
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.text-word-break {
	word-wrap: break-word;
}

.max-width-160 {
	max-width: 160px;
}

.text-whitespace-pre {
	overflow: hidden;
	white-space: pre;
}
