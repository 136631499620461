@import "../Components/BookModal/book-modal.less";
@import "../Components/BookCard/bookCard.less";
@import "../Components/BooknMediaCard/BooknMediaCard.less";
@import "../Components/CenteredSpinner/spinner.less";
@import "../Components/ColorPicker/CustomColorPicker.less";
@import "../Components/FileUpload/FileUpload.less";
@import "../Components/Google-Place-AutoComplete/Google-Place-AutoComplete.less";
@import "../Components/HspEditor/HspEditor.less";
@import "../Components/Hsptooltip/HspTooltip.less";
@import "../Components/HspColorPicker/HspColorPicker.less";
@import "../Lesson-Planner/lessonPlanner.less";
@import "../react-calendar/calendar.less";
@import "../react-rescheduler/rescheduler.less";
@import "../HSP-Reports/AttendanceReport/AttendanceReport.less";
@import "../react-chores/chores.less";
// @import "../../AttendanceReport/attendanceReport.less";
@import "../HSP-Reports/PortfolioReport/PortfolioReport.less";
@import "../HSP-Reports/AttendanceReport/AttendanceReport.less";
@import "../GradeBook//GradeBook.less";

@primary-color: "#116682";
.anticon-delete {
  &:hover {
    color: red;
  }
}

.hsp-edit-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    &:hover {
      fill: #116682;
    }
  }
}

.ant-btn-primary {
  background-color: #116682;
}
.ant-btn-primary {
  &:hover {
    background-color: #45899f !important;
  }
}

.ant-tabs-tab-btn {
  &:focus {
    font-weight: 500;
  }
}

.description-container {
  background-color: #eef0f4;
  text-align: justify;
  border-radius: 5px;
  margin: 30px;
  padding: 30px;
}

.capitalize {
  text-transform: capitalize;
}
