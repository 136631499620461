.introjs-overlay {
	position: absolute;
	box-sizing: content-box;
	z-index: 999999;
	background-color: rgba(0,0,0,0.8);
	opacity: 0;
	transition: all 0.3s ease-out;
}

.introjs-fixParent {
	z-index: auto !important;
	opacity: 1.0 !important;
	transform: none !important;
	position: absolute !important;
}

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
	z-index: 9999999 !important;
}

.introjs-disableInteraction {
	z-index: 99999999 !important;
	position: absolute;
	background-color: white;
	opacity: 0;
	filter: alpha(opacity=0);
}

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
	position: relative;
}

.introjs-helperLayer {
	box-sizing: content-box;
	position: absolute;
	z-index: 9999998;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 15px rgba(0,0,0,.4);
	transition: all 0.3s ease-out;
}

.introjs-tooltipReferenceLayer {
	box-sizing: content-box;
	position: absolute;
	visibility: hidden;
	z-index: 10000000;
	transition: all 0.3s ease-out;
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
	box-sizing: content-box;
}

.introjs-helperNumberLayer {
	box-sizing: content-box;
	position: absolute;
	visibility: visible;
	top: 16px;
	right: 16px;
	z-index: 9999999999 !important;
	padding: 2px;
	font-size: 13px;
	font-weight: bold;
	color: white;
	text-align: center;
	text-shadow: 1px 1px 1px rgba(0,0,0,.3);
	background: #ff3019; /* Old browsers */
	background: linear-gradient(to bottom, #ff3019 0%, #cf0404 100%);  /* W3C */
	width: 20px;
	height: 20px;
	line-height: 20px;
	border: 3px solid white;
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0,0,0,.4);
}

.introjs-arrow {
	border: 5px solid white;
	content:'';
	position: absolute;
}
.introjs-arrow.top {
	top: -10px;
	border-top-color:transparent;
	border-right-color:transparent;
	border-bottom-color:white;
	border-left-color:transparent;
}
.introjs-arrow.top-right {
	top: -10px;
	right: 10px;
	border-top-color:transparent;
	border-right-color:transparent;
	border-bottom-color:white;
	border-left-color:transparent;
}
.introjs-arrow.top-middle {
	top: -10px;
	left: 50%;
	margin-left: -5px;
	border-top-color:transparent;
	border-right-color:transparent;
	border-bottom-color:white;
	border-left-color:transparent;
}
.introjs-arrow.right {
	right: -10px;
	top: 10px;
	border-top-color:transparent;
	border-right-color:transparent;
	border-bottom-color:transparent;
	border-left-color:white;
}
.introjs-arrow.right-bottom {
	bottom:10px;
	right: -10px;
	border-top-color:transparent;
	border-right-color:transparent;
	border-bottom-color:transparent;
	border-left-color:white;
}
.introjs-arrow.bottom {
	bottom: -10px;
	border-top-color:white;
	border-right-color:transparent;
	border-bottom-color:transparent;
	border-left-color:transparent;
}
.introjs-arrow.left {
	left: -10px;
	top: 10px;
	border-top-color:transparent;
	border-right-color:white;
	border-bottom-color:transparent;
	border-left-color:transparent;
}
.introjs-arrow.left-bottom {
	left: -10px;
	bottom:10px;
	border-top-color:transparent;
	border-right-color:white;
	border-bottom-color:transparent;
	border-left-color:transparent;
}

.introjs-tooltip {
	box-sizing: content-box;
	position: absolute;
	visibility: visible;
	padding: 10px;
	background-color: white;
	min-width: 200px;
	max-width: 300px;
	border-radius: 3px;
	box-shadow: 0 1px 10px rgba(0,0,0,.4);
	transition: opacity 0.1s ease-out;
}

.introjs-tooltipbuttons {
	text-align: right;
	white-space: nowrap;
}

.introjs-button {
	box-sizing: content-box;
	position: relative;
	overflow: visible;
	display: inline-block;
	padding: 0.3em 0.8em;
	border: 1px solid #d4d4d4;
	font-size: 12px;
	margin: 0;
	text-decoration: none;
	color: #333;
	white-space: nowrap;
	cursor: pointer;
	outline: none;
	background-color: #ececec;
	background-image: linear-gradient(#f4f4f4, #ececec);
	border-radius: 0.2em;
	user-select: none;
	/* IE hacks */
	zoom: 1;
	*display: inline;
	margin-top: 10px;
}

.introjs-button:hover {
	border-color: #bcbcbc;
	text-decoration: none;
	box-shadow: 0px 1px 1px #e3e3e3;
}

.introjs-button:focus,
.introjs-button:active {
	background-image: linear-gradient(#ececec, #f4f4f4);
}

/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.introjs-skipbutton {
	box-sizing: content-box;
	margin-right: 5px;
	color: #7a7a7a;
}

.introjs-prevbutton {
	border-radius: 0.2em 0 0 0.2em;
	border-right: none;
}

.introjs-prevbutton.introjs-fullbutton {
	border-radius: 0.2em;
}

.introjs-nextbutton {
	border-radius: 0 0.2em 0.2em 0;
}

.introjs-nextbutton.introjs-fullbutton {
	border-radius: 0.2em;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
	color: #9a9a9a;
	border-color: #d4d4d4;
	box-shadow: none;
	cursor: default;
	background-color: #f4f4f4;
	background-image: none;
	text-decoration: none;
}

.introjs-hidden {
	display: none;
}

.introjs-bullets {
	text-align: center;
}
.introjs-bullets ul {
	box-sizing: content-box;
	clear: both;
	margin: 15px auto 0;
	padding: 0;
	display: inline-block;
}
.introjs-bullets ul li {
	box-sizing: content-box;
	list-style: none;
	float: left;
	margin: 0 2px;
}
.introjs-bullets ul li a {
	box-sizing: content-box;
	display: block;
	width: 6px;
	height: 6px;
	background: #ccc;
	border-radius: 10px;
	text-decoration: none;
	cursor: pointer;
}
.introjs-bullets ul li a:hover {
	background: #999;
}
.introjs-bullets ul li a.active {
	background: #999;
}

.introjs-progress {
	box-sizing: content-box;
	overflow: hidden;
	height: 10px;
	margin: 10px 0 5px 0;
	border-radius: 4px;
	background-color: #ecf0f1
}
.introjs-progressbar {
	box-sizing: content-box;
	float: left;
	width: 0%;
	height: 100%;
	font-size: 10px;
	line-height: 10px;
	text-align: center;
	background-color: #08c;
}

.introjsFloatingElement {
	position: absolute;
	height: 0;
	width: 0;
	left: 50%;
	top: 50%;
}

.introjs-fixedTooltip {
	position: fixed;
}

.introjs-hint {
	box-sizing: content-box;
	position: absolute;
	background: transparent;
	width: 20px;
	height: 15px;
	cursor: pointer;
}
.introjs-tooltiptext {
	font-size: 15px;
}
.introjs-hint:focus {
	border: 0;
	outline: 0;
}
.introjs-hidehint {
	display: none;
}

.introjs-fixedhint {
	position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse {
	border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
	box-sizing: content-box;
	width: 10px;
	height: 10px;
	border: 5px solid rgba(60, 60, 60, 0.27);
	border-radius: 30px;
	background-color: rgba(136, 136, 136, 0.24);
	z-index: 10;
	position: absolute;
	transition: all 0.2s ease-out;
}
.introjs-hint-no-anim .introjs-hint-dot { animation: none; }
.introjs-hint-dot {
	box-sizing: content-box;
	border: 10px solid rgba(146, 146, 146, 0.36);
	background: transparent;
	border-radius: 60px;
	height: 50px;
	width: 50px;
	animation: introjspulse 3s ease-out;
	animation-iteration-count: infinite;
	position: absolute;
	top: -25px;
	left: -25px;
	z-index: 1;
	opacity: 0;
}

@keyframes introjspulse {
	0% {
		transform: scale(0);
		opacity: 0.0;
	}
	25% {
		transform: scale(0);
		opacity: 0.1;
	}
	50% {
		transform: scale(0.1);
		opacity: 0.3;
	}
	75% {
		transform: scale(0.5);
		opacity: 0.5;
	}
	100% {
		transform: scale(1);
		opacity: 0.0;
	}
}
