tags-input {
	display: block;
}
tags-input *,
tags-input *:before,
tags-input *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
tags-input .host {
	position: relative;
	margin-top: 5px;
	margin-bottom: 5px;
	height: 100%;
}
tags-input .host:active {
	outline: none;
}

tags-input .tags {
	//-moz-appearance: textfield;
	//-webkit-appearance: textfield;
	padding: 1px;
	overflow: hidden;
	word-wrap: break-word;
	cursor: text;
	background-color: #fff;
	border: 1px solid darkgray;
	box-shadow: 1px 1px 1px 0 lightgray inset;
	height: 100%;
	border-radius: 20px;
}
tags-input .tags.focused {
	outline: none;
	-webkit-box-shadow: 0 0 3px 1px rgba(5, 139, 242, 0.6);
	-moz-box-shadow: 0 0 3px 1px rgba(5, 139, 242, 0.6);
	box-shadow: 0 0 3px 1px rgba(5, 139, 242, 0.6);
}
tags-input .tags .tag-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
tags-input .tags .tag-item {
	margin: 5px;
	padding: 0 5px;
	display: inline-block;
	float: left;
	height: 26px;
	line-height: 25px;
	border: 1px solid #acacac;
	border-radius: 3px;
	background: -webkit-linear-gradient(
		top,
		#f0f9ff 0%,
		#cbebff 47%,
		#a1dbff 100%
	);
	background: linear-gradient(
		to bottom,
		#f0f9ff 0%,
		#cbebff 47%,
		#a1dbff 100%
	);
}
tags-input .tags .tag-item.selected {
	background: -webkit-linear-gradient(
		top,
		#febbbb 0%,
		#fe9090 45%,
		#ff5c5c 100%
	);
	background: linear-gradient(
		to bottom,
		#febbbb 0%,
		#fe9090 45%,
		#ff5c5c 100%
	);
}
tags-input .tags .tag-item .remove-button {
	margin: 0 0 0 5px;
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
	vertical-align: middle;
	color: #585858;
}
tags-input .tags .tag-item .remove-button:active {
	color: #ff0000;
}
tags-input .tags .input {
	border: 0;
	outline: none;
	margin: 5px;
	padding: 0;
	padding-left: 5px;
	float: left;
	height: 26px;
}
tags-input .tags .input.invalid-tag {
	color: #ff0000;
}
tags-input .tags .input::-ms-clear {
	display: none;
}
tags-input.ng-invalid .tags {
	-webkit-box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
	-moz-box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
	box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
}
tags-input[disabled] .host:focus {
	outline: none;
}
tags-input[disabled] .tags {
	background-color: #eee;
	cursor: default;
}
tags-input[disabled] .tags .tag-item {
	opacity: 0.65;
	background: -webkit-linear-gradient(
		top,
		#f0f9ff 0%,
		rgba(203, 235, 255, 0.75) 47%,
		rgba(161, 219, 255, 0.62) 100%
	);
	background: linear-gradient(
		to bottom,
		#f0f9ff 0%,
		rgba(203, 235, 255, 0.75) 47%,
		rgba(161, 219, 255, 0.62) 100%
	);
}
tags-input[disabled] .tags .tag-item .remove-button {
	cursor: default;
}
tags-input[disabled] .tags .tag-item .remove-button:active {
	color: #585858;
}
tags-input[disabled] .tags .input {
	background-color: #eee;
	cursor: default;
}

tags-input .autocomplete {
	margin-top: 5px;
	position: absolute;
	padding: 5px 0;
	z-index: 9999 !important;
	width: 100%;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
tags-input .autocomplete .suggestion-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	max-height: 280px;
	overflow-y: auto;
	position: relative;
}
tags-input .autocomplete .suggestion-item {
	padding: 5px 10px;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #000;
	background-color: #fff;
}
tags-input .autocomplete .suggestion-item.selected {
	color: #fff;
	background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item.selected em {
	color: #fff;
	background-color: #0097cf;
}
tags-input .autocomplete .suggestion-item em {
	color: #000;
	background-color: #fff;
}

