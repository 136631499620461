//
// Login
// -------------------------------------------------

.login {
	.form-group {
		overflow: visible;
	}

	.error-msg {
		font-size: 14px;
		border-radius: 4px;
		background: $brand-danger;
		text-align: center;
		color: $primary-color;

		margin-bottom: 20px;
		&:empty {
			display: none;
		}
		a {
			color: $white;
			font-weight: bold;
		}
	}

	.success-msg {
		@extend .error-msg;
		background: rgba(0, 255, 0, 0.05);
		color: $brand-success;
	}

	.forget-link {
		color: $primary-color;
		text-decoration: none;
		transition: color 0.3s;
		font-size: 14px;
		&:hover {
			color: #007bff;
		}
	}

	.verify-email-link {
		color: $primary-color;
		text-decoration: none;
		transition: color 0.3s;
		font-size: 14px;
		&:hover {
			color: #007bff;
		}
	}

	.holding-card {
		// right: 20px;
		color: #000;
		.login-input {
			height: 50px;
			padding: 15px 21px;
			padding-left: 50px;
		}

		.subtitle {
			color: rgb(94, 94, 94);
			margin-bottom: 2.2rem;
			font-size: 1.15vw;
		}

		h2 {
			margin-bottom: 8px;
			font-weight: 500;
			font-size: 1.55vw;
			letter-spacing: 0;

			.highlight {
				font-weight: 700;
			}
		}
	}

	.holding-card-title {
		margin-bottom: 40px;
	}

	.action-container {
		display: flex;
		justify-content: space-between;
		.action-container-right-block {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}

	.btn-login {
		width: 100%;
		height: 52px;
		border-radius: 40px;
		border: none;
		background-color: #0d6580;
		font-size: 1rem;
		color: #fff;
		/* font-family: 'Poppins', sans-serif; */
		text-transform: uppercase;
		margin: 0.7rem 0;
		cursor: pointer;
		transition: .5s;
	}

	.external-services {
		margin-top: 60px;
	}

	.main-page-left-side {
		padding: 50px 10px 0 10px;
		.hide {
			display: none;
		}
		
		.show {
			display: block;
		}
	}

	.login-pills {
		// position: absolute;
		display: flex;
		border: 1px solid rgb(158, 158, 158);
		border-radius: 25px;
		margin: 0;
		padding: 0;
		overflow: hidden;
		width: 60%;

		.slide {
			position: absolute;
			width: 50%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			// background-color: #0d6580;
			transition: 0.5s;
			opacity: 0;
		}
		
		li:nth-child(1).active~.slide {
			left: 0;
			opacity: 1;
		}

		li:nth-child(2).active~.slide {
			left: 50%;
			opacity: 1;
		}

		li {
			list-style: none;
			width: 50%;

			a {
				display: block;
				padding: 6px;
				transition: 0.5s;
				text-decoration: none;
				text-align: center;
				color: #555;
				font-weight: 500;
			}
			&.active a {
				text-decoration: none;
				color: white;
				background-color: #0d6580;
			}
		}
	}
 }

 /* ======= START CAROUSEL ======= */
 .main-page-right-side {
	#carousel,
	.carousel-inner,
	.item,
	.item .banner-content-container,
	.item .carousel-first-caption,
	.item .carousel-second-caption,
	.item .carousel-third-caption,
	.item .carousel-fourth-caption {
		height: 100%;
	}
	.banner-content-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		// padding: 5rem 3rem 0 3rem;
	}
	
	.item {
		background-color: #053d4e;
		padding-top: 100px;
	}
	
	.item .highlight-banner {
		font-weight: 700;
		color: #4ed8aa;
	}
	
	// .carousel-indicators {
	// 	margin-bottom: 2rem;
	// 	position: absolute;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	z-index: 15;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	-ms-flex-pack: center;
	// 	justify-content: center;
	// 	padding-left: 0;
	// 	margin-right: 15%;
	// 	margin-left: 15%;
	// 	list-style: none;
	// 	li {
	// 		box-sizing: content-box;
	// 		-ms-flex: 0 1 auto;
	// 		flex: 0 1 auto;
	// 		width: 30px;
	// 		height: 3px;
	// 		margin-right: 3px;
	// 		margin-left: 3px;
	// 		text-indent: -999px;
	// 		cursor: pointer;
	// 		background-color: #fff;
	// 		background-clip: padding-box;
	// 		border-top: 10px solid transparent;
	// 		border-bottom: 10px solid transparent;
	// 		opacity: .5;
	// 		transition: opacity .6s ease;
		
	// 	}
	// 	.active {
	// 		opacity: 1;
	// 	}
	// }
	
	.item .carousel-second-caption img {
		position: relative;
		// right: 38px;
	}
	
	
	/* Signup button on carousel */
	
	.carousel-signup-login {
		display: flex;
		align-items: center;
		position: absolute;
		right: 55px;
		top: 30px;
		z-index: 1;
		.signup-login-text {
			padding-top: 3px;
			font-size: 1.2rem;
		}
		.pr-4, .px-4 {
			padding-right: 1.5rem!important;
		}
	}
	
	.carousel-signup-login-btn {
		border-radius: 50px;
		padding: 8px 45px;
		font-size: 0.9rem;
		border: 1px solid transparent;
	}

	.btn-outline-light {
		color: #f8f9fa;
		border-color: #f8f9fa;
	}
 }

.email-verify {
	@extend .login;

	.holding-card-title {
		margin-bottom: 10px;
	}

	@media screen and (min-width: 600px) {
		#login-btn {
			display: inline;
		}

		#app-btn {
			display: none;
		}
	}

	@media screen and (max-width: 600px) {
		#login-btn {
			display: none;
		}

		#app-btn {
			display: block;
		}
	}
}

.text-white {
	color: #fff!important;
}

@media (max-width: 768px) {
	.main-page-right-side {
		display: none;
	}
	.main-page-left-side {
		max-width: 100%;
		flex-grow: 100;
	}
}