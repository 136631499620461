.control-label {
    @extend .text-capitalize;
    font-size: 14px;
    font-weight: bold;
    color: $darker-gray;
}

.form-control[disabled] {
    background-color: #f4f4f4;
    z-index: 0;
}

.has-error .error-block{
	color: $error-red;
}

.has-success .form-control{
    box-shadow: none;
}