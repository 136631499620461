//
// Dropdown menus
// --------------------------------------------------
// Dropdown arrow/caret
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: $caret-width-base dashed;
    border-top: $caret-width-base solid \9; // IE8
    border-right: $caret-width-base solid transparent;
    border-left: $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
    position: relative;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
    outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none;
    float: left;
    min-width: 175px;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: $font-size-base;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    background-color: $dropdown-bg;
    border-radius: 4px;
    box-shadow: 0px 5px 10px 0 #9E9E9E;
    background-clip: padding-box;
    >li+li {
        border-top: 1px solid $light-gray;
    }
    .menu-item {
        display: block;
        padding: 10px 20px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap;
        text-decoration: none;
    }
}

// Hover/Focus state
.dropdown-menu>li>.menu-item {
    &:hover,
		&:focus {
        text-decoration: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
    }
}

// Active state
.dropdown-menu>.active>.menu-item {
    &,
    &:hover,
		&:focus {
        color: $dropdown-link-active-color;
        text-decoration: none;
        outline: 0;
        background-color: $dropdown-link-active-bg;
    }
}

// Open state for the dropdown
.open {
    // Show the menu
    >.dropdown-menu {
        display: block;
    }
    // Remove the outline when :focus is triggered
    >a {
        outline: 0;
    }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
    left: auto; // Reset the default from `.dropdown-menu`
    right: 0;
}

// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
    left: 0;
    right: auto;
}

// Right aligned dropdowns
.pull-right>.dropdown-menu {
    right: 0;
    left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
.dropup {
    // Reverse the caret
    .caret {
        border-top: 0;
        border-bottom: $caret-width-base dashed;
        border-bottom: $caret-width-base solid \9; // IE8
        content: "";
    }
    // Different positioning for bottom up menu
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-bottom: 2px;
    }
}

// Enable google places autocomplete to be on top of bootstrap modal
.pac-container {
    font-family: $primary-font !important;
    z-index: 1500 !important;
}