@keyframes bounce-3rd {
    0% {
      transform: translateY(0);
    }
    16% {
      transform: translateY(-125%);
    }
    33% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
}

@mixin typing-dots($width, $height, $dot-size){
    height: $height;
    width: $width;
    padding: $height/2 0;
    position:relative;

    [class^=dot-] {
        animation: 800ms ease-out bounce-3rd infinite;
    }
    
    .dot-1 {
        animation-delay: 0s;
        left: $width/4;
    }
        
    .dot-2 {
        animation-delay: 150ms;
        left: $width/2 - $dot-size/2;
    }
    
    .dot-3 {
        animation-delay: 300ms;
        right: $width/4;
    }
    
    
    &.right-message {
        right: 0;
    }   
    
    &.left-message {
        left: 0;
    }
    
    [class^=dot-] {
        width: $dot-size;
        height: $dot-size;
        position: absolute;
        bottom: $height/2 - $dot-size/2;
        background: $darker-gray;
        border-radius: 50%;
        box-shadow: 0 0 1px $darker-gray;
    }
}
  
.typing-dots{
    @include typing-dots(50px, 30px, 5px);

    &.md{
        @include typing-dots(120px, 40px, 8px);
    }

    &.green-color{
        [class^=dot-] {
            background: $primary-light-color;
            box-shadow: 0 0 1px $primary-light-color;
        }
    }
}
