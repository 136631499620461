.refferal-page {
	.referral-header {
		position: relative;
		padding-bottom: 40px;
		padding-top: 15px;
	}

	.referral-steps {
		margin-top: 30px;
	}

	.share-icon {
		width: 30px;
		height: 30px;
		// display: inline;
		fill: #005574;
		margin-top: 4px;
	}

	.dollar-icon {
		width: 40px;
		height: 40px;
		//display: inline;
		fill: #005574
	}

	.shopping-cart-icon {
		width: 40px;
		height: 40px;
		//display: inline;
		fill: #005574
	}

	.url-link-icon {
		width: 20px;
		height: 20px;
		fill: #fff;
		box-sizing: border-box;
	}

	.credit-icon {
		fill: #fff;
		box-sizing: border-box;

		&.paper-plan {
			width: 40px;
			height: 40px;
		}

		&.credit-earned {
			width: 50px;
			height: 50px;
		}

		&.save-money {
			width: 50px;
			height: 50px;
		}

		&.available-credit {
			width: 50px;
			height: 50px;
		}
	}

	.primary-color {
		color: #005574;
	}

	.primary-bg {
		color: #fff;
		background-color: #005574;
	}

	.header-heading {
		color: #58585A;
		font-weight: bold;
	}

	.text-description {
		color: #58585A;
		font-size: 13px;

		&.header-description {
			padding-top: 10px;
		}

		&.social-text {
			margin: auto;
		}
	}

	.round-input-padding {
		border-radius: 10px;
		padding: 10px 20px !important;
		padding-bottom: 20px !important;
	}

	.referral-rewarded {
		padding-top: 40px;
		padding-bottom: 20px;
	}

	.social-media-box {
		flex-direction: column;
		margin-top: 30px;
		border: 1px solid #e9eaeb;
		border-radius: 10px;
		//padding: 20px;
		// margin-left: 134px;
		// margin-right: 100px;
	}

	.share-copylink-icon {
		width: 25px;
		height: 25px;
		fill: #C6C6C6;
		margin-left: -18px;
	}

	.copy-link {
		padding-top: 30px;
	}

	.border-left-color {
		border-left: none;
		border-left-color: #fff;
	}

	.social {
		flex-basis: 30%;
		margin-right: 10px;
		padding: 15px;
		// justify-content: space-between;
		box-sizing: border-box;
		background-color: #FAFAFA;
	}

	.social-media {
		display: flex;
		width: 30px;
		height: 30px;
		margin: auto;
	}

	.socialmedia-text {
		padding-top: 40px;
	}

	.socialmedia-share {
		display: flex;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.round-input {
		padding: 19px 12px;
		font-size: 11px;
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;
	}

	.round-copylink-button {
		text-transform: initial;
		width: max-content;
		display: flex;
		align-items: center;
		padding: 10px 25px !important;
		border-top-right-radius: 19px !important;
		border-bottom-right-radius: 19px !important;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.round-share {
		display: flex;
		border: 1px solid #ccc;
		border-top-left-radius: 19px !important;
		border-bottom-left-radius: 19px !important;
		// border-right: none;
		// border-top-right-radius: 0px !important;
		// border-bottom-right-radius: 0px !important;
	}

	.round-share-button {
		display: flex;
		padding: 6px 40px !important;
		// border-right: 1px solid #ccc;
		// border-top-right-radius: 0px;
		// border-bottom-right-radius: 0;
		//border-right: 1px solid #ccc;
		//border-top-left-radius: 19px !important;
		//border-bottom-left-radius: 19px !important;
		// border-top-right-radius: 0px !important;
		// border-bottom-right-radius: 0px !important;
	}

	.text-button {
		margin-left: 3px;
		font-size: 10px;
		padding-bottom: 3px;
		box-sizing: border-box;
	}

	.credits {
		display: flex;
		padding-top: 40px;
	}

	.credit-details {
		display: flex;
		justify-content: space-between;
		flex-basis: 20%;
		height: 75px;
	}

	.credit-data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center
	}

	.credit-text {
		font-size: 12px;
		color: #fff;
	}

	.reward-description {
		padding-top: 30px;
	}

	.reward-circle {
		border: 1px solid #005574;
		border-radius: 50%;
		padding: 8px 12px;
	}

	.footer {
		padding-top: 40px;
	}
}
