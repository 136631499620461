//
// Spinner
// --------------------------------------------------


.spinner {
	display: inline-block;
	z-index: 5;
	width: 15px;
	height: 15px;
	position: relative;
	top: 2px;
	border: solid 2px transparent;
	border-left-color: $primary-light-color;
	border-top-color: $primary-light-color;
	border-radius: 10px;
	animation: spinner 0.8s linear infinite;
}

.inline-spinner{
    position: absolute;
    right: 20px;
    top: 10px;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner.primary{
	border-left-color: $blue;
	border-top-color: $blue;
}