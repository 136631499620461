//
// Reset password stylesheet
//

.reset-password {
	.form-group {
		overflow: visible;
	}

	.error-msg {
		height: 25px;
		font-size: 14px;
		border-radius: 4px;
		background: $brand-danger;
		text-align: center;
		color: $primary-color;
		margin-bottom: 20px;
		&:empty {
			display: none;
		}
	}

	.success-msg {
		@extend .error-msg;
		background: rgba(0, 255, 0, 0.05);
		color: $brand-success;
	}

	.help-text {
		font-size: 13px;
	}

	.holding-card {
		right: 20px;
		height: 400px;
		border-radius: 10px;
	}
}
