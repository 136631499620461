.book-temp {
  .header {
    display: flex;
    justify-content: space-between;
  }
  body {
    font-family: sans-serif;
  }
  p {
    color: #666;
  }
  h2 {
    font-size: 2em;
    color: #e74c3c;
  }
  .centered {
    margin: 0 auto;
    padding: 0 1em;
  }
  @media screen and (min-width: 52em) {
    .centered {
      max-width: 52em;
    }
  }
  /*--------------------------------------------------------------
Header styles minus menu
--------------------------------------------------------------*/
  .masthead {
    background: #e74c3c;
    box-shadow: 3px 3px 8px hsl(0, 0%, 70%);
  }
  .site-title {
    margin: 0 0 1em;
    padding: 1em 0;
    font-size: 2em;
    font-weight: 300;
    text-align: center;
    color: black;
  }
  @media screen and (min-width: 44.44em) {
    .site-title {
      font-size: 2em;
    }
  }
  @media screen and (min-width: 50em) {
    .site-title {
      font-size: 2.5em;
    }
  }
  .site-title a {
    color: hsl(5, 45%, 95%);
    text-decoration: none;
  }
  .site-title a:hover {
    text-decoration: underline;
  }
  /* Card Based Layout - Base styles */
  body {
    background: #ecf0f1;
    line-height: 1.4;
  }
  .site-title {
    color: white;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background: white;
    border-radius: 5px;
    margin-bottom: 2em;
  }
  .card a {
    color: black;
    text-decoration: none;
  }
  .card a:hover {
    box-shadow: 3px 3px 8px hsl(0, 0%, 70%);
  }
  .card-content {
    padding: 1.4em;
  }
  .card-content h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: normal;
  }
  .card-content p {
    font-size: 95%;
  }
  img {
    margin-top: 30px;
    width: 35%;
    height: 15%;
  }
  /* Flexbox styles */
  @media screen and (min-width: 60em) {
    .cards {
      margin-top: -1em;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .card {
      margin-bottom: 1em;
      display: flex;
      flex: 0 1 calc(50% - 1em);
      /* width: calc(50% - 1em); */
    }
  }
  /* mq 40em*/
  @media screen and (min-width: 60em) {
    .cards {
      margin-top: inherit;
    }
    .card {
      margin-bottom: 2em;
      display: flex;
      flex: 0 1 calc(49% - 0.5em);
      /* width: calc(33% - 1em); */
    }
  }
  /* mq 60em*/
}

.assign-book {
  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;
    .empty-title {
      margin: 0 10px 40px;
    }
  }
  .heading {
    padding: 20px;
    background-color: $gray;
    font-family: $primary-font;
    color: $primary-light-color;
  }
  .avatar {
    margin-right: 10px;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .book-data {
    max-width: 850px;
    margin: 0 auto;
    .book-desc h4 {
      max-width: inherit;
      word-wrap: break-word;
    }
    .duration-label {
      color: $secondary-font-color;
    }
  }
  .student {
    padding: 10px;
    border-bottom: 1px solid $light-gray;
  }
  .book-students {
    margin-top: 20px;
  }
  .assigned-students,
  .assign-students {
    display: table-cell;
  }
  .assigned-students {
    min-width: 250px;
  }

  .assign-students {
    min-width: 665px;
    border: 1px solid $light-gray;
  }
  .assign-data {
    border-bottom: 1px solid $light-gray;
    .student {
      border: 0;
    }
    .select {
      width: 41px;
      height: 21px;
    }
  }
  .actionbar {
    @extend .text-center;
    margin-top: 50px;
  }
  .assign-form {
    padding: 10px 0 10px 10px;
    .date-input {
      max-width: 140px;
    }
  }
}

.book-detail {
  .select {
    width: 16px;
    height: 16px;
  }
  .rating {
    color: #a9a9a9;
    margin: 0;
    // margin-left: 10px;
    padding: 0;
  }
  ul.rating {
    display: inline-block;
  }
  .rating li {
    list-style-type: none;
    display: inline-block;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
  .rating .filled {
    color: #ffff1e;
  }
  .title-details {
    color: $primary-color;
    .del:hover {
      background-color: white;
      color: $primary-color;
    }
    // border-bottom: 4px solid $gray;
    border-bottom: 1px solid #e9eaeb;
    margin-bottom: 20px;
    padding-bottom: 12px;
    .title {
      word-wrap: break-word;
      width: 600px;
    }
    .action {
      display: inline-block;
      font-size: 0;
      margin-left: 20px;
      .icon {
        fill: $secondary-font-color;
        width: 16px;
        height: 16px;
      }
    }
  }
  .desc {
    margin: 0;
    margin-top: 10px;
    color: black;
    font-size: 16px;
  }
  .details {
    .image-upload-section {
      display: flex;
      flex-direction: column;
    }
    hsp-file-upload {
      display: flex;
      justify-content: center;
    }
    // border-bottom: 4px solid $gray;
    // margin-bottom: 15px;
    // margin-top:32px;
    // padding-bottom: 10px;
    .file-upload {
      a {
        //margin-left: 20px;
      }
    }
    display: flex;
    flex-direction: row;
    .flexbox-1 {
      flex: 0;
      height: 100%;
      width: 100%;
      // border: solid 3px red;
    }
    .flexbox-2 {
      flex: 2;
      // border: solid 3px blue;
      /* height: 200px; */
      margin-left: 20px;
      .content {
        max-width: 440px;
        width: 100%;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .small {
        width: 100%;
        max-width: 440px;
        height: 30px;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .title,
      .author,
      .pages,
      .level,
      .desc {
        // width: 100%;
        font-size: 16px;
        color: black;
        margin-bottom: 5px;
      }
      .title {
        font-weight: bold;
      }
      .disc {
        font-size: 14px;
        color: black;
        margin-top: 5px;
      }
    }
  }
  .delete-btn,
  .assign-btn {
    min-width: 115px;
    margin-right: 30px;
    border-radius: 20px;
    .btn.btn-sm {
      padding: 3px 6px;
    }
  }
  .delete-btn {
    background-color: #fff;
    color: $primary-color;
    border-radius: 0px;
  }
  .assigned-students {
    .avatar {
      @extend .round;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 5px;
    }
    &__item {
      padding-top: 7px;
      padding-bottom: 7px;
      &:not(:last-child) {
        border-bottom: 1px solid $gray;
      }
      .student-name {
        width: 20 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .student-name > * {
        display: inline-block;
      }
    }
  }
  .tab {
    margin-top: 30px;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }
  .tab-content {
    min-height: 180px;
    margin-bottom: 40px;
  }
  .empty-text-template {
    padding: 10px;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #f4f4f4;
    p {
      font-size: 14px;
      color: $darker-gray;
      font-weight: bold;
    }
  }
}

.book-list,
.book {
  // .card {
  //     height: 290px;
  // }
  .inline-list {
    list-style: none;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
    // margin: 0;
    li {
      // margin-left: 10px;
      //margin-right: 5px;
      margin-bottom: 10px;
      max-width: 200px;
      display: flex;

      border-radius: 20px;
      padding-right: 5px;
      .name {
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #6a6a6a;
        line-height: 18px;
        opacity: 0.7;
        font-size: 12px;
      }
    }
    .avatar {
      @extend .round;
      width: 20px;
      height: 20px;
      border-radius: 0px;
      margin-top: 2px;
      margin-left: 6px;
      border-radius: 2px;
    }
  }

  .book-manual-add-new {
    display: flex;
    align-items: center;
    width: 184px;
    border: 1px solid #106682;
    padding-left: 10px;
    border-radius: 25px;
    padding-right: 10px;
    margin-right: 60px;
    .add-book-icon {
      width: 21px;
      height: 21px;
      margin-right: 10px;
    }
  }

  .book-shelf-add-dropdown {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 4.3px 20px;
    color: #fff;
    width: 150px;
    height: 34px;
    display: flex;
    margin-left: 30px;
    align-items: center;
    .book-shelf-a {
      width: 100%;
    }
    span {
      color: #555;
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }
    .icon-self-dropdown {
      width: 18px;
      height: 18.5px;
      fill: #808080;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    .dropdown-menu.dropdown-menu-right {
      min-width: 250px;
      margin-bottom: 20px;
      li a {
        border-bottom: 0.5px solid #e8e7e7;

        &:last-child {
          border-bottom: unset;
        }
      }
    }
  }

  .book-search-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    .p-line {
      padding-left: 10px;
      padding-right: 15px;
    }
    .select-book-all {
      color: #106682;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
      label {
        padding-right: 20px;
      }
      input[type='checkbox'] {
        position: relative;
        width: 22px;
        height: 22px;
        color: #363839;
        border: 1px solid #999;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        &::before {
          position: absolute;
          content: '';
          display: block;
          top: 3px;
          left: 8px;
          width: 5px;
          height: 11px;
          border-style: solid;
          border-color: #fff;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
          opacity: 0;
        }
        &:checked {
          color: #fff;
          border-color: #116682;
          background: #116682;
          &::before {
            opacity: 1;
          }
          ~ label::before {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }
      }
    }
  }
}

.lesson-book-data {
  input[type='checkbox'] {
    position: relative;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    color: #363839;
    border: 1px solid #999;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 3px;
      left: 8px;
      width: 5px;
      height: 11px;
      border-style: solid;
      border-color: #fff;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:checked {
      color: #fff;
      border-color: #116682;
      background: #116682;
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
}

.book-list,
.book {
  .empty-title {
    font-size: 20px;
  }
  .container {
    max-width: 1110px;
    margin: 0 auto;
  }
  .cards {
    // margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: flex-start;
    padding: 0;
  }
  .book-image {
    height: 192px;
    width: 128px;
    padding-top: 15px;
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 15px;
    @media (min-width: 0rem) {
      width: 46%;
    }
    @media (min-width: 160rem) {
      width: 33.3333%;
    }
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: 0.2s;
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .first {
    // border:1px solid;
    display: flex;
    height: 170px;
  }
  .first > * {
    margin: 10px;
  }
  .second {
    border: 1px solid black;
    padding: 2px 16px;
    margin-right: 20px;
    margin-bottom: 10px;
    max-width: 100px;
    display: inline-flex;
    .student-image {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
  .left {
    float: left;
    // width:100%;
    // height:95%;
    //   border:1px solid;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      color: black;
      font-weight: bold;
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .right {
    display: inline-block;
    //   border:1px solid;
    width: 66%;
    .opt {
      float: right;
      .icon {
        display: inline-block;
        width: 22px;
        height: 22px;
      }
    }
    .desc p {
      min-width: 200px;
      max-width: 420px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .more {
      text-decoration: underline;
    }
    .data {
      color: black;
    }
    .menu-item {
      display: flex;
      .icon {
        margin-right: 8px;
      }
    }
  }
}

.lesson-list {
  padding: 15px 14px 12px 14px !important;
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .page-title {
    border-bottom-color: transparent;
  }
  .page-title a {
    display: inline-flex;
    color: inherit;
    text-decoration: none;
  }
  .page-title .icon {
    fill: $primary-light-color;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;
    .empty-title {
      margin: 0 10px 40px;
    }
  }
  .loading-state {
    max-width: 760px;
    min-height: 200px;
    margin: 50px auto;
    z-index: 5;
    line-height: 1;
    .spinner {
      margin-right: 10px;
    }
  }
}

.course-container {
  padding-top: 10px;
  margin-bottom: 5px;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  margin-left: -30px;
  margin-right: -30px;
  a.accordion-toggle {
    @extend .text-uppercase;
    @extend .text-primary-light;
    text-decoration: none;
  }
  .panel-title {
    padding-left: 38px;
    padding-bottom: 11px;
    .icon.chevron {
      margin-right: 16px;
      transition: all 0.2s ease-in-out;
    }
  }
  .lesson-list {
    padding: 20px 50px 20px 50px;
    width: 100%;
    &__item {
      border: 1px solid $gray;
      padding: 10px;
      margin-bottom: 10px;
      .duration-label {
        color: $secondary-font-color;
      }
    }
    &__item-header {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: start;
      .lessons-label {
        font-weight: 300;
      }
      .subject-name h4 {
        min-width: 200px;
        max-width: 420px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .assign-btn {
        margin: 0 10px;
        .btn.btn-sm {
          padding: 3px 6px;
        }
      }
      .more-options {
        margin-top: 5px;
        &-icon {
          width: 30px;
          height: 30px;
          transform: rotate(90deg);
          transition: fill 0.3s ease-in-out;
          border: 1px solid $darker-gray;
          padding: 5px;
          fill: $darker-gray;
          border-radius: 4px;
        }
        .dropdown-menu {
          .icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          a.menu-item {
            display: flex;
          }
        }
      }
      .lesson-duration {
        min-width: 340px;
      }
    }
    .student-details {
      .duration-label {
        min-width: 130px;
      }
      .inline-list {
        list-style: none;
        padding: 0;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;
        li {
          margin-right: 20px;
          margin-bottom: 10px;
          max-width: 200px;
          display: flex;
          .name {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .avatar {
          @extend .round;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;
        }
      }
    }
  }
  .lesson-data {
    padding-left: 60px;
    padding-right: 50px;
    width: 100%;
    td {
      padding: 10px;
      border-bottom: 1px solid $gray;
    }
    .subject-name {
      width: 20%;
      max-width: 20%;
      word-wrap: break-word;
    }
    .lesson-duration {
      width: 40%;
      max-width: 40%;
    }
    .duration-label {
      color: $secondary-font-color;
    }
    .btn-icon {
      width: 23px;
      height: 17px;
    }
    .action {
      display: inline-block;
      font-size: 0;
      margin-left: 20px;
      .icon {
        fill: $secondary-font-color;
        width: 20px;
        height: 20px;
      }
    }
    tr:last-child td {
      border-color: transparent;
    }
  }
  &.panel-open .panel-title {
    padding-bottom: 10px;
    border-bottom: 1px solid $gray;
    .icon.chevron {
      transform: rotate(90deg);
    }
  }
}

.book {
  margin: 0 auto;
  .header {
    display: flex;
  }
  .note {
    color: gray;
    margin-top: 20px;
    margin-left: 5px;
  }
  .sicon-book {
    // position: absolute;
    // top: 27px;
    // display: inline-block;
    // right: 0px;
    // padding-right: 6px;
    height: 20px;
    width: 20px;
    fill: $primary-color;
    font-style: normal;
    margin-right: 10px;
  }
  .sicon1 {
    position: relative;
    height: 21px;
    padding: 0px;
    top: 5px;
    width: 25px;
    fill: $primary-color;
  }
  .inner-addon {
    position: relative;
    width: 50%;
    display: inline-block;
    .round-right {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
  .round-left {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
  }
  .half {
    width: 10%;
    margin-left: 175px;
    margin-right: 10px;
  }
  // .form-control{
  //     display: block;
  //     width: 100%;
  //     height: 40px;
  //     padding: 6px 12px;
  //     font-size: 14px;
  //     line-height: 1.42857143;
  //     color: #555;
  //     background-color: #fff;
  //     // background-image: none;
  //     // border: 1px solid #ccc;
  //     border-radius: 4px;
  //     // -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  //     // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  //     // -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  //     // -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  //     // transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  // }
  .search {
    font-size: 19px;
    padding: 8px;
    margin-top: 20px;
    border-bottom: 1px solid $gray;
  }
  .panel {
    margin-top: 25px;
    border: 1px solid $gray;
    border-radius: 4px;
  }
  .panel-heading {
    display: flex;
    flex-grow: 1;
    background: none;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 9px;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray;
    .strong {
      font-weight: bold;
    }
    .buttons {
      display: flex;
      .astext {
        background: none;
        border: none;
        font-size: x-small;
        color: grey;
        margin-right: 30px;
      }
    }
  }
  .panel-body {
    display: flex;
    flex-direction: column;
    .firstrow {
      padding: 6px;
      padding-left: 20px;
      // padding-bottom: 8px;
      display: flex;
      .firstpar {
        padding-right: 15px;
        width: 133px;
        color: grey;
        position: relative;
        &:after {
          content: ':';
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .content {
        margin-left: 10px;
      }
    }
  }
  .import {
    background: none;
    border: none;
    margin-right: 21px;
    right: -15px;
    position: absolute;
    top: 7px;
    padding: 0;
    font-size: x-small;
    color: $primary-color;
  }
  .loading-state {
    max-width: 760px;
    min-height: 200px;
    margin: 50px auto;
    z-index: 5;
    line-height: 1;
    .spinner {
      margin-right: 10px;
    }
  }
  button:focus {
    outline: 0;
  }
}

.card-load-more {
  justify-content: center;
}

.book-read {
  border: 1.5px solid $primary-color;
  // border-radius: 13px;
  // padding: 7px 5px 3px 4px;
}
.book-unread {
  border: 0.8px solid #808080;
}

.border-unread {
  padding: 3px 5px 3px 4px;
  .name {
    line-height: 19px;
  }
}
.border-padding {
  padding: 2px 7px 2px 8px;
}

.icon-add-book {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-left: 10px;
  fill: #3cb21f;
}

.edit-book-form-element {
  display: flex;
}

.book-search {
  display: flex;
  position: relative;
  top: -5px;
  .filter-button {
    padding: 4.5px 20px !important;
    display: flex;
    align-items: center;
  }
}
.book-second-header {
  top: -5px;
  .btn-sm {
    padding: 5px 20px 5px 20px;
  }
}

.book-search-icon {
  width: 16px;
  height: 16px;
  fill: #3cb21f;
}

.book-search-button {
  position: absolute;
  right: 15px;
  top: 9px;
}

.book-serach-text {
  // width: 600px !important;
  width: 350px !important;
}

.go-back-previous-state-book {
  fill: #919498;
  margin-top: 5px;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 40px;
  top: 25px;
}

.add-Image:focus,
.file-upload:focus {
  outline: none;
}

.search-book-title {
  border-bottom: 1px solid #e9eaeb;
  margin-bottom: 20px;
  padding-bottom: 12px;
  color: $primary-color;
}

.more-options-book-icon {
  width: 50px;
  height: 30px;
  //transform: rotate(90deg);
  transition: fill 0.3s ease-in-out;
  //border: 1px solid #919498;
  padding: 13px 15px;
  fill: #919498;
  border-radius: 10px;
  background-color: #eef0f4;
}

.assign-book-menu {
  position: relative;
  .icon {
    position: absolute;
    top: 13px;
  }
  span {
    margin-left: 30px;
  }
}

.result-book-assignment-label {
  text-transform: capitalize;
  /* min-width: 200px; */
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.book-assignment-result-header {
  border-bottom: 1px solid #e9eaeb;
  padding: 10px;
  display: flex;
  align-content: center;
  align-items: center;
}

.add-book-icon {
  fill: $primary-color;
  width: 32px;
  height: 32px;
}
.data-margin {
  p {
    margin: 5px 0px;
  }
}
.book-list-data {
  p {
    max-width: 100%;
    white-space: unset;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    max-height: 25px;
  }
  .control-label-text {
    font-size: 14px;
    color: $primary-color;
  }
}

.book-list-left {
  height: 220px !important;
}

.book-list-card {
  display: flex;
  border: 0px solid #e9eaeb;
  margin: 0 0;
  .title {
    font-weight: 600;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $primary-color;
  }
  .book-image {
    height: 213px;
    min-width: 156px;
  }
  img {
    margin-right: 10px;
    // width: 130px;
  }
}

.book-list-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 192px;
  text-align: left;
  padding: 10px 10px 10px 0px;
  .book-list-header .icon {
    display: inline-block;
    width: 20px !important;
    height: 15px !important;
    fill: #919498 !important;
    margin-top: 1px;
  }
  .dropdown-menu .menu-item {
    display: block;
    padding: 10px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  }
}

.book-list-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  min-height: 22px;
  max-height: 30px;
}

.book-detail-label {
  font-size: 14px;
  color: $primary-color;
}

.book-list-author {
  font-size: 14px;
}

.book-action-icon {
  position: absolute;
  top: 12px;
}
input[type='checkbox'].book-shelf-checkbox {
  color: #fff;
  background: #116682;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border: 1px solid #116682;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &.uncheck {
    background: unset;
    border-color: #cacaca;
    &::before {
      border-color: unset;
      width: 0px;
      height: 0px;
      border-width: 0;
    }
  }
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 1px;
    left: 5px;
    width: 4px;
    height: 8px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
  }
}

.ml-30 {
  margin-left: 30px;
}

.book-review-user-img {
  width: 45px !important;
  height: 45px !important;
}

.book-review-user {
  display: flex;
  flex-direction: column;
}

.book-review-user-name {
  line-height: 1;
  // min-width: 100px;
  max-width: 100px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.book-review-remove-btn {
  position: absolute;
  right: 0px;
}

.book-review-edit-btn {
  position: absolute;
  right: 0px;
}

.book-unassign-btn {
  position: absolute;
  right: 0;
  margin-right: 5px;
  font-size: 12px;
  padding: 3px 6px;
}

.booklist-header-buttons {
  color: $primary-color !important;
  border: 1px solid $primary-color;
  margin-left: 7px;
}

.book-page-title {
  position: fixed;
  top: 74px;
  height: 54px;
  padding: 15px;
  margin: 0 auto;
  background-color: #fff;
  display: -webkit-box;
  box-shadow: 0 8px 6px -6px #00000038;
  width: calc(100% - 260px);
  z-index: 1;
  margin-left: 15px;
}

.book-list-search-icon {
  fill: #919498;
  position: relative;
  top: 7px;
  // right: 25px;
}

.book-list-subheader {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 15px;
}

.pos-rel {
  position: relative;
}

.book-list-header-icon {
  position: relative;
  top: 3px;
  right: 7px;
  svg {
    fill: $primary-color;
  }
}
.book-list-header-text-print {
  position: relative;
  top: 3px;
}

.book-close-icon {
  transform: rotate(45deg);
  fill: #999ea2 !important;
  // position: relative;
  right: 5px;
  top: 3px;
}

.book-mini {
  height: 16px;
  width: 16px;
  margin-right: 2px;
}

.book-list-main-data {
  max-width: 100%;
  margin: 0 auto;
  // background-color: #e8e7e7;
  //padding-left: 19px;
}

.book-list-close-icon {
  margin-left: 10px;
}

.book-lesson-time-label {
  font-size: 12px;
  color: #919498;
  padding-left: 10px;
  position: relative;
  bottom: 3px;
}
.rounded-right-input {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}
.book-container {
  background: white;
  border-radius: 15px;
  margin: 10px;
  max-width: 32%;
  text-align: center;
}
.lesson-container {
  // background-color: #e8e7e7;
  border: transparent !important;
  box-shadow: unset;
  margin: 0 auto;
  border-radius: unset;
  padding-top: 10px !important;
  padding-left: 5px !important;
}
.myBooks-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  fill: #555555;
}
.search-select {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  padding-left: 15px !important;
}
.more-options-icon {
  width: 20px;
  height: 20px;
  margin-right: 0px !important;
  fill: #919498;
}
.book-author-name {
  color: $primary-color;
  font-weight: 500;
}
.book-description {
  color: $primary-color;
  font-weight: 500;
  .text-inner-status {
    margin-left: 6px;
    color: #6a6a6a;
    opacity: 0.7;
    font-size: 12px;
  }
}
.filter-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
  float: right;
  margin-top: -1px;
  margin-left: 5px;
  transform: rotate(90deg);
}
.myBooks-heading {
  margin-right: 20px;
  margin-left: 35px;
  line-height: 30px;
}
.marginTop {
  margin-top: 15px !important;
}
.display-inherit {
  display: inherit;
}
.ots-text {
  margin-left: 115px;
  margin-bottom: 15px;
  color: black;
}
.display-inline-all {
  display: inline;
}
.timepicker {
  margin: 0px !important;
}
.background-grey {
  background-color: #e9eaeb;
}
.book-list-header {
  .icon {
    display: inline-block;
    width: 25px !important;
    height: 25px !important;
    fill: #919498 !important;
  }
}
.see-more {
  padding-left: 9px;
  padding-top: 3px;
}

@media (max-width: 1235px) {
  .book-serach-text {
    width: 313px !important;
  }
}
@media (max-width: 1200px) {
  .btn.btn-sm {
    padding: 6px 12px;
  }
}

@media (max-width: 992px) {
  .book-page-title {
    display: inherit;
    height: unset;
  }
  .book-list {
    padding-top: 35px !important;
  }
  .book-first-header {
    display: flex;
    justify-content: center;
    margin-top: -30px;
  }
  .book-second-header {
    display: flex;
    position: absolute;
    justify-content: center;
    margin-top: 15px;
    .btn-sm {
      padding: 7px 12px 2px 12px;
    }
  }
  .title {
    padding-left: 30px;
  }
  .book-list-header {
    justify-content: flex-start !important;
    .title {
      padding-left: 0 !important;
    }
    .more-options {
      position: absolute;
      right: 8px;
    }
  }
  .textWrap {
    white-space: pre-wrap !important;
    .book-author-name {
      width: 100% !important;
      display: inline-block !important;
    }
  }
}

@media (max-width: 768px) {
  .timepicker {
    margin: 7px !important;
  }

  .book-container {
    padding-bottom: 27px;
  }
  .book-list {
    padding-top: 55px !important;
  }
  .book-serach-text {
    width: 100% !important;
  }
  .book-first-header {
    margin-top: 0px;
  }
  .myBooks-icon {
    left: 250px;
  }
  .myBooks-heading {
    text-align: center;
    padding-bottom: 5px;
  }
  .filter-icon {
    display: none;
  }
  .btn {
    padding: 6px 10px;
    font-weight: 400;
  }

  .book-detail .details .image-upload-section {
    display: block;
    flex-direction: column;
  }
  .book-detail .details {
    display: inline;
    flex-direction: row;
    text-align: center;
  }
  .add-Image {
    display: inherit;
    margin-bottom: 15px;
  }
  .edit-book-form-element {
    display: inline;
  }
  .ots-text {
    margin-left: 0px;
  }
  .buttons-div {
    display: flex;
    justify-content: center;
  }
  .assign-btn {
    min-width: 115px;
    /* margin-right: 30px; */
    border-radius: 20px;
  }

  .book-detail .title-details {
    margin-top: 10px;
  }

  .col-sm-12 {
    max-width: 100%;
  }
  .book-list-card {
    display: inline !important;
  }
  .book-list-header .title {
    max-width: 200px !important;
  }
  .datepicker-select .month {
    width: 75px;
    border-right: 1px solid #c8c9cb;
  }
  .date-input .icon {
    display: none !important;
  }

  .width-118-percent {
    width: 118% !important;
  }

  .margin-right-minus {
    margin-right: -21px !important;
  }
  .book-list-card {
    .book-list-detail {
      height: auto !important;
    }
  }

  .book-list-main-data {
    margin-top: 70px !important;
  }
}

@media (max-width: 375px) {
  .myBooks-icon {
    left: 86px;
  }
}

.create-assignment {
  .assigned-students__item {
    .checkbox input[type='checkbox'] + span:before {
      margin-top: -2px !important;
      width: 18px !important;
      height: 18px !important;
    }
    .checkbox input[type='checkbox']:checked + span:after {
      left: 4px;
      top: 4px;
    }
  }
}

.box {
  padding: 60px 0px;
}

.box-part {
  background: #fff;
  border-radius: 0;
  //padding:60px 10px;
  margin: 15px 0px;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 1px #c4c4c4;
  .book-list-detail {
    width: calc(100% - 166px);
    .book-list-header {
      .title {
        overflow: hidden;
        color: $primary-color;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .book-list-data {
      p {
        max-width: 100%;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
        max-height: 25px;
        -webkit-box-orient: vertical;
        color: #116682;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .book-card-label {
        display: inline-block;
        min-width: 80px;
        color: #999;
      }

      .books-students-div {
        display: flex;
        align-items: center;

        .inline-list {
          margin-top: 0;
          margin-bottom: 0;

          li {
            max-width: unset !important;
            margin-bottom: unset !important;
          }

          .student-book-card {
            display: flex;
            //justify-content: center;
            align-items: center;
            .avatar {
              width: 40px;
              height: 40px;
            }

            .student-image-card {
              position: relative;
              .avatar {
                width: 40px;
                height: 40px;
              }
              .icon {
                position: absolute;
                top: 0;
                right: 0;
              }
            }
            .popover-student-image {
              .icon {
                right: 20px;
              }
            }

            .name {
              padding-left: 5px;
              color: #555 !important;
              text-transform: capitalize;
              opacity: unset !important;
              white-space: nowrap;
              //width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          //@media (max-width: 1366px){
          //	.student-book-card {
          //		.name {
          //			width: 40px;
          //		}
          //	}
          //}
          //@media (max-width: 1280px){
          //	.student-book-card {
          //		.name {
          //			width: 32px;
          //		}
          //	}
          //}
          .see-more {
            height: 40px;
            width: 40px;
            background: rgba(16, 102, 130, 0.1);
            border-radius: 50%;
            color: #106682;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0px;
            padding-top: 0px;
          }
        }
      }
    }
  }
}
.text {
  margin: 20px 0px;
}

.fa {
  color: #4183d7;
}

.page {
  .feature-book {
    .row {
      margin-left: -15px;
      margin-right: -15px;
    }
    .feature-title {
      font-size: 20px;
      margin-top: 10px;
    }
    .card-suggestion-option {
      font-size: 16px;
      margin-left: 20px;
      margin-bottom: 5px;
      text-decoration: underline;
    }
    .manual-add-hr {
      margin-top: 15px;
      margin-bottom: 10px;
      height: 2px;
      background: #e9eaeb;
    }
    .feature-book-card {
      padding-left: 0px;
    }
  }
}

@media (max-width: 1600px) {
  .page {
    .book-row {
      .col-lg-4 {
        width: 50%;
      }
    }
    &.sidebar-is-close {
      .book-row {
        .col-lg-4 {
          width: 33.3333333333%;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .page {
    &.sidebar-is-close {
      .book-row {
        .col-lg-4 {
          width: 50%;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &.sidebar-is-close {
      .book-row {
        .col-lg-4 {
          width: 100%;
        }
      }
    }
  }
}

.show-lesson {
  box-shadow: 0px 0px 2px 1px #c4c4c4;
  padding: 5px 15px;
  border-radius: 10px;
  .title {
    font-weight: 500;
    font-size: 14px;
    padding: 7px 0px;
    color: $primary-color;
    text-transform: capitalize;
  }
}

.input-icon {
  position: absolute;
  right: 40px;
  top: 8px;
  transform: rotate(45deg);
}

.input-height {
  height: 30px !important;
}

.date-warning {
  color: red !important;
}

.tag-height {
  //height: 30px;
}

tags-input .tags {
  border: 1px solid #ccc;
  box-shadow: unset;
  font-size: 14px !important;
  .tag-list {
    .tag-item {
      height: 23px !important;
      margin: 2px 3px !important;
      line-height: 21px !important;
      font-size: 13px;
    }
  }
  .input {
    height: 21px !important;
    margin: 3px !important;
    width: 100%;
  }
}

.round-right-input {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.show-search-icon {
  position: absolute;
  top: 9px;
  right: 13px;
  .icon {
    opacity: 0.6;
  }
}

.filter-option-row {
  margin: 5px 15px;
  .filter-option-box {
    border: 1px solid #cccccc;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 20px;
    .option-title {
      padding: 3px 10px;
      border-right: 1px solid #555555;
      background-color: #116682;
      span {
        color: #ffffff;
      }
    }
    .option-value {
      padding: 3px 5px 3px 10px;
      background-color: #ffffff;
      align-items: center;
      display: flex;
      span {
        color: #555555;
        text-transform: capitalize;
      }
      .icon {
        fill: #999ea2;
        margin-left: 10px;
        transform: rotate(45deg);
      }
    }
  }
}

.remove-margin {
  margin: 0px;
}

.popup-button-div {
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 14px;
    color: #116682;
    width: 350px;
  }
}

.expend-row {
  margin-left: -40px;
  margin-right: -40px;
}

.expand-textarea {
  resize: none;
  max-height: 150px !important;
  overflow-y: hidden;
}
.book-rating-badge {
  position: absolute;
  color: #e39929;
  border: 1px solid #16455f;
  background-color: #16455f;
  border-top-right-radius: 5px;
  text-align: center;
  width: 55px;
  height: 28px;
  top: 25px;
  left: 96px;
}

.book-rating-start-icon {
  margin-bottom: 1px;
  margin-right: 3px;
}
.book-rating-badge-text {
  font-size: 20px;
  display: inline-block;
  line-height: 27px;
}

.error-message {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: red;
}
.addAssignee {
  .form-control.hour,
  .form-control.minute,
  .form-control.meridian {
    border: none;
    padding: 0px;
    color: #116682;
  }
  .timepicker .form-control {
    width: 25px;
    border: none;
    background-image: none !important;
  }
  .form-control:focus {
    box-shadow: none;
  }
  select {
    padding-right: 0px !important;
  }

  .assignee-btn {
    border-radius: 20px;
    .btn.btn-sm {
      padding: 3px 6px;
    }
  }
  .addCalendar-btn {
    //border: 1px solid #e9eaeb;
    border-radius: 20px;
    height: 42px;
  }
  .checkbox input[type='checkbox'] + span:before {
    border-radius: 30%;
    margin-right: 5px;
    color: #116682;
    display: inline-block;
    vertical-align: text-top;
    width: 14px;
    height: 14px;
  }
  @media (min-width: 992px) {
    .col-md-offset-1 {
      margin-left: 6.333333%;
    }
  }
}

.page-with-sidebar {
  &.book-shelf {
    width: calc(100% - 30px) !important;
    margin: 15px;
    //background-color: white;
    //border-radius: 15px;
    //box-shadow: 0px 0px 2px 1px #c4c4c4;
    height: unset !important;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
  }
  .lesson-list {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
  .book-list {
    padding-top: 0 !important;
    background: unset;

    .book-shelf-header {
      padding: 14px 10px;
      border-radius: 15px 15px 0 0;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 #e2e5e8;
      h4 {
        color: #555;
        font-family: Poppins;
        font-size: 16px;
      }
    }
  }
  .book-shelf-sidebar {
    border-radius: 15px 0 0 15px;
    background-color: #fff;
    box-shadow: 0 0 2px 0 #e2e5e8;

    .btn-manual-add {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 30px;
      padding-left: 0;
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-top: 10px;
      color: #106682;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      background-color: rgba(16, 102, 130, 0.2);
      border: unset;
      text-transform: capitalize;
      .manual-add-icon {
        width: 24px;
        height: 24px;
        fill: #106682;
        margin-top: -1px;
        margin-left: 10px;
        margin-right: 10px;
        transform: rotate(90deg);
      }
    }
    .manual-add-hr {
      margin-top: 15px;
      margin-bottom: 10px;
      height: 2px;
      background: #e9eaeb;
    }
    .shelf-list-header {
      width: calc(100% - 20px);
      background-color: #eef0f4;
      color: #555;
      border-radius: 10px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      .shelf-list-icon {
        width: 14px;
        height: 20px;
        fill: #555;
        margin-top: -1px;
        margin-left: 10px;
        margin-right: 10px;
      }
      &.feature-btn-book {
        //background-color: white;
        //color: $primary-color;
        border: 1px solid #7dc568;
        background-color: #7dc568;
        color: #fff;
        .shelf-list-icon {
          fill: white;
        }
      }
    }
    .shelf-list-ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 5px;

      .shelf-list-item {
        display: flex;
        padding: 13px;
        padding-left: 50px !important;
        border-bottom: 0.5px solid #e8e7e7;
        color: #555;
        position: relative;
        justify-content: space-between;

        .btn-shelf-edit {
          position: absolute;
          height: 30px;
          width: 30px;
          right: 15px;
          top: 7px;
          padding: 0;
          background-color: unset;
          border-color: unset;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn-shelf-title {
            display: inline-block;
            width: 100%;
          }
          svg {
            height: 16px;
            width: 16px;
          }
        }
        &.active {
          background-color: #eef0f4;
        }
      }
    }

    .edit-shelf-div {
      .edit-shelf-header {
        position: relative;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 20px;
        h4 {
          font-size: 13px;
          font-weight: 600;
        }
        .btn-shelf-edit {
          position: absolute;
          height: 30px;
          width: 30px;
          right: 15px;
          top: 7px;
          padding: 0;
          background-color: #eef0f4;
          border-color: #eef0f4;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
      .form-control {
        margin-left: 10px;
        width: calc(100% - 20px);
        border-color: #ccc;
        margin-bottom: 20px;
        padding: 7.5px 10px;
        height: unset !important;
      }

      .edit-shelf-buttons {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
        .btn-shelf-cancel {
          margin-right: 10px;
          border-color: #e9eaeb;
          background-color: #fff;
          color: #919498;
          padding: 8.5px 20px;
        }
      }
    }
  }
}
.new-book-card {
  .book-list-detail {
    width: calc(100% - 140px);
    padding-top: 15px;
    height: 200px;
    position: relative;
    canvas {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}
.book-list-new-card {
  .book-image {
    height: 180px;
    min-width: 100px;
    margin: 10px;
    border-radius: 5px;
    background-color: #fff;
    border: 0.5px solid #999;
    padding-top: 0px !important;
  }
}

.new-book-list-header {
  justify-content: unset !important;
  align-items: flex-start;
  border-bottom: 1px solid #c8c8c8;
  min-height: unset;
  max-height: unset;

  .book-more-options {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #eef0f4;
    //margin-right: 5px;
    margin-left: 5px;
    padding: 13px 15px;
    height: 30px;

    .icon.more-options-icon {
      margin-top: 3px;
    }
  }

  .title {
    margin-right: auto;
    //padding-right: 5px;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 48px;
    color: #555 !important;
    white-space: unset !important;
  }

  .book-shelf-select {
    display: flex;
    align-items: center;

    .btn-shelf-read {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 30px;
      overflow: hidden;
      width: 120px;
      border-right: 1px solid white;
      padding: 0;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-family: Poppins;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      text-transform: capitalize;
      padding-right: 10px;

      &.btn-shelf-tick {
        background-color: #eef0f4;
        color: #555;
        border-color: #eef0f4;

        .icon-shelf-tick {
          fill: #555;
        }
      }

      .icon-shelf-tick {
        //height: 9.5px;
        width: 12px;
        fill: white;
        min-width: 12px;
        margin-top: 2px;
        margin-right: -1px;
        margin-left: -4px;
      }

      .btn-shelf-div {
        text-overflow: ellipsis;
        overflow: hidden;
        //white-space: nowrap;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
      }
    }

    .book-shelf-a {
      background-color: #106682;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      display: flex;
      padding: 7.2px 10px;
      height: 30px;

      .icon-self-dropdown {
        height: 15.63px;
        width: 12.71px;
        fill: #fff;
      }
    }

    .dropdown-menu {
      min-width: 195px;
      top: 28px;
    }
  }
}
.add-book-container {
  overflow: visible;
}
.add-book-shelf {
  .form-group {
    overflow: visible;
  }

  .book-shelf-add-dropdown {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 4.3px 20px;
    color: #fff;
    height: 32px;
    span {
      color: #555;
      width: 100%;
      display: inline !important;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }
    .icon-self-dropdown {
      width: 18px;
      height: 18.5px;
      fill: #808080;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    .dropdown-menu.dropdown-menu-right {
      width: 365px;
      margin-bottom: 20px;
      li a {
        border-bottom: 0.5px solid #e8e7e7;

        &:last-child {
          border-bottom: unset;
        }
      }
    }
  }
}

.student-review-new {
  .student-review-header {
    width: calc(100% - 40px);
    .book-review-user-img {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
    }
  }
  .book-review-user {
    flex-direction: row;
    align-items: center;
    .rating {
      padding-top: 4px;
    }
    .btn-main-student-notStarted {
      margin-left: 50px;
      padding: 0;
      height: 20px;
      width: 20px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(112, 112, 97);
      .icon-dropdown {
        height: 20px;
        width: 20px;
      }
      &.btn-main-student-onHold {
        border: 1px solid rgb(148, 203, 208);
      }
      &.btn-main-student-inProgress {
        border: 1px solid rgb(243, 160, 72);
      }
      &.btn-main-student-completed {
        border: 1px solid rgb(125, 197, 104);
      }
    }
    .book-review-user-name {
      padding-left: 20px;
      min-width: 170px;
    }
    .icon-onhold {
      border-radius: 50%;
      border: 1px solid rgb(148, 203, 208);
    }
    .icon-inprogress {
      border-radius: 50%;
      border: 1px solid rgb(243, 160, 72);
    }
    .icon-notstarted {
      border-radius: 50%;
      border: 1px solid rgb(112, 112, 97);
    }
    .icon-completed {
      border-radius: 50%;
      border: 1px solid rgb(125, 197, 104);
    }
    .book-status-more-options {
      margin-left: 10px;
      ul.dropdown-menu {
        border-radius: 10px;
        min-width: 140px;
        li {
          .icon {
            margin-bottom: -3px;
          }
          .ml-30 {
            margin-left: 10px;
          }
          &:last-child {
            .menu-item:hover {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
          &:first-child {
            .menu-item:hover {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
            }
          }
        }
      }
      .btn-onhold-status {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 130px;
        padding: 0px;
        height: 30px;
        background-color: #fff;
        border: 1px solid #94cbd0;
        color: #94cbd0;
        font-weight: 400;
        font-size: 13px;
        border-radius: 25px;
        .icon-dropdown {
          fill: #94cbd0;
          height: 15px;
          width: 15.42px;
        }
        &.btn-notstarted-status {
          color: #707061;
          border-color: #707061;
          .icon-dropdown {
            fill: #707061;
          }
        }
        &.btn-inprogress-status {
          color: #f3a048;
          border-color: #f3a048;
          .icon-dropdown {
            fill: #f3a048;
          }
        }
        &.btn-completed-status {
          color: #7dc568;
          border-color: #7dc568;
          .icon-dropdown {
            fill: #7dc568;
          }
        }
      }
    }
  }

  .review-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.reading-progress {
  max-width: 400px;
  width: 400px;
  // overflow-y: scroll;
max-height: 200px;  
  border-radius: 10px;
  margin-top: -5px !important;
  .popover-content {
    padding: 0px;
    .progress-header {
      padding: 12px 0px 12px 20px;
      background-color: #eef0f4;
      display: flex;
      align-items: center;
      border-radius: 10px 10px 0 0;

      .progress-header-icon {
        width: 21px;
        height: 21px;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        padding-left: 20px;
      }

      .progress-btn-header {
        margin-right: 10px;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: capitalize;
        font-weight: 500;
        .icon {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          fill: white;
        }
      }
    }
    .progress-body {
      max-height: 144px;
      overflow-y: auto;
      .progress-student-list {
        padding-left: 0px;
        max-height: 220px;
        padding-top:5px;
        li {
          .student-book-card {
            width: 100%;
            flex-direction: row;
            margin-bottom: 15px;
            .avatar {
              margin-right: 20px;
              border-radius: 50%;
            }
            .student-progress-status {
              display: flex;
              flex-direction: row;
              margin-left: auto;
              align-items: center;
              .book-status-more-options {
                margin-left: 10px;
                ul.dropdown-menu {
                  border-radius: 10px;
                  min-width: 140px;
                  li {
                    border-radius: unset;
                    padding-right: 0px;
                    .menu-item {
                      width: 100%;
                      .icon {
                        margin-bottom: -3px;
                      }
                      .ml-30 {
                        margin-left: 10px;
                      }
                    }
                    &:last-child {
                      .menu-item:hover {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                    &:first-child {
                      .menu-item:hover {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      }
                    }
                  }
                }
                .icon-onhold {
                  border-radius: 50%;
                  border: 1px solid rgb(148, 203, 208);
                }
                .icon-inprogress {
                  border-radius: 50%;
                  border: 1px solid rgb(243, 160, 72);
                }
                .icon-notstarted {
                  border-radius: 50%;
                  border: 1px solid rgb(112, 112, 97);
                }
                .icon-completed {
                  border-radius: 50%;
                  border: 1px solid rgb(125, 197, 104);
                }
                .btn-onhold-status {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  width: 130px;
                  padding: 0px;
                  height: 30px;
                  background-color: #fff;
                  border: 1px solid #94cbd0;
                  color: #94cbd0;
                  font-weight: 400;
                  font-size: 13px;
                  border-radius: 25px;
                  .icon-dropdown {
                    fill: #94cbd0;
                    height: 15px;
                    width: 15.42px;
                  }
                  &.btn-notstarted-status {
                    color: #707061;
                    border-color: #707061;
                    .icon-dropdown {
                      fill: #707061;
                    }
                  }
                  &.btn-inprogress-status {
                    color: #f3a048;
                    border-color: #f3a048;
                    .icon-dropdown {
                      fill: #f3a048;
                    }
                  }
                  &.btn-completed-status {
                    color: #7dc568;
                    border-color: #7dc568;
                    .icon-dropdown {
                      fill: #7dc568;
                    }
                  }
                }
              }
              .btn-main-student-notStarted {
                margin-right: 10px;
                padding: 0;
                height: 20px;
                width: 20px;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgb(112, 112, 97);
                .icon-dropdown {
                  height: 20px;
                  width: 20px;
                }
                &.btn-main-student-onHold {
                  border: 1px solid rgb(148, 203, 208);
                }
                &.btn-main-student-inProgress {
                  border: 1px solid rgb(243, 160, 72);
                }
                &.btn-main-student-completed {
                  border: 1px solid rgb(125, 197, 104);
                }
              }
              .student-progress-div {
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                padding-left: 15px;
                padding-right: 15px;
                &.notStarted-border {
                  border: 1px solid rgb(112, 112, 97);
                  color: rgb(112, 112, 97);
                }
                &.onHold-border {
                  border: 1px solid rgb(148, 203, 208);
                  color: rgb(148, 203, 208);
                }
                &.inProgress-border {
                  border: 1px solid rgb(243, 160, 72);
                  color: rgb(243, 160, 72);
                }
                &.completed-border {
                  border: 1px solid rgb(125, 197, 104);
                  color: rgb(125, 197, 104);
                }
              }
            }
          }
        }
      }
    }
  }
}

.student-progress-list {
  display: flex;
  align-items: center;
  .good-job-text {
    margin-left: 10px;
  }
  .btn-main-student-notStarted {
    padding: 0;
    height: 20px;
    width: 20px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(112, 112, 97);
    &:hover {
      color: unset;
      background-color: unset;
      border-color: unset;
    }
    .icon-dropdown {
      height: 20px;
      width: 20px;
    }
    &.btn-main-student-onHold {
      border: 1px solid rgb(148, 203, 208);
    }
    &.btn-main-student-inProgress {
      border: 1px solid rgb(243, 160, 72);
    }
    &.btn-main-student-completed {
      border: 1px solid rgb(125, 197, 104);
    }
  }
  .book-status-more-options {
    margin-left: 10px;
    ul.dropdown-menu {
      border-radius: 10px;
      min-width: 140px;
      li {
        .icon {
          margin-bottom: -3px;
        }
        .ml-30 {
          margin-left: 10px;
        }
        &:last-child {
          .menu-item:hover {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
        &:first-child {
          .menu-item:hover {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
      }
    }
    .icon-onhold {
      border-radius: 50%;
      border: 1px solid rgb(148, 203, 208);
    }
    .icon-inprogress {
      border-radius: 50%;
      border: 1px solid rgb(243, 160, 72);
    }
    .icon-notstarted {
      border-radius: 50%;
      border: 1px solid rgb(112, 112, 97);
    }
    .icon-completed {
      border-radius: 50%;
      border: 1px solid rgb(125, 197, 104);
    }
    .btn-onhold-status {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 130px;
      padding: 0px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #94cbd0;
      color: #94cbd0;
      font-weight: 400;
      font-size: 13px;
      border-radius: 25px;
      .icon-dropdown {
        fill: #94cbd0;
        height: 15px;
        width: 15.42px;
      }
      &.btn-notstarted-status {
        color: #707061;
        border-color: #707061;
        .icon-dropdown {
          fill: #707061;
        }
      }
      &.btn-inprogress-status {
        color: #f3a048;
        border-color: #f3a048;
        .icon-dropdown {
          fill: #f3a048;
        }
      }
      &.btn-completed-status {
        color: #7dc568;
        border-color: #7dc568;
        .icon-dropdown {
          fill: #7dc568;
        }
      }
    }
  }
}
