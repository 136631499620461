//
// Page
// --------------------------------------------------

.page {
  // min-height: 100vh;
  position: relative;
  width: 100%;
  // min-width: 1024px;
  overflow-x: hidden;
  overflow-y: hidden;
  //storm
  background-color: #f1f2f5;
  //background-color: #F7F9FC;
  //background-image: url("/images/mainback.jpg");
  //background-color: #065876;
  background-repeat: no-repeat;
  background-size: cover;

  &.page-backgound-img {
    background-image: url('../images/kids_background_1.jpg');
    background-size: contain;
  }

  &.sidebar-is-close {
    .main-header {
      width: calc(100% - 214px);
      left: 214px;
    }
    .page-container {
      margin-left: 0px;
      .feed-top-header,
      .lesson-content-top {
        width: calc(100% - 35px);
      }
      .collaborate-title {
        width: calc(100% - 35px);
      }
      .book-page-title {
        width: calc(100% - 35px);
      }
      .payment-container-top {
        width: calc(100% - 35px);
      }
    }
    .sidebar {
      left: 0;
      width: 0px;
      .sidenav {
        width: 40px;
        .sidenav-item {
          padding: unset;
          padding-left: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          .sidenav-icon {
            margin-right: 0;
          }
          .sidenav-report {
            margin-left: 10px !important;
            width: 20px !important;
          }
        }
        li a span {
          display: none;
        }
        .sidenav-report-item {
          .sidenav-item {
            padding: 10px 0;
            position: relative;

            .sidenav-icon {
              margin-left: 18px;
              margin-right: 0;
              width: 15px;
            }
            .sidenav-arrow {
              position: absolute;
              right: 0px;
              height: 14px;
            }
            span {
              display: none;
            }
          }
          .sub-sidenav-menu {
            li {
              padding-left: 40px;
              span {
                display: none !important;
              }
              svg {
                margin-left: -30px !important;
              }
            }
          }
        }
        &::-webkit-scrollbar {
          width: 0;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid slategrey;
        }
      }
    }
    .help {
      //left: -256px;
      width: 40px;
      .announcement-div a,
      h4,
      p {
        display: none;
      }
      .announcement-main-link {
        display: block !important;
      }
      .sidenav-help-text {
        .sidenav-icon {
          display: block;
          margin-right: 0;
          margin-top: 25px;
        }
      }
    }
    .header-navicon {
      left: 30px;
    }
    .loading-overlay {
      left: 0px;
    }
    #uiCalendar {
      &.fixedHeaderClass {
        .fc-widget-header.fc-row {
          position: fixed;
          top: 125px;
          width: calc(100% - 65px);
          background: white;
          left: 34px;
          z-index: 100;
        }
      }
    }

    //	yaha
    .calendar-header {
      //display: inline;
    }
  }
}
.page-data {
  padding: 30px;
  padding-bottom: 70px;
}

.page-container {
  margin-left: 256px;
  height: calc(100vh - 70px);
  // transition: margin-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.loading-overlay {
  position: fixed;
  top: 70px;
  left: 256px;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f5;
  z-index: 9999998;
  line-height: 1;
  .spinner {
    margin-right: 10px;
  }
}

.page-with-sidebar {
  &.collaborate .page-sidebar {
    min-width: 100%;
    flex: 0 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid $gray;
  }

  .page-data {
    min-height: calc(100vh - 62px);
    flex: 1 1 auto;
  }

  &.collaborate .page-sidebar,
  &.collaborate .page-data {
    min-height: calc(100vh - 132px);
    height: calc(100vh - 132px);
    padding: 0;
  }
}

.page-title {
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray;
}

.page-content {
  margin-top: 70px;
  //padding-bottom: 44px;
  transition: margin-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  //storm
  // background: #e8e7e7;
  // background-color: #F1F2F5;

  //border-top-left-radius: 18px;
  margin-left: 5px;
  overflow-y: scroll;
  height: calc(100vh - 60px) !important;
}

@media (max-width: 768px) {
  .page {
    &.sidebar-is-close {
      .sidebar {
        display: none;
      }
      .page-container {
        margin-left: 0px;
        .feed-top-header,
        .lesson-content-top {
          width: calc(100% - 4px);
          margin-top: 1px;
        }
        .collaborate-title {
          width: 100%;
        }
        .book-page-title {
          width: 100%;
        }
      }
      .main-header {
        width: 100%;
      }
    }
    .loading-overlay {
      left: 70px;
    }
  }
}
@media (max-width: 465px) {
  .page {
    .page-container {
      .page-content {
        //margin-top: 160px;
        .lesson-content {
          .feed-top-header {
            height: unset;
          }
          .feed-page {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .page {
    &.sidebar-is-close {
      .calendar-header {
        &.fixedHeaderClass {
          left: 70px;
        }
        .create-btn {
          right: 145px;
        }
        .calendar-search-icon {
          margin-left: 60px;
          margin-right: 60px;
          margin-top: 5px;
        }
        .mr-20 {
          margin-right: 60px !important;
        }
        .margin-left-20 {
          margin-left: 50px;
        }
        .caret-pos {
          right: 73px;
        }
      }
      .create-padding-right {
        padding-right: 60px;
      }
    }
  }
}

@media (max-width: 920px) {
  .page {
    &.sidebar-is-close {
      .calendar-header {
        &.fixedHeaderClass {
          left: 70px;
        }
        .create-btn {
          right: 125px;
        }
        .calendar-search-icon {
          margin-left: 50px;
          margin-right: 50px;
          margin-top: 5px;
        }
        .mr-20 {
          margin-right: 50px !important;
        }
        .margin-left-20 {
          margin-left: 40px;
        }
        .caret-pos {
          right: 63px;
        }
      }
      .create-padding-right {
        padding-right: 50px;
      }
    }
  }
}
