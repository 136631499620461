//
// Sidebar
// --------------------------------------------------
.sidebar {
  margin-top: 60px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  z-index: 15;
  // background-color: $sidebar-color;
  //transition: left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  padding-left: 10px;
  padding-right: 5px;
  .help {
    background-color: rgba(0, 0, 0, 0.2);
    @extend .sidenav-item;
    padding: 10px;
    flex-direction: column;
    font-size: 14px;
    text-align: justify;
    width: 238px;
    position: fixed;
    bottom: 0;
    height: 170px;
    margin-bottom: 10px;
    left: 10px;
    right: 0;
    //transition: left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 20px;
    color: #fff;
    // background-color: $primary-color;
    //background-image: url(/images/mainback.jpg);
    //background-position: left center;
    a {
      color: white;
      text-decoration: none;
    }
  }
  .sidenav-help-text {
    max-width: 220px;
    text-align: left;
    font-size: 12px;
    h4 {
      font-size: 16px;
      margin-bottom: 5px;
    }
    p {
      margin-top: 5px;
      font-size: 13px;
      padding: 0px 13px;
    }
  }
}

.sidenav {
  //storm
  // background-color: rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
  // margin-bottom: 175px !important;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  @extend .list-unstyled;
  scrollbar-width: none;
  // background-color: $primary-color;
  li:focus {
    a:focus {
      outline: none;
    }
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0.2em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.sidenav-head {
  padding: 3px 20px;
  // margin-bottom: 10px;
  margin-left: 45px;
  &.introjs-showElement {
    background-color: $primary-color;
  }
}

.gradebook-image {
  margin-left: 3px;
}
.gradebook-text {
  margin-left: 7px;
}
.attendance-image {
  margin-left: -30px !important;
  margin-right: 15px !important;
}
.portfolio-image {
  margin-left: -30px !important;
  margin-right: 18px !important;
}
.portfolio-soon-image {
  margin-left: 45px;
}
.ungraded-assignment {
  background-color: rgb(16, 102, 130);
  font-size: 11px;
  color: #fff;
  margin-top: -21px;
  text-align: center;
  padding: 0 8px;
  margin-left: -19px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #ffffff;
  transition: background 0.2s;
}
.ungraded-assignment-hidden {
  visibility: hidden;
}
// .sidebar-list-elem:hover {
// 	background: rgba(0,0,0,0.05);
//     border-radius: 10px;
// }
.collaborate-chat-count {
  @extend .round;
  @extend .text-center;
  width: 19px;
  height: 18px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  color: $primary-color;
  padding: 3px;
  background-color: $brand-danger;
  margin-left: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.sidenav-logo {
  width: 150px;
  // height: 70px;
}

.sidenav-kids-logo {
  width: 90px;
}

.sidenav-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  //storm
  color: black;
  font-family: $heading-font;
  text-decoration: none;
  &:focus,
  &:hover {
    // color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &.disabled {
    pointer-events: none;
    color: #a0a0a0;

    img.sidenav-icon {
      opacity: 0.5;
    }

    // opacity: 0.5;
  }
  // &.active {
  // font-weight: bold;
  // color: $active-link;
  //storm
  // background-color: rgba(0, 0, 0, 0.3);
  // .sidenav-icon {
  // 	fill: $active-link;
  // }
  // }
}

.sidenav-sub-item {
  display: flex;
  align-items: center;
  // padding: 15px;
  color: #fff;
  font-family: $heading-font;
  text-decoration: none;
  &:focus,
  &:hover {
    color: #fff;
    // background-color: rgba(0, 0, 0, 0.2);
  }
  &.active {
    font-weight: bold;
    color: $primary-light-color;
    // background-color: rgba(0, 0, 0, 0.3);
    .sidenav-icon {
      fill: $primary-light-color;
    }
  }
}

.badge-container {
  position: relative;

  small.chore-badge {
    font-size: 8px;
    padding: 2px 4px 1px;
    background: #3eb149;
    color: #fff;
    position: absolute;
    // top: -15px;
    right: -65px;
    border-radius: 10px;
    opacity: 1;
    &.new {
      font-size: 10px !important;
      right: -35px !important;
      letter-spacing: 1px;
    }
  }
}

.sub-sidenav-menu {
  @extend .list-unstyled;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: #f1f2f5;
  li {
    padding-left: 55px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
    font-family: $heading-font;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
    &.active {
      font-weight: bold;
      color: #a7d382;
    }
  }
}

.sidenav-report-item {
  &.active {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .sub-sidenav-menu {
    .sidenav-icon {
      margin-left: -35px !important;
      margin-bottom: -5px !important;
    }
  }

  .sidenav-img {
    margin-left: -30px !important;
    margin-right: 10px !important;
  }
}

.sidenav-icon {
  width: 38px;
  height: 38px;
  fill: #fff;
  margin-right: 15px;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &.rotate-180 {
    transform: rotate(180deg);
  }
}

.sidenav-arrow {
  width: 30px !important;
  fill: #116682;
}

.sidenav-report {
  margin-left: 3px;
  padding-right: 3px;
}

@media (min-width: 768px) {
}
