.youtube-embed-list{
    display: flex;
    flex-wrap: wrap;

    .ne-video-player{
        width: 320px;
        height: 230px;
    }

    .ne-video-thumb{
        &, & img{
            width: 320px;
            height: 230px;
        }
    }
}