//
// Header
// --------------------------------------------------
.user-menu {
	@extend .dropdown-menu;
	width: 320px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	&:before {
		content: " ";
		position: absolute;
		right: 25px;
		top: -10px;
		display: inline-block;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #ccc;
		border-left: 10px solid transparent;
		border-bottom-color: rgba(0, 0, 0, 0.2);
	}
	&:after {
		position: absolute;
		right: 30px;
		top: -9px;
		display: inline-block;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #ffffff;
		border-left: 10px solid transparent;
		content: "";
	}
	.tab {
		margin-bottom: 0;
	}
	.tab-item {
		border-bottom-color: $gray;
		&.reminder {
			// &:hover{
			//     .notification-tab-clear{
			//         display: inline-block;
			//     }
			//     .notification-tab-count{
			//         display:none;
			//     }
			// }
		}
		&.active {
			border-bottom-color: $primary-light-color;
		}
	}
	.tab-content {
		max-height: 360px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0.5em;
		}
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
		}
		&::-webkit-scrollbar-thumb {
			background-color: darkgrey;
			outline: 1px solid slategrey;
		}
	}
	.tab-content .mark-read {
		padding: 4px 10px;
		background: $primary-color;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 30px;
		&:hover {
			background: darken($primary-color, 10);
		}
		p {
			overflow: hidden;
			font-size: 14px;
			color: $white;
		}
	}
	.tab-content .reminders {
		padding: 10px;
		border-top: 1px solid $lighter-gray;

		&:first-child {
			border-top-color: transparent;
		}
		&.not-seen {
			background: $gray;
		}
		.time {
			@extend .text-secondary;
		}
		p {
			overflow: hidden;
			font-size: 14px;
			color: $primary-font-color;
		}
		.close-icon {
			opacity: 0;
			.icon {
				width: 16px;
				height: 16px;
				&:hover {
					fill: #000;
				}
			}
		}
		.reminder-icon {
			.icon {
				width: 20px;
				height: 20px;
			}
		}
	}
	.user {
		padding: 12px 20px;
	}
	.avatar {
		margin-right: 10px;
		border-radius: 50%;
	}
	.user-desc {
		flex: 1 0 auto;
		max-width: 165px;

		h5 {
			max-width: 100%;
			word-wrap: break-word;
			margin-right: 10px;
			overflow: hidden;
		}
	}
	.icon {
		width: 24px;
		height: 24px;
		fill: $secondary-font-color;
	}
	.action {
		display: inline-block;
		& + .action {
			margin-left: 10px;
		}
		&:hover {
			.icon {
				fill: $primary-color;
			}
		}
	}
	.user-menu-more {
		padding: 10px;
		border-top: 1px solid $light-gray;
		a {
			color: inherit;
			display: block;
			text-decoration: none;
		}
	}
}
