//
// Flex Grid
// --------------------------------------------------
[layout="column"],
[layout="row"] {
    display: flex;
}

[layout="column"] {
    flex-direction: column;
}

[layout="row"] {
    flex-direction: row;
}

[layout-wrap] {
    flex-wrap: wrap;
}

[flex=""] {
    flex: 1 1 auto;
}

[layout-align="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
}

[layout-align="start"],
[layout-align="start start"],
[layout-align="start center"],
[layout-align="start end"],
[layout-align="start stretch"] {
    justify-content: flex-start;
}

[layout-align="center"],
[layout-align="center start"],
[layout-align="center center"],
[layout-align="center end"],
[layout-align="center stretch"] {
    justify-content: center;
}

[layout-align="end"],
[layout-align="end center"],
[layout-align="end start"],
[layout-align="end end"],
[layout-align="end stretch"] {
    justify-content: flex-end;
}

[layout-align="space-around"],
[layout-align="space-around center"],
[layout-align="space-around start"],
[layout-align="space-around end"],
[layout-align="space-around stretch"] {
    justify-content: space-around;
}

[layout-align="space-between"],
[layout-align="space-between center"],
[layout-align="space-between start"],
[layout-align="space-between end"],
[layout-align="space-between stretch"] {
    justify-content: space-between;
}

[layout-align="start start"],
[layout-align="center start"],
[layout-align="end start"],
[layout-align="space-between start"],
[layout-align="space-around start"] {
    align-items: flex-start;
    align-content: flex-start;
}

[layout-align="start center"],
[layout-align="center center"],
[layout-align="end center"],
[layout-align="space-between center"],
[layout-align="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
}

[layout-align="start center"]>*,
[layout-align="center center"]>*,
[layout-align="end center"]>*,
[layout-align="space-between center"]>*,
[layout-align="space-around center"]>* {
    max-width: 100%;
}

[layout-align="start end"],
[layout-align="center end"],
[layout-align="end end"],
[layout-align="space-between end"],
[layout-align="space-around end"] {
    align-items: flex-end;
    align-content: flex-end;
}

[layout-align="start stretch"],
[layout-align="center stretch"],
[layout-align="end stretch"],
[layout-align="space-between stretch"],
[layout-align="space-around stretch"] {
    align-items: stretch;
    align-content: stretch;
}

[layout-align="none baseline"],
[layout-align="start baseline"],
[layout-align="center baseline"],
[layout-align="end baseline"],
[layout-align="space-between baseline"],
[layout-align="space-around baseline"] {
    align-items: baseline;
    align-content: baseline;
}