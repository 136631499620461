.radio {
	font-size: 15px;
	position: relative;
	padding-left: 0;
	margin-top: 5px;
	margin-bottom: 5px;
	input[type="radio"] {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		filter: alpha(opacity=0);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		& + span {
			position: relative;
			padding-left: 30px;
			&:after {
				content: "";
				width: 15px;
				height: 15px;
				border: 2px solid;
				position: absolute;
				left: 0;
				top: 1px;
				margin-top: 1px;
				border-radius: 100%;
				box-sizing: border-box;
			}
		}
		&:checked + span {
			&:before {
				content: "";
				background: $primary-light-color;
				width: 7px;
				height: 7px;
				position: absolute;
				left: 4px;
				top: 5px;
				margin-top: 1px;
				border-radius: 100%;
			}
			&:after {
				border-color: $primary-light-color;
			}
		}
	}
	&:focus,
	&:hover {
		input[type="radio"]:not(:disabled) + span:after {
			border-color: $primary-light-color;
		}
	}
}
