//
// Signup
// -------------------------------------------------

.signup {
	.error-msg {
		font-size: 14px;
		border-radius: 4px;
		background: $brand-danger;
		text-align: center;
		color: $primary-color;
		margin-bottom: 20px;
		&:empty {
			display: none;
		}
	}

	.profile-img {
		display: block;
		width: 50px;
		height: 50px;
		margin: 0 auto;
	}

	.main-card .content {
		max-width: 340px;
		margin-left: auto;
	}

	.holding-card {
		color: #000;
		left: 20px;
		// padding: 30px 60px;
		border-radius: 10px;
		.login-input {
			height: 50px;
			padding: 15px 21px;
			padding-left: 50px;
		}

		.subtitle {
			color: rgb(94, 94, 94);
			margin-bottom: 2.2rem;
			font-size: 1.15vw;
		}

		h2 {
			margin-bottom: 8px;
			font-weight: 500;
			font-size: 1.55vw;
			letter-spacing: 0;
		}

		.btn-signup {
			width: 100%;
			height: 52px;
			border-radius: 40px;
			border: none;
			background-color: #0d6580;
			font-size: 1rem;
			color: #fff;
			/* font-family: 'Poppins', sans-serif; */
			text-transform: uppercase;
			margin: 0.7rem 0;
			cursor: pointer;
			transition: .5s;
		}

		@media (max-width: 850px) {
			padding: 20px;
		}
	}

	.main-page-left-side {
		padding: 50px 10px 0 10px;
	}

	.signup-message {
		font-weight: 600;
		color: #4d4d4d;
		margin-bottom: 20px;
		span{
			font-size: 15px;
			font-weight: 400;
		}
	}

	.holding-card-title {
		padding-bottom: 0px !important;
		margin-bottom: 10px !important;
	}

	.action-container {
		display: flex;
		justify-content: flex-end;
	}

	.terms {
		// font-size: 12px;
		&.link {
			text-decoration: none;
		}
	}

	.external-services {
		margin-top: 40px;
	}
}
