.single-card {
  border: 1px solid #e9eaeb;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;
  margin-top: 7px;

  .single-book {
    display: grid;
    grid-template-columns: 2fr 7fr 1fr;
    background-color: white;
    cursor: pointer;
    .book-img {
      max-height: 100%;
      width: 100%;
      border-radius: 8px 0 0 8px;
    }
    .book-info {
      margin-top: 5px;
      .book-title {
        color: #116682;
        font-size: 15px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        -webkit-box-orient: vertical;
        margin-left: 10px;
      }
      .book-author {
        margin-left: 10px;
        max-width: 100%;
      }
      .book-Pages {
        margin-left: 10px;
      }
    }
    .select-book {
      text-align: end;
      .MediaDeleteIcon {
        cursor: pointer;
        margin-right: 15px;
        font-size: 15px;
        margin-top: 7px;
        &:hover {
          color: red;
        }
      }

      .ant-checkbox-inner {
        height: 22px;
        width: 22px;
      }
    }
  }
  &:hover {
    transition: box-shadow 0.3s, border-color 0.3s;
  }
}

.single-mediaCard {
  border: 1px solid #e9eaeb;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;
  margin-top: 7px;
  background-color: white;
  .single-Media {
    display: grid;
    grid-template-columns: 18% 82%;
    gap: 10px;
    .mediaPic {
      width: 100%;
      height: 100%;
      border-radius: 8px 0px 0px 8px;
    }
    .media-info {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 10px;
      .lesson-book-header {
        display: grid;
        grid-template-columns: 9fr 1fr 2fr;
        gap: 2px;
        .MediaEditIcon {
          &:hover {
            color: #116682;
          }
        }
        .MediaDeleteIcon {
          cursor: pointer;
          margin-right: 5px;
          font-size: 15px;
          margin-top: 3px;
          &:hover {
            color: red;
          }
        }
        .mediaCard-Title {
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 600;
          color: #116682;
        }
        .mediaCard-description {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
