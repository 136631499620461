//
// Common layout
// --------------------------------------------------
//
// Common layout styles
// --------------------------------------------------
* {
    box-sizing: border-box;
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

body {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 1.5;
    font-family: $primary-font;
    color: #555;
    // min-height: 100vh;
    // overflow-y: hidden;

}

img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
}

[flex] {
    flex-grow: 1;
}

.modal.in{
	display:flex !important;
	.modal-dialog{
		margin:auto;
	}
}

.modal-dialog.modal-lg {
    width: 920px;
}

.modal-dialog {
    margin-bottom: 100px;
}

.modal-content{
	border-radius: 10px;
}

.not-seen-icon {
	fill: $primary-color !important;
}

.announcement-div{
	display: block;
	span{
		display: block;
	}
	a{
		display: block;
		
    }
    a:hover{
        text-decoration: underline !important;
    }

}

@media (max-width: 768px) {

}

///flex
@each $i in (3, 5, 8, 10, 12, 15, 18, 20, 25, 30, 33, 35, 40, 45, 50, 55, 60, 66, 70, 80, 85, 90, 95, 100) {
    *[flex="#{$i}"] {
        max-width: #{$i * 1%};
        flex-grow: #{$i};
    }
}
