h4{
	color: $base-color;
	font-size: 18px;
}
.add-resources-tabs {
	._resources-options {
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 16px;

		li {
			margin-right: 12px;

			&:hover {
				color: #106682;
			}
		}
	}

	.activeTab {
		color: #106682;
		border-bottom: 2px solid #106682;
	}
}


.show-electronic-media-cards {
	width: 100%;
	background-color: #EEF0F4;
	border-radius: 8px;
	min-height: 90px;
	max-height: 190px;
	overflow-y: scroll;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 20px;

	.electronicMedia-card {
		display: grid;
		grid-template-columns: 1fr 4fr;
		margin: 5px;
		background: white;
		border-radius: 8px;

		.card-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: 10px 0;

			div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-right: 10px;

				svg {
					&:hover {
						cursor: pointer;
						fill: red;
					}
				}
			}
		}
	}
}

.save-btn {
	text-align: end;

	button {
		border-radius: 25px;
		color: #106682;
		border: none;
		background: #e7eff2;
		padding: 10px 47px;

		&:focus {
			outline: none;
		}

		&:hover {
			cursor: pointer;
			background-color: #d8e8ef;

		}
	}
}
