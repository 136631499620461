.book-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  column-gap: 12px;
  width: 100%;

  .ant-input-group-addon:first-child {
    width: 20%;
    .ant-select-selector {
      width: 110px;
    }
  }
  .ant-input-group-addon:last-child {
    width: 8%;
  }
  .ant-input-search-button {
    width: 100% !important;
  }

  .book-read-options {
    .ant-select {
      width: 165px;
    }
  }
}

.add-manual-book-container {
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
}
.select-all-books {
  text-align: end;
  margin-top: 10px;

  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
}
.books {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  cursor: pointer;
  .ant-card {
    .ant-card-body {
      padding: 0 !important;
    }
  }

  .single-book {
    display: grid;
    grid-template-columns: 2fr 7fr 1fr;
    .book-img {
    }
    .book-info {
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      ._book-title {
        color: @primary-color;
        font-size: 15px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        -webkit-box-orient: vertical;
      }
    }
    .select-book {
      padding: 5px 9px;
      text-align: end;
      .ant-checkbox-inner {
        height: 20px;
        width: 20px;
      }

      svg {
        &:hover {
          fill: @primary-color;
        }
      }
    }
  }
}
