.assignment-tooltip {
  .ant-tooltip-inner {
    background-color: white;
    //border-radius: 8px;
    width: 320px;
  }
  .ant-tooltip-arrow-content {
    background-color: white;
  }
}
