//
// Forgot password stylesheet
//

.forgot-password {
	.msg {
		height: 25px;
		font-size: 14px;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 20px;
		&:empty {
			display: none;
		}
		&.error {
			color: $brand-danger;
			background: rgba(255, 0, 0, 0.05);
		}
		&.success {
			color: $brand-success;
			background: rgba(0, 255, 0, 0.05);
		}
	}

	.help-text {
		margin-bottom: 25px;
		font-size: 13px;
	}

	.holding-card {
		right: 20px;
		height: 400px;
		border-radius: 10px;
	}

}
