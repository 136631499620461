svg {
  &:hover {
    cursor: pointer;
  }
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description-tooltip {
  max-width: 40% !important;
  max-height: 300px;
  min-height: auto;
  overflow-y: scroll;
}

.ant-radio{
  align-self: flex-start !important;
}

//Add new course button styles
#course-select-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  align-items: center;
  .course-select-container-icon {
    padding: 5px;
    color: #555;
    box-sizing: border-box;
    height: 32px;
    width: 50px;
    border-radius: 0 5px 5px 0;
    background-color: #fff;
    border: 1px solid #ccc;

    &:hover {
      border-color: @primary-color;
      color: @primary-color;
    }
    margin-top: 13px;
  }
  .ant-select-selector {
    border: 1px solid #ccc !important;
    border-radius: 5px 0 0 5px !important;
  }
}

//color picker div style
.color-and-credit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

//Search existing courses container styles
.search-course-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  .search-course-container-left {
    flex: 0.6;
  }
}

.course-table {
  width: 700px;
  // &:hover {
  //   .ant-table-cell {
  //     .action {
  //       display: block;
  //     }
  //   }
  // }
  .ant-table-row {
    .action {
      .course-table-icons {
        visibility: hidden;
      }
    }
    &:hover {
      cursor: pointer;
      .course-table-icons {
        visibility: visible;
      }
    }
  }
}

.read-more {
  font-size: 12px;
  cursor: pointer;
  color: @primary-color;
}
.circle {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  border-radius: 50%;
}

.course-option {
  cursor: context-menu;
  .edit-icon {
    display: none;
  }
  &:hover {
    .edit-icon {
      display: block;
      &:hover {
        cursor: pointer;
        color: @primary-color;
      }
    }
  }
}

.tool-tip {
  display: flex;
  .tool-tip-title-main {
    height: 22px;
    width: 96px;
    color: #555;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  .tool-tip-title-sub {
    height: 20px;
    width: 112px;
    color: #555;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.table-description {
  color: #555;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  font-size: 14px;
}

//Add course form tittle
// .add-course-modal-title{
//   color: #555;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 22px;
//   word-wrap: break-word;
// }

.ant-select-selection-item {
  text-transform: capitalize;
}
.hsp-day-picker {
  .DayPicker-Month {
    width: 36%;
    height: 36%;
    .DayPicker-Day {
      display: table-cell;
      padding: 0.5em;
      border-radius: 4px;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      background-clip: content-box, padding-box;
    }
    .DayPicker-Day--today {
      color: #116682;
      font-weight: 800;
    }
    .DayPicker-Day--filteredDaysOff {
      color: #ff9f01 !important;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      position: relative;
      background-color: @primary-color;
      color: #f0f8ff;
    }
  }
}
.drawer-footer {
  float: right;
  // .ant-drawer-footer {
  flex-shrink: 0;
  padding: 25px 16px;
  border-top: 1px solid #f0f0f0;
  // }
}
// .ant-input {
//   text-transform: capitalize;
// }

.assign-lesson-card {
  .ant-card-bordered {
    border-top: 1px solid #f0f0f0;
    border-bottom: 0px solid #f0f0f0;
    border-left: 0px solid #f0f0f0;
    border-right: 0px solid #f0f0f0;
    border-radius: 0%;
  }
  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }

  .ant-card-body {
    padding: 24px 0;
  }
}
.lesson-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}
.drawer-container {
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 0 10px;
  margin-bottom: 15px;
  position: relative;
  .left-container {
    display: flex;
    flex-direction: column;
    .assignment-stats {
      display: grid;
      grid-template-columns: 4fr 3fr;
      gap: 0 10px;
      .stats-label {
        color: #999;
      }
    }
  }
}
.ant-drawer-header {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.select-option-container {
  width: 100%;
  .select-option-label {
    margin-bottom: 5px;
  }
  .select {
    font-size: 12px;
    font-weight: 12px;
    height: 35px;
    width: 100%;
    border-radius: 5px;
  }
  .options {
    font-size: 12px;
    font-weight: 12px;
  }
}
.daysoff-label {
  width: 19px;
  height: 19px;
  background-color: rgb(255, 159, 1);
  border: 1px solid rgb(255, 159, 1);
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-left: -3px;
}
.mini-calendar-note {
  border-radius: 5px;
  background-color: #eef0f4;
}

.drawer-calendar {
  border: 2px solid #dadada;
  outline: none;
  border-color: #fbf8f8;
  box-shadow: 0 0 10px #fbf8f8;
  border-radius: 5px;
}
.drawer-footer {
  float: right;
  position: fixed;
  bottom: 1em;
  /* top: 0.2em; */
  right: 2em;
}
.ant-switch-inner {
  display: flex;
  align-items: center;
}
.lesson-wrapper {
  background-color: white;
  height: 100%;
}

.lesson-form-container {
  padding: 0 10px;
  position: relative;
  background-color: white;

  .lesson-header-react {
    .ant-page-header {
      padding: 7px 1px !important;
    }
    .ant-page-header-back {
      margin-right: 10px !important;
    }
    .ant-page-header-heading-title {
      font-weight: 500;
    }
  }
  .lesson-heading {
    width: 100%;
    // padding: 10px 0;
    margin: 0;
    font-size: 17px;
  }
  .ant-tag {
    text-transform: uppercase;
    padding: 3px 13px;
  }
  .ant-collapse {
    margin-top: 0.3em;
    margin-bottom: 1rem;
    .ant-collapse-header {
      background-color: #eef0f4;
    }
  }
  .ant-tabs {
    margin-bottom: 3rem;
  }
  .ant-form-item-label {
    label {
      font-weight: 500;
    }
  }

  .lesson-form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1em 1.1em;
    position: fixed;
    height: 52px;
    bottom: 0.2em;
    left: 0;
    right: 0.2em;
    border: 1px solid #ccc;
    background: white;
    z-index: 2;
    border-radius: 4px;
  }
}
.hidden-mask {
  display: none;
}
.RichEditor-root {
  z-index: 101;
  .RichEditor-menubar {
    z-index: 101;
  }
}
.editor-mask {
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  //background-color: rgb(168, 194, 168);
  cursor: pointer;
  z-index: 100;
}

.lesson-form-tabs {
  height: auto;
  overflow-y: auto;
  padding-bottom: 10px;
  margin: 10px 0;

  .assignment-controls {
    display: grid;
    grid-template-columns: 66.6% 11.2% 11% 11%;
    margin-bottom: 15px;

    .all-graded {
      justify-self: center;
      margin-left: 21px;
    }
  }
}
.lesson-tab-container {
  .ant-tabs-content-holder {
    background: white;
    margin-bottom: 60px;
  }
}
.daily-note-container {
  display: grid;
  grid-template-columns: 1fr 1fr 6fr;
  margin: 15px 0px;
  border-radius: 4px;
  height: 150px;
  .ReactVirtualized__List {
    padding-right: 10px;
  }
  &:hover {
    background-color: #fafafa;
  }
  // border-bottom: 1px solid #ccc;
  .daily-note-title {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 100px;
    overflow-y: auto;
    padding: 10px;
    word-break: break-all;
    background-color: white;
    &:hover {
      cursor: pointer;
      border-color: @primary-color!important;
    }
  }
}

.react-lesson-files {
  display: flex;
  flex-direction: row;

  .uploaded-files {
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 125px;
    height: 130px;
    text-align: center;
    margin: 5px 5px;
    position: relative;
    &:before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000080;
      opacity: 0;
      transition: all 0.3s;
      content: " ";
    }
    &:hover {
      svg {
        fill: white;
        z-index: 2;
      }
    }
    &:hover:before {
      opacity: 1;
    }

    .uploaded-file-actions {
      z-index: 2;
      position: absolute;
      bottom: 2px;
      display: flex;
      width: 100%;
      justify-content: space-around;
      padding: 0 10px;
      .upload-files-del {
        cursor: pointer;
        position: relative;
        left: unset;
        bottom: unset;
        right: unset;
        svg {
          &:hover {
            fill: red;
          }
        }
      }

      .upload-files-preview {
        cursor: pointer;
        position: relative;
        left: unset;
        bottom: unset;
        right: unset;
        svg {
          &:hover {
            //fill: rgb(48, 47, 47);
          }
        }
      }
    }
  }
}

.event-show-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  .uploaded-files {
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 125px;
    height: 130px;
    text-align: center;
    margin: 5px 5px;
    position: relative;
    &:before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000080;
      opacity: 0;
      transition: all 0.3s;
      content: " ";
    }
    &:hover {
      svg {
        fill: white;
        z-index: 2;
      }
    }
    &:hover:before {
      opacity: 1;
    }

    .uploaded-file-actions {
      z-index: 2;
      position: absolute;
      bottom: 50px;
      display: flex;
      width: 100%;
      justify-content: space-around;
      padding: 0 10px;
      .upload-files-del {
        cursor: pointer;
        position: relative;
        left: unset;
        bottom: unset;
        right: unset;
        svg {
          &:hover {
            fill: red;
          }
        }
      }

      .upload-files-preview {
        cursor: pointer;
        position: relative;
        left: unset;
        bottom: unset;
        right: unset;
        svg {
          &:hover {
            //fill: rgb(48, 47, 47);
          }
        }
      }
    }
  }
}

.lesson-assignment-disabled {
  background-color: #f5f5f5;
}

.lesson-assignments-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr 1fr 1fr 1fr;
  margin: 7px 0;
  height: 100px;
  padding: 10px 2px;
  &:hover {
    //background-color: #f8f9fb;
    background-color: #fafafa;
    border-radius: 4px;
  }
  .lesson-dropDown {
    justify-content: center;
    display: flex;
    .margin-left {
      margin-left: 10px;
      &:hover {
        svg {
          fill: @primary-color;
        }
      }
    }
  }
  .react-lesson-title {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 4rem;
    max-height: 8rem;
    overflow-y: auto;
    padding: 6px;
    outline-color: #116682;
    word-break: break-all;
    width: 100;
    border: 1px solid #ccc;
    position: relative;
    .edit-title {
      display: none;
      position: absolute;
      top: -4px;
      right: 1px;
    }
    &:focus {
      outline: 1px solid #116682;
      border-radius: 4px;
    }
    &:hover {
      border: 1px solid #116682;
      border-radius: 4px;
      cursor: text;
      border-color: @primary-color!important;

      .edit-title {
        display: block;
        svg {
          fill: @primary-color;
        }
      }
    }
  }
}

.select-assignment-types {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-assignment-remove {
    font-size: 12px;
    opacity: 0.8;
    // z-index: 2;
    &:hover {
      font-size: 13px;
      cursor: pointer;
      svg {
        fill: red;
      }
    }
  }
}
.ant-select-selection-item {
  .select-assignment-remove {
    display: none;
  }
}
.list-week {
  justify-self: center;
  font-weight: bold;
}
.hide-week {
  visibility: hidden;
}
.lesson-resources-tab {
  .ant-card {
    .ant-card-body {
      padding: 0 !important;
    }
  }
}

.book-resource-react {
  height: 120px;
  //width: 360px;
  //border: 1px solid #ccc;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  grid-template-rows: repeat(2, 1fr);

  grid-template-areas:
    "image title delete"
    "image select select";
  .resource-image {
    grid-area: image;
    img {
      width: 100%;
      height: 120px;
      border-radius: 4px 0px 0px 4px;
    }
  }
  .resource-book-title {
    padding-left: 10px;
    grid-area: title;
    color: #116682;
    font-size: 15px;
    font-weight: 800;
    p {
      margin: 0 !important;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-box-orient: vertical;
    }
  }
  .resource-book-select {
    padding: 10px;
    grid-area: select;
    .book-range-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      .from-to-range {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
      }
    }
  }
  .resource-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
  }
  .resource-delete-icon {
    svg {
      &:hover {
        fill: red;
      }
    }
  }
  .resource-edit-icon {
    svg {
      &:hover {
        fill: @primary-color;
      }
    }
  }
}
.grade-configuration-tab {
  margin: 15px 15px 30px 15px;

  .lesson-grading-header {
    padding-bottom: 2rem;
  }

  .lesson-grading-percentage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    padding: 10px 0;
    h4 {
      margin-right: 1rem;
    }
  }

  .grade-confg-table {
    margin: 0 auto;
    width: 600px;
    .grading-category {
      text-transform: capitalize;
      margin-left: 10px;
    }
  }
  .total-grade {
    color: green;
  }
  .less-than-grade {
    color: red;
  }
  .greater-than-grade {
    color: rgb(155, 108, 22);
  }
}

/// auto populate design

.label{
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-weight: 500;
  padding: 0px !important;
  white-space: nowrap;
}

.custom-populate-fields {
  display: grid;
  grid-template-columns: .5fr 1.5fr 4fr 1.5fr 1fr 1fr;
  column-gap: 10px;
  margin: 10px 10px;
  label{
    font-weight: 500;
  }
  .weekly-icrement{
    width: 140px;
  }
  .custom-input {
    .ant-input-group {
      display: flex;
      align-content: center;
    }
    .ant-input-group-addon {
      width: 40%;
      padding: 0;
      border: none;
      margin-right: 10px;
    }
    .ant-input {
      border-radius: 4px;
    }
  }
  .custom-checkbox {
    align-self: center;
    justify-self: center;
  }
}
.daily-radio{
  margin-bottom: 1em;
}
.assignment-type-border{
  .ant-select .ant-select-selector{
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    }
  }

.custom-daily-container{
  display: flex;
  align-items: bottom;
  gap: 2%;
  }
  .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-checkbox-wrapper{
    height: 28px;
  }
  .flex-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .toggle-wrapper{
      height: 53%;
    }
}

.all-day-populate {
  .ant-input-group {
    display: flex;
    align-content: center;
  }
  .ant-input-group-addon {
    width: 40%;
    padding: 0;
    border: none;
    margin-right: 10px;
  }
  .ant-input {
    border-radius: 4px;
  }
}
.red-border {
  .ant-input-group-addon {
    border: 1px solid red;

    .ant-input {
      color: red;
      &:focus {
        border: none;
      }
    }
  }
}
.all-day-populate {
  .ant-input-group-addon {
    width: 25%;
    padding: 0;
  }
}
.ant-modal-centered,
.ant-modal {
  vertical-align: initial !important;
}
// @media only screen and (min-width: 1500px) {
//   .all-graded {
//     padding-left: 11.7%;
//   }
// }

// @media only screen and (max-width: 1450px) {
//   .all-graded {
//     padding-left: 7.7%;
//   }
// }

.uploader-container {
  border: 1px dashed #ccc;
  border-radius: 5px;
  margin: 10px 15px;
  height: 115px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  padding: 10px;
  .upload-inner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
      width: 46px;
      height: 46px;
      fill: @primary-color;
    }
  }
  span {
    font-size: 18px;
  }
}
#upload {
  opacity: 0;
  z-index: -1;
  position: absolute;
  left: 0;
}
.uploader-container:hover {
  border-color: @primary-color;
}
.files-list {
  margin-top: 20px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.event-files-list {
  margin-top: 20px;
}

.group-lesson-assign-students {
  align-items: center;
  flex-wrap: wrap;
  .ant-avatar:not(:first-child) {
    margin-left: 0;
  }
  .ant-avatar {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .student-avatar-inactive {
    height: 48.89px;
    width: 48.89px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .student-avatar-active {
    border: 3px solid @primary-color;
    height: 48.89px;
    width: 48.89px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
    .ant-avatar {
      width: 100%;
      height: 100%;
    }
  }
}

.student-list-card {
  margin-top: 100px;
  .student-select-card {
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px 2px 0 #e2e5e8, -2px -2px 2px 0 #e2e5e8;
    .student-label {
      display: grid;
      grid-template-columns: 41% auto;
      font-size: 16px;
      .label-title {
        font-size: 16px;
        font-weight: 500;
        color: #555;
      }
      .student-capital {
        text-transform: capitalize;
      }
    }
  }
}

// range auto fill
.range-autofill-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 10px;
  label {
    display: block;
    font-weight: 500;
    span {
      margin-right: 6px;
    }
  }
}

.auto-populate-helping-text {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.6em;
  p {
    width: 83%;
  }
  .play-info-svg {
    width: 17%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    svg {
      width: 25px;
      height: 25px;
      margin-left: 10px;
      &:hover {
        fill: @primary-color;
      }
    }
  }
}

.backdrop-lesson {
  background-color: rgb(225 225 225 / 15%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

.ReactVirtualized__Grid {
  will-change: unset !important;
}
// .rich-texteditor-ckeditor {
//   height: 100%;
//   border: 1px solid black;
// }

.notepad-editor {
  .rich-texteditor-ckeditor {
    height: 200px;
  }
}
.lesson-form-calendar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .lesson-drawer-title {
    display: flex;
    align-items: center;
    span {
      font-weight: 600;
    }
  }
}
.all-day-populate-grid {
  display: flex;
  justify-content: space-between;

  .all-day-populate-checkbox {
    align-self: center;
    justify-self: center;
  }
}
.Icon-container{
  height: 35px;
}
.sub-assignment-populate-container {
  h4 {
    margin: 10px 0;
    font-size: 15px;
  }
  .sub-assignment-populate {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 2fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
    .sub-assignment-grading-enable {
      justify-self: center;
    }
    .sub-assignment-minus-btn {
      justify-self: center;
    }
  }
}

//Draft Lessons

.draft-badge {
  margin-right: 25px;
}
.lesson-draft-button {
  display: flex;
  align-items: center;
  color: @primary-color;
  cursor: pointer;
  .draft-icon {
    width: 20px;
    height: 20px;
    align-items: center;
    cursor: pointer;
  }
  .draft-button-text {
    height: 22px;
    width: 49px;
    color: #106682;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 5px 15px 5px 5px;
    text-align: center;
    cursor: pointer;
  }
}
.draft-lessons-drawer {
  .drawer-text {
    border-radius: 5px;
    background-color: #eef0f4;
    padding: 5px 5px 5px 5px;
  }
  .draft-subject-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .draft-lesson-list {
    overflow-y: scroll;
    height: 90%;
    margin-top: 10px;
    .draft-lesson-list-item {
      margin-top: 10px;
      .card-container {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-area: 0 5px;
        .draft-lesson-detail {
          .lesson-name {
            display: grid;
            grid-template-columns: 1fr 11fr;
          }
          .lesson-duration {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        .draft-lesson-date-container {
          .draft-lesson-date {
            margin-top: 10px;
            color: #999;
          }
          .draft-lesson-actions {
            display: none;
            .delete-icon {
              &:hover {
                g {
                  fill: red;
                }
              }
            }
          }
        }
      }
      &:hover {
        .draft-lesson-actions {
          display: flex !important;
          gap: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
.confirmation-modal-header {
  display: flex;
  align-items: center;
  .warning-icon {
    color: #fa9917;
    margin-right: 5px;
  }
}
.save-to-draft-btn {
  width: 170px;
  border-radius: 5px;
  background-color: rgba(16, 102, 130, 0.1);
  color: @primary-color;
  float: left;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}
.adhoc-assignment-container {
  margin-top: 20px;
  .adhoc-assignment-item {
    margin-top: 10px;
  }
}
.adhoc-student {
  margin-left: 5px;
}
.adhoc-header-container {
  display: grid;
  grid-template-columns: 2fr 4fr 4fr 1fr 1fr;
  gap: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 5px;
}

.not-available {
  color: #d3d3d3;
  font-style: italic;
}
.adhoc-header {
  font-size: 12px;
  font-weight: bold;
}

.adhoc-assignment-detail {
  &:hover {
    background-color: rgba(117, 117, 117, 0.06);
  }
  .adhoc-container {
    display: grid;
    grid-template-columns: 2fr 4fr 4fr 1fr 1fr;
    align-items: center;
    margin-top: 5px;
    .adhoc-student {
      margin-top: 10px;
    }
    .adhoc-assignment-type {
      text-transform: capitalize;
    }
    .assignment-title-description {
      // display: -webkit-box !important;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      .media {
        width: 50%;
      }
    }
  }
}

.adhoc-title-tooltip {
  max-width: 20% !important;
  max-height: 300px;
  min-height: auto;
  overflow-y: scroll;
}

.adhoc-divider {
  margin: 10px 0;
}
.tooltip-Row {
  display: grid;
  grid-template-columns: 16% 44% 20% 5%;
  gap: 5px;
  margin-top: 10px;
  width: 95%;
  .ant-avatar {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    .ant-avatar-string {
      position: absolute;
      left: 50%;
      transform-origin: 0 center;
      bottom: -4px;
    }
  }
  .toolPercent {
    border-radius: 10px;
    background-color: #f1f2f5;
    width: 50px;
    padding: 1px;
    text-align: center;
  }
}
.adhoc-body {
  height: 500px;
  overflow-y: scroll;
}
.empty-adhoc {
  margin-top: 150px;
}

.custom-grading-list {
  .ant-spin-nested-loading {
    max-height: 340px;
    overflow-y: scroll;
  }
}

.assignment-type-list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.assignment-type-list {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  .assignment-type-add {
    justify-self: center;
  }
  .assignment-type-name {
    .default-type {
      margin-right: 10px;
    }
    text-transform: capitalize;
  }
  .assignment-type-list-delete {
    justify-self: center;
  }

  .assignment-type-actions {
    justify-self: flex-end;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .ant-tag {
      margin: 0;
    }

    div {
      margin-left: 10px;
    }
  }
}

.lesson-additional-features {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lesson-grading-body {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: row;

  .lesson-grading-table {
    min-width: 500px;
    max-width: 700px;
    margin: 1rem 2rem;
  }

  .grading-tab-list {
    margin-top: 4rem;
    width: 400px;
    .ant-collapse-header {
      background: #fff;

      color: #116682;
    }
  }
}
/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__canvas {
  width:100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

#lesson-plan-student-multi-select {
  .ant-select-selector {
    overflow-x: auto !important;
    cursor: pointer;
    .ant-select-selection-overflow {
      flex-wrap: nowrap !important;
    }
  }
}

@media screen and (max-width: 780px) {
  .lesson-grading-body {
    flex-direction: column !important;
    align-items: center;
  }
  .lesson-grading-table {
    width: 700px;
  }
  .grading-tab-list {
    width: 700px;
  }
}

.preview-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(16, 102, 130, 0.1) !important;
  color: #106682 !important;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  text-shadow: none;
  box-shadow: none;
  padding: 6px 10px;
  &:hover {
    background-color: rgba(5, 115, 151, 0.1) !important;
    color: #fff !important;
    text-decoration: none !important;
    border: 1px solid #106682 !important;
    outline: none !important;
  }
  &:focus {
    outline: none !important;
    border: none !important;
  }
  &:not(:hover) {
    border: 1px solid rgba(16, 102, 130, 0.1) !important;
    text-decoration: none !important;
    outline: none !important;
  }
}

.ap-btn-wrapper{
  position: relative;
  .enahanced-icon {
    position: absolute;
    right: -8px;
    bottom: -10px;
  }
}