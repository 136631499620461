.rich-texteditor-ckeditor {
  height: 120px;
  overflow: auto;
  border-radius: 4px;
  overflow: auto;
  border: none;
  box-shadow: inset 0 1px 8px -3px #ababab;
  .ck-editor__editable_inline {
    height: 100%;
  }
  .ck-focused {
    border: none !important;
    background-color: white !important;
    box-shadow: inset 0 1px 8px -3px #ababab !important;
  }
  .ck-blurred {
    background-color: white !important;
  }
}
