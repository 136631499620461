.db-wrapper {

}
.db-padding-btm {
	padding-bottom: 50px;
}


.db-attached > .col-lg-4,
.db-attached > .col-lg-3,
.db-attached > .col-md-4,
.db-attached > .col-md-3,
.db-attached > .col-sm-4,
.db-attached > .col-sm-3 {
	padding-left: 0;
	padding-right: 0;
}


.payment-main-heading {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 65px;
	margin-top: 40px;
	font-family: 'Montserrat', sans-serif;
	color: #696969;
	span {
		color: #116682;
		font-weight: 500;
	}
}

.payment-trial {

	display: flex;
	flex-direction: row-reverse;

	.payment-upgrade {

		.payment-upgrade-btn {
			//width: 100px;
			color: #fff;
			border: 1px solid #fff;
			background-color: #116682;
			font-weight: 900 !important;
			border-radius: 25px !important;
			margin-left: 5%;

			&:hover {
				background-color: #fff;
				color: #116682;
				border: 1px solid #116682;
			}
		}
	}

	.payment-upgrade-text-div {

		line-height: 35px;

		.trial-info-text{
			text-decoration: none;
			color: #fff;
			font-size: 13px;
		}

		.trial-info-text:hover{
			color: #fff !important;
		}

		.trial-info-text:visited {
			color: #ffffff;
		}
	}



}


.payment-card-view-button {

	margin-top: 25px;
	margin-bottom: 30px;

	.btn-select-plan {
		border-radius: 25px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		text-transform: none;
		font-weight: 400;
		font-size: 12px;
	}
	.btn-payment-method {
		border-radius: 25px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		text-transform: none;
		font-weight: 400;
		font-size: 12px;
	}
	.btn-middle-plan {
		border-radius: 0px;
		text-transform: none;
		font-weight: 400;
		font-size: 12px;
	}
	.not-selected {
		background-color: #ffffff;
		color: #777777;;
		border-color: #b2b5bb;
	}

}



.payment-secondary-heading {
	font-size: 25px;
	font-weight: 600;
	margin-bottom: 20px;
	margin-top: 30px;
}
.payment-secondary-thank{
	font-size: 36px;
	font-weight: 300;
	color: #7cb342;
	margin-bottom: 20px;
}
.payment-success-msg{
	font-size: 18px;
	font-weight: 500;
	color:#343d44;
	width: 75%;
	.primary-color{
		padding-left: 5px;
		padding-right: 5px;
		color: $primary-color;
	}
}
.payment-renewal-msg{
	color:#626264;
	.primary-color{
		padding-left: 5px;
		padding-right: 5px;
		color: $primary-color;
	}
}
.payment-btn{
	margin-top: 20px;
	border-radius: 2px !important;
}
.zoho-help-text{
	text-align: center;
	font-size: 13px;
	cursor: pointer;
}
.payment-failed{
	color:#ff6245;
}
.icon-zoho{
	width: 30px;
	height: 30px;
	position: relative;
	top: 9px;
	margin-left: 10px;
}
.payment-card {
	padding: 0px;

	.plan-button {
		font-size: 16px;
		background-color: #116682;
		border-color: #116682;
		border-radius: 4px;
		width: 130px;
		font-weight: bold;
		-moz-box-shadow: 0px 2px 3px 1px #ccc;
		-webkit-box-shadow: 0px 2px 3px 1px #ccc;
		box-shadow: 0px 2px 3px 1px #ccc;
	}

	.plan-type-btn {
		color: #116682;
		padding: 20px 20px 10px 20px;
		font-weight: normal;
		font-size: 24px;
	}
}

.plan-detail {
	width: 347px;
	border: 1px solid #e9e9e9;
	box-shadow: 0 0 5px rgba(0, 0, 0, .5);
	height: 200px;
	border-radius: 10px;
	margin-bottom: 14px;
	font-weight: 600;
	color: #666;
	padding-top: 90px;
}


.db-wrapper-light {
	margin: 10px;
	border-radius: 8px;

	.db-pricing-eleven {
		margin-bottom: 30px;
		margin-top: 50px;
		text-align: center;
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		color: #fff;
		border-radius: 7px;

		.features-heading h3 {
			font-size: 23px;
			color: #58585a;
			font-weight: 500;
			margin-bottom: 15px;
		}
	}

	.db-pricing-eleven ul {
		list-style: none;
		margin: 0;
		text-align: center;
		padding-left: 0px;
		color : #929497;
		margin-bottom: 30px;
		padding-bottom: 20px;
	}

	.db-pricing-eleven ul li {
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 16px;
		font-weight: 300;
	}

	.db-pricing-eleven .price {
		padding: 20px 20px 20px 20px;
		font-size: 60px;
		font-weight: 400;
		line-height: 1;
		margin-bottom: 20px;
		color: #116682;
	}

	.db-pricing-eleven .price small {
		color: #929497;
		display: block;
		font-size: 16px;
		// margin-top: 22px;
	}

	.db-pricing-eleven .type {
		color: #116682;
		padding: 20px 20px 10px 20px;
		font-weight: 600;
		font-size: 22px;
	}

	.db-pricing-eleven .border-top {
		border-top:2px solid #e3e3e3;
		padding-bottom: 15px;
	}

	.db-button-color-square {
		width: 300px;
		color: #fff;
		background-color: #116682;
		font-weight: 900 !important;
		text-transform: uppercase;
		border-radius: 7px !important;
		border: 1px solid #116682;
	}

	.db-button-color-square:hover {
		color: #116682 !important;
		background-color: #FFF;
		border: 1px solid #116682;
	}

}

.db-wrapper-light:hover {
	margin: 10px;
	border-radius: 35px;

	.db-pricing-eleven {
		margin-bottom: 30px;
		margin-top: 50px;
		text-align: center;
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		color: #fff;
		border-radius: 7px;
		background-color: #116682;
		margin-top: 0px;

		.features-heading h3 {
			font-size: 23px;
			color: #e7e7e8;
			font-weight: 500;
			margin-bottom: 15px;
		}
	}

	.db-pricing-eleven ul {
		list-style: none;
		margin: 0;
		text-align: center;
		padding-left: 0px;
		color : #b2b5bb;
		margin-bottom: 30px;
		padding-bottom: 15px;
	}

	.db-pricing-eleven ul li {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 16px;
	}

	.db-pricing-eleven .price {
		padding: 20px 20px 40px 20px;
		font-size: 80px;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 20px;
		line-height: 1;
		.dollar-sign-size {
			top: -1.01em;
		}
	}

	.db-pricing-eleven .price small {
		color: #b2b5bb;
		display: block;
		font-size: 16px;
		// margin-top: 22px;
	}

	.db-pricing-eleven .type {
		color: #FFFFFF;
		padding: 15px 20px;
		font-weight: 600;
		font-size: 20px;
	}

	.db-pricing-eleven .border-top {
		border-top:2px solid #4e8da2;
		padding-bottom: 15px;
	}

	.db-button-color-square {
		width: 300px;
		color: #116682;
		background-color: #fff;
		font-weight: 900 !important;
		text-transform: uppercase;
		border-radius: 7px !important;
		border: 1px solid #116682;
	}

	.db-button-color-square:hover {
		color: #FFF !important;
		background-color: #116682;
		border: 1px solid #FFF;
	}

}


.db-wrapper-themetic {

	margin: 10px;
	border-radius: 35px;

	.db-pricing-eleven {
		margin-bottom: 30px;
		margin-top: 50px;
		text-align: center;
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		color: #fff;
		border-radius: 7px;
		background-color: #116682;
		margin-top: 0px;

		.features-heading h3 {
			font-size: 23px;
			color: #e7e7e8;
			font-weight: 500;
			margin-bottom: 15px;
		}
	}

	.db-pricing-eleven ul {
		list-style: none;
		margin: 0;
		text-align: center;
		padding-left: 0px;
		color : #b2b5bb;
		margin-bottom: 30px;
		padding-bottom: 15px;
	}

	.db-pricing-eleven ul li {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 16px;
	}

	.db-pricing-eleven .price {
		padding: 20px 20px 40px 20px;
		font-size: 80px;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 20px;
		line-height: 1;
		.dollar-sign-size {
			top: -1.01em;
		}
	}

	.db-pricing-eleven .price small {
		color: #b2b5bb;
		display: block;
		font-size: 16px;
		// margin-top: 22px;
	}

	.db-pricing-eleven .type {
		color: #FFFFFF;
		padding: 15px 20px;
		font-weight: 600;
		font-size: 20px;
	}

	.db-pricing-eleven .border-top {
		border-top:2px solid #4e8da2;
		padding-bottom: 15px;
	}

	.db-button-color-square {
		width: 300px;
		color: #116682;
		background-color: #fff;
		font-weight: 900 !important;
		text-transform: uppercase;
		border-radius: 7px !important;
		border: 1px solid #116682;
	}

	.db-button-color-square:hover {
		color: #FFF !important;
		background-color: #116682;
		border: 1px solid #FFF;
	}

}

.db-wrapper-themetic-plan-two {

	margin: 10px;
	border-radius: 35px;

	.db-pricing-eleven {
		margin-bottom: 30px;
		margin-top: 50px;
		text-align: center;
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		color: #fff;
		border-radius: 7px;
		background-color: #ffffff;
		margin-top: 0px;

		.features-heading h3 {
			font-size: 23px;
			color: #58585a;
			font-weight: 500;
			margin-bottom: 15px;
		}
	}

	.db-pricing-eleven ul {
		list-style: none;
		margin: 0;
		text-align: center;
		padding-left: 0px;
		color : #929497;
		margin-bottom: 30px;
		padding-bottom: 15px;
	}

	.db-pricing-eleven ul li {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 16px;
	}

	.db-pricing-eleven .price {
		padding: 20px 20px 40px 20px;
		font-size: 80px;
		font-weight: 400;
		color: #116682;
		margin-top: 20px;
		line-height: 1;
		.dollar-sign-size {
			top: -1.01em;
		}
	}

	.db-pricing-eleven .price small {
		color: #b2b5bb;
		display: block;
		font-size: 16px;
		// margin-top: 22px;
	}

	.db-pricing-eleven .type {
		color: #FFFFFF;
		padding: 15px 20px;
		font-weight: 600;
		font-size: 20px;
	}

	.db-pricing-eleven .border-top {
		border-top: 2px solid #e3e3e3;
		padding-bottom: 15px;
	}

	.db-button-color-square {
		width: 300px;
		color: #116682;
		background-color: #fff;
		font-weight: 900 !important;
		text-transform: uppercase;
		border-radius: 7px !important;
		border: 1px solid #116682;
	}

	.db-button-color-square:hover {
		color: #FFF !important;
		background-color: #116682;
		border: 1px solid #FFF;
	}

}


.db-wrapper-themetic-plan-two:hover {

	margin: 10px;
	border-radius: 35px;

	.db-pricing-eleven {
		margin-bottom: 30px;
		margin-top: 50px;
		text-align: center;
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		color: #fff;
		border-radius: 7px;
		background-color: #116682;
		margin-top: 0px;

		.features-heading h3 {
			font-size: 23px;
			color: #e7e7e8;
			font-weight: 500;
			margin-bottom: 15px;
		}
	}

	.db-pricing-eleven ul {
		list-style: none;
		margin: 0;
		text-align: center;
		padding-left: 0px;
		color : #b2b5bb;
		margin-bottom: 30px;
		padding-bottom: 15px;
	}

	.db-pricing-eleven ul li {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 16px;
	}

	.db-pricing-eleven .price {
		padding: 20px 20px 40px 20px;
		font-size: 80px;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 20px;
		line-height: 1;
		.dollar-sign-size {
			top: -1.01em;
		}
	}

	.db-pricing-eleven .price small {
		color: #b2b5bb;
		display: block;
		font-size: 16px;
		// margin-top: 22px;
	}

	.db-pricing-eleven .type {
		color: #FFFFFF;
		padding: 15px 20px;
		font-weight: 600;
		font-size: 20px;
	}

	.db-pricing-eleven .border-top {
		border-top:2px solid #4e8da2;
		padding-bottom: 15px;
	}

	.db-button-color-square {
		width: 300px;
		color: #116682;
		background-color: #fff;
		font-weight: 900 !important;
		text-transform: uppercase;
		border-radius: 7px !important;
		border: 1px solid #116682;
	}

	.db-button-color-square:hover {
		color: #FFF !important;
		background-color: #116682;
		border: 1px solid #FFF;
	}

}







.pricing-footer {
	text-align: center;
	padding-bottom: 30px;
}


.db-pricing-eleven.popular {
	margin-top: 10px;
}

.db-pricing-eleven.popular .price {
	padding-top: 80px;
}

.mid-plan {
	background-color: #116682;
}

.mid-plan-price {
	color: #FFFFFF;
	display: block;
	font-size: 12px;
	margin-top: 22px;
}

.payment-container-top {
    position: fixed;
    top: 74px;
    height: 54px;
    width: calc(100% - 260px);
    z-index: 10;
	box-shadow: 0 8px 6px -6px #00000038;
	background-color: white;
}

.subscribe-confirm-container {
	//width: 650px;
	// background-color: #d8d5d5;
	//height: 460px;
	margin: 0 auto;
	margin-top: 30px;
	border-radius: 10px;
	// padding-top: 50px;
}


.plan-title {
	// text-align: center;
	color: #fff;
	background-color: #116682;
	padding: 15px 25px 15px 25px;
	border-radius: 8px;
	max-width: 450px;
	margin: 0 auto;
	box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.plan-title h3 {
	font-size: 20px;
	display: flex;
    justify-content: center;
}

// .plan-title h3 span {
// 	display: inline-block;
// 	margin-left: 35px;
// 	font-size: 28px;
// 	font-weight: 600;
// }

.charge-monthly-switch {
	display: flex;
	max-width: 450px;
	text-align: center;
	margin: 0 auto;
	margin-top: 35px;

	p {
		display: flex;
		flex: 1;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 26px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #5a5555;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 21px;
		width: 21px;
		left: 4px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: #116682;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196F3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(25px);
		-ms-transform: translateX(25px);
		transform: translateX(25px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}

.charge-yearly-switch {
	display: flex;
	max-width: 450px;
	text-align: center;
	margin: 0 auto;
	margin-top: 35px;

	p {
		display: flex;
		flex: 1;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 26px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #5a5555;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 21px;
		width: 21px;
		left: 10px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: #116682;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196F3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(25px);
		-ms-transform: translateX(25px);
		transform: translateX(25px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}


.or-line {
	max-width: 450px;
	margin: 0 auto;
	padding-top: 35px;
	h2 { width:100%; text-align:center; border-bottom: 1px solid #000; line-height:0.1em; margin:0 0 20px; }
	h2 span { background-color: #FFF; padding:0 10px; font-size: 16px; }
}

.total-container {
	display: flex;
	justify-content: space-between;
	max-width: 450px;
	text-align: right;
	margin: 0 auto;
	margin-top: 35px;
	align-items: center;
	p {
		font-weight: 500;
		font-size: 20px;
	}
	.round-left-input {
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
	}
	.round-right-button {
		border-top-right-radius: 19px;
		border-bottom-right-radius: 19px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		font-weight: 500;
	}



	.code-input {
		width: 125px;
	}

}

.submit-btns {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	max-width: 550px;

	.cancel {
		padding: 2px 10px;
		background-color: #333;
		color: #fff;
		border: 1px solid #333;
		margin-right: 20px;
	}
}

.dollar-sign-size {
	font-size: 35px;
}

.current-plan-details {
	background: #edeeee;
	border-radius: 4px;
	padding: 15px;
    margin-left: 25px;
	margin-right: 25px;
}

.success-message {
	display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
	text-align: center;
  	min-height: 70vh;
}

.success-icon {
	width: 170px;
	height: 170px;
}


.thank-you-message {
	padding-bottom: 40px;
	padding-top: 10px;
	p {
		font-family: 'Montserrat', sans-serif;
		font-size: 26px;
		font-weight: 600;

	}
}

.hsp-features-inline {
	margin-bottom: 40px;
}

.max-width-for-plans {
	max-width: 1200px;
	margin: 0 auto !important;
	text-align: center;

	.plan-panda-logo {
		position: absolute;
		width: 100px;
		right: -11px;
		top: 98px;
	}
}


.below-plan-features {

		p {
			margin: 0;
			font-weight: 500;
			font-size: 19px;
			color: #116682;
		}
	    span {
			margin-left: 10px;
			margin-right: 10px;
			color: #231f20;
		}
}

.feature-header {

	padding: 0;
	margin: 0;
	width: 100%;
	background-color: #116682;

		h3 {
			font-size: 20px;
			background-color: #116682;
			color: #ffffff;
			line-height: 38px;
			font-weight: 500;
		}
}

.annual-cost {
	background-color: #f3f3f4;
	border: 1px solid #ccc;
	height: 100%;
	.plan-name{
		font-size: 16px;
		font-weight: 500;
		padding-top: 5px;
	}
	.plan-type {
		color: #116682;
		padding: 0px 20px 10px 10px;
		font-weight: normal;
		font-size: 22px;
	}

	.plan-price-on-monthly-plan {
		font-size: 28px;
		font-weight: 600;
		display: inline-block;
		margin-top: 18px;
	}

	.plan-price-on-yearly-plan {
		font-size: 28px;
		font-weight: 600;
		display: inline-block;
		margin-top: 18px
	}

	.save-annually-gif{
		position: absolute;
    	width: 120px;
    	bottom: 21px;
    	right: -65px;
	}



	.yearly-price {
		span {
			font-size: 24px;
			color: #116682;
		}
		.plan-price-on-monthly-plan {
			font-size: 24px;
			font-weight: 600;
			padding-bottom: 10px;
			display: inline-block;
		}

		.plan-price-on-yearly-plan {
			font-size: 24px;
			font-weight: 600;
			padding-bottom: 10px;
			display: inline-block;
			margin-left: 10px
		}

		.strike-through {
			text-decoration: line-through;
			text-decoration-color: #f00;
		}
	}



}


.monthly-yearly {
	.payment-card-view-button {

		margin-top: 65px;
		margin-bottom: 40px;

		.btn-select-plan {
			border-radius: 25px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			text-transform: none;
			font-weight: 400;
			font-size: 12px;
			width: 180px;
		}
		.btn-payment-method {
			border-radius: 25px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			text-transform: none;
			font-weight: 400;
			font-size: 12px;
			width: 180px;
		}
		.btn-middle-plan {
			border-radius: 0px;
			text-transform: none;
			font-weight: 400;
			font-size: 12px;
		}
		.not-selected {
			background-color: #ffffff;
			color: #777777;;
			border-color: #b2b5bb;
		}

		.save-18 {
			position: absolute;
			bottom: 10px;
			width: 100px;
		}

		.monthly-not-selected{
			background-color: #ffffff;
			color: #777777;
			border-color: #b2b5bb;
		}

		.monthly-selected{
			background-color: #116682;
			color: #ffffff;
			border-color: #116682;
		}

		.six-monthly-not-selected {
			background-color: #ffffff;
			color: #777777;
			border-color: #b2b5bb;
		}

		.six-monthly-selected {
			background-color: #116682;
			color: #ffffff;
			border-color: #116682;
		}

		.yearly-not-selected{
			background-color: #ffffff;
			color: #777777;
			border-color: #b2b5bb;
		}

		.yearly-selected{
			background-color: #116682;
			color: #ffffff;
			border-color: #116682;
		}
	}
}
.subscribe-button-card {

	margin-top: 30px;
	margin-bottom: 20px;

	.btn-subscibe-plan {
		padding: 8px 30px;
		background-color: #116682;
		border-color: #116682;
		border-radius: 4px;
		width: 300px;
		font-weight: 400;
		font-size: 14px;
		-moz-box-shadow: 0px 2px 3px 1px #ccc;
		-webkit-box-shadow: 0px 2px 3px 1px #ccc;
		box-shadow: 0px 2px 3px 1px #ccc;
		// margin-right: 40px;
	}

	.btn-have-questions{
		padding: 8px 5px !important;
		background-color: #ffffff;
		color: #116682;
		border-color: #116682;
		border-radius: 4px;
		width: 300px;
		font-weight: 300;
		font-size: 13px;
		-moz-box-shadow: 0px 2px 3px 1px #ccc;
		-webkit-box-shadow: 0px 2px 3px 1px #ccc;
		box-shadow: 0px 2px 3px 1px #ccc;
	}

}

.referral-message {

	h3 {
	font-family: 'Montserrat', sans-serif;
	font-size: 26px;
	font-weight: 600;
    }
	span {
		color: #116682;
	}
	p {
		font-weight: normal;
		font-size: 16px;
		display: inline-block;
		max-width: 1200px;
		margin-top: 30px;
		margin-bottom: 30px;
		padding-left: 20px;
		padding-right: 20px;
	}

}

.main-content {
	font-family: 'Montserrat', sans-serif;
	.payment-card-meothds-one {
		border-right: 1px solid #ccc !important;
		// padding: 0% 3%;

		.section-title h3 {
			color: #116682;
			font-size: 26px;
		}

		.monthly-yearly {
			.payment-card-view-button {

				margin-top: 40px;
				margin-bottom: 40px;

				.btn-select-plan{
					border-radius: 25px;
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
					text-transform: none;
					font-weight: 400;
					font-size: 12px;
					width: 160px;
				}
				.btn-payment-method {
					border-radius: 25px;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
					text-transform: none;
					font-weight: 400;
					font-size: 12px;
					width: 160px;
				}
				.btn-middle-plan {
					border-radius: 0px;
					text-transform: none;
					font-weight: 400;
					font-size: 12px;
				}
				.not-selected {
					background-color: #ffffff;
					color: #777777;;
					border-color: #b2b5bb;
				}

				.save-18 {
					position: absolute;
					bottom: 10px;
					width: 100px;
				}
			}
		}
		.plan-name-price{
			.selected-plan-name{
				display: inline-block;
				p{
					font-size: 20px;
					padding-top: 5px;
				}
				p.change-plan{
					text-decoration: underline;
					font-weight: 600;
				}

			}
			.selected-plan-price{
				display: inline-block;
				p{
					font-weight: 600;
					font-size: 23px;
					margin-right: 50px;
					padding-top: 5px;
				}
			}
		}

		.plan-one-two-yearly-selections {
			margin-top: 20px;
			.save-yearly-message{
				p {
					span{
						font-weight: 600;
    					color: #116682;
					}
				}
				.twoyearly{
					color: #dc375d;
				}

			}
			.one-two-yearly-buttons {
				margin: 0;
				padding: 0 !important;
				text-align: end;
				.btn-select-plan{
					border-radius: 25px;
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
					text-transform: none;
					font-weight: 400;
					font-size: 11px;
					width: auto;
					padding: 8px 5px;
				}
				.btn-payment-method {
					border-radius: 25px;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
					text-transform: none;
					font-weight: 400;
					font-size: 11px;
					width: 110px;
					padding: 8px 5px;
				}
				.btn-middle-plan {
					border-radius: 0px;
					text-transform: none;
					font-weight: 400;
					font-size: 12px;
				}
				.monthly-not-selected{
					background-color: #ffffff;
					color: #777777;
					border-color: #b2b5bb;
				}

				.monthly-selected{
					background-color: #116682;
					color: #ffffff;
					border-color: #116682;
				}

				.six-monthly-not-selected {
					background-color: #ffffff;
					color: #777777;
					border-color: #b2b5bb;
				}
		
				.six-monthly-selected {
					background-color: #116682;
					color: #ffffff;
					border-color: #116682;
				}

				.yearly-not-selected{
					background-color: #ffffff;
					color: #777777;
					border-color: #b2b5bb;
				}

				.yearly-selected{
					background-color: #116682;
					color: #ffffff;
					border-color: #116682;
				}

			}
		}

		.promo-code{
			margin-top: 20px;
		}
		.sales-vat-tax {
			margin-top: 20px;

			.text-vat-text {
				p {
					font-size: 16px;
					padding-top: 10px;
				}

			}
		}

		.hr-row {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.total{
			.text-total {
				p{
					font-weight: 600;
					font-size: 23px;
					margin-right: 50px;
					padding-top: 5px;
				}
			}
			.total-price {
				p{
					font-weight: 600;
					font-size: 23px;
					margin-right: 50px;
					padding-top: 5px;
				}
			}

		}
	}
	.payment-card-meothds-two {

		padding: 0% 3%;

		.section-title h3 {
			color: #116682;
			font-size: 26px;
		}

		.goback-link {
			text-transform: capitalize;
			color: #116682;
			text-decoration: underline;
			font-weight: 300;
			font-size: 16px;
			padding-top: 10px;
			padding-left: 0px;
		}
		.subscribe-button {
			color: #fff;
			background-color: #116682;
			padding: 15px 25px 15px 25px;
			border-radius: 8px;
			width: 170px;
			margin: 0 auto;
			box-shadow: 0 0 5px rgba(0,0,0,0.3);
		}

		.subscribe-button:hover {
			border-color: #116682;
		}



		.policy-accept-checkbox-container {

			margin-top: 40px;

			.checkbox-policy {
				display: inline-block;
			}
			.policy-text{
				display: inline-block;
				p{
					a{
						color: #555;
    					font-weight: 500;
					}
				}
			}

		}

		.payment-option-radio-amazon {
			padding-top: 20px;
			padding-bottom: 20px;
			display: flex;

			.radio-amazon {
				display: inline-block;
			}
			.logo-amazon {
				display: inline-block;
			}

			p{
				padding: 10px;
			}

			.regular-radio {
				display: none;
			}

			.regular-radio + label {
				-webkit-appearance: none;
				background-color: #ffffff;
				border: 4px solid #e1e1e1;
				border-radius: 10px;
				width: 100%;
				display: inline-block;
				position: relative;
				width: 20px;
				height: 20px;
				margin-top: 30px;
			}

			.regular-radio:checked + label {
				background: #116682;
				border: 4px solid #e1e1e1;
			}

		}

		.payment-option-radio-paypal {
			display: flex;
			padding-bottom: 20px;
			.radio-paypal {
				display: inline-block;
			}

			.logo-paypal {
				display: inline-block;
				margin-left: 15px;
			}
			p{
				padding: 10px;
			}

			.regular-radio {
				display: none;
			}

			.regular-radio + label {
				-webkit-appearance: none;
				background-color: #ffffff;
				border: 4px solid #e1e1e1;
				border-radius: 10px;
				width: 100%;
				display: inline-block;
				position: relative;
				width: 20px;
				height: 20px;
				margin-top: 10px;
			}

			.regular-radio:checked + label {
				background: #116682;
				border: 4px solid #e1e1e1;
			}
		}

		.policy-accept-checkbox-container {
			.regular-checkbox{
				zoom: 1.3;
				margin-right: 10px !important;
			}
		}




	}


	.yearly-cost-chart{
		background-color: red;
		border: 1px solid blue;
	}
	.promocode {
		display: flex;
		flex-direction: row;

		position: relative;
		.form-control{
			display: unset !important;
		}
		button{
			position: inherit;
		}
	}
	.promocode-enter {
		color: #116682;
		font-weight: bold;
		text-decoration: none;
	}

	.round-left-input {
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
	}
	.round-right-button {
		border-top-right-radius: 19px;
		border-bottom-right-radius: 19px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		font-weight: 500;
	}

}

#AmazonPayButton{
	display:none;

}


.payment-container{
	.payment-title-top{
		margin-top: 20px;

		margin-bottom: 20px;
		h3{
			font-weight: bold;
		}
	}
	.confirm-payment{
		font-family: Arial,Helvetica,"san-serif";
		border: 1px solid #bbb;
		border-radius: 6px;
		margin-bottom: 10px;
		padding: 8px 6px;
		.title-top{
			text-align: left;
			display: flex;
			align-items: center;
			.calendar-icon{
				width: 39px;
				height: 21px;
			}
			span{
				padding-left: 7px;
				color: #2f3841;
				font-weight: 700;
				font-size: 14px;
			}
		}
		.table-payment{
			padding: 15px;
			table{
				margin-top: 5px;
				width: 100%;
				border-collapse: collapse;
				tr{
					th{
						border-bottom: 1px solid #bbb;
					}
					td{
						text-align: left;
						padding-top: 5px;
						width: 33%;
					}
				}
			}
		}
	}
	#addressBookWidgetDiv{
		margin-bottom: 20px;
	}
	#walletWidgetDiv{
		margin-bottom: 20px;
	}
	#consentWidgetDiv{
		margin-bottom: 20px;
	}
	.autorize-payment{
		p{
			font-size:10px;
		}
		button{
			border-radius: 5px;
		}
		.amazon-pic{
			width: 80px;
			top: 12px;
			position: relative;
			padding-right: 10px;
		}
	}
	.credit-payment{
		font-weight: 400 !important;
		font-size: 13px !important;
		text-align: left !important;
		margin-top: 10px;
		color: $primary-color;
		margin-right: 50px;
		display: flex;
		justify-content: space-between;
		p{
			width: calc(100% - 100px);
		}
		span{
			font-size: 16px;
		}

	}
}

.promo-code-applied-section {
	
	.promo-code-applied-message{
		p{
			line-height: 39px;
    		color: #116682;
		}
		
	}
	.promo-code-minus-price {
		p {
			font-weight: 600;
    		font-size: 23px;
    		margin-right: 50px;
			padding-top: 5px;
			text-align: end;
			color: #116682;
		}
	}
}

.promo-code-row {
	margin-left: 0px !important;
	display: inline-flex;
	width: 100% !important;
}


@media not all, (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 192dpi){
.address-list li.selected a{
	background:unset !important;
}}
.address-list li.selected a{
	background:unset !important;
	//background-color:blue !important;
}
.address-list li{
	a{
		background-color:blue !important;
	}
}

.pagination li.next{
	a{
		background: black;
	}
}

@media (max-width:383px) {
	.payment-card-view-button{
		margin-top: 25px;
    	margin-bottom: 30px;
    	width: 318px;
		margin-left: -24px !important;
	}
}

@media (max-width:408px) {
	.payment-card-view-button{
		.btn-middle-plan,
		.btn-select-plan {
			width: 146px !important;
			padding: 10px 10px;
		}
		.btn-payment-method {
			width: 172px;
			padding: 10px 10px;
		}
		.save-18 {
			position: absolute;
			bottom: 22px;
			width: 60px !important;
		}
	}
}



@media (max-width:510px) {

	.plan-name-price{
		.selected-plan-name{
			display: inline-block;
			p{
				font-size: 16px !important;
				padding-top: 5px;
			}
			p.change-plan{
				text-decoration: underline;
				font-weight: 600;
			}

		}
		.selected-plan-price{
			display: inline-block;
			p{
				font-weight: 600;
				font-size: 20px !important;
				margin-right: 0px !important;
				padding-top: 5px;
			}
		}
	}

	.promo-code-applied-section{
		.promo-code-minus-price{
			display: inline-block;
			p {
				font-weight: 600;
				font-size: 20px !important;
				margin-right: 0px !important;
				padding-top: 5px;
		    }
	   }

	} 

	.promo-code-applied-section{
		.promo-code-applied-message {
			p {
			line-height: 20px !important;
			padding-top: 7px;
		  }
     	}
	} 

	.promo-code-row {
		margin-left: 0px !important;
		display: inline-flex;
		width: 100% !important;
		.promocode-input {
			width: 160px !important;
			margin-left: -10px;
			display: inline-block !important;
		}
	}

	.main-content {
		.payment-card-meothds-one {

			.total{
				.total-price {
					p {
					font-weight: 600;
					font-size: 23px;
					margin-right: 0px !important;
					padding-top: 5px;
				}
			}

			}
			.plan-one-two-yearly-selections {
				.one-two-yearly-buttons {
					.btn-select-plan {
						width: 120px !important;
						border-top-right-radius: 25px;
						border-bottom-right-radius: 25px;
						margin-bottom: 5px;
					}
					.btn-payment-method {
						width: 120px !important;
						border-top-left-radius: 25px;
    					border-bottom-left-radius: 25px;
					}
					.btn-middle-plan {
						width: 120px !important;
						margin-bottom: 5px;
					}
				}

			}

		}

		.payment-card-meothds-two {
			border-top: 1px solid #ccc;
			margin-top: 20px;
			padding-top: 20px;
			padding-bottom: 40px !important;
			clear: both;
			.policy-accept-checkbox-container {
				.policy-text{
					p{
						font-size: 14px;
					}
				}
			}
		}

	}


}




@media (max-width:769px) {
	.main-header {
		.payment-trial {
			display: none !important;

		}
	}

	.feature-header {

		h3 {
		font-size: 12px !important;
		background-color: #116682;
		color: #ffffff;
		line-height: 17px !important;
		font-weight: 500;
		text-align: left !important;
	  }
	}

	.annual-cost {
		.plan-name {
			font-size: 15px !important;
			font-weight: 500;
			padding-top: 5px;
		}

		.plan-type {
			color: #116682;
			padding: 0px 0px 0px 0px !important;
			font-weight: normal;
			font-size: 12px !important;
			margin-left: -12px !important;
		}

		.plan-price-on-monthly-plan {
			font-size: 20px !important;
			font-weight: 600;
			display: inline-block;
			margin-top: 18px;
		}
		.save-annually-gif {
			position: absolute;
			bottom: 42px;
			width: 75px !important;
			right: -31px !important;
			top: 30px !important;
		}

	}

	.max-width-for-plans {
		max-width: 1200px;
		margin: 0 auto !important;
		text-align: center;

		.plan-panda-logo {
			position: absolute;
			width: 100px;
			right: -11px;
			top: 91px !important;
		}
	}

	.subscribe-button-card {

		text-align: center;
		width: 100%;
		display: flex;
		flex-direction: column;
		.col-sm-6{
			padding-left: 0px !important;
			padding-right: 0px;
			text-align: center !important;
		}

		.btn-subscibe-plan {
			width: 270px !important;
			float: none !important;
			margin: 0 auto !important;

		}

		.btn-have-questions{
			width: 270px !important;
			margin-top: 10px !important;
			margin: 0 auto;

	   }
   }

   .monthly-yearly {
	.payment-card-view-button {
		margin-top: 50px;
    	margin-bottom: 0;
	}
}

.payment-card-meothds-one {
	.monthly-yearly {
		margin-left: -15px;
        margin-right: -15px;
	}
}

.payment-card-view-button{
	.btn-middle-plan,
	.btn-select-plan {
		// border-top-right-radius: 25px !important;
		// border-bottom-right-radius: 25px !important;
		// margin-bottom: 10px !important;
		width: 172px;
		padding: 10px 10px;
	}
	.btn-payment-method {
		// border-top-left-radius: 25px !important;
		// border-bottom-left-radius: 25px !important;
		// margin-bottom: 10px !important;
		width: 172px;
		padding: 10px 10px;
	}
}

.monthly-yearly {
	.payment-card-view-button {
		.monthly-selected {
			margin-bottom: 10px !important;
		}
		.btn-payment-method {
			margin-bottom: 10px !important;
		}
		.save-18 {
			width: 70px;
		}
	}
}
}

@media (max-width:992px) {
	.main-content{
		.payment-card-meothds-one {
			border-right: 0px solid #ccc !important;
	   }

	}
    .subscribe-button-card{

		.btn-subscibe-plan {
			margin-bottom: 0px !important;	
	   }
	}

	.annual-cost {
		.plan-name {
			font-size: 16px;
		}
		.plan-type {
			font-size: 16px !important;
		}
	}

}



@media (min-width:992px) and (max-width:1031px) {
	.main-content{
		.payment-card-meothds-one {
			border-right: 0px solid #ccc !important;
	   }

	}

	.one-two-yearly-buttons {
		.btn-middle-plan,
		.btn-select-plan{
			font-size: 9px !important;
			padding: 6px 5px !important;
		}
		.btn-payment-method {
			font-size: 9px !important;
			padding: 6px 5px !important;
		}
	}

	
}

	

@media (max-width: 361px){
.max-width-for-plans .plan-panda-logo {
    position: absolute;
    width: 100px;
    right: -11px;
    top: 122px !important;
}
}

@media (max-width:480px) {

	.monthly-yearly {

		.payment-card-view-button {
			.monthly-selected {
				margin-bottom: 10px !important;
				width: 130px !important;
			}
			.btn-payment-method {
				margin-bottom: 10px !important;
				width: 130px !important;
			}
			.save-18 {
				width: 70px;
				bottom: 22px !important;
				width: 75px !important;
				right: -14px !important;
			}
		}
	}
	.annual-cost {
		.plan-type {
			font-size: 12px !important;
		}
	}
}


