//
// Dashboard Styles
// -------------------------------------------------
.rotate-left {
  transform: rotate(90deg);
}
.rotate-right {
  transform: rotate(-90deg);
}
.reduce-padding {
  padding: 5px 0px 10px 10px;
}
.head-color {
  color: #555555;
}

.dashboard {
  padding-right: 10px;
  .card {
    flex: 1 0 calc(50% - 40px);
    flex-basis: calc(50% - 40px);
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
    margin: 10px;
    height: 460px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;

    &.grade-book-card {
      flex: 1 0 calc(100% - 40px);
      height: 510px;
    }

    &.extendHeight {
      height: 560px;
    }

    .title-link {
      text-decoration: none;
      font-weight: bold;
    }
    .empty-state {
      min-height: 300px;
      margin: 0;
      background-position: center center;
      .empty-title {
        font-weight: bold;
        margin: 0 40px;
        padding-top: 70px;
        padding-bottom: 40px;
        font-size: 14px;
        &.budget {
          padding-top: 112px;
        }
        span {
          display: block;
        }
      }
      &.agenda {
        background-image: url(../images/empty-states/dashboard-agenda.svg);
      }
      &.collaborate {
        background-image: url(../images/empty-states/dashboard-chat.svg);
      }
      &.todo {
        background-image: url(../images/empty-states/dashboard-todolist.svg);
      }
    }
  }
  @media (max-width: 768px) {
    .card {
      flex-basis: calc(100% - 40px);
    }
  }

  .card-title {
    padding: 15px;
    background-color: #f3f3f3;
    cursor: move;
  }
  .card-body {
    height: 405px;
    width: 100%;
    overflow: hidden;
  }
  .agenda-title {
    padding: 10px 20px;
    .chevron:hover {
      fill: $primary-light-color;
    }
  }
  .agenda-desc {
    max-width: 150px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.complete {
      color: $brand-success;
    }
    &.onHold {
      color: $brand-danger;
    }
    &.inProgress {
      color: $status-yellow;
    }
    &.notStarted {
      color: $darker-gray;
    }
  }

  .agenda-status {
    text-transform: capitalize;
  }

  .agenda-details {
    // border-collapse: collapse;
    padding: 10px 20px;
    .person {
      padding: 10px 0;
    }

    td {
      width: 1000px;
    }

    .agenda-assignment-status {
      height: 10px;
      width: 10px;
      margin-bottom: 6px;
      margin-right: 7px;
    }
  }
  .appointment-icon {
    width: 47px;
    height: 47px;
    fill: #555555;
  }
  .load-more-link {
    @extend .text-center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    display: block;
  }
  .chart-img {
    padding: 20px 0;
  }
  .conversation {
    position: relative;
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid $gray;
    color: inherit;
    text-decoration: none;

    .avatar {
      min-width: 20px;
      min-height: 20px;
      border-radius: 5%;
    }

    .conversation-info {
      min-width: 450px;
    }
  }
  .scroller {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    //padding-bottom: 70px;

    &::-webkit-scrollbar {
      width: 0.5em;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  .conversation-info {
    flex: 1 1 calc(100% - 60px);
    flex-basis: calc(100% - 60px);
  }
  .conversation-meta {
    line-height: 1;
    position: relative;

    > *:not(:last-of-type) {
      padding-right: 20px;
    }

    .new-message-count {
      @extend .round;
      @extend .text-center;
      //position: absolute;
      //right: 0;
      width: 20px;
      height: 20px;
      padding-top: 4px;
      font-size: 12px;
      line-height: 1;
      background-color: $primary-color;
      color: #fff;
      margin-left: 10px;
      display: inline-block;
    }
    time {
      font-size: 14px;
    }
    .bold-text-new-message {
      font-weight: 600;
    }
  }
  .conversation-message {
    max-width: 260px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .new-conversation-message {
    color: $primary-color;
  }
  .todo-add,
  .todo-list-add {
    .form-control {
      margin-right: 10px;
    }
    .btn {
      padding: 8px 18px;
    }
  }
  .todo-add {
    padding: 20px;
  }
  .todo-list-add {
    padding: 0px 37px 30px 42px;
  }

  .todo-edit {
    padding: 0 38px 20px;
  }
  .todo-item {
    .complete {
      text-decoration: line-through;
    }
    .incomplete {
      text-decoration: none;
    }
    // margin-bottom: 15px;
    padding: 8px 41px;
    font-size: 14px;
    line-height: 1;
    input[type="checkbox"] {
      margin: 0 10px 0 0;
    }
    .item-desc {
      max-width: 380px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .action {
      display: inline-block;
      font-size: 0;
      margin-left: 20px;
      .icon {
        fill: $secondary-font-color;
      }
    }
    .action + .action {
      margin-left: 10px;
    }
  }
  .budget-section {
    @extend .text-center;
    flex: 1 1 50%;
    padding: 20px;
    .section-title {
      display: inline-block;
      padding: 10px 30px;
      border: 1px solid $gray;
      border-radius: 4px;
    }
    /*        .budget-chart {
					max-height: 140px;
					max-width: 200px;
				}*/
  }
  /*    .chart {
			margin: 20px 0;
		}*/
  /*    .legend {
			@extend .list-unstyled;
			text-align: left;
			line-height: 1;
			&.small {
				display: inline-block;
				width: 49%;
			}
		}
		.legend-item {
			margin-bottom: 5px;
		}
		.legend-color {
			@extend .round;
			display: inline-block;
			margin-right: 10px;
			width: 10px;
			height: 10px;
		}*/
  .dndDragging {
    opacity: 0.7;
  }
  .dndDraggingSource {
    display: none;
  }
  .dndPlaceholder {
    background-color: #ccc;
    color: white;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    border: 1px;
    border-color: $primary-light-color;
    font-size: 40px;
  }
}

.dashboard-quote-card {
  border-radius: 4px;
  background-color: lighten($gray, 5);
  margin-bottom: 30px;
  margin-right: 30px;
  padding: 10px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
  position: relative;

  .quote {
    text-align: center;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(45deg);
    opacity: 1 !important;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
.agenda-card {
  margin: 0 10px;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $gray;

  .person {
    width: 50px;
    min-width: 50px;
  }
}

.chore-category {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: rgba(30, 102, 130, 0.05);
  margin-right: 15px;
  overflow: hidden;
  padding: 12px;
  .category-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.agenda-bottom-button {
  flex-direction: column;
  // height: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
}
.load-more-div {
  justify-content: center;
  display: flex;
  padding: 25px;
}
.agenda-item {
  margin-left: 2px;

  .person {
    padding: 0;
  }
  &.default {
    min-width: auto;
  }
  &.two-third {
    flex-grow: 2;
  }
}

.time-color {
  color: $primary-color;
}

.agenda-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.agenda-content-row1 {
  margin-left: 20px;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  flex: 1;
}
.agenda-content-row2 {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.lesson-list {
  padding: 15px 19px 12px 19px;
  width: 100%;

  &__item {
    border-bottom: 1px solid $gray;
    padding: 10px 0px;
    &:first-child {
      padding: 0 0 10px 0;
    }
    .duration-label {
      color: $secondary-font-color;
    }
  }

  &__item-header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: start;

    .lessons-label {
      font-weight: 300;
    }

    .subject-name h4 {
      font-size: 18px;
      text-transform: capitalize;
      width: 100%;
      max-width: 420px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .chore-header {
      margin-bottom: 10px;
      h4 {
        font-size: 14px;
        text-transform: capitalize;
        width: 100%;
        max-width: 420px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        color: #323132;
      }

      .chore-subtitle {
        white-space: nowrap;
        color: #bdbdbd;
        font-weight: 400;
        font-size: 12px;
      }
      .status-container {
        font-size: 12px;
        display: flex;
        padding: 2px 0px;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #bdbdbd;
        border-radius: 50px;
        width: 110px;
        margin-top: 2px;
      }
    }

    .assign-btn {
      margin: 0 10px;
      .btn.btn-sm {
        padding: 3px 6px;
      }
    }

    .more-options {
      margin-top: 5px;
      &-icon {
        width: 30px;
        height: 30px;
        transform: rotate(90deg);
        transition: fill 0.3s ease-in-out;
        border: 1px solid $darker-gray;
        padding: 5px;
        fill: $darker-gray;
        border-radius: 4px;
      }

      .dropdown-menu {
        .icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        a.menu-item {
          display: flex;
        }
      }
    }

    .lesson-duration {
      min-width: 340px;
    }
  }

  .student-details {
    .duration-label {
      min-width: 130px;
    }
    .inline-list {
      list-style: none;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;
      margin: 0;

      li {
        margin-right: 20px;
        margin-bottom: 10px;
        max-width: 200px;
        display: flex;

        .name {
          max-width: 150px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .avatar {
        @extend .round;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 5%;
      }
    }
  }

  .lesson-sharing {
    border-top: 1px solid $gray;
  }
}

.underline {
  border: none;
  // transition: none !important;
  border-bottom: 2px solid #3eb149;
  box-shadow: none !important;
  font-weight: bold;
  font-size: 20px;
}

.underline-todo {
  border: none;
  border-bottom: 2px solid #3eb149;
  font-size: 20px;
}

.underline-todo:focus {
  border: none;
  border-bottom: 2px solid #3eb149;
}

.todo-category-title {
  font-size: 20px;
}

.left-category {
  top: -31px;
  left: -66px;
}

.assignment-header-category {
  text-transform: capitalize;
}

.assignment-title-underline {
  position: relative;
  padding-bottom: 3px;
}
.assignment-title {
  font-size: 24px;
}

.assignment-title-underline:before {
  left: 0px !important;
}

.checkbox input[type="checkbox"] + span:before {
  //margin-top: 1.5px !important;
  border-radius: 50%;
  border-width: 1px !important;
}

input[type="checkbox"]:checked + span:after {
  border-width: 1.5px !important;
}

.todo-checkbox input[type="checkbox"] + span:before {
  padding: 9px;
  width: 20px;
  height: 20px;
  margin-top: 0px;
}

.todo-checkbox input[type="checkbox"]:checked + span:after {
  width: 10px;
  height: 6px;
  top: 6.6px;
  left: 5px;
}

.item-desc {
  margin-top: 1.5px;
  max-width: 380px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.todo-desc {
  font-size: 14px;
}
.todo-bullet {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}
.style {
  margin-left: 70px;
}

.hide {
  display: none;
}
.show {
  display: block;
}
.see-more {
  font-size: 14px !important;
  text-align: center;
}
.see-chore-more {
  font-size: 14px !important;
  position: absolute;
  left: 50%;
  bottom: -25px;
  background-color: white;
  padding: 7px;
  border: 1px solid #e9eaeb;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  .icon {
    width: 14px;
    height: 14px;
    fill: #555;
  }
  .rotate-up {
    transform: rotate(180deg);
  }
}
.btn-todo {
  padding: 1px 3px;
  min-width: 80px;
  text-transform: none;
}
.padding-2 {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.round-input-padding {
  border-radius: 20px;
  padding: 10px !important;
}

.close-icon {
  opacity: 1 !important;
  .icon {
    width: 20px !important;
    height: 20px !important;
  }
}

.todo_list_item_round {
  // border-radius: 15px;
}

.add-remove-margin {
  margin-left: -55px;
}

.overdue-card {
  .total-assignment {
    color: #555;
    font-family: Poppins;
    font-size: 13px;
    line-height: 18px;
    margin-left: 5px;

    span {
      color: #106682;
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .stu-view-dropdown {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 30px;
    width: 90px;
    padding: auto;
    font-size: 13px;
  }
  .dropdown-menu {
    max-height: 60vh;
    .menu-item {
      width: 200px;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 120px;
      }
    }
  }
  .status-details {
    font-weight: normal;
    font-size: 10px;
    width: 150px;

    .status-dropdown {
      color: #555;
      font-size: 13px;
      line-height: 18px;

      .status-label {
        color: #555;
        font-size: 13px;
        line-height: 18px;
        text-transform: none;
        font-weight: normal;
      }
    }

    .dropdown-menu {
      border-radius: 10px;
      box-shadow: 3px 5px 4px 0 #e2e5e8, -3px 0 4px 0 #e2e5e8;

      .reschedule-status-list-all {
        padding-left: 5px;
        min-width: 160px;

        .status {
          margin-right: 20px;
        }
        .text-capitalize {
          color: #555;
          font-size: 13.5px;
          line-height: 18px;
          font-weight: normal;
        }
      }
    }
  }
  .row-item {
    margin-left: 10px;
    margin-top: 8px;

    .date-input {
      width: 130px;
      border-radius: 25px;
      border: 1px solid #ccc;
      padding: -2px;
    }

    .btn.btn-primary {
      height: 34px;
      padding: 3px 10px;
      font-size: 13px;
      text-transform: none;
      font-weight: 500;
    }
  }
  .count-selected {
    padding-top: 0px;
    padding-left: 15px;
    color: #999;
    font-size: 13px;
    line-height: 18px;
  }
  hr {
    width: 100%;
    height: 1px;
    background-color: #e9eaeb;
    border: none;
    outline: none;
  }

  .assignment-row {
    width: calc(100% - 50px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: #555;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      width: 180px;
    }
  }
  .assignment-name {
    width: 270px;
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;

    .assignment-detail {
      width: 240px;
    }
  }
  .assignment-date {
    margin-top: -5px;
    margin-left: auto;
    margin-right: 10px;
    text-align: left;
    width: 120px;

    p {
      color: #555;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .assignment-detail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #555;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    width: 180px;
  }
  .date-input {
    ::placeholder {
      color: #555;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #555;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #555;
    }
  }

  .overdue-checkbox {
    color: #555;
    display: flex;
    align-items: center;
    margin-top: 7px;
    cursor: pointer;
    label {
      margin-left: 7px;
      font-size: 13px;
      line-height: 18px;
    }
    input[type="checkbox"] {
      position: relative;
      width: 15px;
      height: 15px;
      color: #555;
      border: 1px solid #cacaca;
      background: white;
      border-radius: 4px;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
      &::before {
        position: absolute;
        content: "";
        display: block;
        top: 1.4px;
        left: 4.4px;
        width: 4px;
        height: 8px;
        border-style: solid;
        border-color: #fff;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
        opacity: 0;
      }
      &:checked {
        color: #fff;
        border-color: #116682;
        background: #116682;
        &::before {
          opacity: 1;
        }
        ~ label::before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }
}

.gradebook-card {
  .total-assignment {
    color: #555;
    font-family: Poppins;
    font-size: 13px;
    line-height: 18px;
    margin-left: 5px;

    span {
      color: #106682;
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .stu-view-dropdown {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 30px;
    width: 90px;
    padding: auto;
    font-size: 13px;
    .icon {
      position: absolute;
      top: 8px;
      right: 28px;
    }
  }
  .dropdown-menu {
    max-height: 340px !important;
    width: 380px;
    right: 15px;
    border-radius: 10px;
    .date-menu {
      padding: 10px 20px;
      margin-top: 10px;
      .form-group {
        display: flex;
        align-items: flex-end;
        .date-from {
          min-width: 0px;
          display: flex;
          flex-direction: column;
          padding-right: 20px;
          position: relative;
          .icon-calender {
            position: absolute;
            bottom: 10px;
            right: 32px;
          }
          label {
            padding-bottom: 10px;
          }
          .form-control {
            border-radius: 25px;
            border: 1px solid #ccc;
          }
        }
        .clear-filter {
          margin-bottom: 7px;
        }
      }
    }

    .inner-dropdown {
      .panel-title {
        font-size: 14px;
      }
      .list-unstyled {
        .filter-item.menu-item {
          padding: 10px 30px;
          padding-left: 40px;
          border-top: 1px solid #e8e7e7;
          .tick-margin.icon {
            width: 12px;
          }
        }
      }
      .grade-inner-dropdown {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 12px 20px;
        color: #555;
        position: relative;
        &.active {
          background-color: #f1f2f5;
          color: #555;
        }
        .icon {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 20px;
          margin-top: -2px;
        }
      }
    }

    .menu-item {
      width: 100%;
      color: #555;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 120px;
      }
    }
  }
  hr {
    width: 100%;
    height: 1px;
    background-color: #e9eaeb;
    border: none;
    outline: none;
  }
  .assignment-name {
    width: 220px;
    margin-right: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .assignment-date {
    margin-top: -5px;
    margin-left: auto;
    margin-right: 10px;
    text-align: left;
    width: 130px;

    p {
      color: #555;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .assignment-detail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #555;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    width: 180px;
  }
  .chatbox-options {
    padding: 5px 15px;
    margin-right: 15px;
    margin-left: auto;
    border-radius: 10px;
    background-color: #eef0f4;
  }
}

.gradebook-modal {
  .heading {
    font-size: 16px;
  }
  .hr-divider {
    margin-bottom: 10px;
  }
  .tab-content {
    line-height: 30px;
    font-size: 14px;
    color: #555;
    margin-left: -1%;
    margin-right: -1%;
  }
  .modal-input {
    border: none;
    border: solid 1px #ccc;
    border-radius: 25px;
    outline: none;
    height: 35px;
    color: #555;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .btn {
    height: 35px;
    border-radius: 25px;
  }
}

@media (min-width: 2100px) {
  .assignment-name {
    width: 530px !important;
    .assignment-detail {
      width: 510px !important;
    }
  }
}

@media (min-width: 1700px) and (max-width: 2099px) {
  .assignment-name {
    width: 370px !important;
    .assignment-detail {
      width: 350px !important;
    }
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .assignment-name {
    width: 200px !important;
    .assignment-detail {
      width: 180px !important;
    }
  }
}

@media (min-width: 1199px) and (max-width: 1299px) {
  .assignment-name {
    width: 160px !important;
    .assignment-detail {
      width: 140px !important;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1310px) {
  .header-weather {
    left: 30% !important;
  }
}
@media (min-width: 1121px) and (max-width: 1200px) {
  .header-weather {
    left: 24% !important;
  }
}

@media (min-width: 991px) and (max-width: 1120px) {
  .header-weather {
    left: 24% !important;
    width: auto !important;
  }
}

@media (max-width: 990px) {
  .header-weather {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .header-weather {
    display: none !important;
  }
  .main-header {
    padding: 16px 10px;
    position: fixed;
  }
  .sidenav-logo {
    width: 130px;
  }
  .agenda-card {
    display: inline;
    .person {
      width: 100% !important;
      min-width: 50px;
      align-items: center;
      flex-direction: column;
    }
    .agenda-content-row1 {
      margin-left: 0px;
      flex-direction: column;
      text-align: center;
      .text-right {
        text-align: center !important;
      }
    }
    .agenda-content-row2 {
      margin: 5px auto;
    }
  }
  .dashboard .conversation-message {
    width: 152px !important;
  }
  .collaborate-search {
    padding: 5px !important;
    .user-location {
      display: none;
    }
  }
}

.center-div-width {
  width: 150px;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 350px;
    width: auto;
    margin: 30px auto;
  }
  .modal-content {
    padding: 30px 15px 20px 15px;
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .center-div-width {
    width: 250px;
  }

  .add-remove-margin {
    margin-left: 0px;
  }

  .dashboard .card {
    flex: 1 0 calc(100% - 40px);
    flex-basis: calc(100% - 40px);
  }
  .page {
    &.sidebar-is-close {
      .dashboard .card {
        flex: 1 0 calc(50% - 40px);
        flex-basis: calc(50% - 40px);
      }
    }
  }
  .dashboard .agenda-desc {
    max-width: 250px;
  }
}

@media (max-width: 1280px) {
  .center-div-width {
    width: 110px !important;
  }
  .dashboard .agenda-desc {
    max-width: 110px !important;
  }
}
@media (max-width: 1580px) {
  .card-body {
    .chart {
      .chart-pie {
        width: 593px !important;
        height: auto !important;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 1360px) {
  .center-div-width {
    width: 150px;
  }
  .dashboard .agenda-desc {
    max-width: 150px;
  }
}

.hello {
  color: white;
}

.text-align-tooltip-p {
  //text-align: left;
  font-size: 11px;
}

.info-view-modal {
  margin: -5px -40px;
}
.question-icon {
  position: absolute;
  right: 30px;
  top: 10px;
  .calendar-icon {
    fill: $primary-color;
  }
}

.info-modal-title {
  margin-bottom: 10px;
}
.info-modal {
  height: 80vh;
  overflow-y: auto;
  .text-align-tooltip-h {
    text-align: left;
    margin: 5px 0px;
  }
  .modal-content {
    padding: 40px !important;
  }
  p {
    font-size: 15px;
  }
  li {
    font-size: 15px;
  }
  h4 {
    //text-align: center;
    margin: 10px 0px !important;
  }
  .ul-heading {
    margin-top: 5px;
  }
  .ul {
    margin-top: 0px;
  }
  .inner-div {
    display: flex;
  }
  span {
    margin-top: 5px;
    margin-right: 2px;
    color: $primary-color;
  }
  .icon-question-color {
    width: 30px;
    height: 30px;
    fill: $primary-color;
  }
  .tooltip-inner {
    max-width: 500px;
  }
  .tooltip-lessonplan {
    .tooltip-inner {
      max-width: 1000px !important;
    }
  }
  .ul-circle {
    list-style-type: circle;
  }
  .ul-square {
    list-style-type: square;
  }
}

.load-more-divv {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

// @media (min-width: 1500px) {
// 	.chart.chart-pie{
// 		width: 668px !important;
// 		height: 334px !important;
// 		position: relative;
//     left: calc(18% - 56px);
// 	}
// }
// @media (min-width: 1440px) and (max-width: 1920px) {
// 	.chart.chart-pie{
// 		//width: 668px !important;
// 		//height: 270px !important;
// 		position: relative;
//     	//left: calc(18% - 56px);
// 	}
// 	.footer-box{
// 		bottom: 2rem !important;
// 		//bottom: calc(100% - 100px) !important;
// 	}
// }
@media (min-width: 1921px) and (max-width: 2300px) {
  .chart.chart-pie {
    bottom: 5rem !important;
    width: 700px !important;
    height: 350px !important;
    left: calc(18% - 56px);
  }
  .footer-box {
    bottom: 7rem !important;
    //bottom: calc(100% - 100px) !important;
  }
}

@media (min-width: 1801px) and (max-width: 1920px) {
  .chart.chart-pie {
    bottom: 6.5rem !important;
  }
  .footer-box {
    bottom: 9.5rem !important;
  }
}

@media (min-width: 1601px) and (max-width: 1800px) {
  .chart.chart-pie {
    bottom: 6rem !important;
    // width: 500px !important;
    // height: 250px !important;
    //left: calc(18% - 56px);
  }
  .footer-box {
    bottom: 6rem !important;
    //bottom: calc(100% - 100px) !important;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .chart.chart-pie {
    // width: 668px !important;
    // height: 270px !important;
    bottom: 4rem !important;
    // padding-right: 100px;
    //left: calc(18% - 56px);
  }
  .footer-box {
    bottom: 4rem !important;
    //bottom: calc(100% - 100px) !important;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  .chart.chart-pie {
    bottom: 2rem !important;
  }
  .footer-box {
    bottom: 1rem !important;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .chart.chart-pie {
    bottom: 1rem !important;
  }
  .footer-box {
    bottom: -1rem !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .chart.chart-pie {
    bottom: 0 !important;
  }
  .footer-box {
    bottom: 0 !important;
  }
}

@media (min-width: 800px) and (max-width: 1023px) {
  .chart.chart-pie {
    bottom: 2rem !important;
  }
  .footer-box {
    bottom: 4rem !important;
  }
}

.trail-info-modal {
  height: auto;
  overflow-y: hidden;
  background-image: url(../images/trail-info-model-bg.png);
  img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    margin-top: 10px;
  }
  h4 {
    font-size: 20px;
    color: #116682;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .buttons-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;

    .btn {
      width: 300px;
      border-radius: 25px;
      margin-bottom: 10px;
    }

    .btn-close {
      background-color: #d4d4d4;
      border: 1px solid #d4d4d4;
      color: #847f7f;
    }
  }
}
.footer-box {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 30px;
  padding-right: 10px;
}
.date-view {
  display: flex;
  align-items: center;
  border: 1px solid #116682;
  width: 190px;
  height: 40px;
  background-color: #116682;
  border-radius: 20px;
  padding: 4px;
  padding-left: 8px;
}
.budget-heading {
  position: relative;
  left: 15px;
  width: fit-content;
  //background-color: #f3f3f3;
  border-radius: 3px;
}
.selectStyle {
  position: relative;
  top: -70px;
}
.percentage {
  width: 30px;
  height: 6px;
  border-radius: 5px;
}

// @media (max-width: 769px) {
// 	.chart.chart-pie{
// 		width: 668px !important;
// 		height: 334px !important;
// 	}
//}

.lesson-content-top {
  width: calc(100% - 280px);
  margin-left: 15px;
}

.create-tutorial-btn {
  color: #fff;
  border: 1px solid #555;
  background-color: #555;
  font-weight: 500 !important;
  border-radius: 25px !important;
  margin-right: 20px;
  width: 190px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  &:hover {
    background-color: #fff;
    color: #555;
    .icon {
      fill: #555;
    }
  }
  .icon {
    height: 24px;
    width: 24px;
    margin-right: 6px;
    fill: #fff;
  }
}

.widget-list:hover {
  background-color: #f5f5f5;
}
.empty-widget-list {
  height: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.add-new-task {
  padding-top: 5px;
  font-weight: bold;
}

.pos-rel {
  position: relative;
}

.border-bottom-none {
  border-bottom: none !important;
}

.todo-student {
  margin: 0 10px;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9eaeb;
}

.todo-student:last-child {
  border-bottom: none !important;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.space-between-student {
  padding-right: 10px;
}
