//
// Helper classes
// --------------------------------------------------
.round {
    border-radius: 50%;
}

.list-unstyled {
    list-style: none;
    padding-left: 0;
}

.unstyled {
    text-decoration: none;
    color: $primary-font-color;
    &:hover,
    &:focus {
        color: darken($primary-font-color, 10);
    }
}

a.unstyled {
    @extend .unstyled;
}

.mr-5 {
    margin-right: 10px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.ml-5 {
    margin-left: 10px !important;
}

.mb-5 {
    margin-bottom: 10px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

[ng-click],
[ngf-select],
[ngf-drop],
[datepicker],
[ui-sref],
select {
    cursor: pointer;
}

input[type='text'][ng-click] {
    cursor: text;
}

// ngf- {
//     cursor: pointer;
// }
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.text-word-break {
    overflow-wrap: break-word;
}

.inherit-height {
    height: inherit;
}

@each $i in (0, 5, 10, 15, 20, 25, 30, 40, 50) {
    .padding-#{$i} {
        padding: #{$i}px;
    }
    .padding-top-bottom-#{$i} {
        padding-top: #{$i}px;
        padding-bottom: #{$i}px;
    }
    .padding-left-right-#{$i} {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
    }
    .padding-top-#{$i} {
        padding-top: #{$i}px;
    }
    .padding-bottom-#{$i} {
        padding-bottom: #{$i}px;
    }
    .padding-left-#{$i} {
        padding-left: #{$i}px;
    }
    .padding-right-#{$i} {
        padding-right: #{$i}px;
    }
    .margin-#{$i} {
        margin: #{$i}px;
    }
    .margin-top-bottom-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
    }
    .margin-left-right-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
    }
    .margin-top-#{$i} {
        margin-top: #{$i}px;
    }
    .margin-bottom-#{$i} {
        margin-bottom: #{$i}px;
    }
    .margin-left-#{$i} {
        margin-left: #{$i}px;
    }
    .margin-right-#{$i} {
        margin-right: #{$i}px;
    }
}

.or-divider {
    hr {
        flex: auto;
        height: 1px;
        margin: 0;
        border-color: transparent;
        border-top: 1px solid $gray;
    }
    span {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.share-social-icons {
    .icon {
        width: 50px;
        height: 50px;
        margin: 0px 20px;
    }
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

.position-fixed {
    position: fixed;
}

.icon.icon-gif {
    width: 28px;
    height: 28px;
}

.cursor-default {
    cursor: default;
}