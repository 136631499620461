.auto-complete-place-wrapper {
  position: relative;

  .autocomplete-dropdown-container {
    width: 100%;
    z-index: 10;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    .suggestion {
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #ccc;
      padding: 4px;
      width: 100%;
      z-index: 100;

      .locIcon {
        font-size: 15px;
        margin: 0px 8px;
        color: #999999;
        width: 5%;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        width: 85%;
        text-overflow: ellipsis;
      }
    }
  }
}
