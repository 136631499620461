.hsp-color-picker {
  border: 1px solid;
  width: 100%;
  height: 40px;
  .color-circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}
