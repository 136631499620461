// Budget Styles
// -------------------------------------------------
.budget-details {
  width: calc(100% - 260px);
  .charts {
    width: 100%;
  }
  .add-icon {
    height: 20px;
    width: 20px;
  }
  .amount-fields {
    .form-group.title {
      flex: 1 1 auto;
      & + .form-group {
        margin-left: 15px;
      }
      color: $secondary-font-color;
    }
  }
  .empty-state-plan-budget {
    min-height: 440px;
    background-image: url(../images/empty-states/budget-plan.svg);
    background-position: center center;
    background-repeat: no-repeat;
    .empty-title {
      font-size: 14px;
      text-align: center;
      padding-top: 190px;
    }
  }
  .total-field {
    padding: 8px 15px;
    border-radius: 2px;
    margin-bottom: 20px;
    height: 40px;
    background-color: #daf3fb;
    .add-expense {
      color: #064052;
      .icon {
        fill: $primary-color;
      }
    }
    strong {
      color: #064052;
    }
    span {
      color: #064052;
    }
  }
  .action-container {
    @extend .text-right;
  }
  .transaction-form {
    .amount-input {
      width: 160px;
    }
    .add-image {
      width: 160px;
      margin-bottom: 15px;
    }
  }
  .transaction-summary {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .transactions-list {
    @extend .list-unstyled;
    font-size: 14px;
    overflow: hidden;
    .empty-state {
      min-height: 300px !important;
      background-image: url(../images/empty-states/budget-expense.svg);
      background-position: center center;
      background-repeat: no-repeat;
      .empty-title {
        font-size: 14px;
        text-align: center;
        padding-top: 190px;
      }
    }
    li.transaction {
      display: table;
      width: 100%;
      border-bottom: 1px solid $gray;
    }
    .transaction-view {
      width: 100%;
      td {
        width: 1000px;
      }
      .end {
        @extend .text-right;
        width: 13%;
      }
      .date {
        padding: 10px 0;
        width: 15%;
      }
      .category {
        padding: 10px 0;
        width: 28%;
      }
      .amount {
        padding: 10px 0;
        width: 14%;
      }
      .student {
        padding-left: 10px;
      }
      .action {
        display: inline-block;
        margin-left: 15px;
        width: 12%;
      }
      .note {
        padding: 10px 0;
      }
    }
  }
  .transaction-edit {
    width: 100%;
    padding: 10px 0;
    .category,
    .student {
      width: 35%;
    }
    .end {
      @extend .text-right;
      padding-bottom: 20px;
      width: 20%;
    }
    .amount-input {
      width: 100px;
    }
    .note {
      width: 60%;
    }
    .add-image {
      padding-left: 40px;
      padding-top: 8px;
      vertical-align: sub;
    }
  }
  .tab {
    //margin-top: 10px;
    font-size: 16px;
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
    z-index: 200;
  }
  a.primary {
    font-size: 14px;
    font-weight: bold;
    &,
    &:hover,
    &:active,
    &:focus {
      color: $primary-light-color;
      text-decoration: none;
    }
  }
  .add-category {
    border-bottom: 1px solid $gray;
  }

  .form-group.edit-category {
    overflow: inherit;
    .dropdown-menu {
      width: 100%;
      border-radius: 0;
      border: 1px solid #888;
      li {
        padding: 5px 10px;
        &.active {
          background: rgba($gray, 0.5);
        }
      }
      a {
        @extend .unstyled;
        font-size: 14px;
        align-content: center;
        vertical-align: center;
      }
    }
  }
}

budget-graph {
  width: 100%;
  height: 100%;
  .graph-title {
    padding: 10px 20px;
  }
  .chevron:hover {
    fill: $primary-light-color;
  }
  .chart {
    margin: 0 auto;
  }
  .legend {
    @extend .list-unstyled;
    text-align: left;
    line-height: 1;
    flex-wrap: wrap;
    &.small {
      display: inline-block;
      width: 49%;
    }
  }
  .legend-item {
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .legend-title {
    width: 120px;
    height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legend-color {
    @extend .round;
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
  }
  .empty-state {
    min-height: 300px !important;
    margin: 0;
    background-position: center center;
    background-image: url(../images/empty-states/dashboard-budget.svg);
    .empty-title {
      font-weight: bold;
      margin: 0 40px;
      padding-bottom: 40px;
      font-size: 14px;
      padding-top: 112px;
      span {
        display: block;
      }
    }
  }
}
.budget-list {
  padding: 0px !important;
  .custom-search {
    background-color: #eae8e8;
  }
  .icon-custom-width {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 768px) {
  .transaction-view tr {
    display: grid !important;
  }
  .add-budget-res {
    display: flex;
    flex-direction: column !important;
  }
  .budget-details .total-field {
    padding: 0px !important;
  }
}

.popup-description {
  margin-left: -50px;
  margin-right: -50px;
  margin-top: 30px;
}

.header-tab-cont {
  border-bottom: 1px solid $primary-color;
  //margin-bottom: 30px;
  margin-top: -43px;
  margin-left: -31px;
  margin-right: -30px;
  .header-tab {
    padding-left: 0;
    width: 100%;
    list-style: none;
    margin-bottom: 0px;
    margin-top: 0px;
    .active {
      background-color: $primary-color;
      color: #fff;
    }
    li {
      width: 50%;
      display: inline-block;
      a {
        text-align: center;
        display: block;
        color: $primary-color;
        padding: 29px 0px 17px 0px;
        font-size: 20px;
      }
    }
  }
}

.show-expenses {
  justify-content: flex-end !important;
}
.budget-plan-button {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  .btn {
    text-transform: unset;
    font-size: 15px;
    font-weight: 500;
    padding: 7px 40px;
  }
}

.add-category {
  display: inline-block;
  margin-top: 3px;
  .add-category-icon {
    fill: #116682;
    width: 24px;
    height: 24px;
  }
  .remove-category-icon {
    fill: #919498;
    width: 24px;
    height: 24px;
  }
}

.show-icon {
  display: inline-block;
}

.add-col-margin {
  margin-left: -15px;
  margin-right: -15px;
}

.dollar-view {
  .btn {
    .btn-sm {
      padding: 5px 15px !important;
    }
  }
}

.show-budget {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 15px;
  .show-table-view {
    padding: 15px;
    .top-view {
      width: 100%;
      background-color: #eee;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 20px;
    }
    .show-table {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
      background-color: #eee;
      border-collapse: collapse;
      tr {
        th {
          border-bottom: 1px solid #9f9f9f;
          border-right: 1px solid #9f9f9f;
          padding: 5px 5px 5px 5px;
          text-align: center;
        }
        td {
          border-right: 1px solid #9f9f9f;
          text-align: center;
          color: #9f9f9f;
        }
      }
    }
  }
  .in-row {
    justify-content: space-between;
    padding: 10px 15px;
    p {
      font-size: 16px !important;
      text-align: center;
    }
    .theme-color {
      color: $primary-color;
      font-size: 18px;
    }
    .light-text {
      color: #9f9f9f;
      font-size: 15px;
      margin-left: 10px;
      margin-top: 5px;
    }
    .budget-amount-text {
      color: #555555;
      font-size: 18px !important;
      font-weight: 500 !important;
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .box-budget {
    height: 165px;
    background: #eeeeee;
    border-radius: 0;
    margin: 15px 0px;
    border-radius: 10px;
    .edit-icon {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px 0px 0px;
      .icon {
        fill: $primary-color;
      }
    }
    .top-text {
      width: 100%;
      justify-content: flex-end;
      padding-right: 35px;
    }
    .theme-color-card {
      color: $primary-color;
      font-size: 16px;
      text-align: right;
      margin-right: 5px;
    }
    .light-text-card {
      color: #9f9f9f;
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
    }
    .html5 {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.reduce-padding {
  padding: 3px 12px !important;
}

.col-padding-top {
  padding-top: 20px !important;
}

.search-input-width {
  width: 400px;
}

.input-div {
  width: calc(100% - 180px);
  align-items: center;
  display: flex;
  justify-content: center;
}

.select-box-size {
  height: 30px;
  margin-top: 10px;
}

.select-budget-box-size {
  height: 30px;
}

.edit-button-view {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-color-div {
  display: flex;
  align-items: center;
  width: 50%;
  margin-left: 25%;
  padding: 10px 0px;
  justify-content: space-between;
  .color-div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  span {
    margin-left: 10px;
    font-size: 14px;
  }
}

.name-div {
  padding: 10px 0px;
  span {
    padding: 0px 20px;
    color: #116682;
    font-size: 16px;
  }
}

.empty-fields {
  color: red !important;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .page {
    .category-row {
      .col-lg-4 {
        width: 50%;
      }
    }
    &.sidebar-is-close {
      .category-row {
        .col-lg-4 {
          width: 33.3333333333%;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .page {
    &.sidebar-is-close {
      .category-row {
        .col-lg-4 {
          width: 50%;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &.sidebar-is-close {
      .category-row {
        .col-lg-4 {
          width: 100%;
        }
      }
    }
  }
}

.category-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.graphic-canvas {
  .chart.chart-pie {
    margin-left: auto;
    margin-right: auto;
    max-width: 668px !important;
    max-height: 334px !important;
  }
}
.uib-typeahead-match > a {
  text-decoration: none;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #116682;
  outline: 0;
  outline-color: initial;
  outline-style: initial;
  outline-width: 0px;
}
