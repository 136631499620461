//
// Common users sidebar
// --------------------------------------------------

.page-sidebar {
	@extend .list-unstyled;
	background-color: #ffffff;
	border-right: 1px solid #f5f2f5;
	min-width: 260px;
	max-width: 260px;
	box-shadow: 1px 0px 15px 1px rgba(245, 242, 245, 1);
	float: left;
	// height: 100vh;

	.add-icon {
		width: 35px;
		height: 35px;
		margin-right: 10px;
		fill: $primary-color;
	}

	.page-sidebar-item {
		position: relative;
		padding: 6px 10px;
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid $light-gray;
		transition: background-color 0.2s ease;
		&.active {
			background-color: $light-gray;
		}
		.user-status {
			left: 52px;
		}
		.student-color-dot {
			position: absolute;
			right: 10px;
			top: 30px;
		}
		.user-name {
			max-width: 150px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: $primary-color;
		}
		.create-group {
			background-color: $white !important;
		}
	}
	.avatar {
		margin-right: 10px;
		border-radius: 5%;
	}
	.budget-icon-container {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		margin-right: 10px;
		border: solid 2px $primary-light-color;
		padding: 10px;

		.budget-icon {
			height: 24px;
			fill: $primary-light-color;
		}
	}
}
.dashboard-messenger-avatar {
	min-width: 50px !important;
	min-height: 50px !important;
}

@media (max-width: 768px) {
	.lesson-list {
		flex-direction: column;
	}
	.page-sidebar {
		min-width: 100%;
		max-width: 100%;
		display: block;
	}
}
