.admin-verify-users-table {
    font-weight: 700;
    padding: 15px;
    background-color: #f6f7f7;
}

.admin-dashboard {
    margin-bottom: 20px;
}

.top-highlight {
  margin-bottom: 20px;
}

.admin-users-table-data {
    padding: 5px 0px;
    border-bottom: 1px solid #f6f7f7;
    margin-bottom: 20px;
}

.admin-users-table-detail {
    max-width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.align-center {
    text-align: center;
}

.component-border-remove {
    border-radius: 0px;
}

.admin-users-table-pages {
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.user-list-data {
    margin-bottom: 20px;
}

.admin-cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.admin-card {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #fafafa;
  }

.admin-cards__item {
    display: flex;
    padding: 1rem;
    @media(min-width: 40rem) {
      width: 50%;
    }
    @media(min-width: 56rem) {
      width: 33.3333%;
    }
  }

  .admin-card__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
  }

  .admin-card__title {
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .admin-card__text {
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.5;
    // margin-bottom: 1.25rem;
  }

  .sicon1 {
    position: relative;
    height: 21px;
    padding: 0px;
    top: 5px;
    width: 25px;
    fill: $primary-color;
}

  .astext {
    background: none;
    border: none;
    font-size: x-small;
    color: grey;
    margin-right: 30px;
  }

  .report-table {
    border: 1px solid #333;
    color: #333;
    background: #FAFAFA;

    td, th {  
      border: 1px solid transparent; /* No more visible border */
      height: 30px; 
      transition: all 0.3s;  /* Simple transition for hover effect */
    }
    th {  
      background: #DFDFDF;  /* Darken header a bit */
      font-weight: bold;
    }
    td {  
      background: #FAFAFA;
      text-align: center;
    }

    .table-devider {
      th, td {
        border-top: 1pt solid black;
      }
    }
  }

.admin-affiliate-table-data {
  padding: 5px 0px;
  border-bottom: 1px solid #f6f7f7;
  margin-bottom: 20px;
  font-size: 12px;
}
  
.admin-affiliate-disapprove{
  background-color: rgb(187, 51, 51);
  border: none;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  border-radius: 15px;
  color: white;
  align-self: center;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background-color: rgb(211, 51, 51);
  }
}
  
.admin-affiliate-approve{
  background-color: #116682;
  border: none;
  height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 3px;
  font-weight: bold;
  border-radius: 15px;
  color: white;
  align-self: center;
  text-transform: uppercase;

  &:hover {
    background-color: #247c99;
  }
}