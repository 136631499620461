.customColorContainer {
  border: 1px solid #e0e0e0;
  padding: 4px 8px;
  position: relative;
  width: 100%;
  border-radius: 4px;
  &:focus {
    border-color: #29798f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(17 102 130 / 20%);
    border: 1px solid #29798f;
    border-radius: 4px;
  }
  &:hover {
    border: 1px solid #29798f;
    border-radius: 4px;
  }
  .color-picker-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .picker-show-up {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 5000;
    border-radius: 4px;
    height: 20px;
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.is-active {
      height: 150px;
      opacity: 1;
    }
  }
  .downOutlined {
    svg {
      fill: #c3c3c3;
      width: 12px;
    }
  }
  .customcolorpicker {
    background-color: #fff;
    box-shadow: 1px 1px 5px 1px #e2e5e8;
    height: 235px;
    width: 250px;
    border-radius: 4px;
    .color-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 7px;
      padding: 10px;
      .size {
        transform: scale(1.2);
      }
      .color {
        border: 0;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin: 2px 5px;
        align-self: center;
        justify-self: center;
        &:hover {
          cursor: pointer;
          transform: scale(1.2);
          transition-duration: 0.2s;
          box-shadow: 1px 1px 5px 1px #e2e5e8;
        }
      }
    }
    .remove-container {
      margin: 0 auto;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border: 1px solid #e0e0e0;
      border-radius: 7px;
      width: 150px;
      padding: 5px;
      &:hover {
        cursor: pointer;
        border-color: @primary-color;
      }
    }
  }
}
