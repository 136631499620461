//
// Icons
// --------------------------------------------------

.icon {
	display: inline-block;
	width: 16px;
	height: 16px;
}

.icon-small {
	display: inline-block;
	width: 12px;
	height: 12px;
}

.icon-medium{
	display: inline-block;
	width: 16px;
	height: 16px;	
}