//
// Toast
// --------------------------------------------------

#toast {
	position: fixed;
	top: -100px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 100000;
	display: inline-flex;
	padding: 12px;
	line-height: 1;
	opacity: 0;
	color: $white;
	font-weight: 500;
	border-radius: 4px;
	background-color: $primary-color;
	box-shadow: 0 0 8px transparentize($primary-color, 0.6);
	transition: top 0.6s ease-out, opacity 0.4s ease-out;
	&.success {
		top: 60px;
		display: none;
	}
	&.error {
		background-color: $brand-danger;
		color: $primary-color;
		box-shadow: 0 0 8px transparentize($brand-danger, 0.6);
		display: none;
	}
	&.showing {
		top: 80px;
		opacity: 1 !important;
		display: inline-flex !important;
	}
	&.budget-error {
		top: 80px;
		// left: 44%;
		display: none;
	}
}

#nointernet-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	display: none;
	z-index: 18;
	&.showing {
		display: flex;
		justify-content: center;
		align-content: center;
	}
}

#nointernet-toast {
	position: relative;
	top: -20px;
	height: 40px;
	z-index: 2000;
	display: inline-flex;
	padding: 12px;
	line-height: 1;
	opacity: 0;
	color: #fff;
	border-radius: 4px;
	background-color: $primary-color;
	box-shadow: 0 0 8px transparentize($primary-color, 0.6);
	transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out;

	a {
		font-weight: bold;
		color: #fff;
	}

	&.error {
		background-color: $brand-danger;
		box-shadow: 0 0 8px transparentize($brand-danger, 0.6);
	}
	&.showing {
		top: 80px;
		opacity: 1;
	}
}
