.gb_container {
  .gb_header {
    min-height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-left: 30px;
    padding-right: 20px;
    border-bottom: 1px solid #dddddd;
    color: #555;
    // font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }

  .gb_form_container {
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #e8e7e7;
    // background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: absolute;
    z-index: 10000;
    z-index: 10000px;
    .gb_sections {
      display: absolute;
      z-index: 10000;
      z-index: 10000px;
      padding: 10px;
      border-radius: 5px;
    }
    .gradeBookDisplay {
      // background-color: #f1f2f5;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 10px;
    }
  }
  .switchContainer {
    display: flex;
    justify-content: space-between;
    margin: 30px 30px !important;
    .switchText {
      font-size: 14px;
      margin-right: 8px;
      color: #555;
      font-family: Poppins;
      font-weight: 500;
    }
    .perform_action_btn{
      height: 42px;
    }
  }
}
.labelContainer {
  margin-bottom: 5px;
}

.gradeLabel {
  width: 18%;
  padding-left: 10px;
}

.gradeLabel_last {
  width: auto;
  padding-left: 10px;
}
.inputField {
  width: 18% !important;
}

.code-box-demo .ant-switch {
  margin-bottom: 8px;
}

.ant-select-selection-item {
  text-align: left;
}
.ant-form-item-explain-error {
  text-align: center !important;
  font-size: 11px;
  margin-top: 2px;
}

.model_radio {
  color: #f8ad15;
  font-size: 12px;
  margin: 5px 0 0 2px;
}

.model_grades {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .edit_row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .modal_grade {
      padding: 10px;

      border: 1px solid gray;
      margin-right: 15px;
    }

    .modal_percentage {
      padding: 10px;
      border: 1px solid gray;
    }
  }
}
.acton_radio {
  padding: 20px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.assignmentTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #888;
  max-width: 11vw !important;
  word-break:keep-all;
  font-style: italic;
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  ul,
  li,
  ol {
    font-size: 14px !important;
  }
}

.model_grading_section {
  display: flex;
  flex-direction: row;
}

.model_percentage {
  margin-left: 30px;
  padding: 4px 10px;
  border: 1px solid grey;
  font-weight: 500;
}

.singular_common {
  display: flex;
  justify-content: center;
  padding: 3px 0px;
  border: 1px solid lightgrey;
  width: 60px !important;
  text-align: center !important;
  border-radius: 22px;
  background: #f1f1f1;
}

.clear_button {
  width: 30px;
  height: 30px;
}

.percentage_input {
  width: 196px;
}
.grades_input {
  width: 197px;
}
.score_type_select {
  min-width: 160px;
  width: 160px !important;
  text-align: left;
  border-right: 0;
}

.submit_icon {
  width: 30px !important;
  margin-left: auto;
}

.score_displays {
  display: flex;
  justify-content: center;
}

.singular_obtained {
  width: 90px;
  text-align: center;
}

.bulkButton {
  background-color: #126582;
  color: white;
  height: auto;
  width: auto;
  padding: 9px 22px;
  font-size: 15px;
  text-transform: uppercase;

  &:hover {
    border-color: #054d67;
    color: white !important;
    background-color: #2380a1;
  }
}

.input_grade_row {
  width: 50% !important;
  padding: 5px 0px 5px 5px !important;
}

.date_row {
  font-size: 12px;
  white-space: nowrap;
  color: gray;
}
.avatar_text {
  font-size: 16px;
  font-weight: 700;
}

.subject_row {
  font-size: 13px;
  word-break:keep-all;
}
.percentage_row {
  white-space: nowrap;
  font-size: 11px;
  margin-top: 4px;
  margin-left: 8px !important;
}

.save_grade_row {
  font-size: 20px;
}

.table_spinner {
  text-align: center !important;
}
.modal_spinner {
  text-align: center;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.modal_radio {
  margin-top: 5px;
}

#gradebook-beta {
  background-color: white;
  .ant-checkbox-inner {
    border: 1px solid #555;
  }

  .ant-ribbon.ant-ribbon-placement-start {
    left: 22px;
    border-bottom-left-radius: 0;
    top: 0px;
  }
}

.scroll-stop {
  overflow: hidden;
}

.ant-tooltip, .ant-notification, .ant-dropdown, .ant-modal-wrap{
  z-index: 9999;
}

.ant-tour-mask{
  height: 1000vh !important;
  width: 1000vw !important;
  z-index: 1000 !important;
}

.ant-tour{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}