img-crop {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    background: #fff;
    min-width: 500px;
    min-height: 350px;
    canvas {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        outline: none;
    }
}

.img-upload-with-crop-modal {
    .img-upload-with-crop-modal-container {
        margin-top: 30px;
    }
    .crop-area {
        background: #ffffff;
        overflow: hidden;
        width: 500px;
        height: 350px;
        margin-bottom: 30px;
    }
    .cropped-image {
        overflow: hidden;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        margin: 85px 160px;
        margin-bottom: 115px;
        img {
            width: 180px;
        }
    }
}