.report-page {
  .empty-transcript {
    // max-width: 760px;
    min-height: 500px;
    // margin: 50px auto;
    background-image: url(../images/empty-states/Attendence.svg); //background-image: url(/images/empty-states/Transcript.svg);
    // background-position: center center;
    // background-repeat: no-repeat;
    /* background-size: 338px 763px; */
    // color:rgb(235, 235, 222);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .empty-title {
      font-size: 25px;
      color: $default-font-color;
      margin: 0 10px 40px;
    }
  }

  .empty-grade {
    min-height: 500px;
    background-image: url(../images/empty-states/Attendence.svg); //background-image: url(/images/empty-states/Grade.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .empty-title {
      font-size: 25px;
      color: $default-font-color;
      margin: 0 10px 40px;
    }
  }

  .empty-attendence {
    min-height: 500px;
    background-image: url(../images/empty-states/Attendence.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .empty-title {
      font-size: 25px;
      color: $default-font-color;
      margin: 0 10px 185px;
    }
  }

  .empty-portfolio {
    min-height: 500px;
    background-image: url(../images/empty-states/Attendence.svg); //background-image: url(/images/empty-states/Portfolio.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .empty-title {
      font-size: 25px;
      color: $default-font-color;
      margin: 0 10px 180px;
    }
  }

  .details-section {
    background: $dark-gray;
    border-radius: 4px;

    input {
      z-index: 0;
      background: #fff;
    }
  }

  .loading-results {
    min-height: 50vh;
  }

  .result-block {
    .fixedHeaderClass {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: #fff;
      padding: 10px 30px;
      margin-left: $sidebar-width;
      box-shadow: 0px 2px 3px 0 rgba(200, 201, 203, 0.7);
    }

    .report-list {
      margin-top: 30px;
      margin-bottom: 60px;
    }

    .student-details {
      background: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 4px;
      padding: 10px 30px;
      color: #ffffff;

      .name {
        font-size: 18px;
        margin-left: 20px;
        min-width: 346px;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
      }
    }
  }
}

.grade-result {
  .lesson-detail {
    background-color: #e9eaeb;
    text-transform: capitalize;
  }
  .lesson-list {
    margin-bottom: 30px;
    margin-top: 30px;
    border: 1px solid $gray;
    box-shadow: unset;
    border-radius: 15px;
    .title-border {
      border-bottom: 1px solid $gray;
    }
    .lesson-detail,
    .assignment-list {
      border-radius: 4px;
      border-bottom: 1px solid $gray !important;
      padding: 10px 20px;
      .hr {
        border-bottom: 1px solid $gray;
        margin-top: 12px;
      }
      .control-label {
        font-size: 16px;
        color: #4e4e4e;
      }
      .span-margin {
        margin-left: 50px;
      }
    }
    .assignment-list:last-child {
      border-bottom: 0px solid $gray !important;
    }
  }
  .no-border {
    border: unset;
    box-shadow: unset;
  }
}

.attendance-result {
  .report-list {
    border: 1px solid $gray;
    border-radius: 6px;
    .student-details {
      padding: 10px 30px;
      background: none;
      border: none;
      .title-border {
        border-bottom: 1px solid $gray;
      }
    }
    .lesson-list {
      // border-bottom: 1px solid $gray;
      padding: 13px 31px;
      // border: 1px solid $gray;
      .hr {
        border-bottom: 1px solid $gray;
        margin-top: 12px;
        margin-bottom: -8px;
      }
      .control-label {
        font-size: 16px;
      }
    }

    .total-attendance {
      margin-top: 30px;
      margin-bottom: 30px;
      border-radius: 4px;
      padding: 7px 0px;
      padding-bottom: 15px;
      background-color: #e9eaeb;
      border-radius: 9px;
    }
  }
}

.portfolio-result {
  @extend .grade-result;

  .pictures-list {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: table;
      border-collapse: collapse;
      max-width: 100%;
    }
    li {
      display: table-cell;
    }
    img {
      padding: 5px;
      display: block;
      max-width: 100%;
      height: 278px;
      object-fit: cover;
    }
  }
}

.transcript-result {
  h5 {
    font-size: 18px;
  }

  .transcript-section-title {
    border: 1px solid $gray;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
  }

  .add-another-grade {
    border: 1px solid $gray;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;

    .icon.icon-add {
      fill: $primary-light-color;
      height: 24px;
      width: 24px;
      margin-right: 20px;
    }
  }

  .grades-record-result {
    margin-top: 15px;
    padding: 0 50px;
    justify-content: space-around;
    // > *:not(:last-child){
    //     margin-right: 60px;
    // }
  }

  .grades-record-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $gray;

    li {
      display: flex;
      padding-bottom: 15px;

      &.header {
        color: $darker-gray;
        border-bottom: 1px solid $gray;
        margin-bottom: 15px;
      }

      > * {
        display: inline-block;
      }

      .col-6 {
        width: 60%;
      }
      .col-4 {
        width: 40%;
      }
      .col-3 {
        width: 25%;
      }
      .col-2 {
        width: 15%;
      }
      .col-1 {
        width: 10%;
      }
    }
  }

  .transcript-section-container {
    .transcript-section-full {
      border: 1px solid $gray;
      border-radius: 4px;

      .transcript-section-header {
        padding: 15px 20px;
        border-bottom: 1px solid $gray;
      }

      .transcript-section-details {
        padding: 15px 20px;
      }
    }

    .transcript-section-half {
      @extend .transcript-section-full;
      padding: 20px;
      width: calc(50% - 20px);
    }

    .transcript-section-half:first-child {
      margin-right: 20px;
    }

    .transcript-section-half:last-child {
      margin-left: 20px;
    }

    a.primary-link {
      &,
      &:hover,
      &:focus {
        color: $primary-light-color;
      }
    }

    .icon-edit {
      width: 14px;
      height: 14px;
      fill: $primary-light-color;
    }

    p.detail {
      margin-top: 10px;
      display: flex;
      span.label,
      span.value {
        display: inline-block;
      }

      span.label {
        min-width: 140px;
        font-weight: bold;
        color: $darker-gray;
      }

      span.value {
        padding-left: 30px;
        position: relative;
        // z-index: -1;

        &:before {
          content: ':';
          position: absolute;
          left: 0;
        }
      }
    }

    p.note-data {
      min-height: 60px;
    }
  }
}

.report-data-edit-modal {
  .title {
    padding-bottom: 20px;
    border-bottom: 1px solid $gray;
    margin-bottom: 20px;
  }
}

.icon-report {
  width: 25px;
  //margin-right: 10px;
}
.report-page .result-block .report-list {
  padding-left: 5px;
}

@media (max-width: 768px) {
  .report-page {
    text-align: center;
  }
  .select-option {
    width: 100%;
    margin-left: 0px !important;
    max-width: 100% !important;
  }
  .transcript-section-container {
    display: inline !important;
  }
  .transcript-section-half {
    width: calc(100%) !important;
    float: right;
    margin-top: 10px;
  }

  .flex-full-width {
    display: flex;
    max-width: 100% !important;
    padding-right: 0px !important;
  }
  .report-data-edit-modal {
    .control-label {
      width: 30%;
    }
  }
}

@media (max-width: 992px) {
  .select-option {
    width: 100%;
    margin-left: 0px !important;
    max-width: 100% !important;
  }
  .report-page .result-block .student-details .name {
    margin-left: 0px;
    min-width: 188px;
  }
}

.text-in-span {
  display: inline-block;
  width: 160px;
  text-align: left;
}
.color-dark {
  color: #555;
}

.lesson-labels {
  padding-top: 7px;
  padding-bottom: 7px;
  display: inline-block;
}
.lesson-labels-row {
  margin-top: 30px;
  text-align: center;
  background-color: #e9eaeb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.lesson-labels-row-content {
  text-align: center;
  border: 1px solid #e9eaeb;
}

.border-only {
  border: 1px solid #444;
  width: 1px;
  text-align: center;
}
.description-cell {
  border: 1px solid #edeeee;
  padding: 5px;
  text-align: center;
}

.description-cell-portfolio {
  //border: 1px solid #edeeee;
  background-color: #ffffff !important;
  text-align: center;
  padding: 15px;
  font-weight: normal;
  font-size: 16px;
  width: 16%;
  overflow: hidden;
}

.student-details-portfolio {
  padding: 20px 30px !important;
  margin-bottom: 25px;
}

.files-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

// Light Box Image

$maxWidthContent: 900px;

.angular-lightbox {
  &.hidden {
    display: none;
  }

  section {
    position: fixed;
    z-index: 100065;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    > article {
      color: white;

      > header {
        a.close-btn {
          position: absolute;
          top: 20px;
          right: 30px;
          font-size: 3em;
          cursor: pointer;
          color: #ddd;

          &:hover,
          &:focus {
            color: white;
          }
        }
      }

      > content {
        margin: 0 auto;
        height: 50%;
        position: absolute;
        top: 20%;
        left: 0%;
        text-align: center;
        width: 100%;

        > iframe {
          max-width: $maxWidthContent;
          margin: 0 auto;
        }

        /*  @media screen and (min-width: $maxWidthContent) {
                   width: $maxWidthContent;
                 } */
      }
    }
  }
}

.angular-lightbox {
  img {
    display: inline-block;
    /* width: 100%; */
    height: 100% !important;
    width: auto !important;
    vertical-align: middle;
  }
}
