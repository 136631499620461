//
// Forms
// --------------------------------------------------
// Normalize non-controls
//
// Restyle and baseline non-control form elements.
label {
  display: inline-block;
}

// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.
// Override content-box in Normalize (* isn't specific enough)
input[type='search'] {
  @include box-sizing(border-box);
}

input[type='file'] {
  display: block;
}

// Make range inputs behave like textual form controls
input[type='range'] {
  display: block;
  width: 100%;
}

// Fix select element being displayed differantly in differant os
select {
  padding-right: 20px !important;
  background-image: url('../images/caret.png') !important;
  background-repeat: no-repeat;
  background-position: right 5px bottom 6px;
  background-size: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
}

// Make option tag more legible to use as dropdown
option {
  font-size: 15px;
}

// Focus for file, radio, and checkbox
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  @include tab-focus;
}

// Adjust output element
output {
  display: block;
  padding-top: ($padding-base-vertical + 1);
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
}

// Common form controls
//
.form-control {
  display: block;
  height: $input-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  font-family: inherit;
  line-height: $line-height-base;
  color: $primary-font-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style;
  border: 1px solid $input-border;
  border-radius: 2px;
  @include transition(
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s
  );
  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;
  // Placeholder
  @include placeholder;
  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }
  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }
  &.full {
    width: 100%;
  }
  &.medium {
    width: 90%;
  }
}

// Reset height for `textarea`s

/*textarea.form-control {
	height: auto;
}*/

// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.
input[type='search'] {
  -webkit-appearance: none;
}

// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 9.3, iOS doesn't support `week`.
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    &.form-control {
      line-height: $input-height-base;
    }
    &.input-sm,
    .input-group-sm & {
      line-height: $input-height-small;
    }
    &.input-lg,
    .input-group-lg & {
      line-height: $input-height-large;
    }
  }
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.
.form-group {
  margin-bottom: $form-group-margin-bottom;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 2px;
  .raound-inputbox {
    border-radius: 25px;
  }
}

.input-group {
  margin-bottom: $form-group-margin-bottom;
  position: relative;
  border-top-right-radius: 2px;
}

.required-wrapper {
  position: absolute;
  top: 0.5px;
  right: 0.5px;
  overflow: hidden;
  height: 30px;
  width: 30px;
}

.input-group.typeahead-group {
  .dropdown-menu > li {
    @extend .menu-item;
    a {
      text-decoration: none;
      color: $default-font-color;
    }
    &:hover,
    &:focus,
    &.active,
    &:active {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
}

// Static form control text
//
// Apply class to a `p` element to make any string of text align with labels in
// a horizontal form layout.
.form-control-static {
  // Size it appropriately next to real form controls
  padding-top: ($padding-base-vertical + 1);
  padding-bottom: ($padding-base-vertical + 1);
  // Remove default margin from `p`
  margin-bottom: 0;
  min-height: ($line-height-computed + $font-size-base);
  &.input-lg,
  &.input-sm {
    padding-left: 0;
    padding-right: 0;
  }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.
@include input-size(
  '.input-sm',
  $input-height-small,
  $padding-small-vertical,
  $padding-small-horizontal,
  $font-size-small,
  $line-height-small,
  $input-border-radius-small
);
.form-group-sm {
  .form-control {
    height: $input-height-small;
    padding: $padding-small-vertical $padding-small-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    border-radius: $input-border-radius-small;
  }
  select.form-control {
    height: $input-height-small;
    line-height: $input-height-small;
  }
  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }
  .form-control-static {
    height: $input-height-small;
    min-height: ($line-height-computed + $font-size-small);
    padding: ($padding-small-vertical + 1) $padding-small-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
  }
}

@include input-size(
  '.input-lg',
  $input-height-large,
  $padding-large-vertical,
  $padding-large-horizontal,
  $font-size-large,
  $line-height-large,
  $input-border-radius-large
);
.form-group-lg {
  .form-control {
    height: $input-height-large;
    padding: $padding-large-vertical $padding-large-horizontal;
    font-size: $font-size-large;
    line-height: $line-height-large;
    border-radius: $input-border-radius-large;
  }
  select.form-control {
    height: $input-height-large;
    line-height: $input-height-large;
  }
  textarea.form-control,
  select[multiple].form-control {
    height: auto;
  }
  .form-control-static {
    height: $input-height-large;
    min-height: ($line-height-computed + $font-size-large);
    padding: ($padding-large-vertical + 1) $padding-large-horizontal;
    font-size: $font-size-large;
    line-height: $line-height-large;
  }
}

// Date inputs
.date-input {
  position: relative;
  overflow: hidden;
  .form-control {
    position: relative;
    padding-right: 30px;
    background-color: transparent;
    z-index: 2;
  }
  .icon {
    position: absolute;
    position: absolute;
    right: 12px;
    top: 9px;
    fill: $secondary-font-color;
    z-index: 1;
  }
  &.has-success {
    .icon {
      fill: $primary-light-color;
    }
  }
  &.has-error {
    .icon {
      fill: $brand-danger;
    }
  }
}

.datepicker-select {
  padding-left: 0;
  .month,
  .day,
  .year {
    border: 0;
    padding: 6px 10px;
    background-position: right 0px bottom 7px !important;
  }
  .month {
    width: 115px;
    border-right: 1px solid $silver;
  }
  .day {
    width: 65px;
    border-right: 1px solid $silver;
  }
  .year {
    width: 65px;
    flex-grow: 1;
  }
}

.duration {
  .hours {
    border-right: 0;
  }
}

phone-number {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  .phone-number {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
}

.country-code {
  .country-code-label {
    display: block;
    width: 65px;
    flex-basis: 84px;
    background-image: url('../images/caret.png') !important;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 18px;
    color: inherit;
    border-right: 0;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
  }
  .country-menu {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 208px;
  }
}

// Timepicker
.timepicker {
  .form-control {
    padding-left: 4px;
    padding-right: 0px;
    width: 50px;
    font-size: 13px;
  }
  .hour {
    border-radius: 25px 0 0 25px;
    margin-left: 10px;
    padding-left: 8px;
  }
  .minute {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .meridian {
    border-radius: 0 25px 25px 0;
    margin-right: 10px;
  }
}

option:disabled {
  color: #000;
}

// Required indecation on form control
.required-input {
  display: inline-block;
  color: #fff;
  position: absolute;
  top: -6px;
  right: -16px;
  font-size: 24px;
  line-height: 1.7;
  padding-left: 8px;
  background: #9aca40;
  width: 31px;
  height: 20px;
  user-select: none;
  transform: rotate(45deg);
}

// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.
.has-feedback {
  // Enable absolute positioning
  position: relative;
  // Ensure icons don't overlap text
  .form-control {
    padding-right: ($input-height-base * 1.25);
  }
}

// Feedback icon (requires .glyphicon classes)
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2; // Ensure icon is above input groups
  display: block;
  width: $input-height-base;
  height: $input-height-base;
  line-height: $input-height-base;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: $input-height-large;
  height: $input-height-large;
  line-height: $input-height-large;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: $input-height-small;
  height: $input-height-small;
  line-height: $input-height-small;
}

// Feedback states
.has-success {
  .form-control {
    border-color: $primary-light-color;
    box-shadow: 0 0 3px $primary-light-color;
  }
  .required-input {
    background-color: $primary-light-color;
  }
}

.has-error {
  .form-control {
    border-color: $error-red;
    box-shadow: 0 0 3px $error-red;
  }
  .required-input {
    background-color: $error-red;
  }
}

// Reposition feedback icon if input has visible label above
.has-feedback label {
  & ~ .form-control-feedback {
    top: ($line-height-computed + 5); // Height of the `label` and its margin
  }
  &.sr-only ~ .form-control-feedback {
    top: 0;
  }
}

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.
.help-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 10px;
  color: lighten($text-color, 25%); // lighten the text some for contrast
}

.auto-expand {
  resize: none;
  word-break: break-all;
}

.form-label,
.form-normal-label {
  font-weight: bold;
  color: $darker-gray;
}

.form-label {
  font-size: 12px;
  word-break: normal;
}

.form-normal-label {
  font-size: 14px;
  word-break: normal;
}
