.list-container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(172, 172, 172, 0.12);
  border-radius: 15px;
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-header-text {
      font-weight: 600;
      font-size: 26px;
      line-height: 39px;
    }
  }
  .list-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
}

.analytics-card-container {
  position: relative;
  overflow: hidden;
  .analytics-wrapper {
    display: flex;
    align-items: center;

    .analytics-detail {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: normal;
    }
    svg {
      width: 34px;
      height: 36px;
      margin-right: 18px;
    }
  }
  .analytics-text {
    // width: 50%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  .analytics-background {
    position: absolute;
    right: 0;
    top: 15%;
    opacity: 0.05;
    width: 45% !important;
    height: 100px;
    svg {
      width: 100% !important;
      height: 100%;
    }
  }
}
.dropdown-container {
  .ant-dropdown-trigger {
    width: 192px;
    height: 40px;
    .ant-space-align-center {
      width: 100%;
      justify-content: space-between;
      color: #686868;
      .ant-space-item {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.tabs-container {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 8px;
  .ant-radio-group {
    display: flex;
    margin-bottom: 0 !important;
  }
  .ant-radio-button-wrapper {
    width: 133px;
    height: 50px;
    margin-right: 10px;
    border-radius: 10px;
    border-inline-start-width: 0px;
    border: 0px;
    color: #4c4849;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-radio-button-wrapper::before {
    display: none;
  }
  .ant-radio-button-wrapper:hover {
    color: #4c4849;
  }
  .ant-radio-button-wrapper-checked {
    color: #ffffff;
    background: #116682;
    box-shadow: 0px 4px 15px rgba(97, 97, 97, 0.3);
  }
  .ant-radio-button-wrapper-checked:hover {
    color: #ffffff;
  }
}

.chore-card-container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(164, 164, 164, 0.2);
  border-radius: 0px 10px 10px 0px;
  padding: 15px;
  position: relative;
  .left-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
  }
  .inner-container {
    .inner-wrapper {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
      .left-container {
        width: 70%;
        padding: 0 25px;
        .chore-title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 42px;
        }
      }
      .right-container {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .chore-assignee {
          text-align: center;
          .assignee-text {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }
    .chore-card-footer {
      display: flex;
      align-items: center;
      .button-wrapper {
        width: 60%;
        display: flex;
        justify-content: flex-end;
      }
      .time-wrapper {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #bdbdbd;
      }
    }
  }
}

.horizantal-card-container {
  cursor: pointer;
  width: 100;
  position: relative;
  .left-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
  }
  .card-left-container {
    width: 70%;
    display: flex;
    .card-logo-wrapper {
      width: 64px;
      height: 64px;
      border-radius: 50px;
      background-color: rgba(30, 102, 130, 0.05);
      margin-right: 15px;
      overflow: hidden;
      padding: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .card-content-wrapper {
      width: 60%;
      font-family: "Poppins";
      font-style: normal;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .card-title {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        color: #323132;
        margin-bottom: 5px;
      }
      .card-date {
        white-space: nowrap;
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        color: #686868;
        margin-bottom: 5px;
      }
      .repeatation-text {
        white-space: nowrap;
        color: #bdbdbd;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .card-right-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // justify-content: space-between;
    justify-content: space-around;
    font-family: "Poppins";
    font-style: normal;
    .card-time {
      white-space: nowrap;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(17, 102, 130, 0.84);
    }
    .card-assignee {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #686868;
    }
  }
}

.chores-main-container {
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .left-container {
    width: 60%;
  }
  .right-container {
    width: 40%;
  }
}

@media (max-width: 1680px) {
  .horizantal-card-container {
    .card-left-container {
      .card-logo-wrapper {
        width: 60px;
        height: 60px;
        margin-right: 12px;
      }
      .card-content-wrapper {
        .card-title {
          font-size: 18px;
        }
        .card-date {
          font-size: 12px;
          line-height: 18px;
        }
        .repeatation-text {
          font-size: 14px;
        }
      }
    }
    .card-right-container {
      .card-time {
        font-size: 12px;
        line-height: 18px;
      }
      .card-assignee {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .chore-card-container {
    padding: 12px;
    .left-border {
      width: 7.32px;
      border-radius: 0px 15px 15px 0px;
    }
    .inner-container {
      .inner-wrapper {
        margin-bottom: 12px;
        .left-container {
          width: 79%;
          .chore-title {
            font-size: 22px;
            line-height: 33px;
          }
        }
        .right-container {
          width: 21%;
          .chore-assignee {
            .assignee-text {
              font-size: 13px;
              line-height: 20px;
            }
          }
        }
      }
      .chore-card-footer {
        .time-wrapper {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  .tabs-container {
    .ant-radio-button-wrapper {
      width: 102.06px;
      height: 38.37px;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .dropdown-container {
    .ant-dropdown-trigger {
      width: 140.49px;
      height: 29.27px;
      .ant-space-align-center {
        .ant-space-item {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  .analytics-card-container {
    .analytics-wrapper {
      .analytics-icon {
        width: 59.02px;
        height: 59.02px;
      }
      .analytics-detail {
        font-size: 28px;
        line-height: 45px;
      }
    }
    .analytics-text {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .list-container {
    .list-header {
      .list-header-text {
        font-size: 19.0244px;
        line-height: 29px;
      }
    }
    .list-items {
      gap: 18px;
      margin-top: 15px;
    }
  }

  .chores-main-container {
    margin: 8px 6px;
    gap: 15px;
    .left-container {
      width: 55%;
    }
    .right-container {
      width: 45%;
    }
  }
}

.create-chores-heading {
  color: #263238;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-input-container {
  .input-label {
    color: #686868;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: 16px;
    position: relative;
    left: 10px;
    top: 8px;
    z-index: 1;
    background: #fff;
  }
  .ant-input {
    color: #4c4849 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px !important;
  }
  .ant-select-selector {
    height: 44px !important;
    color: #4c4849 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px !important;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-space-item {
      color: #4c4849 !important;
      font-family: Poppins !important;
      font-size: 16px !important;
      font-style: normal !important;
      line-height: 24px !important;
    }
  }
  .chores-date-picker {
    width: 100%;
    height: 44px;
    .ant-picker-input {
      input {
        color: #4c4849 !important;
        font-family: Poppins !important;
        font-size: 16px !important;
        font-style: normal !important;
        line-height: 24px !important;
      }
    }
  }
  .ant-input-number .ant-input-number-input {
    height: 44px !important;
    color: #686868;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.repeat-text {
  color: #686868;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 11.494px;
}

.oc-text {
  color: #c4c4c4;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  line-height: 11.494px;
  white-space: nowrap;
}

.rc-radio {
  accent-color: #1e6682;
  width: 20px;
  height: 20px;
}

.ant-space {
  .ant-space-item {
    display: flex;
    align-items: center;
  }
}

.of-text {
  position: relative;
  left: -11px;
  top: -11px;
  color: #c4c4c4;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  line-height: 11.494px;
}

.chores-cat-select {
  .chores-edit-cat-icon {
    display: none !important;
  }
  .chores-cat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.ant-popover-inner {
  .ant-popover-inner-content {
    display: flex;
  }
}

.chores-main-card-avatar-group {
  span {
    height: 45px !important;
    width: 45px !important;
  }
  .chores-avatar-wrapper {
    span {
      height: 40px !important;
      width: 40px !important;
    }
  }
}
.chores-cat-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chores-cat-btn {
  color: #686868;
  padding: 6px 12px;
  margin-top: 2px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}

.family-member-check {
  display: flex;
  align-items: center;
  color: #686868;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  margin-bottom: 20px;
}
.chore-avatar-wrapper {
  position: relative;
  .ant-avatar-string {
    top: 8px;
  }
  .owner-avatar {
    position: absolute;
    left: 40px;
    top: -4px;
    z-index: 1;
    width: 29px;
    height: 20px;
    text-align: center;
    flex-shrink: 0;
    border-radius: 11.681px;
    border: 2.336px solid #fff;
    background: #7dc56a;
    color: #fff;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.DOM {
  display: flex;
  .OTM {
    white-space: nowrap;
    margin-left: 16px;
    margin-top: 16px;
    color: #c4c4c4;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    line-height: 11.494px;
  }
}

.upload-wrapper {
  .ant-upload-select {
    border: 2px dashed #1e6682 !important;
  }
  .ant-upload {
    width: 125px !important;
    height: 125px !important;
    background-color: rgba(30, 102, 130, 0.06) !important;
    color: #1e6682 !important;
    font-weight: 500 !important;
    border-radius: 20px !important;
    .anticon {
      svg {
        height: 26px !important;
        width: 26px !important;
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #1e6682 !important;
    color: #ffffff !important;
    .chores-cat-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      background-color: white;
      border-radius: 50%;
    }
  }
  .ant-select-item-option {
    .chores-edit-cat-icon {
      display: none !important;
      .chores-cat-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
      }
    }
    &:hover {
      .chores-edit-cat-icon {
        transition: all 0.1s;
        display: inline-block !important;
      }
    }
  }
}

.checkableWeeks {
  padding: 6px !important;
  height: 30px !important;
  width: 30px !important;
  text-align: center !important;
  border-radius: 50% !important;
  background-color: #f3f3f1 !important;
  color: #4c4849 !important;
  &:hover,
  &.ant-tag-checkable-checked {
    background-color: #1e6682 !important;
    color: #ffffff !important;
  }
}
.radio-dot {
  height: 16px;
  width: 16px;
  border: 1px solid #1e6682;
  padding: 2px;
  border-radius: 50%;
  .dot {
    width: 100%;
    height: 100%;
    background-color: #1e6682;
    border-radius: inherit;
  }
}
.chores-reminder-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(30, 102, 130, 0.05);
  border: 1px solid rgba(30, 102, 130, 0.5);
  color: #1e6682;
  font-weight: 500;
  letter-spacing: 1px;
  svg {
    margin: 0 10px;
  }
  &:hover {
    background: rgba(30, 102, 130, 0.1) !important;
    border: 1px solid rgba(30, 102, 130, 0.5) !important;
    color: #1e6682 !important;
  }
}

.category-icon-input {
  width: 100% !important;
  margin: 25px 0 7px 0 !important;
  .ant-space-item:nth-of-type(2) {
    width: 100% !important;
  }
}

.chores-cat-icon-dropdown {
  padding: 3px;
  border-radius: 50%;
  background-color: #f8f8f8;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover {
    .hover-icon-container {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  &.upload-icon {
    background-color: #116682 !important;
    svg {
      fill: #f8f8f8 !important ;
    }
  }
  svg {
    fill: #116682 !important;
  }
  .hover-icon-container {
    display: none !important;
    background-color: #116682;
    padding: 3px !important;
    border-radius: 50% !important;
    position: absolute;
    top: -7px !important;
    left: -3px !important;
    &.close {
      background-color: #ff4152;
      left: auto !important;
      right: -3px;
    }
    svg {
      fill: #f8f8f8 !important ;
      height: 10px !important;
      width: 10px !important;
    }
  }
}

ul.cat-icon-menu {
  max-width: 180px !important;
  max-height: 350px !important;
  padding: 20px 10px !important;
  overflow-y: auto !important;
  .ant-dropdown-menu-item-active {
    background-color: transparent !important;
  }
  .ant-dropdown-menu-item {
    padding: 0px !important;
  }
}

.chores-cat-modal-btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .cancel-btn {
    width: 48%;
    color: #686868 !important;
    font-weight: 500;
    line-height: 17.447px;
    background-color: #f3f3f1;
    border-color: #f3f3f1;
    height: auto;
    span {
      margin: 8px 50px;
    }
    &:hover {
      background-color: #e0e0e0 !important;
      border-color: #e0e0e0 !important;
    }
  }
  .save-btn {
    width: 48%;
    color: #ffffff !important;
    font-weight: 500;
    line-height: 17.447px;
    background-color: #1e6682;
    border-color: #1e6682;
    text-align: center !important;
    height: auto;
    span {
      margin: 8px;
    }
    span.ant-btn-loading-icon {
      margin: 0 !important;
    }
    &:hover {
      background-color: #1a5a74 !important;
      border-color: #1a5a74 !important;
    }
  }
}

.cancel-btn,
.chores-save-btn {
  height: 36px;
  width: 100px;
  span {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: 17.447px;
  }
}
.cancel-btn {
  span {
    color: #686868 !important;
  }
}
.create-chores-upload-list {
  text-align: end;
  direction: rtl;
  .ant-upload-list {
    .ant-upload-list-item-container {
      height: 127px !important;
      width: 125px !important;
      .ant-upload-list-item {
        border-radius: 20px !important;
      }
    }
  }
}

.chores-cat-icon {
  // height: 100%;
  // width: 100%;
  object-fit: cover;
}
// slider css
.slider-container {
  width: 90%;
  display: flex;
  height: 100%;
  .slider-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    height: 100%;
    justify-content: center;
    align-items: center;
    .slider-content-wrapper {
      overflow: hidden;
      width: 78%;
      height: 100%;
      .carousel-content {
        display: flex;
        transition: all 250ms linear;
        -ms-overflow-style: none;
        scrollbar-width: none;
        height: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
        * {
          width: 100%;

          flex-shrink: 0;
          flex-grow: 1;
        }
      }
    }
  }
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
}

.left-arrow {
  left: 12px;
}

.right-arrow {
  right: 12px;
}
.choresMonthname {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
// horizontal-calendar
.horizontal-calendar {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hr-cal-slide-wrapper {
  padding: 8px;
  height: 100%;
  .date-slide {
    border-radius: 15px;
    background: rgba(243, 243, 243, 0.8);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 9px 14px;
    cursor: pointer;

    .hr-cal-date {
      color: #4c4849;
      font-weight: 600;
      text-align: center;
      font-size: 20px;
    }
    .hr-cal-day-name {
      color: #86909c;
      font-weight: 400;
      text-align: center;
      font-size: 14px !important;
    }

    //active
    &.date-slide-active {
      background-color: #116682 !important;
      .hr-cal-date,
      .hr-cal-day-name {
        color: #ffffff !important;
      }
    }
  }
}

.hr-cal-date-picker {
  cursor: pointer;
  border: none;
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  background: rgba(30, 102, 130, 0.06);
  display: flex;
  position: relative;
  &::after {
    content: "5656";
    content: url(../images/calender.png);
    display: inline-block;
    position: absolute;
    left: 23%;
    top: 23%;
  }
  .ant-picker-input {
    display: none !important;
    input {
      display: none !important;
    }
  }
}

// .chores-status-dropdown {
//   display: flex;
//   align-items: center;
// }

.rounded-dropdown {
  .ant-select-selector {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-radius: 50px !important;
    background-color: transparent !important;
    color: #999;

    &:hover,
    &:focus {
      border: 1px solid #9e9e9e !important;
    }
  }
  .ant-select-arrow {
    svg {
      fill: #9e9e9e !important;
    }
  }
}
.chores-status-dropdown-option {
  display: flex;
  align-items: center;
}

.chores-status-dropdown-checkbox {
  .ant-checkbox + span {
    padding-inline-start: unset !important;
    padding-inline-end: unset !important;
  }
}

.chores-manage-tooltip {
  &.ant-tooltip {
    max-width: 380px !important ;
  }
  .ant-tooltip-content {
    .ant-tooltip-inner {
      max-height: 300px !important;
      overflow-y: auto;
      border-radius: 15px !important;
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
}

.avatargroup-tooltip-content {
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.06);
  }
  &.avatargroup-tooltip-content-active {
    background-color: rgb(65, 65, 65);
  }
}

//chores detail
.view-chores-detail-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  .chores-view-category-warpper,
  .view-chores-action-icon-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chores-view-category-warpper {
    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .icon-outline {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    background: rgba(164, 164, 164, 0.1);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.action-icon {
      height: 30px;
      width: 30px;
    }
  }
}

//chores-detail-card
.choresdetail-main-container {
  margin-top: 20px;
  padding: 30px 20px 20px 40px;
  box-shadow: 11px 11px 10px 0px rgba(207, 207, 207, 0.25);
  border-radius: 20px !important;
  border: 1px solid #fbfbfb;

  .chores-detail-container {
    .chore-title {
      color: #8b8d97;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .chore-description {
      color: #4c4849;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.chores-detail-modal {
  .ant-modal-body {
    padding: 0px 15px !important;
  }
}

.chores-detail-filetab {
  margin-top: 50px;
}

.chores-detail-files-tab {
  .ant-tabs-tab-active {
    background: #1e6682 !important;
    color: #ffffff !important;
    padding: 5px 35px !important;
    .ant-tabs-tab-btn {
      color: #ffffff !important;
    }
  }
  .ant-tabs-tabpane-active {
    background: #fbfbfb !important;
    padding: 14px !important;
    min-height: 150px;
    span.ant-avatar {
      height: 100px !important;
      width: 90px !important;
      img {
        object-fit: unset !important;
      }
    }
  }
}

.ant-modal-centered {
  .ant-modal {
    vertical-align: middle !important;
  }
}

.btn-loadmore {
  width: 182px;
  height: 56px;
  display: table;
  margin: auto;
  background-color: rgba(17, 102, 130, 0.08);
  color: #116682;
  border: 1px solid #116682;
  &:hover {
    background-color: #116682;
    color: #fff !important;
    border: 1px solid #116682 !important;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the slide-down animation to newly added elements */
.card-item {
  animation: slideDown 0.6s ease;
}

.chores-avatar-status {
  position: relative;
  cursor: pointer;
  &.progress {
    .chores-avatar-wrapper {
      border: 3px solid #1e6682;
      border-right: 3px solid transparent;
      transform: rotate(45deg);
      .ant-avatar {
        transform: rotate(-45deg) !important;
      }
    }
  }
  &.hold {
    .chores-avatar-wrapper {
      border: 3px solid #ff9136;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      transform: rotate(-45deg);
      .ant-avatar {
        transform: rotate(45deg) !important;
      }
    }
  }
  &.complete {
    .chores-avatar-wrapper {
      border: 3px solid #7dc56a;
    }
  }
  &.not-strated {
    .chores-avatar-wrapper {
      border: 3px solid #999;
    }
  }
  .chores-avatar-wrapper {
    padding-inline-start: 3px;

    border-radius: 50%;
    .ant-avatar {
      margin-inline-start: -3px !important;
      border: 2px solid white !important;
    }
  }
  .assignee-status-icon {
    position: absolute;
    top: -5px;
    right: 5px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50%;
    height: 20px;
    width: 21px;
  }
}

.chores-tootip-first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  .ant-checkbox-wrapper {
    height: auto !important;
  }
}

.ant-select-selector {
  span.ant-select-selection-placeholder {
    line-height: 45px !important;
  }
}

.chores-overlay{
  z-index: 1000 !important;
}