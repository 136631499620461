.title_text1 {
  padding: 18px;
  background-color: #fff;
  font-size: 20px;
  border-radius: 10px;
}

.report_title1 {
  margin: 10px 10px 20px 10px;
  background-color: #fff;
  border-radius: 10px;
}
.parent {
  padding: 30px 15px 30px 15px;
  margin: 10px;
  background-color: rgb(255, 253, 253);
  height: 100%;
  border-radius: 10px;
  min-height: 115px;
}

.r-container {
  background-color: rgb(225, 76, 76);
  padding: 10px;
  margin: 10px;
}

.report-card-form {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 15px 20px;
  min-height: 115px;
}

.report-card-container {
  background-color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;

  .part {
    display: flex;
    padding: 22px;
    width: 100%;
    position: relative;
    .result {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        height: 28px;
        width: 74px;
        color: #555;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
    }

    .print-btn {
      width: 100%;
      position: absolute;
      top: 22px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }

  .title {
    display: flex;
    padding: 15px;
    width: 100%;
    justify-content: center;
    background-color: #b4d0da;
    .rep-icon {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-right: 20px;
    }
    .title-text {
      display: flex;
      align-items: center;
      justify-content: start;
      p {
        height: 56px;
        width: 681px;
        color: #106682;
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 15px;
        line-height: 56px;
      }
    }
  }
  .date-range {
    padding: 22px;
    margin: 30px;
    border: 1px solid gray;
    width: 100%;

    p {
      color: #555;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }
    .first-p {
      color: #555;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
  }

  .acd-records {
    .acd-records-row {
      display: flex;
      width: 100%;
      background-color: #f1f2f5;
    }

    div {
      padding: 10px;
    }

    .first {
      color: #555;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }

    .second {
      color: #555;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }

    .grade {
      width: 25%;
    }

    .year {
      width: 40%;
    }

    .attendance {
      width: 25%;
    }
    .edit {
      width: 10%;
    }
  }

  .my-col {
    font-size: large;
    background-color: aqua;
  }

  .attendance-table-container {
    border: 1px solid black;
    width: 100%;
    margin: 20px 0px 20px 0px;
    padding: 15px;

    text-align: center;
    width: auto;

    .actionBlock {
      display: none;
    }

    .action {
      display: block;
    }

    .ant-table-row {
      position: relative;
    }

    .ant-table-row:hover .actions {
      display: block;
      height: 54px;
      right: 0;
    }

    .table-title {
      background-color: #f1f2f5;
      display: flex;
      padding: 15px;
      margin: 15px;
      border-radius: 5px;

      .first {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        color: #555;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }
      .second {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
      }
    }
  }
}

.hello-test {
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0 !important;
  }
}
