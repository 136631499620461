//
// Student Pages
// -------------------------------------------------

.student-details {
  .tab {
    margin-bottom: 20px;
    border-bottom: 1px solid #e9eaeb;
  }
  .tab-title {
    font-size: 16px;
    // font-weight: bold;
    margin-right: 20px;
    // margin-bottom: 20px;
    // padding-bottom: 20px;
    // border-bottom: 1px solid #e9eaeb;
  }

  .rating {
    color: #a9a9a9;
    margin: 0;
    margin-left: 10px;
    padding: 0;
  }

  ul.rating {
    display: inline-block;
  }

  .rating li {
    list-style-type: none;
    display: inline-block;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .rating .filled {
    color: #ffff1e;
  }

  .cards {
    // margin-top: 25px;
    // display: flex;
    // flex-wrap: wrap;
    // list-style: none;
    // margin: 0;
    padding: 0;
  }

  .book-image {
    height: 170px;
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-right: 20px;
    border-radius: 10px;
    border: 0.5px solid #919498;

    // width: 47%;
    // @media(min-width: 0rem) {
    //     width: 46%;
    // }
    // @media(min-width: 160rem) {
    //     width: 33.3333%;
    // }
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    // border-radius: 5px;
    // border: 2px solid $gray;
    // transition: 0.2s;
  }

  // .card:hover {
  //     box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  // }
  .first {
    border-bottom: 1px solid $gray;
    display: flex;
    height: 195px;
  }
  .first > * {
    margin: 10px;
  }
  .second {
    border: 1px solid black;
    padding: 2px 16px;
    margin-right: 20px;
    margin-bottom: 10px;
    max-width: 100px;
    display: inline-flex;
    .student-image {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
  .left {
    float: left;
    // width:100%;
    // height:95%;
    //   border:1px solid;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      // font-weight: bold;
      color: black;
      font-weight: bold;
      max-width: 900px;
      // // min-width: 300px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // border:1px solid;
    }
  }

  .right {
    display: inline-block;
    //   border:1px solid;
    width: 80%;
    .opt {
      float: right;
      .icon {
        display: inline-block;
        width: 22px;
        height: 22px;
        fill: $primary-color;
      }
    }
    .desc p {
      overflow: hidden;
      line-height: 1.6em;
      height: 3em;
    }
    .more {
      text-decoration: underline;
    }
    .data {
      color: black;
    }
  }
  .action {
    display: inline-block;
    font-size: 0;
    & + .action {
      margin-left: 15px;
    }
  }
  .student-avatar {
    @extend .round;
    width: 160px;
    height: 160px;
    border-radius: 10px !important;
  }
  .property {
    margin-bottom: 10px;
  }
  .property-min-name {
    display: inline-block;
    min-width: 125px;
  }
  .property-name {
    display: inline-block;
    min-width: 160px;
  }
  .property-name:after {
    content: ":";
  }
  .property-title {
    font-size: 16px;
    color: #999;
  }
  .property-value {
    color: $primary-color;
  }
  .property-title-value {
    font-size: 16px;
  }
  // .property-value:before {
  // content: ":";
  // margin-right: 10px;
  // }

  .parent-heading,
  .attendance-heading {
    margin-top: 70px;
  }

  .color-menu {
    margin-top: 10px;
    [uib-dropdown-toggle=""] {
      @extend .text-center;
      display: block;
    }
    .dropdown-menu {
      top: 25px;
    }
  }

  .lesson-add {
    padding-left: 10px;
    // padding-bottom: 15px;
    .icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      fill: $primary-color;
    }
    a {
      display: inline-flex;
      color: inherit;
      text-decoration: none;
    }
  }
  .lesson-data {
    margin-top: 20px;

    tr {
      vertical-align: top;
    }
    td {
      padding: 10px;
      border-bottom: 1px solid $gray;
      &.lesson-duration {
        width: 50%;
        max-width: 50%;

        > p {
          max-width: 350px;
          text-align: center;
        }
      }
    }
  }
  .assign-form {
    .select-lesson {
      min-width: 180px;
      margin-right: 10px;
    }
    .date-input {
      max-width: 140px;
    }
  }
  .assign-action {
    @extend .text-right;
    max-width: 770px;
    margin: 20px 0;
  }
  .loading-state {
    max-width: 760px;
    min-height: 200px;
    margin: 50px auto;
    z-index: 5;
    line-height: 1;
    .spinner {
      margin-right: 10px;
    }
  }
  .edit-rev-buttons {
    color: #116682 !important;
    border: 1px solid #116682;
  }
  .profile-title {
    margin-bottom: 15px;
    border-bottom: 1px solid #e9eaeb;
    padding: 15px 0px;
    padding-left: 20px;
    margin: auto 20px;
  }
}

.add-student {
  .add-student-title {
    font-size: 16px;
    padding-bottom: 15px;
    color: #555;
    border-bottom: 2px solid #e9eaeb;
  }
  .date-picker-pop {
    background: white;
    width: 100%;
    cursor: pointer !important;
  }
  .add-academic {
    border: 1px solid #106682;
    display: flex;
    align-items: center;

    border-radius: 25px;
    padding: 9px 14px;
  }
  .uib-daypicker {
    .uib-left {
      background-image: url(../images/arrow_right.png) !important;
      background-repeat: no-repeat;
      background-position: center;
    }
    .uib-right {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/arrow_left.png) !important;
    }
  }
  .avatar-holder {
    //margin-right: 40px;
    flex: 0 0 150px;
    flex-basis: 150px;
  }
  .student-info {
    flex: 1 1 auto;
    padding-right: 20px;
  }
  .student-avatar {
    @extend .round;
    width: 150px;
    height: 150px;
    border-radius: 20px;
  }
  .upload-avatar {
    @extend .text-center;
    width: 150px;
    margin-top: 10px;
    font-size: 12px;
    color: inherit;
    text-transform: uppercase;
    border-radius: 20px;
  }
  .color-menu {
    margin-top: 10px;
    [uib-dropdown-toggle=""] {
      @extend .text-center;
      display: block;
    }
    .dropdown-menu {
      top: 25px;
    }
  }
  .form-group-half > .form-group {
    flex: 0 0 50%;
    flex-basis: 50%;
    & + .form-group {
      margin-left: 15px;
    }
  }
  .parent-heading,
  .lesson-heading {
    margin-top: 50px;
  }
  .add-lesson {
    padding-left: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      fill: $primary-color;
    }
    a {
      display: inline-flex;
      color: inherit;
      text-decoration: none;
    }
  }
  .sub-label {
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
  }
  .action-container {
    @extend .text-right;
    margin-top: 60px;
    .btn {
      min-width: 120px;
    }
  }
}

.page-with-sidebar.student {
  width: calc(100% - 30px) !important;
  margin: 15px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 1px rgba(196, 196, 196, 1);
  height: calc(100% - 70px);
  overflow: hidden;
  display: flex;
  .page-sidebar {
    height: unset;
  }
  .empty-state {
    max-width: 244px;
    min-height: 370px;
    margin: 0 auto;
    background-image: url(../images/empty-states/budget-user.svg);
    background-size: contain;
    background-position: center center;
    .empty-title {
      font-weight: bold;
      padding-top: 135px;
      font-size: 13px;
    }
  }
}

.color-menu {
  font-size: 14px;
  .student-color-dot {
    margin-right: 20px;
    vertical-align: middle;
  }
  [uib-dropdown-toggle=""] {
    color: inherit;
    line-height: 1;
    vertical-align: middle;
  }
}

.student-color-dot {
  @extend .round;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: $gray;
}

.icon-academic {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  fill: $primary-color;
}

.empty-text-template {
  padding: 10px;
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #f4f4f4;
  p {
    font-size: 14px;
    color: $darker-gray;
    font-weight: bold;
  }
}

.student-login-info {
  padding: 10px 20px 20px 0px;
  .form-group {
    margin-bottom: 0px;
  }
  .form-group-wrapper {
    display: flex;
    //justify-content: space-between;
    align-items: center;
  }
  .edit-permissions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //height: 45px;
    padding: 10px 10px;
    background-color: #daf3fb;
    border-radius: 5px;
  }
  .edit-permissions-control {
    padding: 15px 30px 15px 40px;
  }
  .edit-permissions-title {
    font-size: 13px;
  }

  .edit-permissions-alter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 0px 10px;
    border-radius: 5px;
  }
}

.search-svg {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
}

.searchbox-layout {
  margin: 15px 10px 10px 10px;
}

.edit-margin {
  margin-left: 20px;
  margin-bottom: 5px;
}

.content-view {
  padding: 10px;
  padding-bottom: 70px;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.gender-student {
  max-width: 40% !important;
}

@media (max-width: 768px) {
  .student-details .assign-form .date-input {
    max-width: 100%;
    margin-top: 10px;
    .icon {
      display: inline !important;
    }
  }
  .student-details .assign-form .select-lesson {
    min-width: 100% !important;
  }
  .padding-left-768 {
    padding-left: 20px;
  }
  .margin-reset {
    margin-left: 0px !important;
  }
  .gender-input-div {
    display: grid;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .margin-left-adjust {
    margin-left: -15px !important;
  }
  .academic-days-768 {
    max-width: 60% !important;
  }
  .padding-left-30 {
    padding-left: 0px !important;
    flex-direction: column-reverse !important;
  }
  .margin-top-20 {
    margin-bottom: 20px;
  }
  .student-details .student-avatar {
    margin-left: 24px;
    border-radius: 5px;
    border-radius: 10px !important;
  }
  .student-details .tab-title {
    font-size: 9px;
  }
  .add-student .form-group-half > .form-group + .form-group {
    margin-left: 0px !important;
  }
  .form-group-half {
    display: inline !important;
  }
  .display-flex {
    display: flex !important;
  }
  .gender-student {
    max-width: 100% !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .add-student .avatar-holder {
    text-align: -webkit-right;
  }
  .add-student .action-container {
    margin-top: 60px;
    display: inline-flex;
    margin-left: -20px;
  }
  .course-container .lesson-data {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
  }
  .edit-rev-buttons {
    width: 88px;
    margin-left: 11px;
    margin-bottom: 7px;
  }
  .student-details .card {
    display: inline;
  }

  .page-with-sidebar.student {
    display: block;
  }
}

// .panel-open{
// 	&.icon{
// 		transform: rotate(90deg) !important;
// 	}
// }
.tab-item.active {
  border-radius: 10px 10px 0 0 !important;
  font-weight: unset !important;
  // padding: 8px !important;
}
.tab-item {
  // padding: 8px 10px !important;
}
.list-tab-item {
  display: inline-block;
  padding: 8px !important;
}
.student-book-description {
  width: 100%;
  border: 1px solid #c8c8c8;
  border-radius: 25px;
  box-sizing: border-box;
  height: 35px;
  margin-top: 6px;
}

.file-upload {
  max-width: 130px;
  display: flex;
  align-items: center;
  height: 100%;
}

.padding-top-10 {
  padding-top: 10px;
}

.display-flex {
  display: flex;
}

.flex-width {
  flex: 0.9;
}
.student-course .course-container .panel-title {
  padding-left: 30px !important;
}
.student-course .course-container .lesson-data tr:last-child td {
  padding-left: 0px !important;
}

.new-user-info {
  background-color: unset !important;
  .page-with-sidebar.student {
    background-color: unset !important;
    box-shadow: unset !important;
    .page-sidebar {
      box-shadow: 0 0 2px 0 #e2e5e8;
    }
  }
}

.academic-span-new {
  color: #999;
}

.edit-btn-white {
  background-color: white;
}
.remove-btn-new {
  border-color: #e9eaeb !important;
  color: #919498 !important;
  background-color: white !important;
}
.color-list-new {
  li:last-child {
    a:hover {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .color-row {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    border-top: none;

    .student-color-dot {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      position: relative;
    }

    .no-fill-color-btn {
      color: #116682;
      border: 1px solid #116682;
      background-color: #fff;
      font-weight: 500 !important;
      width: 95%;
      display: flex;
      align-items: center;
      padding: 5px 20px;

      .student-color-dot {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        background-color: #e9eaeb;
      }
    }
  }
}

.action-danger {
  &:hover {
    svg {
      fill: red;
    }
  }
}
