//
// User avatars
// --------------------------------------------------

.avatar {
	@extend .round;
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	&.mini {
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}
	&.micro {
		width: 16px;
		height: 16px;
		border-radius: 1px;
	}
}

.avatars {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	.avatar {
		width: 20px;
		height: 20px;
		margin-right: 5px;
		margin-bottom: 5px;
	}
}

.more-users {
	@extend .round;
	@extend .text-center;
	width: 20px;
	height: 20px;
	padding-top: 2px;
	margin-bottom: 5px;
	font-size: 12px;
	color: #fff;
	background-color: $primary-color;
}

.user-status {
	@extend .round;
	@extend .icon;
	position: absolute;
	left: 40px;
	bottom: 10px;
	background-color: #fff;
}
