.hsp-files {
    margin: 10px;
    width: 160px;
    height: 160px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    outline-color: $darker-gray;
    outline-width: thin;
    outline-style: inset;
    position: relative;
    .view-image {
        position: relative;
        overflow: hidden;
        width: 160px;
        height: 160px;
        cursor: pointer;

        .play-icon{
            position: absolute;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: rgba(50, 50, 50, 0.3);
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            .icon{
                fill: #fff;
                width: 40px;
                height: 40px;
            }
        }
    }
    img {
        min-width: 160px;
        min-height: 160px;
        width: auto;
        height: auto;
    }
    .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        transform: rotate(45deg);
    }
    .view-actionbar,
    .view-upload,
    .view-temp {
        position: absolute;
        padding: 5px;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    }
    .file-name {
        font-size: 12px;
        text-overflow: ellipsis;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
    }
    .view-actionbar {
        height: 30px;
        background: #b4b4b4;
        .dropdown-menu {
            min-width: 120px;
            max-width: 120px;
            box-shadow: none;
            border-radius: 0;
            border: 1px solid #888;
            li {
                padding: 5px 10px;
            }
            a {
                font-size: 10px;
                align-content: center;
                vertical-align: center;
            }
            .icon {
                width: 12px;
                height: 12px;
            }
        }
    }
    .view-temp {
        top: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 10;
        .icon {
            fill: $secondary-font-color;
            width: 24px;
            height: 24px;
        }
        .close-icon:hover .icon,
        .icon:hover
        {
            fill: darken($secondary-font-color, 20);
        }
        .file-name {
            font-weight: bold;
            white-space: normal;
        }
    }
    .view-upload {
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 10;
        .file-name,
        * {
            color: $white;
        }
        .abort-icon {
            position: absolute;
            bottom: 10px;
            .icon {
                fill: $light-gray;
                width: 24px;
                height: 24px;
                transform: rotate(45deg);
                transition: fill 0.3s ease-in-out;
            }
            &:hover .icon {
                fill: lighten($light-gray, 20);
            }
        }
    }
    .file-upload-progress {
        width: 140px;
        height: 4px;
        border-radius: 2px;
        background: $dark-gray;
        margin: 10px 0;
        position: relative;
        .progress-bar {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 4px;
            border-radius: 2px;
            background: $blue;
            transition: all 0.1s ease-in;
        }
    }
}

.files-subsection {
    padding-left: 10px;
    padding-bottom: 4px;
    font-size: 16px;
    border-bottom: 1px solid $gray;
    margin-top: 16px;
}

.assignment-upload {
    width: 160px;
    height: 160px;
    border: 1px dashed $darker-gray;
    color: $darker-gray;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    .icon {
        width: 48px;
        height: 48px;
        margin-bottom: 10px;
        fill: $darker-gray;
    }
}

.assignment-filesdrop {
    @extend .assignment-upload;
    // background: #E0F7FA;
    // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3), 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.file-upload[disabled='disabled']{
    cursor: not-allowed;
}

// .file-view-modal{
// }

.file-view-modal-preview{
    // overflow: hidden;
    max-width: 100%;
    display: flex;
    justify-content: center;
    &, img, object, video{
        //height: 600px;
		max-height: calc(100vh - 200px);
    }
}
.hsp-files .view-actionbar{
	overflow: visible;
}
