.main_container {
  padding: 10px;
  background-color: #f1f2f5;
  //   background-color: #1539a5;
}
.report-card-container {
  .title {
    display: flex;
    // margin: 30px;
    justify-content: center;
    padding: 15px;
    width: 100%;
    background-color: #b4d0da;
    .rep-icon {
      display: flex;
      align-items: center;
      justify-content: end;
      // padding: 25px;
      // width: 25%;
      margin-right: 20px;
    }
    .title-text {
      display: flex;
      align-items: center;
      justify-content: start;
      // width: 75%;
      p {
        height: 56px;
        width: 681px;
        color: #106682;
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 15px;
        line-height: 56px;
      }
    }
  }
}
.title_text {
  padding: 18px;
  background-color: #fff;
  font-size: 20px;
  border-radius: 10px;
}

.report_title {
  margin: 10px 0px 20px 0px;
  background-color: #fff;
  border-radius: 10px;
}

.report_data_container {
  background-color: #fff;
  padding: 18px;
  border-radius: 10px;
}

.attendance-table-container {
  border: 1px solid black;
  width: 100%;
  margin: 20px 0px 20px 0px;
  padding: 15px;
  // color: white;

  .acadYear_info {
    padding: 20px;
    margin-bottom: 40px;
    background-color: #b4d0da !important;
    // color: #fff !important;

    .acad_year_title {
      display: flex;
      padding-left: 5vw;
      // color: #fff !important;
    }

    .name_title {
      display: flex;
      padding-left: 2vw;
      // color: white !important;
    }
  }
}

.report_form {
  background-color: #f1f2f5;
  padding: 20px;
}
.std_details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 10px;
  height: 100%;
  .std_title {
    color: #fff !important;
  }
  .std_year_title {
    color: #fff !important;
  }
  .std_grade {
    font-size: 25px;
    font-weight: bold;
    color: #fff !important;
  }
}

.btn_section {
  margin: auto 0px;

  .uploadButton {
    height: auto;
    margin: 10px;
    padding: 10px 8px;
    /* border: #e0e0ff; */
  }
}
.file_list_section {
  max-width: 70%;
  border-left: 1px solid grey;
  padding: 10px;
  background-color: #ededed;
  .files_list_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1px;
  }
  .file_item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      color: rgb(38, 165, 207);
    }
  }

  .del_icon {
    width: 18%;
    text-align: end;
  }
}

.file_del_icon {
  width: 20%;
  text-align: end;
}

.status {
  background-color: @primary-color;
  // height: px;
}

// very important or else data would not regard it width
.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-word;
}
