//
// Header
// --------------------------------------------------

.main-header {
  position: fixed;
  width: calc(100% - 256px);
  //storm
  //padding: 16px 30px;
  top: 0;
  // background-color: $primary-color;
  // box-shadow: 0px 3px 5px 0 rgba(200, 201, 203, 0.7);
  z-index: 1000;

  .header-weather {
    //display: none !important;
    position: fixed;
    top: 18px;
    text-align: center;
    left: 35%;
    //width: 350px;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 7px 10px;
    border-radius: 20px;
    // margin-right: -175px;
    height: 40px;
    line-height: 40px;
    .calender-text {
      //display: inline-block;
      vertical-align: middle;
      line-height: normal;
      //margin-bottom: 17px;
    }
    .line {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      //margin-bottom: 15px;
      padding: 0px 16px;
      font-size: 19px;
      font-weight: lighter;
    }
    span {
      margin-left: 5px;
    }
    .calender-notify-icon {
      fill: $white;
      width: 22px;
      height: 22px;
      //margin-bottom: 3px;
    }
  }

  // .payment-upgrade-btn:hover {
  // 	color: #FFF !important;
  // 	background-color: rgba(0,0,0,0.2);
  // 	border: 1px solid #FFF;
  // }

  .notification-menu {
    font-size: 0;
  }
  .notification-count {
    @extend .round;
    @extend .text-center;
    position: absolute;
    left: 15px;
    top: -9px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: $primary-color;
    padding-top: 4px;
    background-color: $brand-danger;
  }
  .payment-notification-count {
    @extend .round;
    @extend .text-center;
    position: absolute;
    right: -8px;
    top: -8px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    padding-top: 4px;
    background-color: #cc0000;
  }
  .notification-tab-count {
    @extend .round;
    @extend .text-center;
    width: 15px;
    height: 15px;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: $primary-color;
    padding: 1px 6px;
    margin-left: 4px;
    background-color: $brand-danger;
  }
  .tab-item-notifications {
    padding: 0px 20px !important;
    margin-top: 2px !important;
  }
  .reminder-actions-container {
    display: inline-block;
    height: 10px;
    min-width: 40px;
  }
  .notification-tab-clear {
    @extend .round;
    @extend .text-center;
    width: 20px;
    height: 25px;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    padding: 2px 5px;
    margin-left: 5px;
    position: relative;
    .icon {
      height: 19px;
      width: 18px;
      left: 0.5px;
      top: 0;
      position: absolute;
      fill: $white;
      &:hover {
        fill: $status-red;
      }
    }
  }
  .header-navicon {
    @extend .round;
    padding: 8px;
    background-color: $dark-primary-color;
    font-size: 0;
    position: absolute;
    left: -16px;
    top: 12px;
    transition: left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 9999999;
    .icon {
      fill: #fff;
    }
  }
  .user-info {
    margin-left: 20px;
  }
  .remainder-icon {
    margin-left: 20px;
  }
  .user-avatar {
    @extend .round;
    width: 42px;
    height: 42px;
  }
  .user-location {
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
  }
  .user-info {
    position: relative;
    .menu-item {
      display: flex;
    }
    .menu-item-layout {
      padding: 10px 0px;
    }
    .sidenav-item-bg {
      &:hover {
        background-color: #f5f5f5;
      }
    }
    .icon:not(.status-icon) {
      margin-right: 8px;
    }
    .status-icon {
      @extend .round;
      position: absolute;
      background-color: #fff;
      bottom: -2px;
      right: -2px;
    }
  }
  .status-menu {
    @extend .dropdown-menu;
    display: none;
    top: 40px;
    left: auto;
    right: 100%;
  }
  .status-item:hover .status-menu {
    display: block;
  }
  .notification-icon {
    fill: $white;
    width: 30px;
    height: 30px;
  }
  .calender-notify-icon {
    fill: $white;
    width: 30px;
    height: 30px;
  }
  .requests-menu {
    top: 170%;
    right: -30px;
    padding-top: 6px;
    .no-requests {
      @extend .text-center;
      padding: 10px;
    }
    &:before {
      right: 30px;
    }
  }
}

.header-item {
  display: flex;
  align-items: center;
  padding: 15px;
  font-family: $heading-font;
  text-decoration: none;
  font-weight: bold;
}

.head-navicon {
  @extend .round;
  padding: 8px;
  //background-color: $dark-primary-color;
  font-size: 0;
  position: fixed;
  left: 12px;
  top: 21px;
  transition: left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 1049;
  .icon {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}
.logo_div {
  // background: $primary-color;
  width: 256px;
  position: fixed;
  z-index: 1000;
}
/*
	left: 256px;
	position: fixed;
	right: 0;
	background: white;
	z-index: 16;
	top: 0;
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;

*/

.new-profile-menu {
  min-width: 250px;
  padding: 5px 20px;
  .sub-sidenav-menu {
    width: 200px;
  }
  .status-new-style {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 30%; /* or 100px */
      border-bottom: 2px solid $primary-color;
    }
  }
}

.new-create-menu {
  min-width: 300px;
  padding: 5px 20px;
  li {
  }
  .sub-sidenav-menu {
    width: 200px;
  }
  .status-new-style {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 30%; /* or 100px */
      border-bottom: 2px solid $primary-color;
    }
  }
}

.top-header {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px;
  //background-color: blue;
  width: 100% !important;
  height: 60px;
  left: 0px !important;

  .logo_div {
    position: relative !important;
  }
  .sidenav-head {
    margin-left: 0px !important;
  }
  .right-header {
    .right-header-align {
      border: 1px solid #e2e3e9;
      border-radius: 50%;
      width: 41px;
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: #e2e3e9;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .create-icon-color {
      background-color: #7dc569;
      border: 1px solid #7dc569;
      &:hover {
        background-color: rgb(109, 179, 90);
        border: 1px solid rgb(109, 179, 90);
      }
    }
    .icon-position {
      position: absolute;
      left: 28%;
    }

    .icon-create-option {
      width: 36px;
      height: 36px;
    }

    .list-text-padding {
      padding-left: 10px;
    }

    .create-option {
      .create-text {
        padding-left: 5px;
        .create-text-style {
          color: #116682;
          font-weight: 600;
          font-size: large;
        }
      }

      .list-border-none {
        border-top: none;
      }
      .list-element-style {
        padding-left: 5px;
        display: flex;
        align-items: center;
      }

      .list-subtext {
        display: flex;
        flex-direction: column;
      }
      .list-subtext-style {
        padding-left: 10px;
        font-size: 12px;
        color: #8a8d91;
      }
    }
  }
  .btn-tab-active {
    border: 1px solid #116682 !important;
    border-top: 3px solid #116682 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    //background: #5eddfd;
  }
  .footer-btn {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #999;
    //margin-right: 30px;
    width: 110px;
  }
  .footer-btn-selected {
    color: #116682 !important;
  }
  .header-icon-btn {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    font-size: 16px;
    color: #999;
    //margin-right: 30px;
    width: 110px;
    .icon-top-margin {
      margin-top: 15px;
    }
    .icon-top-border {
      position: absolute;
      bottom: 0px;
      width: 80%;
    }
  }
  .header-icon-div {
    width: 80%;
    height: 100%;
    padding: 4px;
    display: flex;
    justify-content: center;
  }
  .header-icon-div:hover {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 15px;
  }
  // .header-icon-btn-selected {
  // 	fill: $primary-light-color !important;
  // }

  .group-hr-btn {
    margin-block-end: 0 !important;
    border-top: 1px solid #fff;
    border: 1px solid #fff;

    &.active {
      border: 1px solid #116682 !important;
      border-top: 3px solid #116682 !important;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      //background: #5eddfd;
    }
  }
  .margin-left-10 {
    margin-left: 10px !important;
  }
  .icon-size {
    width: 20px;
    height: 20px;
  }
  .centered-div {
    // margin-top: 20px;
    .icon-header-center {
      width: 35px;
      height: 30px;
      align-self: center;
      fill: #65676b;

      &.active {
        fill: $primary-light-color !important;
      }
    }
  }
}

.create-academic-year {
  .academic-title-details {
    margin-bottom: 20px;
    .academic-title {
      font-size: 24px;

      &.academic-title-underline {
        position: relative;
        padding-bottom: 3px;
      }

      &.academic-title-underline:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        /* left: 25%; */
        border-bottom: 2px solid rgb(150, 150, 150);
      }
    }

    .academic-add {
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;
        fill: #116682;
      }
    }
  }

  .academic-year {
    .card-row {
      padding: 0px 10px;
      margin-bottom: 0;
    }

    .academic-add {
      padding-bottom: 10px;
      .icon {
        fill: #116682;
      }
    }

    .icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .card-add {
      padding: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
      border-radius: 10px;
      border: 0.5px solid #919498;
    }

    .icon-cal {
      right: 3px;
      top: 3px;
    }

    .academic-action {
      @extend .text-right;
      max-width: 770px;
      margin-top: 10px;
    }
  }

  .empty-state {
    max-width: 760px;
    min-height: 440px;
    margin: 50px auto;
    background-image: url(../images/empty-states/lesson-planner.svg);
    background-position: center center;
    .empty-title {
      margin: 0 10px 40px;
    }
  }

  .academic-year-list {
    .card {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-bottom: 10px;
      border-radius: 10px;
      border: 0.5px solid #919498;

      .property {
        margin-bottom: 10px;
        margin-right: 10px;

        .property-title {
          color: #999;
          margin-right: 10px;
        }

        .property-img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          border-radius: 5px;
        }
      }
    }
  }
}
